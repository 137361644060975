import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const slideIn = keyframes`
  from { right: -480px; }
  to { right: 0px; }
`;

const slideOut = keyframes`
  from { right: 0px; }
  to { right: -480px; }
`;

export const UploadWrapper = styled.div`
  width: 480px;
  max-width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 201;
  top: 0;
  right: -480px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #1a1a1a;
  box-shadow: 6px 6px 48px #111111;
  border-radius: 24px 0px 0px 24px;
  overflow-x: hidden;
  overflow: scroll;
  overflow-x: auto;
  animation: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  animation-name: ${({ open }) => {
    if (open) return slideIn;
    if (!open) return slideOut;
    return null;
  }};

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 24px;
  background: #222222;
  border-radius: 0px 0px 0px 24px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 12px;
`;

export const Title = styled.span`
  font-weight: bold;
  font-size: 20px;
  text-transform: capitalize;
  color: #ffffff;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 24px;
`;

export const TabItem = styled.button`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  border: 0;
  width: 100%;
  height: 40px;
  box-shadow: none;
  padding: 12px 24px;
  cursor: pointer;
  background: ${({ isActive }) => (isActive ? '#333333' : 'transparent')};
  border-radius: 12px;

  &:hover {
    background: #333333;
    border-radius: 12px;
  }
`;

export const CloseModalIcon = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-right: 12px;
`;

export const ModalContent = styled.div`
  max-height: calc(100% - 75px);
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;

export const Divider = styled.div`
  border: 1px solid #444444;
  transform: rotate(90deg);
  width: 16px;
  height: 0px;
`;
