import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { lighten, darken } from 'polished';

const slideIn = keyframes`
  from { right: -600px; }
  to { right: 0px; }
`;

const slideOut = keyframes`
  from { right: 0px; }
  to { right: -600px; }s
`;

export const RootWrapper = styled.div`
  width: 600px;
  max-width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 201;
  top: 0;
  right: -600px;
  display: flex;
  flex-direction: column;
  background-color: #1a1a1a;
  box-shadow: 6px 6px 48px #111111;
  border-radius: 24px 0px 0px 24px;
  overflow-x: hidden;
  overflow: scroll;
  overflow-x: auto;
  animation: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  animation-name: ${({ open }) => {
    if (open) return slideIn;
    if (!open) return slideOut;
    return null;
  }};

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: #222222;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 28px;
`;

export const ArtworkTile = styled.div`
  display: flex;
  border-radius: 12px;
  margin-bottom: 24px;
`;

export const ArtworkInfo = styled.div`
  display: flex;
  flex-direction: column;
  background: #333333;
  width: 100%;
  padding: 24px;
  gap: 16px;
  border-radius: 0 12px 12px 0;
`;

export const ArtistInfo = styled.dvi`
  display: flex;
  border-radius: 12px;
`;

export const ArtworkTitle = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.015em;
  color: #ffffff;
`;

export const ArtistName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.025em;
`;

export const ArtistAvatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 360px;
  margin-right: 12px;
`;

export const CloseModalIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-right: 20px;
`;

export const ModalContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const ArtworkImageWrapper = styled.div`
  position: relative;
  width: 300px;
  height: 300px;
  text-align: center;
`;

export const ArtworkIconWrapper = styled.div`
  position: absolute;
  left: 30%;
  top: 30%;
  cursor: pointer;
`;

export const ImageIconLabel = styled.label``;

export const EditIcon = styled.img`
  width: 116px;
  height: 118px;
  cursor: pointer;
  opacity: ${({ opacity }) => opacity};
`;

export const InputFile = styled.input`
  display: none;
`;

export const ArtworkImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 12px;
`;

export const ArtworkInfoWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

export const ModalContainer = styled.div``;

export const ModalTitle = styled.h3`
  text-align: center;
  margin: 0 0 20px; 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
`;

export const Divider = styled.div`
  border: 1px solid #444444;
  transform: ${({ rotate }) => rotate || 'rotate(90deg)'};
  width: 16px;
  height: 0px;
`;

export const NavLink = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 12px;
  border-radius: 12px;
  position: relative;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  border: 0;
  background: transparent;
  cursor: pointer;
  ${({ isActive, theme }) =>
    isActive &&
    `  background: ${theme.colors.base4};
  border-radius: 12px;`}

  @media (min-width: 375px) {
    font-size: 12px;
    line-height: 16px;
  }
  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1em;
  }
`;

export const LowerWrapper = styled.div`
  padding: 24px;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const SectionSubHeader = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.025em;
  color: #a5a5a5;
  margin-bottom: 24px;
`;

export const Step = styled.div`
  border-radius: 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-basis: 100%;
  max-width: 100%;
`;

export const Types = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 100%;
  max-width: 100%;
  width: 100%;
`;

export const TypesOne = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 100%;
  max-width: 100%;
  width: 100%;

  .top {
    margin-bottom: 24px;
  }

  @media (min-width: 768px) {
    gap: 24px;
    flex-direction: row;

    > * {
      flex-basis: calc(50% - 12px);
      max-width: calc(50% - 12px);
    }
  }
`;

export const TypeChoice = styled.div`
  background: #333333;
  border-radius: 12px;
  padding: 24px;
  cursor: pointer;
  height: 100%;

  &:hover {
    background: #910048;
  }

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #ffffff;
    margin: 0 0 12px 0;
    text-transform: none;
  }
  .top {
    margin-bottom: 12px;
  }
`;

export const InfoWrapper = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-basis: 100%;
    max-width: 100%;
  }
`;

export const DetailsWrapper = styled.div`
  display: flex;
  gap: 24px;

  @media (min-width: 600px) {
    gap: 48px;
  }
`;

export const SectionHeading = styled.h1`
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.015em;
  color: #a5a5a5;
`;

export const SectionDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.025em;
  color: #ffffff;
`;

export const FiatPrice = styled.span`
  display: inline-block;
  width: auto;
  font-size: 18px;
  background: #64b072;
  font-weight: bold;
  color: white;
  border-radius: 8px;
  padding: 5px 10px;
  margin-bottom: 15px;
`;

export const FlexWrap = styled.div`
  display: flex;

  .wrapper {
    background: #333333;
    border-radius: 12px;
    margin: 24px 0 17px 0;
    padding: 0px;

    img {
      width: 48px;
      height: 48px;
    }
  }
  .open {
    background: #333333;
    border-radius: 12px;
    margin: 33px 0 41px 0;
    padding: 0px;

    img {
      width: 48px;
      height: 48px;
    }

    @media (min-width: 768px) {
      margin: 33px 0 58px 0;
    }
  }
  .number {
    width: 100%;

    @media (min-wdith: 768px) {
      max-width: 25%;
    }
  }
`;

export const ReviewHeading = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #a5a5a5;
  margin-bottom: 24px;
`;

export const ReviewLabel = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 12px;
  display: block;
`;

export const ReviewValue = styled.span`
  display: block;
  margin-bottom: 20px;
  font-size: 18px;
`;

export const PricingReview = styled.div`
  text-align: center;
`;

export const ReviewNumber = styled.span`
  display: block;
  font-size: 50px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
`;

export const VideoPreview = styled.img`
  display: block;
  margin: 0 auto;
  height: 100%;
  max-width: 600px;
  max-height: 600px;
`;

export const WarningMessage = styled.span`
  display: block;
  padding: 10px 20px;
  border-radius: 12px;
  color: ${darken(0.3, '#ffa800')};
  background: ${lighten(0.25, '#ffa800')};
  border: 1px solid #ffa800;
  margin-bottom: 20px;
  text-align: center;
  svg {
    margin-right: 10px;
  }
`;

export const PlayableWrapper = styled.div`
  display: flex;
  height: 80px;
  button {
    display: block;
    flex: 1;
    border: 0;
    cursor: pointer;
    transition: all 0.1s ease-in;
  }
`;

export const PurchaseWrapper = styled.div`
  padding: 24px;
  display: flex;
  text-decoration: none;
  min-height: 98px;
`;

export const CTA = styled.div`
  flex: 1;
  margin-right: 20px;
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #a5a5a5;
    margin-top: 0;
  }
`;

export const Pricing = styled.div`
  flex-basis: calc(100% / 3);
`;

export const Stock = styled.span`
  display: block;
`;

export const Price = styled.span`
  display: block;
`;

export const Visibility = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: #333333;
  z-index: 300;
  border-radius: 0px 0px 24px 0px;
  padding: 12px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
`;

export const TokenList = styled.div`
  margin-bottom: 24px;
  padding: 24px;
  div {
    margin-top: 12px;
  }
`;

export const IngestPlaceholder = styled.div`
  background: ${({ theme }) => theme.colors.lightLighter};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  margin: 12px;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.colors.lightDarker};
`;

export const IngestTitle = styled.span`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.lightDarker};
  margin-bottom: 20px;
`;

export const IngestDescription = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.lightDarker};
`;
