import styled from '@emotion/styled';

export const Wrapper = styled.div``;

export const FlexContainer = styled.div`
  display: flex;
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  flex-direction: ${({ flexDirection }) => flexDirection};

  @media (max-width: 499px) {
    flex-direction: column;
  }

  @media (min-width: 500px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;
  padding: 24px;

  @media (min-width: 1024px) {
    flex-direction: row;

    > * {
      flex-basis: calc(50% - 12px);
      max-width: calc(50% - 12px);
    }
  }
  @media (min-width: 1440px) {
    > * {
      flex-basis: calc(33% - 13px);
      max-width: calc(33% - 13px);
    }
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  padding: 24px;

  > * {
    flex-basis: calc(50% - 12px);
    max-width: calc(50% - 12px);
  }

  @media (min-width: 768px) {
    > * {
      flex-basis: calc(33% - 14px);
      max-width: calc(33% - 14px);
    }
  }
  @media (min-width: 1024px) {
    > * {
      flex-basis: calc(25% - 18px);
      max-width: calc(25% - 18px);
    }
  }
  @media (min-width: 1440px) {
    > * {
      flex-basis: calc(16.6% - 20px);
      max-width: calc(16.6% - 20px);
    }
  }
`;

export const Test = styled.div`
  background: #222222;
  border-radius: 24px;
`;

export const LoadingMore = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;
  padding: 0 24px 24px;

  @media (min-width: 1024px) {
    flex-direction: row;

    > * {
      flex-basis: calc(50% - 12px);
      max-width: calc(50% - 12px);
    }
  }
  @media (min-width: 1440px) {
    > * {
      flex-basis: calc(33% - 13px);
      max-width: calc(33% - 13px);
    }
  }
`;
