import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

// Components
import { ArtCardSkeleton } from '../../Skeletons/ArtCardSkeleton';
import { ArtworkCard } from '../Card';
import { VaultCard } from '../VaultCard';

// Hooks
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver';
import {
  useAllArtworkInfinite,
  useSingleCollectionInfinite,
  useUserOwnedArtwork,
} from '../../../hooks/data';

// Styled
import { Wrapper, FlexContainer, GridItem, LoadingMore } from './styled';

export function InfiniteArtworkGrid({ queryKey, queryParams, limit }) {
  const loadMoreRef = useRef();
  const artwork = useAllArtworkInfinite(queryKey, queryParams, limit);
  const { pathname } = useLocation();

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: artwork.fetchNextPage,
    enabled: artwork.hasNextPage,
  });

  return (
    <Wrapper>
      <FlexContainer padding="12px" paddingLarge="24px">
        {artwork?.data?.pages?.map((page) => (
          <React.Fragment key={`page-${page.nextOffset}`}>
            {page.artwork?.map((art) => (
              <GridItem key={art.id}>
                {pathname === '/vault' ? (
                  <VaultCard
                    artwork={art}
                    collectionId={artwork?.artist?.playlistId}
                    refetch={artwork.refetch}
                  />
                ) : (
                  <ArtworkCard
                    artwork={art}
                    collectionId={artwork?.artist?.playlistId}
                  />
                )}
              </GridItem>
            ))}
          </React.Fragment>
        ))}
      </FlexContainer>
      <LoadingMore ref={loadMoreRef}>
        {(artwork.isFetchingNextPage || artwork.isLoading) && (
          <FlexContainer>
            <ArtCardSkeleton
              margin="12px"
              marginMid="24px"
              flexBasis={1 / 2}
              flexBasisMid={1 / 2}
              flexBasisLarge={1 / 3}
            />
            <ArtCardSkeleton
              margin="12px"
              marginMid="24px"
              flexBasis={1 / 2}
              flexBasisMid={1 / 2}
              flexBasisLarge={1 / 3}
            />
            <ArtCardSkeleton
              margin="12px"
              marginMid="24px"
              flexBasis={1 / 2}
              flexBasisMid={1 / 2}
              flexBasisLarge={1 / 3}
            />
            <ArtCardSkeleton
              margin="12px"
              marginMid="24px"
              flexBasis={1 / 2}
              flexBasisMid={1 / 2}
              flexBasisLarge={1 / 3}
            />
            <ArtCardSkeleton
              margin="12px"
              marginMid="24px"
              flexBasis={1 / 2}
              flexBasisMid={1 / 2}
              flexBasisLarge={1 / 3}
            />
            <ArtCardSkeleton
              margin="12px"
              marginMid="24px"
              flexBasis={1 / 2}
              flexBasisMid={1 / 2}
              flexBasisLarge={1 / 3}
            />
          </FlexContainer>
        )}
        {!artwork.isFetchingNextPage &&
          !artwork.isLoading &&
          !artwork.hasNextPage &&
          artwork.data?.pages.length > 1 && <span>No more artwork...</span>}
      </LoadingMore>
    </Wrapper>
  );
}

InfiniteArtworkGrid.propTypes = {
  queryKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  queryParams: PropTypes.object,
  limit: PropTypes.number,
};

InfiniteArtworkGrid.defaultProps = {
  queryParams: {},
  limit: 12,
};

export function InfiniteSingleCollectionGrid({
  id,
  queryParams,
  limit,
  isOwner,
}) {
  const loadMoreRef = useRef();
  const collection = useSingleCollectionInfinite(id, queryParams, limit);
  const { pathname } = useLocation();

  React.useEffect(() => {
    collection.remove();
    collection.refetch();
  }, [queryParams.order]);

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: collection?.fetchNextPage,
    enabled: collection?.hasNextPage,
  });

  return (
    <Wrapper>
      <FlexContainer padding="12px" paddingLarge="24px">
        {collection?.data?.pages?.map((page) => (
          <React.Fragment key={`page-${page.nextOffset}`}>
            {page?.collection?.artwork?.map((art) => (
              <GridItem key={art.id}>
                {pathname === '/vault' ? (
                  <VaultCard
                    artwork={art}
                    collectionId={id}
                    isOwner={isOwner}
                    isCollectionPlayable={
                      !['USER_UPLOAD', 'NFT_IMPORT'].includes(
                        page?.collection?.playlistType
                      )
                    }
                    refetch={collection.refetch}
                  />
                ) : (
                  <ArtworkCard
                    artwork={art}
                    collectionId={id}
                    isOwner={isOwner}
                    isCollectionPlayable={
                      !['USER_UPLOAD', 'NFT_IMPORT'].includes(
                        page?.collection?.playlistType
                      )
                    }
                  />
                )}
              </GridItem>
            ))}
          </React.Fragment>
        ))}
      </FlexContainer>
      <LoadingMore ref={loadMoreRef}>
        {(collection.isFetchingNextPage || collection.isLoading) && (
          <FlexContainer>
            <ArtCardSkeleton
              margin="0 12px 12px 12px"
              marginMid="0 24px 24px 24px"
              marginL="0 24px 24px 24px"
              flexBasis={1 / 2}
              flexBasisMid={1 / 2}
              flexBasisLarge={1 / 3}
            />
            <ArtCardSkeleton
              margin="12px"
              marginMid="0 24px 24px 24px"
              marginL="0 24px 24px 24px"
              flexBasis={1 / 2}
              flexBasisMid={1 / 2}
              flexBasisLarge={1 / 3}
            />
            <ArtCardSkeleton
              margin="12px"
              marginMid="24px"
              marginL="0 24px 24px 24px"
              flexBasis={1 / 2}
              flexBasisMid={1 / 2}
              flexBasisLarge={1 / 3}
            />
            <ArtCardSkeleton
              margin="12px"
              marginMid="24px"
              flexBasis={1 / 2}
              flexBasisMid={1 / 2}
              flexBasisLarge={1 / 3}
            />
            <ArtCardSkeleton
              margin="12px"
              marginMid="24px"
              flexBasis={1 / 2}
              flexBasisMid={1 / 2}
              flexBasisLarge={1 / 3}
            />
            <ArtCardSkeleton
              margin="12px"
              marginMid="24px"
              flexBasis={1 / 2}
              flexBasisMid={1 / 2}
              flexBasisLarge={1 / 3}
            />
          </FlexContainer>
        )}
        {!collection.isFetchingNextPage &&
          !collection.isLoading &&
          !collection.hasNextPage &&
          collection.data?.pages?.length > 1 && <span>No more artwork...</span>}
        {collection.data?.pages?.length === 1 &&
          collection.data?.pages?.[0]?.collection?.artwork?.length === 0 && (
            <span>No artwork...</span>
          )}
      </LoadingMore>
    </Wrapper>
  );
}

InfiniteSingleCollectionGrid.propTypes = {
  id: PropTypes.string.isRequired,
  queryParams: PropTypes.object,
  limit: PropTypes.number,
  isOwner: PropTypes.bool.isRequired,
};

InfiniteSingleCollectionGrid.defaultProps = {
  queryParams: {},
  limit: 30,
};

export function InfiniteUserOwnedArtworkGrid({ id }) {
  const loadMoreRef = useRef();
  const ownedArtwork = useUserOwnedArtwork(id);

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: ownedArtwork.fetchNextPage,
    enabled: ownedArtwork.hasNextPage,
  });

  return (
    <Wrapper>
      <FlexContainer padding="12px" paddingLarge="24px">
        {ownedArtwork?.data?.pages?.map((page) => (
          <React.Fragment key={`page-${page.nextOffset}`}>
            {page.artwork?.map((art) => (
              <GridItem key={art.id}>
                <ArtworkCard artwork={art} />
              </GridItem>
            ))}
          </React.Fragment>
        ))}
      </FlexContainer>
      <LoadingMore ref={loadMoreRef}>
        {(ownedArtwork.isFetchingNextPage || ownedArtwork.isLoading) && (
          <FlexContainer>
            <ArtCardSkeleton
              margin="12px"
              marginMid="24px"
              flexBasis={1 / 2}
              flexBasisMid={1 / 2}
              flexBasisLarge={1 / 3}
            />
            <ArtCardSkeleton
              margin="12px"
              marginMid="24px"
              flexBasis={1 / 2}
              flexBasisMid={1 / 2}
              flexBasisLarge={1 / 3}
            />
            <ArtCardSkeleton
              margin="12px"
              marginMid="24px"
              flexBasis={1 / 2}
              flexBasisMid={1 / 2}
              flexBasisLarge={1 / 3}
            />
            <ArtCardSkeleton
              margin="12px"
              marginMid="24px"
              flexBasis={1 / 2}
              flexBasisMid={1 / 2}
              flexBasisLarge={1 / 3}
            />
            <ArtCardSkeleton
              margin="12px"
              marginMid="24px"
              flexBasis={1 / 2}
              flexBasisMid={1 / 2}
              flexBasisLarge={1 / 3}
            />
            <ArtCardSkeleton
              margin="12px"
              marginMid="24px"
              flexBasis={1 / 2}
              flexBasisMid={1 / 2}
              flexBasisLarge={1 / 3}
            />
          </FlexContainer>
        )}
        {!ownedArtwork.isFetchingNextPage &&
          !ownedArtwork.isLoading &&
          !ownedArtwork.hasNextPage &&
          ownedArtwork.data?.pages.length > 1 && (
            <span>No more artwork...</span>
          )}
      </LoadingMore>
    </Wrapper>
  );
}

InfiniteUserOwnedArtworkGrid.propTypes = {
  id: PropTypes.string.isRequired,
};
