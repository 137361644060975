import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  max-width: 1440px;
  margin: 70px auto 0 auto;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  flex-direction: column;

  @media (min-width: 375px) {
    padding: 48px 24px;
  }
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const HeaderTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.015em;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.02em;
    margin-bottom: 0;
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  .total {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.025em;
    color: #777777;
  }

  @media (min-width: 768px) {
    gap: 24px;
  }
`;

export const ButtonWrapper = styled.button`
  border: 0;
  text-transform: none;
  background: tansparent;
  width: 64px;
  height: 32px;
  background: ${({ bg }) => bg || '#3d3d3d'};
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.45);
  border-radius: 18px;
  cursor: pointer;

  img {
    width: 16px;
    height: 16px;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;
  padding: 24px;

  @media (min-width: 1024px) {
    flex-direction: row;

    > * {
      flex-basis: calc(50% - 12px);
      max-width: calc(50% - 12px);
    }
  }
  @media (min-width: 1440px) {
    > * {
      flex-basis: calc(33% - 13px);
      max-width: calc(33% - 13px);
    }
  }
`;

export const Item = styled(Link)`
  display: flex;
  background: #222222;
  border-radius: ${({ artist }) => (artist ? '100px 12px 12px 100px' : '12px')};
  height: 84px;
  text-decoration: none;
  position: relative;

  &:hover {
    background: #333333;
  }

  @media (min-width: 768px) {
    height: 144px;
  }
`;

export const ThumbnailContainer = styled.div`
  img {
    object-fit: cover;
    height: 84px;
    width: 84px;
    border-radius: ${({ artist }) => (artist ? '100px' : '12px')};

    @media (min-width: 768px) {
      width: 144px;
      height: 144px;
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  justify-content: center;

  @media (min-width: 768px) {
    padding: 24px;
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  min-height: 24px;
  letter-spacing: 0.025em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.015em;
    margin-bottom: 16px;
  }
`;

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.025em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  img {
    margin-right: 12px;
    border-radius: 360px;
    width: 20px;
    height: 20px;
  }

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    min-height: 40px;
    -webkit-line-clamp: 2;
  }
`;

export const LoadingMore = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
`;

export const RemoveButton = styled.button`
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  border: 0;
  background: rgba(51, 51, 51, 0.7);
  backdrop-filter: blur(6px);
  border-radius: 0px 12px;
  z-index: 1;
  width: 48px;
  height: 48px;
  padding: 0;

  &:hover {
    background: #910048;
  }
`;
