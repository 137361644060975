import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Components
import { Loader } from '../Loader';

// Images
import MetaMaskIcon from '../../assets/images/meta-mask-logo.svg';
import WalletConnectIcon from '../../assets/images/wallet-connect-logo.svg';

// Styled
import { StyledButton } from './styled';

export function WalletButton({
  to,
  href,
  children,
  pending,
  variant,
  ...props
}) {
  const renderIcon = () => {
    switch (variant) {
      case 'metamask':
        return <img src={MetaMaskIcon} alt="" />;
      case 'walletconnect':
        return <img src={WalletConnectIcon} alt="" />;
      default:
        return <img src={MetaMaskIcon} alt="" />;
    }
  };

  if (to) {
    return (
      <StyledButton to={to} as={Link} pending={pending} {...props}>
        {renderIcon()}
        {pending && <Loader size="16px" />}
        {!pending && children}
      </StyledButton>
    );
  }

  if (href) {
    return (
      <StyledButton href={href} as="a" pending={pending} {...props}>
        {renderIcon()}
        {pending && <Loader size="16px" />}
        {!pending && children}
      </StyledButton>
    );
  }

  return (
    <StyledButton type="button" as="button" pending={pending} {...props}>
      {renderIcon()}
      {pending && <Loader size="16px" />}
      {!pending && children}
    </StyledButton>
  );
}

WalletButton.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  pending: PropTypes.bool,
  variant: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

WalletButton.defaultProps = {
  to: null,
  href: null,
  pending: false,
  variant: 'metamask',
  children: null,
};
