import React from 'react';
import PropTypes from 'prop-types';

// Assets
import defaultProfile from '../../../assets/images/default/Profile.png';

// Styled
import {
  StyledLink,
  ThumbnailContainer,
  CollectorTitle,
  TextContainer,
  CollectorBio,
} from './styled';

export function CollectorCard({ collector }) {
  function addDefaultSrc(ev) {
    ev.target.src = defaultProfile;
  }

  return (
    <StyledLink to={`/collectors/${collector?.id}`}>
      <ThumbnailContainer>
        <img
          key={`${collector?.id}`}
          src={collector?.media?.images?.avatar}
          onError={addDefaultSrc}
          alt="collector avatar"
        />
      </ThumbnailContainer>
      <TextContainer>
        <CollectorTitle>
          {collector?.displayName ||
            collector?.artist?.displayName ||
            `${collector?.givenName} ${collector?.surname}`}
        </CollectorTitle>
        <CollectorBio>{collector?.bio || collector?.artist?.bio}</CollectorBio>
      </TextContainer>
    </StyledLink>
  );
}

CollectorCard.propTypes = {
  collector: PropTypes.object.isRequired,
};
