import React from 'react';

// Components
import { CollectionCarousel } from '../../../components/Collections/Carousel';
import { DiscoverHeader } from '../../../components/DiscoverHeader';
import { CollectionCardSkeleton } from '../../../components/Skeletons/CollectionCardSkeleton';

// Hooks
import { useAllCategories } from '../../../hooks/data';

// Styled
import { Wrapper, LowerWrapper, FlexContainer } from './styled';

export function CollectionHome() {
  const categories = useAllCategories();

  return (
    <Wrapper>
      <DiscoverHeader activeLink="collections" />
      <LowerWrapper>
        {categories.isLoading && (
          <FlexContainer flexBasis={1 / 3}>
            <CollectionCardSkeleton
              margin={0}
              marginMid={0}
              flexBasis={1 / 1}
              flexBasisMid={1 / 2}
              flexBasisLarge={1 / 3}
            />
            <CollectionCardSkeleton
              margin={0}
              marginMid={0}
              flexBasis={1 / 1}
              flexBasisMid={1 / 2}
              flexBasisLarge={1 / 3}
            />
            <CollectionCardSkeleton
              margin={0}
              marginMid={0}
              flexBasis={1 / 1}
              flexBasisMid={1 / 2}
              flexBasisLarge={1 / 3}
            />
            <CollectionCardSkeleton
              margin={0}
              marginMid={0}
              flexBasis={1 / 1}
              flexBasisMid={1 / 2}
              flexBasisLarge={1 / 3}
            />
            <CollectionCardSkeleton
              margin={0}
              marginMid={0}
              flexBasis={1 / 1}
              flexBasisMid={1 / 2}
              flexBasisLarge={1 / 3}
            />
            <CollectionCardSkeleton
              margin={0}
              marginMid={0}
              flexBasis={1 / 1}
              flexBasisMid={1 / 2}
              flexBasisLarge={1 / 3}
            />
          </FlexContainer>
        )}
        {!categories.isLoading && (
          <>
            {categories.data?.categories.map((category) => (
              <CollectionCarousel
                title={category.name}
                collections={category.collections}
                key={category.id}
              />
            ))}
          </>
        )}
      </LowerWrapper>
    </Wrapper>
  );
}
