import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

// Styled
import { Wrapper, Inner, UploadInstructions, FileInput } from './styled';

export function ProfileUploader({
  maxFileSizeMB,
  allowedTypes,
  handleDrop,
  inputRef,
}) {
  const [dragOver, setDragOver] = useState(false);
  const accepted = allowedTypes.join();

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
  });

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    setDragOver(true);
  });

  const handleDragLeave = useCallback(() => {
    setDragOver(false);
  });

  return (
    <Wrapper dragOver={dragOver}>
      <Inner
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        dragOver={dragOver}
      >
        <UploadInstructions>
          Change by dragging and dropping an image here, or click to browse.
        </UploadInstructions>
        <UploadInstructions>{`| .mp4, .jpg, .png, .bmp, .gif | ${
          maxFileSizeMB / 1000
        } MB Max`}</UploadInstructions>
        <FileInput
          type="file"
          ref={inputRef}
          onChange={handleDrop}
          accept={accepted}
        />
      </Inner>
    </Wrapper>
  );
}

ProfileUploader.propTypes = {
  maxFileSizeMB: PropTypes.number,
  handleDrop: PropTypes.func,
  inputRef: PropTypes.func.isRequired,
  allowedTypes: PropTypes.array,
};

ProfileUploader.defaultProps = {
  maxFileSizeMB: 1000,
  allowedTypes: ['image/jpeg', 'image/gif', 'image/png'],
  handleDrop: () => {},
};
