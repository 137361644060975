import React from 'react';

// Components
import { Button } from '../../components/Button';

// Assets
import Marketplaces from '../../assets/images/galleries/NFT_Marketplaces.webp';
import Lara from '../../assets/images/galleries/Lara_Headshot.webp';
import KollectiffGallery from '../../assets/images/galleries/Kollectiff_Gallery.webp';
import SeattleGallery from '../../assets/images/galleries/seattleNFTMuseum.webp';
import tickBox from '../../assets/images/icons/Tick Box.svg';
import WhiteGlove from '../../assets/images/galleries/white-glove.webp';
import playIcon from '../../assets/images/galleries/PlayRed.svg';
import fewocious from '../../assets/images/galleries/Fewocious.webp';
import heroMobile from '../../assets/images/galleries/Gallery_Mobile.webp';
import heroTablet from '../../assets/images/galleries/Gallery_Tablet.webp';
import heroDesktop from '../../assets/images/galleries/Gallery_Desktop.webp';

// Data
import { iconData, firstData } from './data';

// Styled
import {
  HeaderWrapper,
  HeaderBarWrapper,
  VideoAspectRatioBox,
  ImageAspectRatioBoxTwo,
  Wrapper,
  VideoFrame,
  VideoFrameTwo,
  HeaderBar,
  HeaderTitle,
  HeaderSubtitle,
  FlexWrapper,
  Title,
  Subtitle,
  ItemSub,
  ItemTitle,
  TopTitle,
  VideoSubtext,
  CaseTitle,
  CaseSubtitle,
  ScrollIcon,
  ContactTitle,
  PositionTitle,
  Name,
  Email,
  ImageAvatar,
  ButtonWrapper,
  ButtonWrapperOne,
  FormWrapper,
  BackgroundRect,
  CaseWrapper,
  DesktopImg,
  TabletImg,
  MobileImg,
} from './styled';

const Galleries = () => {
  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/shell.js';
    document.body.appendChild(script);
    const hbsptLoad = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '5104433',
          formId: '181d3a10-07f4-4bcd-80e6-f2d00aee3be8',
          target: '#hubspotForm',
        });
      }
    };
    script.addEventListener('load', hbsptLoad);
  }, []);

  return (
    <>
      <HeaderWrapper>
        <VideoFrameTwo objectFit="cover">
          <MobileImg src={heroMobile} alt="home screen" />
          <TabletImg src={heroTablet} alt="home screen" />
          <DesktopImg src={heroDesktop} alt="home screen" />
        </VideoFrameTwo>
        <HeaderBarWrapper>
          <HeaderBar>
            <HeaderTitle>Gallery Services</HeaderTitle>
            <HeaderSubtitle>
              Blackdove technology enables galleries, museums and exhibitions to
              easily and cost effectively manage digital art curation, sales and
              customer installations.
            </HeaderSubtitle>
          </HeaderBar>
          <ScrollIcon>
            <img src={playIcon} alt="" />
          </ScrollIcon>
        </HeaderBarWrapper>
      </HeaderWrapper>
      <Wrapper bg="#1a1a1a">
        <FlexWrapper
          fd="column"
          margin="auto"
          fdxl="row"
          paddingXLarge="96px 0 48px 0"
        >
          <FlexWrapper
            margin="24px"
            marginL="48px"
            padding="24px 0 0 0"
            paddingL="48px 0 0 0"
            fd="column"
            fdl="row"
            fdxl="column"
            jc="space-between"
            ai="center"
            paddingXLarge="0 0 0 0"
          >
            <FlexWrapper fb="50%">
              <Title>Curate, Display and Sell</Title>
            </FlexWrapper>
            <ButtonWrapperOne>
              <Button color="red" href="#hubspotForm">
                Contact us
              </Button>
            </ButtonWrapperOne>
          </FlexWrapper>
          <FlexWrapper
            fd="column"
            fdl="row"
            fw="wrap"
            padding="24px"
            paddingLarge="48px"
            fbxl="66%"
            gap="24px"
            gapXL="48px"
          >
            {firstData.map((item) => (
              <FlexWrapper
                fd="row"
                margin="0 0 24px 0"
                fbl="calc(50% - 12px)"
                fbxl="calc(50% - 24px)"
              >
                <div>
                  <img src={tickBox} alt="checkbox" />
                </div>
                <FlexWrapper fd="column" margin="5px 0 0">
                  <ItemTitle>{item.title}</ItemTitle>
                  <ItemSub>{item.sub}</ItemSub>
                </FlexWrapper>
              </FlexWrapper>
            ))}
          </FlexWrapper>
          <ButtonWrapper fb="50%">
            <Button color="red" href="#hubspotForm">
              Contact us
            </Button>
          </ButtonWrapper>
        </FlexWrapper>
      </Wrapper>
      <Wrapper bg="#222222">
        <FlexWrapper fd="column" fdl="row" margin="auto">
          <FlexWrapper
            margin="48px 24px"
            marginL="48px"
            fd="column"
            fbl="50%"
            padding="0 48px"
            jc="center"
            ai="center"
            ail="flex-start"
          >
            <Title mb="24px">Blockchain Integrated</Title>
            <Subtitle>
              Embed a MetaMask wallet to the Blackdove reseller portal,
              integrate with any existing NFT platform(s) and showcase digital
              works on 4K displays.
            </Subtitle>
          </FlexWrapper>
          <FlexWrapper fbl="50%">
            <VideoAspectRatioBox
              margin="0 24px"
              marginL="0 48px"
              marginXL="0 48px 0 0"
              paddingBottom="50%"
              paddingBottomLarge="75%"
            >
              <VideoFrameTwo objectFit="contain">
                <img src={Marketplaces} alt="NFT Marketplaces" />
              </VideoFrameTwo>
            </VideoAspectRatioBox>
          </FlexWrapper>
        </FlexWrapper>
      </Wrapper>
      <Wrapper bg="#1a1a1a">
        <FlexWrapper fd="column" fdl="row" margin="auto">
          <FlexWrapper fbl="50%" order="1" orderL="0">
            <VideoAspectRatioBox paddingBottom="75%">
              <VideoFrameTwo objectFit="cover">
                <img src={WhiteGlove} alt="White Glove Service" />
              </VideoFrameTwo>
            </VideoAspectRatioBox>
          </FlexWrapper>
          <FlexWrapper
            fd="column"
            fbl="50%"
            padding="48px"
            jc="center"
            ai="center"
            ail="flex-start"
            order="0"
            orderL="1"
          >
            <Title mb="24px">White-Glove Installation</Title>
            <Subtitle>
              Collector clients are able to purchase artworks alongside
              Blackdove Digital Canvases with installation and support provided
              by the Blackdove expert technical team.
            </Subtitle>
          </FlexWrapper>
        </FlexWrapper>
      </Wrapper>
      <Wrapper bg="#222222">
        <FlexWrapper
          fd="row"
          padding="24px"
          paddingLarge="48px"
          margin="auto"
          jc="space-evenly"
        >
          {iconData.map((icon) => (
            <>
              <ImageAspectRatioBoxTwo>
                <VideoFrame>
                  <img src={icon.icon} alt={icon.alt} />
                </VideoFrame>
              </ImageAspectRatioBoxTwo>
            </>
          ))}
        </FlexWrapper>
      </Wrapper>
      <Wrapper bg="#ffffff" padding="24px">
        <FlexWrapper
          fd="column"
          paddingM="24px"
          paddingLarge="48px"
          position="relative"
          margin="0 auto 24px auto"
        >
          <TopTitle>Case studies</TopTitle>
          <FlexWrapper
            position="relative"
            marginL="0 48px 0 0"
            marginXL="0 150px 0 0"
          >
            <CaseWrapper>
              <VideoAspectRatioBox paddingBottom="56.25%">
                <VideoFrameTwo objectFit="cover">
                  <img src={fewocious} alt="Blackdove X Christies" />
                </VideoFrameTwo>
              </VideoAspectRatioBox>
              <VideoSubtext>
                Acclaimed NFT artist FEWOCiOUS in front of Blackdove digital
                canvases at Christie&apos;s New York in Rockefeller Center.
                Video courtesy Christie&apos;s.
              </VideoSubtext>

              <CaseTitle>Blackdove x Christie&apos;s</CaseTitle>
              <CaseSubtitle>
                Following the record-setting $69.3 million sale of Beeple&apos;s{' '}
                <i>Everyday&#45;The First 5000 Days</i> NFT in Spring 2021,
                Blackdove forged an ongoing partnership with Christie&apos;s to
                imagine and implement the auction house&apos;s first
                installations of NFTs at Rockefeller Center in New York City.
                Blackdove has designed and supported the Christie&apos;s NFT
                program, collaborating with the specialists and operations teams
                to present the highest quality digital artworks in the
                Christie&apos;s galleries for preview by clients, collectors,
                and visitors from all over the world.
              </CaseSubtitle>
            </CaseWrapper>
          </FlexWrapper>
          <BackgroundRect />
        </FlexWrapper>

        <FlexWrapper
          fd="column"
          paddingM="24px"
          paddingLarge="48px"
          position="relative"
          margin="0 auto 24px auto"
        >
          <FlexWrapper
            position="relative"
            marginL="0 48px 0 0"
            marginXL="0 150px 0 0"
          >
            <CaseWrapper>
              <VideoAspectRatioBox paddingBottom="56.25%">
                <VideoFrameTwo objectFit="cover">
                  <iframe
                    src="https://www.youtube.com/embed/1rhKpvMByvU?autoplay=1&mute=1"
                    frameBorder="0"
                    allowFullScreen
                    title="video"
                    allow="autoplay; fullscreen;"
                  />
                </VideoFrameTwo>
              </VideoAspectRatioBox>
              <VideoSubtext>
                Heaven&apos;s Gate by digital artist Marco Brambilla installed
                on Blackdove digital canvases at the Pérez Art Museum Miami.
                Video courtesy PAMM.
              </VideoSubtext>

              <CaseTitle>Blackdove x Pérez Art Museum Miami</CaseTitle>
              <CaseSubtitle>
                The Pérez Art Museum Miami enlisted Blackdove to visualize and
                produce a site-specific showcase for Heaven&apos;s Gate, a
                monumental new work by video artist Marco Brambilla. On view
                through 2023, the floor-to-ceiling tall digital canvases create
                an exceptional setting for experiencing the artist&apos;s
                digital psychedelia through state-of-the-art computer
                compositing technology. In a totem-like display of screens by
                Blackdove and LG, viewers to the PAMM exhibition travel through
                a vertical and immersive landscape of infinitely-looping scenes
                from Hollywood films.
              </CaseSubtitle>
            </CaseWrapper>
          </FlexWrapper>
          <BackgroundRect />
        </FlexWrapper>

        <FlexWrapper
          fd="column"
          paddingM="24px"
          paddingLarge="48px"
          position="relative"
          margin="0 auto 24px auto"
        >
          <FlexWrapper
            position="relative"
            marginL="0 48px 0 0"
            marginXL="0 150px 0 auto"
          >
            <CaseWrapper>
              <VideoAspectRatioBox paddingBottom="56.25%">
                <VideoFrameTwo objectFit="cover">
                  <img src={KollectiffGallery} alt="Kollectiff Gallery" />
                </VideoFrameTwo>
              </VideoAspectRatioBox>
              <VideoSubtext>
                The Gallery by Kollectiff powered by Blackdove. Photo courtesy
                of Kollectiff.
              </VideoSubtext>

              <CaseTitle>The Gallery by Kollectiff</CaseTitle>
              <CaseSubtitle>
                Kollectiff is a web3 venture studio leveraging blockchain
                technology and NFTs to create engaging and connected brand
                experiences, focusing on utility and experiential design. The
                studio helps IP owners and brands understand and unlock the full
                potential of Web3, NFTs, and the Metaverse from concept ideation
                to creative and technical execution. The gallery and event space
                blends art and technology seamlessly in a 4,600 sq. foot
                location, fitted with high-quality screens{' '}
                <b>powered by Blackdove</b> for bespoke NFT art activations,
                collection exhibitions, NFT auctions/launches, and Web3 events.
              </CaseSubtitle>
            </CaseWrapper>
          </FlexWrapper>
          <BackgroundRect />
        </FlexWrapper>

        <FlexWrapper
          fd="column"
          paddingM="24px"
          paddingLarge="48px"
          position="relative"
          margin="0 auto 24px auto"
        >
          <FlexWrapper
            position="relative"
            marginL="0 48px 0 0"
            marginXL="0 150px 0 0"
          >
            <CaseWrapper>
              <VideoAspectRatioBox paddingBottom="56.25%">
                <VideoFrameTwo objectFit="cover">
                  <img src={SeattleGallery} alt="Seattle NFT Gallery" />
                </VideoFrameTwo>
              </VideoAspectRatioBox>
              <VideoSubtext>
                The Seattle NFT Museum galleries powered by Blackdove. Photo
                courtesy SNFTM.
              </VideoSubtext>

              <CaseTitle>Blackdove x Seattle NFT Museum</CaseTitle>
              <CaseSubtitle>
                In response to the international explosion of interest in art
                and NFTs, the Seattle NFT Museum—the first ever museum dedicated
                to NFTs—opened its doors in January of 2022, bringing together
                artists, creators, collectors, and the crypto art community. The
                Museum uses Blackdove software technology to curate and manage
                shows on more than 30 displays throughout the museum galleries.
              </CaseSubtitle>
            </CaseWrapper>
          </FlexWrapper>
          <BackgroundRect />
        </FlexWrapper>
      </Wrapper>

      <Wrapper bg="#1a1a1a">
        <FlexWrapper fd="column" fdl="row" margin="auto">
          <FlexWrapper
            fd="column"
            fbl="40%"
            padding="48px 24px"
            paddingLarge="96px 48px"
            jc="center"
            ai="center"
          >
            <ContactTitle>Get in touch!</ContactTitle>
            <ImageAvatar>
              <img src={Lara} alt="lara contact" />
            </ImageAvatar>
            <Name>Lara Binnet</Name>
            <PositionTitle>Gallery Manager</PositionTitle>
            <Email>lara@blackdove.com</Email>
          </FlexWrapper>
          <FlexWrapper
            fd="column"
            fbl="60%"
            bg="#222222"
            padding="24px"
            paddingLarge="48px"
            jc="center"
            ai="center"
          >
            <FormWrapper>
              <div className="contact" id="hubspotForm" />
            </FormWrapper>
          </FlexWrapper>
        </FlexWrapper>
      </Wrapper>
    </>
  );
};

export default Galleries;
