import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';

// Components
import { Checkbox } from '../../../FormNext';
import { TextInput } from '../../../FormNext/components/TextInput/TextInput';
import { Button } from '../../../Button';

// Styled
import { SignUpWrapper } from '../../styled';

const validationSchemaSignup = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(8).required(),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required()
    .label('Repeat Password'),
  givenName: yup.string().required(),
  surname: yup.string().required(),
  isArtist: yup.bool(),
});
const initialValuesSignup = {
  email: '',
  password: '',
  repeatPassword: '',
  givenName: '',
  surname: '',
  isArtist: false,
};

export function Signup({ loading, handleSignin, onNext }) {
  return (
    <>
      <Formik
        initialValues={initialValuesSignup}
        validationSchema={validationSchemaSignup}
        onSubmit={onNext}
      >
        <Form style={{ maxWidth: 480, textAlign: 'start' }}>
          <Field
            name="email"
            type="email"
            label="Email"
            placeholder="you@email.com"
            component={TextInput}
          />
          <Field
            name="password"
            type="password"
            label="Password"
            component={TextInput}
          />
          <Field
            name="repeatPassword"
            type="password"
            label="Repeat Password"
            component={TextInput}
          />
          <Field name="givenName" label="First Name" component={TextInput} />
          <Field name="surname" label="Last Name" component={TextInput} />
          <Field
            name="isArtist"
            label="Register as an artist to earn royalties and mint your artwork"
            component={Checkbox}
          />
          <br />
          <Button type="submit" color={loading ? '' : 'red'} disabled={loading}>
            {loading ? 'Loading....' : 'Create Account'}
          </Button>
        </Form>
      </Formik>
      <SignUpWrapper>
        <div>
          Already have an account?{' '}
          <button type="button" className="bold" onClick={handleSignin}>
            Log in here
          </button>
        </div>
      </SignUpWrapper>
    </>
  );
}

Signup.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSignin: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};
