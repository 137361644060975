import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';

import styled from '@emotion/styled';
import arrowDown from '../../../../assets/images/icons/arrow-outline-down.svg';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 24px;
  background: #910048;
  display: flex;
  flex-direction: column;
  border-radius: 14px;
`;

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 24px;
  background: #3d3d3d;
  display: flex;
  flex-direction: column;
  border-radius: 14px;
`;

const Input = styled.select`
  width: 100%;
  background: url(${arrowDown}) no-repeat right #3d3d3d;
  background-position: top -4px right 15px;
  border-radius: 0 0 8px 8px;
  padding: 0 0 0px 16px;
  max-height: 46px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  border: 1px solid #3d3d3d;
  border-width: 0;
  user-select: none;
  color: #fff;

  &:focus {
    outline: none;
    border: 1px solid #3d3d3d;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

const Label = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.025em;
  text-align: start;
  padding: 8px 0 0 16px;
  color: #a5a5a5;
  background: #3d3d3d;
  display: inline-block;
  border-radius: 8px 8px 0 0;
`;

const LengthCounter = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: ${({ exceed }) => (exceed ? '#ffa800' : '#333')};
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.45);
  border-radius: 12px;
  font-weight: 600;
  font-size: 11px;
  color: #ffffff;
  padding: 1px 9px 2px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const HelperText = styled.span`
  display: block;
`;

const ErrorText = styled.span`
  display: block;
  color: #ffa800;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  padding-left: 22px;
  padding-top: 5px;
`;
const InputContainer = styled.div`
  display: flex;
  background: #3d3d3d;
  max-height: 56px;
  border-radius: 8px;

  img {
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 12px;
    cursor: ${({ iconClick }) => iconClick && 'pointer'};
    fill: ${({ open }) => open && '#910048'};
    color: ${({ open }) => open && '#910048'};
  }
`;

export function Select({
  field,
  form: { touched, errors },
  label,
  helperText,
  maxLength,
  children,
  Icon,
  iconClick,
  ...props
}) {
  const hasError =
    getIn(touched, field.name) && Boolean(getIn(errors, field.name));
  const errorText = getIn(errors, field.name);
  return (
    <Wrapper>
      <InputContainer open={open} iconClick={iconClick}>
        <InputWrapper>
          {label && <Label htmlFor={field.name}>{label}</Label>}
          <Input
            id={field.name}
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            arrowDown={arrowDown}
            maxLength={maxLength > 0 ? maxLength : ''}
            {...props}
          >
            {children}
          </Input>
        </InputWrapper>
        {Icon && (
          <IconWrapper onClick={iconClick}>
            <img src={Icon} alt="edit" />
          </IconWrapper>
        )}
      </InputContainer>
      {maxLength && (
        <LengthCounter
          exceed={field.value.length > maxLength}
        >{`${field.value.length} / ${maxLength}`}</LengthCounter>
      )}
      {helperText && <HelperText>{helperText}</HelperText>}
      {hasError && <ErrorText>{errorText}</ErrorText>}
    </Wrapper>
  );
}

Select.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
  }).isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  maxLength: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  Icon: PropTypes,
  iconClick: PropTypes.func,
};

Select.defaultProps = {
  label: '',
  helperText: '',
  maxLength: 0,
  children: null,
  Icon: null,
  iconClick: null,
};
