import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { MetaMaskProvider } from 'metamask-react';
import { NotificationsProvider } from 'reapop';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import { RouteLayout } from './components/RouteLayout';

// ------ Logged In Pages ------- //
import { ArtworkHome } from './pages/Artwork/Home';
import { ArtworkDetails } from './pages/Artwork/Details';
import { ArtworkLatest } from './pages/Artwork/Latest';
import { ArtistHome } from './pages/Artists/Home';
import { ArtistDetails } from './pages/Artists/Details';
import { CollectorDetails } from './pages/Collectors/Details';
import { CollectionHome } from './pages/Collections/Home';
import { CollectionDetails } from './pages/Collections/Details';
import { CollectionTotal } from './pages/Collections/Total';
import { NFTSuccessPage } from './pages/NFT/Success';
import { NFTImport } from './pages/NFT/Import';
import { OrderConfirmation } from './pages/Order/Confirmation';
import { ArtworkSuccessPage } from './pages/Order/Confirmation/Artwork';

import AccountOverview from './pages/Account/AccountOverview';
import { Settings } from './pages/Settings';
import { Statistics } from './pages/Statistics';
import { Vault } from './pages/Vault';

// ---------- Logged Out Pages ----------- //
import Home from './pages/Home';
import About from './pages/About';
import Brand from './pages/Brand';
import { Pricing } from './pages/Pricing';
import Partners from './pages/ForIndustry/Partners';
import { AccountSetup } from './pages/AccountSetup';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import PageNotFound from './pages/PageNotFound';
import Contact from './pages/Contact';
import Galleries from './pages/Galleries';
import Privacy from './pages/Privacy';

import reducer from './reducer';

const queryClient = new QueryClient();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  {},
  composeEnhancers(applyMiddleware(thunk))
);

// TODO: Set this based on NODE_ENV
// const stripePromise = loadStripe('pk_test_CwFNQLF0fkyOp98BpMWd97hD');
const stripePromise = loadStripe('pk_live_8YPTwOAeydlQbXQKYSHVDAI6');

const App = () => (
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <MetaMaskProvider>
        <Elements stripe={stripePromise}>
          <NotificationsProvider>
            <DndProvider backend={HTML5Backend}>
              <BrowserRouter>
                <Routes>
                  {/* Home Routes */}
                  <Route
                    path="/"
                    element={
                      <RouteLayout component={Home} layout="MAIN" isPublic />
                    }
                  />
                  <Route
                    path="/home"
                    element={
                      <RouteLayout component={Home} layout="MAIN" isPublic />
                    }
                  />
                  <Route
                    path="/login"
                    element={
                      <RouteLayout component={Home} layout="MAIN" isPublic />
                    }
                  />
                  <Route
                    path="/signup"
                    element={
                      <RouteLayout component={Home} layout="MAIN" isPublic />
                    }
                  />
                  {/* Pricing Routes */}
                  <Route
                    path="/pricing"
                    element={
                      <RouteLayout component={Pricing} layout="MAIN" isPublic />
                    }
                  />
                  {/* Collector Routes */}
                  <Route
                    path="/collectors/:id"
                    element={
                      <RouteLayout
                        component={CollectorDetails}
                        layout="MAIN"
                        isPublic
                      />
                    }
                  />
                  {/* Artist Routes */}
                  <Route
                    path="/artists"
                    element={
                      <RouteLayout
                        component={ArtistHome}
                        layout="MAIN"
                        isPublic
                      />
                    }
                  />
                  <Route
                    path="/discover/artists"
                    element={
                      <RouteLayout
                        component={ArtistHome}
                        layout="MAIN"
                        isPublic
                      />
                    }
                  />
                  <Route
                    path="/artist/:id"
                    element={
                      <RouteLayout
                        component={ArtistDetails}
                        layout="MAIN"
                        isPublic
                      />
                    }
                  />
                  <Route
                    path="/artists/:id"
                    element={
                      <RouteLayout
                        component={ArtistDetails}
                        layout="MAIN"
                        isPublic
                      />
                    }
                  />
                  {/* Artwork Routes */}
                  <Route
                    path="/artwork"
                    element={
                      <RouteLayout
                        component={ArtworkHome}
                        layout="MAIN"
                        isPublic
                      />
                    }
                  />
                  <Route
                    path="/marketplace"
                    element={
                      <RouteLayout
                        component={ArtworkHome}
                        layout="MAIN"
                        isPublic
                      />
                    }
                  />
                  <Route
                    path="/artwork/latest"
                    element={
                      <RouteLayout
                        component={ArtworkLatest}
                        layout="MAIN"
                        isPublic
                      />
                    }
                  />
                  <Route
                    path="/artwork/:id"
                    element={
                      <RouteLayout
                        component={ArtworkDetails}
                        layout="MAIN"
                        isPublic
                      />
                    }
                  />
                  <Route
                    path="/vault"
                    element={
                      <RouteLayout component={Vault} layout="MAIN" isPublic />
                    }
                  />

                  {/* Collection Routes */}
                  <Route
                    path="/collection/:type"
                    element={
                      <RouteLayout
                        component={CollectionTotal}
                        layout="MAIN"
                        isPublic
                      />
                    }
                  />
                  <Route
                    path="/collections"
                    element={
                      <RouteLayout
                        component={CollectionHome}
                        layout="MAIN"
                        isPublic
                      />
                    }
                  />
                  <Route
                    path="/discover/collections"
                    element={
                      <RouteLayout
                        component={CollectionHome}
                        layout="MAIN"
                        isPublic
                      />
                    }
                  />
                  <Route
                    path="/collections/:id"
                    element={
                      <RouteLayout
                        component={CollectionDetails}
                        layout="MAIN"
                        isPublic
                      />
                    }
                  />
                  <Route
                    path="/playlists/:id"
                    element={
                      <RouteLayout
                        component={CollectionDetails}
                        layout="MAIN"
                        isPublic
                      />
                    }
                  />
                  {/* NFT Routes */}
                  <Route
                    path="/nft/import"
                    element={
                      <RouteLayout component={NFTImport} layout="MAIN" />
                    }
                  />
                  <Route
                    path="/importnft"
                    element={
                      <RouteLayout component={NFTImport} layout="MAIN" />
                    }
                  />
                  <Route
                    path="/wallets"
                    element={
                      <RouteLayout component={NFTImport} layout="MAIN" />
                    }
                  />
                  <Route
                    path="/wallet"
                    element={
                      <RouteLayout component={NFTImport} layout="MAIN" />
                    }
                  />
                  <Route
                    path="/nft/success/:id"
                    element={
                      <RouteLayout component={NFTSuccessPage} layout="MAIN" />
                    }
                  />
                  {/* Order Confirmation */}
                  <Route
                    path="/order/confirmation"
                    element={
                      <RouteLayout
                        component={OrderConfirmation}
                        layout="MAIN"
                      />
                    }
                  />
                  <Route
                    path="/order/confirmation/artwork/:id"
                    element={
                      <RouteLayout
                        component={ArtworkSuccessPage}
                        layout="MAIN"
                      />
                    }
                  />
                  {/* Account Routes */}
                  <Route
                    path="/account/overview"
                    element={
                      <RouteLayout
                        component={AccountOverview}
                        layout="ACCOUNT"
                      />
                    }
                  />
                  <Route
                    path="/settings"
                    element={<RouteLayout component={Settings} layout="MAIN" />}
                  />
                  <Route
                    path="/settings/:tab"
                    element={<RouteLayout component={Settings} layout="MAIN" />}
                  />
                  <Route
                    path="/statistics"
                    element={
                      <RouteLayout component={Statistics} layout="MAIN" />
                    }
                  />
                  <Route
                    path="/statistics/:tab"
                    element={
                      <RouteLayout component={Statistics} layout="MAIN" />
                    }
                  />

                  {/* Public Account Routes */}
                  <Route
                    path="/account-setup"
                    element={
                      <RouteLayout
                        component={AccountSetup}
                        layout="MAIN"
                        isPublic
                      />
                    }
                  />
                  <Route
                    path="/forgot-password"
                    element={
                      <RouteLayout
                        component={ForgotPassword}
                        layout="MAIN"
                        isPublic
                      />
                    }
                  />
                  <Route
                    path="/reset-password/:token"
                    element={
                      <RouteLayout
                        component={ResetPassword}
                        layout="PUBLIC"
                        isPublic
                      />
                    }
                  />

                  {/* Public Info Routes */}
                  <Route
                    path="/partners"
                    element={
                      <RouteLayout
                        component={Partners}
                        layout="MAIN"
                        isPublic
                      />
                    }
                  />
                  <Route
                    path="/partner"
                    element={
                      <RouteLayout
                        component={Partners}
                        layout="MAIN"
                        isPublic
                      />
                    }
                  />
                  <Route
                    path="/about"
                    element={
                      <RouteLayout component={About} layout="MAIN" isPublic />
                    }
                  />
                  <Route
                    path="/brand"
                    element={
                      <RouteLayout component={Brand} layout="MAIN" isPublic />
                    }
                  />
                  <Route
                    path="/contact"
                    element={
                      <RouteLayout component={Contact} layout="MAIN" isPublic />
                    }
                  />
                  <Route
                    path="/contactus"
                    element={
                      <RouteLayout component={Contact} layout="MAIN" isPublic />
                    }
                  />
                  <Route
                    path="/privacy"
                    element={
                      <RouteLayout component={Privacy} layout="MAIN" isPublic />
                    }
                  />
                  <Route
                    path="/galleries"
                    element={
                      <RouteLayout
                        component={Galleries}
                        layout="MAIN"
                        isPublic
                      />
                    }
                  />
                  <Route
                    path="/forindustry/galleries"
                    element={
                      <RouteLayout
                        component={Galleries}
                        layout="MAIN"
                        isPublic
                      />
                    }
                  />
                  <Route
                    path="*"
                    element={
                      <RouteLayout
                        component={PageNotFound}
                        layout="MAIN"
                        isPublic
                      />
                    }
                  />
                </Routes>
              </BrowserRouter>
            </DndProvider>
          </NotificationsProvider>
        </Elements>
      </MetaMaskProvider>
    </Provider>
  </QueryClientProvider>
);

export default App;
