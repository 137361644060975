import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { VideoPlayer } from '@blackdove/player';
import { useNotifications } from 'reapop';

// Components
import { HeaderSplash } from '../HeaderSplash';
import { Button } from '../Button';
import arrowLeftIcon from '../Images/ArrowLeft.svg';
import { Loader } from '../Loader';

// Hooks
import { useManyCollectionsInfinite } from '../../hooks/data';
import { useDeleteArtwork, useAddToCollection } from '../../hooks/mutations';
import { useMenuClickOutside } from '../../hooks/useMenuClickOutside';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';

// Actions
import { openModal } from '../../store/app/actions';

// Assets
import dots from '../../assets/images/More.svg';
import mute from '../../assets/images/icons/muted.png';

// Styled
import {
  Wrapper,
  ArtworkContainer,
  AspectRatioBox,
  Frame,
  ActionButtonContainer,
  Dropdown,
  SubNavMenu,
  Icon,
  LoadingMore,
} from './styled';

export function ArtworkHeader({
  orientation,
  artwork,
  menu,
  isOwner,
  refetch,
}) {
  const { id } = artwork;
  const dispatch = useDispatch();
  const { notify } = useNotifications();
  const [muted, setMuted] = useState(true);
  const deleteArtwork = useDeleteArtwork();
  const userCollections = useManyCollectionsInfinite('userCollections', '', 50);
  const addToCollection = useAddToCollection();

  const dropRef = useRef(null);
  const subNavRef = useRef(null);
  const loadMoreRefs = useRef();

  const [isActive, setIsActive] = useMenuClickOutside(dropRef, false);
  const [isSubActive, setIsSubActive] = useMenuClickOutside(subNavRef, false);

  useEffect(() => {}, [artwork]);

  useIntersectionObserver({
    target: loadMoreRefs,
    onIntersect: userCollections?.fetchNextPage,
    enabled: userCollections?.hasNextPage,
  });

  const handleCollections = () => {
    setIsSubActive(!isSubActive);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `https://app.blackdove.com/artwork/${
        artwork.slug ? artwork.slug : artwork.id
      }`
    );
    notify({
      status: 'success',
      title: 'Copied to Clipboard',
      message: 'The share link for this artwork was copied to your clipboard.',
    });
  };

  const handleEditArtwork = () => {
    dispatch(
      openModal({
        modal: 'EDIT_ARTWORK',
        params: { artwork, refetch },
      })
    );
  };

  const handleDeleteArtwork = () => {
    if (confirm('Are you sure you want to delete this playlist?')) {
      deleteArtwork.mutate({ id });
    }
  };
  return (
    <Wrapper>
      <HeaderSplash
        height="100%"
        padding="60px 24px 24px 24px"
        paddingLarge="60px 48px 36px 48px"
      >
        <ArtworkContainer>
          {artwork.contentType === 'VIDEO' && (
            <AspectRatioBox muted={muted}>
              <Frame
                orientation={orientation}
                menu={
                  menu?.menuOpen
                    ? 'max-width: calc(100vw - 228px);'
                    : 'max-width: 100vw;'
                }
                rotate={artwork.orientation === 'portrait'}
              >
                <VideoPlayer
                  videoUrl={artwork.media.video.hls}
                  posterUrl={artwork.media.image.low.landscape}
                  artistName=""
                  initialize
                  autoplay
                  muted={muted}
                  showLogo={false}
                  showLoading={false}
                />
              </Frame>
              <button type="button" onClick={() => setMuted(!muted)}>
                <img src={mute} alt="mute button" />
              </button>
            </AspectRatioBox>
          )}
          {artwork.contentType === 'HTML5' && (
            <iframe
              src={artwork.media.html5}
              title={artwork.name}
              frameBorder="0"
              width="600px"
              height="600px"
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block',
                marginTop: '40px',
              }}
              allowFullScreen
            />
          )}
        </ArtworkContainer>
        <ActionButtonContainer>
          <Button
            type="button"
            className="more"
            onClick={() => setIsActive(!isActive)}
          >
            <img src={dots} alt="more" />
          </Button>

          <Dropdown isActive={isActive} ref={dropRef}>
            <div className="cards">
              <button
                type="button"
                className="button"
                onClick={handleCollections}
              >
                <Icon src={arrowLeftIcon} /> Add to collection
              </button>

              <SubNavMenu ref={subNavRef} visible={isSubActive}>
                <ul>
                  {userCollections.data?.pages?.map((page) => (
                    <React.Fragment key={`page-${page.nextOffset}`}>
                      {page?.collections?.map((collection) =>
                        collection.playlistType === 'STANDARD' ? (
                          <>
                            <button
                              key={collection.id}
                              type="button"
                              onClick={() =>
                                addToCollection.mutate({
                                  id: collection.id,
                                  artworkId: artwork.id,
                                })
                              }
                            >
                              {collection.name}
                            </button>
                          </>
                        ) : null
                      )}
                    </React.Fragment>
                  ))}

                  <LoadingMore ref={loadMoreRefs}>
                    {(userCollections?.isFetchingNextPage ||
                      userCollections?.isLoading) && <Loader size="15px" />}
                  </LoadingMore>
                </ul>
              </SubNavMenu>
              {isOwner && (
                <>
                  <button
                    className="button"
                    type="button"
                    onClick={handleEditArtwork}
                  >
                    Edit
                  </button>
                  <button
                    className="button"
                    type="button"
                    onClick={handleDeleteArtwork}
                  >
                    Delete
                  </button>
                </>
              )}
            </div>
          </Dropdown>
          <Button type="button" className="share" onClick={handleCopy}>
            Share
          </Button>
        </ActionButtonContainer>
      </HeaderSplash>
    </Wrapper>
  );
}

ArtworkHeader.propTypes = {
  artwork: PropTypes.object.isRequired,
  orientation: PropTypes.string.isRequired,
  menu: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  refetch: PropTypes.func.isRequired,
};
