import styled from '@emotion/styled';

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 48px;
  margin: auto;

  button {
    margin-top: 24px;
    width: 300px;
  }
`;

export const HeaderWrapper = styled.div`
  height: 156px;
  background: #222222;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.45);
  border-radius: 0px 0px 24px 24px;
  padding: 60px 0px 0px;
  display: flex;
  justify-content: center;
  position: relative;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    text-transform: none;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: rgba(255, 255, 255, 0.15);
    margin: 24px;
  }
`;

export const Header = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  text-transform: none;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  width: 100%;
  max-width: 1440px;
  color: rgba(255, 255, 255, 0.15);
`;

export const SortWrapper = styled.div`
  background: #222222;
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const LowerWrapper = styled.div`
  padding: 20px 0 0 0;
  max-width: 1440px;
  margin: auto;

  @media (min-width: 800px) {
    padding: 20px 0 0 0;
  }
`;

export const UploadWrapper = styled.div`
  margin: auto;
  border: 1px dashed #3d3d3d;
  display: flex;
  padding: 24px;
  border-radius: 12px;
  justify-content: space-between;
  background: ${({ dragOver }) => (dragOver ? '#222222' : 'transparent')};
  cursor: pointer;
  max-width: 850px;
  flex-direction: column;
  margin-bottom: 12px;

  .text {
    display: flex;
    flex-direction: column;
    padding: 24px;
    justify-content: space-between;
  }

  input {
    display: none;
  }

  &:hover {
    background: #222222;
  }
  @media (min-width: 375px) {
    flex-direction: Row;
  }
`;

export const UploadText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.025em;
  color: #a5a5a5;
`;

export const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;

  img {
    margin-right: 12px;

    @media (min-width: 768px) {
      margin-right: 24px;
    }
  }
`;

export const Divider = styled.div`
  border: 1px solid #444444;
  transform: rotate(90deg);
  width: 16px;
  height: 0px;
`;

export const NavLink = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 12px;
  border-radius: 12px;
  position: relative;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  border: 0;
  background: transparent;
  cursor: pointer;
  ${({ isActive, theme }) =>
    isActive &&
    `  background: ${theme.colors.base4};
  border-radius: 12px;`}

  @media (min-width: 375px) {
    font-size: 12px;
    line-height: 16px;
  }
  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1em;
  }
`;

export const Dropdown = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;

  .styled {
    background: #444444;
  }
  .cards {
    display: ${({ open, isActive }) => (open || isActive ? 'block' : 'none')};
    position: absolute;
    background-color: #444444;
    min-width: 100px;
    box-shadow: 6px 6px 48px #111111;
    border-radius: 12px;
    z-index: 1;
    right: 24px;
    button:last-child {
      border-radius: 0 0 12px 12px;
    }
  }
  .button {
    background: #444444;
    color: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.025em;
    display: block;
    width: 191px;
    border-bottom: 1px solid #444444;
    border-left: 0;
    border-top: 0;
    color: inherit;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    text-transform: none;
    text-align: start;
    margin: 0;
    padding: 12px;
    height: 44px;
  }
`;

export const ButtonText = styled.div`
  font-weight: 700;
  font-size: 9px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 500px;
  gap: 12px;

  @media (min-width: 500px) {
    flex-direction: row;
  }
`;

export const UploadButton = styled.button`
  cursor: pointer;
  width: 100%;
  height: 60px;
  border-radius: 12px;
  padding: ${({ padding }) => padding};

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;
