import React from 'react';
// import { useNavigate } from 'react-router-dom';
import { VideoPlayer } from '@blackdove/player';

// Components
import { Button } from '../../components/Button';
// import { ArtworkCard } from '../../components/Artwork/Card';
// import { CollectionCard } from '../../components/Collections/Card';
// import { ArtistCard } from '../../components/Artists/Card';
// import { ArtCardSkeleton } from '../../components/Skeletons/ArtCardSkeleton';

// Hooks
// import { useSingleCollectionInfinite, useSingleArtist } from '../../hooks/data';
import useWindowDimensions from '../../hooks/useWindowDimensions';

// Assets
// import digitalCanvas from '../../assets/images/digitalCanvasOne.webp';
import resolution from '../../assets/images/icons/4k.png';
import media from '../../assets/images/icons/Media Player.png';
import secure from '../../assets/images/icons/Secure Storage.png';
import smartphone from '../../assets/images/icons/Smartphone.png';

import {
  // Wrapper,
  CarouselContainer,
  VideoAspectRatioBox,
  VideoFrameTwo,
  // HeaderContainer,
  // TopHeader,
  // TopSubheader,
  // Divider,
  // CardContainer,
  // CollectionContainer,
  InfoWrapper,
  FlexContainer,
  TextContainer,
  HeaderText,
  HeaderSubText,
  FeatureItem,
  CardTitle,
  CardText,
  FormWrapper,
  WrapperTwo,
  FlexWrapperTwo,
} from './styled';
import { DesktopVideoPlayer, MobileVideoPlayer } from './Landing/styled';

const Home = () => {
  // const navigate = useNavigate();
  const { width } = useWindowDimensions();
  // const artworks = useSingleCollectionInfinite(
  //   '05bd1e8a-2ad2-4ac8-ab36-aa2a7abd94aa'
  // );

  // const collectionOne = useSingleCollectionInfinite(
  //   'abf02b4d-7887-469f-b339-79e9cff396ce'
  // );
  // const collectionTwo = useSingleCollectionInfinite(
  //   '55fabc99-803a-4281-8134-02c63ef385f8'
  // );
  // const collectionThree = useSingleCollectionInfinite(
  //   '1cfb7f75-c839-40ac-9a3a-7373998effef'
  // );
  // const collectionFour = useSingleCollectionInfinite(
  //   '5657b510-4edc-4026-90b9-33b87fe99b15'
  // );
  // const collectionFive = useSingleCollectionInfinite(
  //   'edf96b67-f748-4ad3-aa34-fe189fb738fe'
  // );
  // const collectionSix = useSingleCollectionInfinite(
  //   '0c265f60-bd5a-4685-90d3-df1eb50c9e94'
  // );
  // const collections = [
  //   collectionOne,
  //   collectionTwo,
  //   collectionThree,
  //   collectionFour,
  //   collectionFive,
  //   collectionSix,
  // ];

  // const artistOne = useSingleArtist(
  //   'WVhKMGFYTjA0NTM4NThkMC03OWRmLTExZWEtYmUzMS0xZDM2ODcxYTY1ZDY='
  // );
  // const artistTwo = useSingleArtist('jonathanmccabe');
  // const artistThree = useSingleArtist('benheim');
  // const artistFour = useSingleArtist('mgoglktko');
  // const artistFive = useSingleArtist(
  //   'WVhKMGFYTjA0ZmIzNmJlMC01MGE0LTExZWMtOTZhZS0wOTExMTRkYjQ5NmU='
  // );
  // const artistSix = useSingleArtist('scorpiondagger');
  // const artistSeven = useSingleArtist(
  //   'WVhKMGFYTjA1YzdmM2ZkMC05NjFlLTExZWEtYTM5OS1iZDUxZjk0YTUwMGQ='
  // );
  // const artistEight = useSingleArtist(
  //   'WVhKMGFYTjAxYjA5MmViMC03ZWU0LTExZTgtOWYyOC1iZmE0YjhhZjBiMGM='
  // );
  // const artists = [
  //   artistOne,
  //   artistTwo,
  //   artistThree,
  //   artistFour,
  //   artistFive,
  //   artistSix,
  //   artistSeven,
  //   artistEight,
  // ];

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/shell.js';
    document.body.appendChild(script);
    const hbsptLoad = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '5104433',
          formId: '181d3a10-07f4-4bcd-80e6-f2d00aee3be8',
          target: '#hubspotForm',
        });
      }
    };
    script.addEventListener('load', hbsptLoad);
  }, []);

  return (
    <>
      <CarouselContainer height={window.innerHeight}>
        <VideoAspectRatioBox
          paddingBottom="177.78%"
          paddingBottomLarge="56.25%"
        >
          {width > 767 ? (
            <>
              <VideoFrameTwo>
                <VideoPlayer
                  videoUrl="https://assets.blackdove.com/videos/BD_WEB_desk-Digital_Canvas_x_Braden.mp4"
                  artistName=""
                  initialize
                  autoplay
                  muted
                  showLogo={false}
                  showLoading={false}
                />
              </VideoFrameTwo>
            </>
          ) : (
            <>
              <VideoFrameTwo>
                <VideoPlayer
                  videoUrl="https://assets.blackdove.com/videos/BD_WEB_mobile-Digital_Canvas_x_Braden.mp4"
                  artistName=""
                  initialize
                  autoplay
                  muted
                  showLogo={false}
                  showLoading={false}
                />
              </VideoFrameTwo>
            </>
          )}
        </VideoAspectRatioBox>
      </CarouselContainer>

      {/* <Wrapper>
        <HeaderContainer>
          <TopHeader>Featured artwork</TopHeader>
          <TopSubheader>
            Explore artworks researched and curated by Blackdove&apos;s
            curatorial team
          </TopSubheader>
        </HeaderContainer>
        <Divider />
        <CardContainer flexBasis={1 / 2} flexBasisLarge={1 / 3}>
          {artworks.isLoading ? (
            <>
              <ArtCardSkeleton margin={0} marginMid={0} />
              <ArtCardSkeleton margin={0} marginMid={0} />
              <ArtCardSkeleton margin={0} marginMid={0} />
              <ArtCardSkeleton margin={0} marginMid={0} />
              <ArtCardSkeleton margin={0} marginMid={0} />
              <ArtCardSkeleton margin={0} marginMid={0} />
            </>
          ) : (
            artworks.data?.pages?.[0]?.collection?.artwork.map((art) => (
              <>
                <ArtworkCard artwork={art} />
              </>
            ))
          )}
        </CardContainer>
        <Button
          color="red"
          className="button"
          onClick={() => navigate('artwork/latest')}
        >
          View all artworks
        </Button>
      </Wrapper> */}

      <InfoWrapper>
        <FlexContainer>
          <FeatureItem>
            <img src={resolution} alt="4k-icon" />
            <div className="text">
              All art in stunning 4K resolution and smooth 60FPS playback
            </div>
          </FeatureItem>
          <FeatureItem>
            <img src={media} alt="display-icon" />
            <div className="text">
              Exhibit your collection of art on a Smart TV or the Digital Canvas
            </div>
          </FeatureItem>
          <FeatureItem>
            <img src={secure} alt="secure-icon" />
            <div className="text">
              Artwork is securely stored on IPFS as well as our own servers
            </div>
          </FeatureItem>
          <FeatureItem>
            <img src={smartphone} alt="mobile-icon" />
            <div className="text">
              Companion mobile app to manage & display your collection
            </div>
          </FeatureItem>
        </FlexContainer>
      </InfoWrapper>

      {/* <Wrapper>
        <HeaderContainer>
          <TopHeader>Featured collections</TopHeader>
          <TopSubheader>
            Explore collections researched and curated by Blackdove&apos;s
            curatorial team
          </TopSubheader>
        </HeaderContainer>
        <Divider />
      </Wrapper>
      <CollectionContainer>
        {collections.map((collection) => (
          <>
            <CollectionCard
              collection={collection.data?.pages?.[0]?.collection}
            />
          </>
        ))}
      </CollectionContainer>
      <Wrapper>
        <Button
          color="red"
          className="button"
          onClick={() => navigate('/collections')}
        >
          View all collections
        </Button>
      </Wrapper> */}

      {/* <InfoWrapper>
        <FlexContainer>
          <ImageContainer>
            <ImageAspectRatioBox paddingBottom="100">
              <VideoFrame>
                <img src={digitalCanvas} alt="digital canvas" />
              </VideoFrame>
            </ImageAspectRatioBox>
          </ImageContainer>
          <TextContainer>
            <HeaderText>Premium Digital Canvas Installations</HeaderText>
            <HeaderSubText>
              Enjoy the most pleasing digital art experience on the Blackdove
              high-resolution 4K Digital Canvas available from 55” to 98”
            </HeaderSubText>
            <Button
              color="red"
              onClick={() =>
                // window.open('https://canvas.blackdove.com').focus()
                window.open('https://digitalcanvas.io').focus()
              }
            >
              Shop now
            </Button>
          </TextContainer>
        </FlexContainer>
      </InfoWrapper> */}

      <InfoWrapper>
        <FlexContainer>
          <VideoAspectRatioBox paddingBottom="60vh">
            <VideoFrameTwo objectFit="cover">
              <MobileVideoPlayer>
                <VideoPlayer
                  videoUrl="https://assets.blackdove.com/videos/BD_CA_mobile-Banner.mp4"
                  initialize
                  autoplay
                  muted
                  showLogo={false}
                  showLoading={false}
                />
              </MobileVideoPlayer>
              <DesktopVideoPlayer>
                <VideoPlayer
                  videoUrl="https://assets.blackdove.com/videos/BD_CA_desktop-Banner.mp4"
                  initialize
                  autoplay
                  muted
                  showLogo={false}
                  showLoading={false}
                />
              </DesktopVideoPlayer>
            </VideoFrameTwo>
          </VideoAspectRatioBox>
        </FlexContainer>
        <TextContainer>
          <HeaderText>Premium Digital Canvas Installations</HeaderText>
          <HeaderSubText>
            Enjoy the most pleasing digital art experience on the Blackdove
            high-resolution 4K Digital Canvas available from 55” to 98”
          </HeaderSubText>
          <Button
            color="red"
            onClick={() =>
              // window.open('https://canvas.blackdove.com').focus()
              window.open('https://digitalcanvas.io').focus()
            }
            style={{ width: 200 }}
          >
            Shop now
          </Button>
        </TextContainer>
      </InfoWrapper>

      {/* <Wrapper>
        <HeaderContainer>
          <TopHeader>Featured artists</TopHeader>
          <TopSubheader>
            Explore artists researched and curated by Blackdove&apos;s
            curatorial team
          </TopSubheader>
        </HeaderContainer>
        <Divider />
        <CardContainer flexBasis={1 / 2}>
          {artists.map((artist) => (
            <>
              <ArtistCard artist={artist.data} />
            </>
          ))}
        </CardContainer>
        <Button
          color="red"
          className="button"
          onClick={() => navigate('/artists')}
        >
          View all artists
        </Button>
      </Wrapper> */}

      <WrapperTwo bg="#1a1a1a">
        <FlexWrapperTwo fd="column" fdl="row" margin="auto">
          <FlexWrapperTwo
            fd="column"
            fbl="40%"
            padding="48px 24px"
            paddingLarge="96px 48px"
            jc="center"
            ai="center"
          >
            <CardTitle>Get in touch!</CardTitle>
            <CardText ta="center">
              Need some help? Send us a message through our form and one of our
              associates will get in touch with you shortly. info@blackdove.com
            </CardText>
          </FlexWrapperTwo>
          <FlexWrapperTwo
            fd="column"
            fbl="60%"
            bg="#222222"
            padding="24px"
            paddingLarge="48px"
            jc="center"
            ai="center"
          >
            <FormWrapper>
              <div className="contact" id="hubspotForm" />
            </FormWrapper>
          </FlexWrapperTwo>
        </FlexWrapperTwo>
      </WrapperTwo>
    </>
  );
};

export default Home;
