import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import styled from '@emotion/styled';

// Styled
const Wrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 24px;
  background: #910048;
  display: flex;
  flex-direction: column;
  border-radius: 14px;
`;

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 24px;
  background: #2b2b2b;
  display: flex;
  flex-direction: column;
  border-radius: 14px;
`;

const Input = styled.input`
  width: 100%;
  border: none;
  background: #2b2b2b;
  border-radius: 0 0 8px 8px;
  color: ${({ theme }) => theme.colors.white};
  padding: 0 0 0px 16px;
  max-height: 46px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;

  ::placeholder {
    color: #fff;
    opacity: 1;
  }

  &:focus {
    outline: none;
    ::placeholder {
      color: #a5a5a5;
      opacity: 1;
    }
  }

  &:disabled {
    opacity: 0.5;
  }

  &:-webkit-autofill {
    border: 2px solid ${({ theme }) => theme.colors.base3};
    -webkit-text-fill-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 0 0px 1000px ${({ theme }) => theme.colors.base3} inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const Label = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.025em;
  text-align: start;
  padding: 8px 0 0 16px;
  color: #a5a5a5;
  background: #2b2b2b;
  display: inline-block;
  border-radius: 8px 8px 0 0;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
`;

const LengthCounter = styled.div`
  background: ${({ exceed, theme }) =>
    exceed ? theme.colors.warning : theme.colors.base3};
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.45);
  border-radius: 0 0 8px 8px;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  text-align: end;
  color: ${({ theme }) => theme.colors.white};
  padding: 6px;
  background: #910048;
  height: 28px;
`;

const HelperText = styled.span`
  display: block;
`;

const ErrorText = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.warning};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
`;

const InputContainer = styled.div`
  display: flex;
  background: #2b2b2b;
  max-height: 56px;
  border-radius: 8px;

  svg {
    cursor: ${({ iconClick }) => iconClick && 'pointer'};
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export function TextInput({
  field,
  form: { touched, errors },
  label,
  helperText,
  maxLength,
  Icon,
  iconClick,
  iconColor,
  iconText,
  iconWidth,
  iconHeight,
  iconViewBox,
  ...props
}) {
  const hasError =
    getIn(touched, field.name) && Boolean(getIn(errors, field.name));
  const errorText = getIn(errors, field.name);

  return (
    <Wrapper>
      <InputContainer iconClick={iconClick}>
        <InputWrapper>
          {label && <Label htmlFor={field.name}>{label}</Label>}
          <Input
            type="text"
            id={field.name}
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            maxLength={maxLength > 0 ? maxLength : ''}
            {...props}
          />
        </InputWrapper>
        {Icon && (
          <IconWrapper onClick={iconClick}>
            <Icon
              className="icon"
              color={iconColor}
              width={iconWidth}
              height={iconHeight}
              viewBox={iconViewBox}
              alt={iconText}
            />
          </IconWrapper>
        )}
      </InputContainer>
      {maxLength && (
        <LengthCounter
          exceed={field.value?.length > maxLength}
        >{`${field.value?.length} / ${maxLength}`}</LengthCounter>
      )}
      {helperText && <HelperText>{helperText}</HelperText>}
      {hasError && <ErrorText>{errorText}</ErrorText>}
    </Wrapper>
  );
}

TextInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
  }).isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  maxLength: PropTypes.number,
  Icon: PropTypes,
  iconClick: PropTypes.func,
  iconColor: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
  iconViewBox: PropTypes.string,
  iconText: PropTypes.string,
};

TextInput.defaultProps = {
  label: '',
  helperText: '',
  maxLength: null,
  Icon: null,
  iconClick: null,
  iconColor: '#ffffff',
  iconWidth: '24',
  iconHeight: '24',
  iconViewBox: '0 0 16 16',
  iconText: null,
};
