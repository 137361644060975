import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  format,
  parseISO,
  differenceInSeconds,
  differenceInMinutes,
} from 'date-fns';

const IngestWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 70%;
  overflow: hidden;
`;

const IngestStep = styled.div`
  padding: 10px 0;
  margin-right: 2px;
  text-align: start;

  .sub {
    display: flex;
    justify-content: center;
    > * {
      &:first-child {
        margin-right: 12px;
      }
    }
  }
`;

const StepTitle = styled.span`
  width: 100%;
  display: block;
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  border-radius: 24px;
  padding: 12px 24px;

  background: ${({ isCompleted, isCurrent, isError, theme }) => {
    if (isCurrent) return theme.colors.success;
    if (isCompleted) return theme.colors.accent;
    if (isError) return theme.colors.error;
    return theme.colors.base3;
  }};
`;

const StepDate = styled.span`
  display: block;
  font-size: 0.8rem;
`;

const getDuration = (start, end) => {
  const seconds = differenceInSeconds(start, end);
  const minutes = differenceInMinutes(start, end);
  const extraSeconds = seconds - minutes * 60;

  if (seconds < 59) return `${seconds}s`;
  if (seconds === 60) return '1m';
  return `${minutes}m ${extraSeconds}s`;
};

export const IngestTimeline = ({ artwork }) => {
  const { events, status, transcodeMetadata } = artwork?.sourceFile;

  const uploadedDate = events?.uploadedAt ? parseISO(events?.uploadedAt) : null;
  const analyzedDate = events?.analyzedAt ? parseISO(events?.analyzedAt) : null;
  const queuedDate = events?.queuedAt ? parseISO(events?.queuedAt) : null;
  const transcodeCompletedDate = events?.transcodeCompletedAt
    ? parseISO(events?.transcodeCompletedAt)
    : null;
  const ingestCompletedDate = events?.ingestCompletedAt
    ? parseISO(events?.ingestCompletedAt)
    : null;
  const failedDate = events?.failedAt ? parseISO(events?.failedAt) : null;

  return (
    <IngestWrapper>
      <IngestStep>
        <StepTitle isCompleted isCurrent={false} isFirst>
          Started
        </StepTitle>
        {events?.uploadedAt && (
          <div className="sub">
            <StepDate>{format(uploadedDate, 'MMM dd, yyyy')}</StepDate>
            <StepDate>{format(uploadedDate, 'hh:mm:ss a')}</StepDate>
          </div>
        )}
      </IngestStep>
      <IngestStep>
        <StepTitle
          isCompleted={events?.analyzedAt !== undefined}
          isCurrent={status === 'UPLOADED'}
        >
          {status === 'UPLOADED'
            ? 'Analyzing'
            : `Analyzed ${
                events.analyzedAt ? getDuration(analyzedDate, uploadedDate) : ''
              }`}
        </StepTitle>
        {events?.analyzedAt && (
          <div className="sub">
            <StepDate>{format(analyzedDate, 'MMM dd, yyyy')}</StepDate>
            <StepDate>{format(analyzedDate, 'hh:mm:ss a')}</StepDate>
          </div>
        )}
      </IngestStep>
      <IngestStep>
        <StepTitle
          isCompleted={events?.queuedAt !== undefined}
          isCurrent={status === 'ANALYZED'}
        >
          {status === 'ANALYZED'
            ? 'Queuing'
            : `Queued ${
                events.queuedAt ? getDuration(queuedDate, analyzedDate) : ''
              }`}
        </StepTitle>
        {events?.queuedAt && (
          <div className="sub">
            <StepDate>{format(queuedDate, 'MMM dd, yyyy ')}</StepDate>
            <StepDate>{format(queuedDate, 'hh:mm:ss a')}</StepDate>
          </div>
        )}
      </IngestStep>
      <IngestStep large>
        <StepTitle
          isCompleted={events?.transcodeCompletedAt !== undefined}
          isCurrent={status === 'TRANSCODING'}
          isError={events?.failedAt !== undefined}
        >
          {status === 'TRANSCODING'
            ? `Transcoding ${transcodeMetadata.jobPercentComplete}%`
            : `Transcoded ${
                events.transcodeCompletedAt
                  ? getDuration(transcodeCompletedDate, queuedDate)
                  : ''
              }`}
        </StepTitle>
        {events?.transcodeCompletedAt && (
          <div className="sub">
            <StepDate>
              {format(transcodeCompletedDate, 'MMM dd, yyyy')}
            </StepDate>
            <StepDate>{format(transcodeCompletedDate, 'hh:mm:ss a')}</StepDate>
          </div>
        )}
      </IngestStep>
      {status === 'FAILED' && (
        <IngestStep>
          <StepTitle isCompleted={false} isCurrent={false} isError>
            Failed
          </StepTitle>
          {events?.ingestCompletedAt && (
            <div className="sub">
              <StepDate>{format(failedDate, 'MMM dd, yyyy')}</StepDate>
              <StepDate>{format(failedDate, 'hh:mm:ss a')}</StepDate>
            </div>
          )}
        </IngestStep>
      )}
      {status !== 'FAILED' && (
        <IngestStep>
          <StepTitle
            isCompleted={events?.ingestCompletedAt !== undefined}
            isCurrent={status === 'TRANSCODED'}
            isLast
          >
            {status === 'TRANSCODED' ? 'Packaging' : 'Completed'}
          </StepTitle>
          {events?.ingestCompletedAt && (
            <div className="sub">
              <StepDate>{format(ingestCompletedDate, 'MMM dd, yyyy')}</StepDate>
              <StepDate>{format(ingestCompletedDate, 'hh:mm:ss a')}</StepDate>
            </div>
          )}
        </IngestStep>
      )}
    </IngestWrapper>
  );
};

IngestTimeline.propTypes = {
  artwork: PropTypes.object.isRequired,
};
