import React from 'react';

// Components
import { Button } from '../../../components/Button';

// Data
import { dataOne } from './data';

// Assets
import living from '../../../assets/images/partners/Living Space.jpg';
import playIcon from '../../../assets/images/galleries/PlayRed.svg';

// Styled
import {
  Wrapper,
  WrapperTwo,
  IconWrapper,
  IconHeader,
  Icon,
  TopCardWrapper,
  CardContainer,
  ThumbnailContainer,
  ThumbnailAspectRatioBox,
  ThumbnailFrame,
  ThumbnailImage,
  CardTextContainer,
  CardTitle,
  CardText,
  VideoContainer,
  VideoAspectRatioBoxHeader,
  VideoFrameTwo,
  ImageWrapper,
  FlexContainer,
  TitleWrapper,
  CardSubTitle,
  ClientWrapper,
  FlexWrapper,
  TestimonialWrapper,
  CardWrapper,
  CardTextContainerTwo,
  ClientTitle,
  TopCardWrapperTwo,
  HeaderBarWrapper,
  HeaderBar,
  HeaderTitle,
  HeaderSubtitle,
  ScrollIcon,
  FlexWrapperTwo,
  FormWrapper,
  ButtonWrapper,
} from './styled';

const Partners = () => {
  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/shell.js';
    document.body.appendChild(script);
    const hbsptLoad = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '5104433',
          formId: '181d3a10-07f4-4bcd-80e6-f2d00aee3be8',
          target: '#hubspotForm',
        });
      }
    };
    script.addEventListener('load', hbsptLoad);
  }, []);

  return (
    <div>
      <VideoContainer>
        <VideoAspectRatioBoxHeader>
          <VideoFrameTwo>
            <img src={living} alt="Living Space" />
          </VideoFrameTwo>
          <HeaderBarWrapper>
            <HeaderBar>
              <HeaderTitle>Digital Art Installations</HeaderTitle>
              <HeaderSubtitle>
                Let us craft an unparalleled digital art experience: Build in
                Blackdove.
              </HeaderSubtitle>
              <ButtonWrapper>
                <Button color="red" href="#hubspotForm">
                  Contact Us
                </Button>
              </ButtonWrapper>
            </HeaderBar>
            <ScrollIcon>
              <img src={playIcon} alt="" />
            </ScrollIcon>
          </HeaderBarWrapper>
        </VideoAspectRatioBoxHeader>
      </VideoContainer>

      <Wrapper>
        <div>
          <TopCardWrapper>
            {dataOne.firstCards.map((card) => (
              <CardContainer className="first">
                <ThumbnailContainer borderRadius="24px 24px 0 0">
                  <ThumbnailAspectRatioBox paddingBottom="56.25%">
                    <ThumbnailFrame>
                      <ThumbnailImage backgroundImage={card.image} />
                    </ThumbnailFrame>
                  </ThumbnailAspectRatioBox>
                </ThumbnailContainer>
                <CardTextContainer>
                  {card?.title && (
                    <CardTitle className="title">{card.title}</CardTitle>
                  )}
                  {card?.text && (
                    <CardText className="text">{card.text}</CardText>
                  )}
                  {card?.textTwo && <CardText>{card.textTwo}</CardText>}
                  {card?.textThree && <CardText>{card.textThree}</CardText>}
                </CardTextContainer>
              </CardContainer>
            ))}
          </TopCardWrapper>
          <TopCardWrapper>
            <CardContainer className="integration">
              <TitleWrapper>
                <CardTitle>Seamless Integrations</CardTitle>
              </TitleWrapper>
              {dataOne.secondCards.map((card) => (
                <FlexContainer>
                  <ImageWrapper>
                    <img src={card.image} alt="device integration" />
                  </ImageWrapper>
                  <CardTextContainer>
                    {card?.title && <CardSubTitle>{card.title}</CardSubTitle>}
                    {card?.text && <CardText>{card.text}</CardText>}
                    {card?.textTwo && <CardText>{card.textTwo}</CardText>}
                    {card?.textThree && <CardText>{card.textThree}</CardText>}
                  </CardTextContainer>
                </FlexContainer>
              ))}
            </CardContainer>
          </TopCardWrapper>
          <CardWrapper>
            {dataOne.testimonial.map((card) => (
              <FlexContainer>
                <TestimonialWrapper>
                  {card?.title && <CardSubTitle>{card.title}</CardSubTitle>}
                  {card?.text && (
                    <CardText className="text">{card.text}</CardText>
                  )}
                  {card?.textTwo && (
                    <CardText className="text-two">{card.textTwo}</CardText>
                  )}
                </TestimonialWrapper>
                <ThumbnailContainer
                  className="thumbnail-container"
                  borderRadius="0 0 24px 24px"
                >
                  <ThumbnailAspectRatioBox
                    className="aspect"
                    paddingBottom="56.25%"
                  >
                    <ThumbnailFrame>
                      <ThumbnailImage backgroundImage={card.image} />
                    </ThumbnailFrame>
                  </ThumbnailAspectRatioBox>
                </ThumbnailContainer>
              </FlexContainer>
            ))}
          </CardWrapper>
          <IconWrapper>
            <TitleWrapper>
              <CardTitle className="title">Industry Partners</CardTitle>
            </TitleWrapper>
            <div>
              <IconHeader>
                <p>Home Automation</p>
              </IconHeader>
              <div className="iconsTwo">
                {dataOne.iconsThree.map((icon) => (
                  <Icon className="iconThree" src={icon} />
                ))}
              </div>
            </div>
            <div>
              <IconHeader>
                <p>Display Integrations</p>
              </IconHeader>
              <div className="iconsTwo">
                {dataOne.iconsTwo.map((icon) => (
                  <Icon className="iconTwo" src={icon} />
                ))}
              </div>
            </div>
          </IconWrapper>
          <TopCardWrapperTwo>
            <CardContainer>
              <TitleWrapper>
                <ClientTitle>Client Installs</ClientTitle>
              </TitleWrapper>
              <FlexWrapper>
                {dataOne.fourthCards.map((card) => (
                  <ClientWrapper>
                    <ThumbnailContainer
                      className="install"
                      borderRadius="24px 24px 24px 24px"
                    >
                      <ThumbnailAspectRatioBox paddingBottom="133.33%">
                        <ThumbnailFrame>
                          <ThumbnailImage backgroundImage={card.image} />
                        </ThumbnailFrame>
                      </ThumbnailAspectRatioBox>
                    </ThumbnailContainer>
                    <CardTextContainerTwo>
                      {card?.title && (
                        <CardTitle className="title">{card.title}</CardTitle>
                      )}
                      {card?.text && (
                        <CardText className="text">{card.text}</CardText>
                      )}
                      {card?.textTwo && (
                        <CardText className="text">{card.textTwo}</CardText>
                      )}
                      {card?.textThree && (
                        <CardText className="text">{card.textThree}</CardText>
                      )}
                    </CardTextContainerTwo>
                  </ClientWrapper>
                ))}
              </FlexWrapper>
            </CardContainer>
          </TopCardWrapperTwo>
          <WrapperTwo bg="#1a1a1a">
            <FlexWrapperTwo fd="column" fdl="row" margin="auto">
              <FlexWrapperTwo
                fd="column"
                fbl="40%"
                padding="48px 24px"
                paddingLarge="96px 48px"
                jc="center"
                ai="center"
              >
                <CardTitle>Get in touch!</CardTitle>
                <CardText ta="center">
                  Enhance the visual appeal of the displays you are installing,
                  make your clients happier and earn new revenues.
                </CardText>
              </FlexWrapperTwo>
              <FlexWrapperTwo
                fd="column"
                fbl="60%"
                bg="#222222"
                padding="24px"
                paddingLarge="48px"
                jc="center"
                ai="center"
              >
                <FormWrapper>
                  <div className="contact" id="hubspotForm" />
                </FormWrapper>
              </FlexWrapperTwo>
            </FlexWrapperTwo>
          </WrapperTwo>
        </div>
      </Wrapper>
    </div>
  );
};

export default Partners;
