import React from 'react';
import PropTypes from 'prop-types';

export function Facebook({ color, width, height, viewBox }) {
  return (
    <div>
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox={viewBox || '0 0 16 16'}
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.00016 1.33333C4.32418 1.33333 1.3335 4.32402 1.3335 8C1.3335 11.676 4.32418 14.6667 8.00016 14.6667C11.6761 14.6667 14.6668 11.676 14.6668 8C14.6668 4.32402 11.6761 1.33333 8.00016 1.33333ZM8.00016 2.33333C11.1357 2.33333 13.6668 4.86446 13.6668 8C13.6668 10.7936 11.6559 13.1021 9.00016 13.5736V9.66666H10.2085C10.3765 9.66666 10.5186 9.54101 10.5392 9.37434L10.6642 8.37434C10.6762 8.27968 10.6462 8.18463 10.5828 8.11263C10.5198 8.04096 10.4292 8 10.3335 8H9.00016V6.83333C9.00016 6.46566 9.29916 6.16666 9.66683 6.16666H10.3335C10.5175 6.16666 10.6668 6.01766 10.6668 5.83333V4.70833C10.6668 4.53566 10.5351 4.39163 10.3634 4.3763C10.3438 4.37463 9.87349 4.33333 9.27816 4.33333C7.80916 4.33333 7.00016 5.20539 7.00016 6.78906V8H5.66683C5.48283 8 5.3335 8.14899 5.3335 8.33333V9.33333C5.3335 9.51766 5.48283 9.66666 5.66683 9.66666H7.00016V13.5736C4.34444 13.1021 2.3335 10.7936 2.3335 8C2.3335 4.86446 4.86462 2.33333 8.00016 2.33333Z"
          fill={color}
        />
      </svg>
    </div>
  );
}

Facebook.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};

Facebook.defaultProps = {
  color: '#ffffff',
  height: '16',
  width: '16',
  viewBox: '0 0 16 16',
};
