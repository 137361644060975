import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Components
import { Loader } from '../../components/Loader';
import { Analytics } from '../Analytics';
import { Earnings } from '../Earnings';

// Hooks
import { useUser } from '../../hooks/data';

// Styled Components
import { Wrapper, FilterWrapper, ButtonWrapper, DividerTwo } from './styled';

export function Statistics() {
  const user = useUser();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Wrapper>
      {user.isLoading ? (
        <Loader />
      ) : (
        <>
          <FilterWrapper>
            <ButtonWrapper
              active={
                pathname === '/statistics/analytics' ||
                pathname === '/statistics'
              }
              onClick={() => navigate('/statistics')}
            >
              ANALYTICS
            </ButtonWrapper>
            <DividerTwo />
            <ButtonWrapper
              active={pathname === '/statistics/earnings'}
              onClick={() => navigate('/statistics/earnings')}
            >
              EARNINGS
            </ButtonWrapper>
          </FilterWrapper>
        </>
      )}
      {!user.isLoading &&
        (pathname === '/statistics/analytics' ||
          pathname === '/statistics') && (
          <>
            <Analytics />
          </>
        )}

      {!user.isLoading &&
        user.data?.user?.artist?.id &&
        pathname === '/statistics/earnings' && (
          <>
            <Earnings />
          </>
        )}
    </Wrapper>
  );
}
