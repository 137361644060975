import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNotifications } from 'reapop';

// Components
import { HeaderSplash } from '../HeaderSplash';
import { Button } from '../Button';
import { WalletList } from '../Wallets/WalletList';

// Actions
import { openModal } from '../../store/app/actions';

// Hooks
import { useDeleteCollection } from '../../hooks/mutations';
import { useMenuClickOutside } from '../../hooks/useMenuClickOutside';
import { useAllWallets } from '../../hooks/data';

// Assets
import bdlogo from '../../assets/images/bd-logo-dove.svg';
import dots from '../../assets/images/More.svg';

// Styled
import {
  SplashWrapper,
  CollectionInfoWrapper,
  InteractionWrapper,
  VideoAspectRatioBoxHeader,
  CollectionPic,
  CollectionPicWrapper,
  CollectionTitle,
  ButtonWrapper,
  MetadataWrapper,
  CollectionDescription,
  Dropdown,
  ButtonText,
} from './styled';

const CollectionHeader = ({
  id,
  image,
  creator,
  name,
  user,
  total,
  artwork,
  followerCount,
  description,
  collectionType,
  isFollowing,
  handlePlay,
  handleFollow,
  handleUnfollow,
  handleAddDiscovery,
  handleUpload,
  handleAddWallet,
  handleWalletRefresh,
  setOrderBy,
  orderBy,
}) => {
  const [more, setMore] = React.useState(false);
  const dropRef = useRef(null);
  const [isActive, setIsActive] = useMenuClickOutside(dropRef, false);

  const dispatch = useDispatch();
  const { notify } = useNotifications();
  const wallets = useAllWallets();
  const deleteCollection = useDeleteCollection();

  const handleEditCollection = () => {
    dispatch(
      openModal({
        modal: 'COLLECTION',
        params: { id, name, description, image },
      })
    );
  };

  const handleReorder = () => {
    dispatch(openModal({ modal: 'ORDER_ARTWORK', params: { id } }));
  };

  const handleDeleteCollection = () => {
    if (confirm('Are you sure you want to delete this playlist?')) {
      deleteCollection.mutate({ id });
    }
  };

  const handleOrderBy = (filter) => {
    setOrderBy(filter);
    setIsActive(!isActive);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `https://app.blackdove.com/collections/${id}`
    );
    notify({
      status: 'success',
      title: 'Copied to Clipboard',
      message:
        'The share link for this collection was copied to your clipboard.',
    });
  };

  return (
    <>
      <SplashWrapper>
        <VideoAspectRatioBoxHeader>
          <img
            src={artwork?.[0]?.media?.image?.low?.landscape}
            alt="header splash"
          />
        </VideoAspectRatioBoxHeader>
        <CollectionPicWrapper>
          <CollectionPic>
            <img src={image || bdlogo} alt="collection" />
          </CollectionPic>
        </CollectionPicWrapper>
      </SplashWrapper>
      <HeaderSplash padding="0" paddingLarge="0">
        <InteractionWrapper>
          <MetadataWrapper xsUp="true">
            <span>{total || 0} Artworks</span>
            <span>{followerCount || 0} Followers</span>
          </MetadataWrapper>
          {collectionType === 'USER_UPLOAD' && (
            <>
              <Dropdown isActive={isActive} ref={dropRef}>
                <Button type="button" onClick={() => setIsActive(!isActive)}>
                  <ButtonText>
                    {orderBy?.[0]?.[1] === 'DESC' ? 'Most Recent' : 'Oldest'}
                  </ButtonText>
                </Button>
                <div className="cards">
                  <button
                    className="button"
                    type="button"
                    onClick={() =>
                      handleOrderBy([
                        ['createdAt', 'DESC'],
                        ['order', 'ASC'],
                      ])
                    }
                  >
                    Most Recent
                  </button>
                  <button
                    className="button"
                    type="button"
                    onClick={() =>
                      handleOrderBy([
                        ['createdAt', 'ASC'],
                        ['order', 'ASC'],
                      ])
                    }
                  >
                    Oldest
                  </button>
                </div>
              </Dropdown>
            </>
          )}
          {collectionType === 'STANDARD' && (
            <>
              {creator?.id === user?.data?.user?.id && (
                <>
                  <Dropdown isActive={isActive} ref={dropRef}>
                    <Button
                      type="button"
                      onClick={() => setIsActive(!isActive)}
                    >
                      <img src={dots} alt="more" />
                    </Button>
                    <div className="cards">
                      <button
                        className="button"
                        type="button"
                        onClick={handleUpload}
                      >
                        Upload art
                      </button>
                      <button
                        className="button"
                        type="button"
                        onClick={handleReorder}
                      >
                        Reorder
                      </button>
                      <button
                        className="button"
                        type="button"
                        onClick={handleAddDiscovery}
                      >
                        Submit for discovery
                      </button>
                      <button
                        className="button"
                        type="button"
                        onClick={handleCopy}
                      >
                        Share
                      </button>
                      <button
                        className="button"
                        type="button"
                        onClick={handleEditCollection}
                      >
                        Edit
                      </button>
                      <button
                        className="button"
                        type="button"
                        onClick={handleDeleteCollection}
                      >
                        Delete
                      </button>
                    </div>
                  </Dropdown>
                </>
              )}
              {!isFollowing && creator?.id !== user?.data?.user?.id && (
                <Button type="button" color="red" onClick={handleFollow}>
                  Follow
                </Button>
              )}
              {isFollowing && creator?.id !== user?.data?.user?.id && (
                <Button type="button" onClick={handleUnfollow}>
                  Unfollow
                </Button>
              )}
            </>
          )}
        </InteractionWrapper>
        <CollectionInfoWrapper>
          <CollectionTitle>{name}</CollectionTitle>
          {description && (
            <CollectionDescription>
              {more ? description : description.slice(0, 200)}
              <button type="button" onClick={() => setMore(!more)}>
                {description?.length > 200 &&
                  (more ? '...Show less' : '...Show more')}
              </button>
            </CollectionDescription>
          )}

          {collectionType === 'NFT_IMPORT' && wallets.isFetched && (
            <WalletList wallets={wallets} />
          )}

          {collectionType !== 'NFT_IMPORT' && (
            <MetadataWrapper>
              <p>Curated by</p>
              <div>{creator?.displayName}</div>
            </MetadataWrapper>
          )}

          <ButtonWrapper>
            {collectionType === 'STANDARD' && (
              <Button color="red" onClick={handlePlay}>
                Play
              </Button>
            )}

            {collectionType === 'USER_UPLOAD' &&
              creator?.id === user?.data?.user?.id && (
                <Button type="button" color="red" onClick={handleUpload}>
                  Add New
                </Button>
              )}

            {collectionType === 'NFT_IMPORT' &&
              creator?.id === user?.data?.user?.id && (
                <>
                  {wallets.isFetched && wallets.data.length > 0 && (
                    <Button
                      type="button"
                      color="red"
                      onClick={handleWalletRefresh}
                    >
                      Resync Wallets
                    </Button>
                  )}
                  {!wallets.isFetched ||
                    (wallets.data.length === 0 && (
                      <Button
                        type="button"
                        color="red"
                        onClick={handleAddWallet}
                      >
                        Add Wallet
                      </Button>
                    ))}
                </>
              )}
          </ButtonWrapper>
          <MetadataWrapper mDown="true">
            <span>{artwork ? artwork.length : 0} Artworks</span>
            <span>{followerCount || 0} Followers</span>
          </MetadataWrapper>
        </CollectionInfoWrapper>
      </HeaderSplash>
    </>
  );
};
export default CollectionHeader;

CollectionHeader.propTypes = {
  id: PropTypes.string.isRequired,
  creator: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  user: PropTypes.object,
  total: PropTypes.number,
  artwork: PropTypes.array.isRequired,
  followerCount: PropTypes.number,
  image: PropTypes.string,
  description: PropTypes.string,
  collectionType: PropTypes.string.isRequired,
  isFollowing: PropTypes.bool.isRequired,
  handlePlay: PropTypes.func.isRequired,
  handleFollow: PropTypes.func.isRequired,
  handleUnfollow: PropTypes.func.isRequired,
  handleAddDiscovery: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
  handleAddWallet: PropTypes.func.isRequired,
  handleWalletRefresh: PropTypes.func.isRequired,
  setOrderBy: PropTypes.func,
  orderBy: PropTypes.array,
};

CollectionHeader.defaultProps = {
  image: null,
  description: null,
  user: null,
  total: null,
  followerCount: 0,
  setOrderBy: null,
  orderBy: null,
};
