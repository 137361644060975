import { useQuery, useInfiniteQuery } from 'react-query';
import { BlackdoveClient } from '@blackdove/utils';

export function useUser() {
  const isAuthed = !!BlackdoveClient.getCredentials().accessToken;
  return useQuery(
    'user',
    async () => {
      const result = await BlackdoveClient.get('/me');
      return result.data;
    },
    { enabled: isAuthed }
  );
}

export function useSingleUser(id) {
  return useQuery(['user', id], async () => {
    const result = await BlackdoveClient.get(`/v2/users/${id}`, null, true);
    return result.data;
  });
}

export function useAccount() {
  const isAuthed = !!BlackdoveClient.getCredentials().accessToken;
  return useQuery(
    'account',
    async () => {
      const result = await BlackdoveClient.get('/v2/account');
      return result.data;
    },
    { enabled: isAuthed }
  );
}

export function useUserPublicCollections(userId) {
  return useInfiniteQuery(
    ['user', 'collections', 'public'],
    async ({ pageParam = { offset: 0 } }) => {
      const result = await BlackdoveClient.get(
        `/v2/collections/public/${userId}`,
        { ...pageParam },
        true
      );
      return result.data;
    },
    {
      getNextPageParam: (page) =>
        page.nextOffset < page.total ? { offset: page.nextOffset } : false,
    }
  );
}

export function useUserOwnedArtwork(userId) {
  return useInfiniteQuery(
    ['user', 'artworks', 'owned'],
    async ({ pageParam = { offset: 0 } }) => {
      const result = await BlackdoveClient.get(
        `/v2/artwork/owned/${userId}`,
        { ...pageParam },
        true
      );
      return result.data;
    },
    {
      getNextPageParam: (page) =>
        page.nextOffset < page.total ? { offset: page.nextOffset } : false,
    }
  );
}

export function useUserFollowing() {
  return useQuery(['user', 'following'], async () => {
    const result = await BlackdoveClient.get('/v2/users/followers');
    return result.data;
  });
}

export function useAllArtists(queryParams = {}) {
  const isAuthed = !!BlackdoveClient.getCredentials().accessToken;
  return useQuery('artists', async () => {
    const result = await BlackdoveClient.get(
      '/v2/artists',
      queryParams,
      !isAuthed
    );
    return result.data;
  });
}

export function useAllArtistsInfinite(queryKey, queryParams = {}, limit = 24) {
  const isAuthed = !!BlackdoveClient.getCredentials().accessToken;
  return useInfiniteQuery(
    queryKey,
    async ({ pageParam = { limit, offset: 0 } }) => {
      const result = await BlackdoveClient.get(
        '/v2/artists',
        { ...queryParams, ...pageParam },
        !isAuthed
      );
      return result.data;
    },
    {
      getNextPageParam: (page, allPages) => {
        const currentOffset = allPages.length * limit;
        const nextOffset = currentOffset + limit;
        return nextOffset < page.total ? { limit, offset: nextOffset } : false;
      },
    }
  );
}

export function useSingleArtist(id) {
  const isAuthed = !!BlackdoveClient.getCredentials().accessToken;
  return useQuery(['artists', id], async () => {
    const result = await BlackdoveClient.get(
      `/v2/artists/${id}`,
      null,
      !isAuthed
    );
    return result.data;
  });
}

export function useAllArtwork(queryParams = {}) {
  const isAuthed = !!BlackdoveClient.getCredentials().accessToken;
  return useQuery('artwork', async () => {
    const result = await BlackdoveClient.get(
      '/v2/artwork',
      queryParams,
      !isAuthed
    );
    return result.data;
  });
}

export function useAllArtworkInfinite(queryKey, queryParams = {}, limit = 12) {
  const isAuthed = !!BlackdoveClient.getCredentials().accessToken;
  return useInfiniteQuery(
    queryKey,
    async ({ pageParam = { limit, offset: 0 } }) => {
      const result = await BlackdoveClient.get(
        '/v2/artwork',
        { ...queryParams, ...pageParam },
        !isAuthed
      );
      return result.data;
    },
    {
      getNextPageParam: (page) =>
        page.nextOffset < page.total
          ? { limit, offset: page.nextOffset }
          : false,
    }
  );
}

export function useSingleArtwork(id) {
  const isAuthed = !!BlackdoveClient.getCredentials().accessToken;
  return useQuery(['artwork', id], async () => {
    const result = await BlackdoveClient.get(
      `/v2/artwork/${id}`,
      null,
      !isAuthed
    );
    return result.data;
  });
}

export function useTokens(id) {
  const isAuthed = !!BlackdoveClient.getCredentials().accessToken;
  return useQuery(
    ['artwork', id, 'tokens'],
    async () => {
      const result = await BlackdoveClient.get(
        `/v2/artwork/${id}/tokens`,
        null
      );
      return result.data;
    },
    { enabled: isAuthed }
  );
}

export function useAllCategories() {
  const isAuthed = !!BlackdoveClient.getCredentials().accessToken;
  return useQuery('categories', async () => {
    const result = await BlackdoveClient.get('/v2/categories', null, !isAuthed);
    return result.data;
  });
}

export function useManyCollections(queryKey, subpath = '', limit = 100) {
  const isAuthed = !!BlackdoveClient.getCredentials().accessToken;
  return useQuery(
    queryKey,
    async () => {
      const result = await BlackdoveClient.get(
        `/v2/collections${subpath}`,
        { limit },
        !isAuthed
      );
      return result.data;
    },
    { enabled: isAuthed }
  );
}

export function useManyCollectionsInfinite(queryKey, subpath = '', limit = 50) {
  const isAuthed = !!BlackdoveClient.getCredentials().accessToken;
  return useInfiniteQuery(
    queryKey,
    async ({ pageParam = { limit, offset: 0 } }) => {
      const result = await BlackdoveClient.get(
        `/v2/collections${subpath}`,
        { ...pageParam },
        !isAuthed
      );
      return result.data;
    },

    {
      getNextPageParam: (page) =>
        page.nextOffset < page.total
          ? { limit, offset: page.nextOffset }
          : false,
    }
  );
}

export function useSingleCollectionInfinite(id, queryParams = {}, limit = 12) {
  const isAuthed = !!BlackdoveClient.getCredentials().accessToken;
  return useInfiniteQuery(
    ['collections', id],
    async ({ pageParam = { limit, offset: 0 } }) => {
      const result = await BlackdoveClient.get(
        `/v2/collections/${id}`,
        { ...queryParams, ...pageParam },
        !isAuthed
      );
      return result.data;
    },
    {
      getNextPageParam: (page) =>
        page.nextOffset < page.total
          ? { limit, offset: page.nextOffset }
          : false,
    }
  );
}

export function useSingleCollection(id, queryParams = {}) {
  const isAuthed = !!BlackdoveClient.getCredentials().accessToken;
  return useQuery(
    ['collections', id],
    async () => {
      const result = await BlackdoveClient.get(
        `/v2/collections/${id}`,
        { ...queryParams },
        !isAuthed
      );
      return result.data;
    },
    {
      enabled: !!id,
    }
  );
}

export function useFavoritesCollection() {
  const isAuthed = !!BlackdoveClient.getCredentials().accessToken;
  return useQuery(
    ['collections', 'favorites'],
    async () => {
      const result = await BlackdoveClient.get(
        `/v2/collections/favorites`,
        null,
        !isAuthed
      );
      return result.data;
    },
    {
      staleTime: 10000,
      enabled: isAuthed,
    }
  );
}

export function useAllDevices(queryParams = {}) {
  const isAuthed = !!BlackdoveClient.getCredentials().accessToken;
  return useQuery('devices', async () => {
    const result = await BlackdoveClient.get(
      '/v2/devices',
      queryParams,
      !isAuthed
    );
    return result.data;
  });
}

export function useAllDevicesInfinite(queryKey, queryParams = {}, limit = 12) {
  const isAuthed = !!BlackdoveClient.getCredentials().accessToken;
  return useInfiniteQuery(
    queryKey,
    async ({ pageParam = { limit, offset: 0 } }) => {
      const result = await BlackdoveClient.get(
        '/v2/devices',
        { ...queryParams, ...pageParam },
        !isAuthed
      );
      return result.data;
    },
    {
      getNextPageParam: (page) =>
        page.nextOffset < page.total
          ? { limit, offset: page.nextOffset }
          : false,
    }
  );
}

export function useSingleDevice(id) {
  const isAuthed = !!BlackdoveClient.getCredentials().accessToken;
  return useQuery(
    ['devices', id],
    async () => {
      const result = await BlackdoveClient.get(
        `/v2/devices/${id}`,
        null,
        !isAuthed
      );
      return result.data;
    },
    {
      enabled: isAuthed && !!id,
    }
  );
}

export function useAllWallets() {
  const isAuthed = !!BlackdoveClient.getCredentials().accessToken;
  return useQuery(
    'wallets',
    async () => {
      const result = await BlackdoveClient.get('/v2/wallets', null, !isAuthed);
      return result.data;
    },
    {
      enabled: isAuthed,
    }
  );
}

export function useWalletPreview(address) {
  const isAuthed = !!BlackdoveClient.getCredentials().accessToken;
  return useQuery(
    ['wallets', 'preview', address],
    async () => {
      const result = await BlackdoveClient.get(
        `/v2/wallets/${address}/preview`,
        null,
        !isAuthed,
        60000
      );
      return result.data;
    },
    {
      enabled: isAuthed && !!address,
    }
  );
}

export function useSetupToken(token, email) {
  return useQuery(['account', 'setup'], async () => {
    const result = await BlackdoveClient.get(
      '/v2/setupToken',
      { token, email },
      true
    );
    return result.data;
  });
}

export function useSubscriptionPricing() {
  return useQuery(['subscriptions', 'pricing'], async () => {
    const result = await BlackdoveClient.get(
      '/v2/billing/subscriptions/pricing',
      null,
      true
    );
    return result.data;
  });
}

export function useSubscriptionPrices() {
  return useQuery('subscriptions', async () => {
    const result = await BlackdoveClient.get(`/ecommerce/products`, null, true);
    return result.data;
  });
}

export function useSearchAllQuery(query) {
  return useQuery(
    ['search', 'all'],
    async () => {
      const result = await BlackdoveClient.get(
        `/v2/search/all`,
        { query },
        true,
        60000
      );
      return result.data;
    },
    { enabled: Boolean(query) }
  );
}

export function useSearchAllArtists(params) {
  const { query, limit, offset } = params;
  return useQuery(
    ['search', 'artists'],
    async () => {
      const result = await BlackdoveClient.get(
        `/v2/search/artists`,
        { query, limit, offset },
        true,
        60000
      );
      return result.data;
    },
    { enabled: Boolean(query) }
  );
}

export function useSearchAllArtworks(params) {
  const { query, limit, offset } = params;
  return useQuery(
    ['search', 'artworks'],
    async () => {
      const result = await BlackdoveClient.get(
        `/v2/search/artworks`,
        { query, limit, offset },
        true,
        60000
      );
      return result.data;
    },
    { enabled: Boolean(query) }
  );
}

export function useSearchAllCollections(params) {
  const { query, limit, offset } = params;
  return useQuery(
    ['search', 'collections'],
    async () => {
      const result = await BlackdoveClient.get(
        `/v2/search/collections`,
        { query, limit, offset },
        true,
        60000
      );
      return result.data;
    },
    { enabled: Boolean(query) }
  );
}

export function useStats() {
  return useQuery('stats', async () => {
    const result = await BlackdoveClient.get(`/artist/analytics/artwork`);
    return result.data;
  });
}

export function useTotals() {
  return useQuery('totals', async () => {
    const result = await BlackdoveClient.get(`/artist/analytics`);
    return result.data;
  });
}

export function useGraphs(params) {
  return useQuery(['graphs', params], async () => {
    const result = await BlackdoveClient.get(
      `/artist/analytics/statistics/views`,
      params
    );
    return result.data;
  });
}

export function useEarnings() {
  return useQuery(['earnings'], async () => {
    const result = await BlackdoveClient.get(`/artist/earnings/overview`);
    return result.data;
  });
}

export function usePayments() {
  return useQuery('payments', async () => {
    const result = await BlackdoveClient.get(`/artist/earnings/withdrawals`);
    return result.data;
  });
}

export function useProducts() {
  return useQuery('products', async () => {
    const result = await BlackdoveClient.get(`/artist/products`);
    return result.data;
  });
}

export function useStripeConnectAccount(status) {
  const isAuthed = !!BlackdoveClient.getCredentials().accessToken;
  return useQuery(
    'stripeConnectAccount',
    async () => {
      const result = await BlackdoveClient.get('/v2/users/stripe/connect');
      return result.data;
    },
    { enabled: isAuthed && status !== 'NOT_CREATED' }
  );
}
