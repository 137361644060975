import React from 'react';
import PropTypes from 'prop-types';
import { Warning } from '@emotion-icons/ionicons-sharp';

import { Box, Flex } from '../../../../Flexbox';
import { Button } from '../../../../Button';
import { ConnectButton } from '../../../../Metamask/ConnectButton';

import { formatMoney } from '../../../../../util/formatMoney';

import {
  Step,
  ReviewLabel,
  ReviewHeading,
  WarningMessage,
  SectionSubHeader,
} from '../../styled';

export function StepThree({
  artworkData,
  products,
  status,
  finalizing,
  mintAttempted,
  mintFailed,
  onNext,
  onReset,
  onMint,
}) {
  return (
    <Step>
      <Flex flexDirection="column" width="100%">
        <ReviewHeading>Review</ReviewHeading>
        <SectionSubHeader>
          This information will appear on the lisitng page for your artwork.
        </SectionSubHeader>
        <br />
        <Flex marginBottom={20} width="100%">
          <Flex flexDirection="column" flexBasis="50%">
            <Box width={[1, 1, 1 / 2]}>
              <ReviewLabel>Artwork Type</ReviewLabel>
              <SectionSubHeader>{artworkData.artworkType}</SectionSubHeader>
            </Box>
          </Flex>
          <Flex flexDirection="column" flexBasis="50%">
            <Box width={[1, 1, 1 / 2]}>
              {artworkData.artworkType === 'NFT' && (
                <>
                  <ReviewLabel>Tokens</ReviewLabel>
                  <SectionSubHeader>{artworkData.quantity}</SectionSubHeader>
                  <ReviewLabel>Token Price</ReviewLabel>
                  <SectionSubHeader>{artworkData.price} ETH</SectionSubHeader>
                </>
              )}

              {artworkData.artworkType === 'OPEN' && (
                <>
                  <ReviewLabel>Price</ReviewLabel>
                  <SectionSubHeader>
                    {formatMoney(
                      products.find(
                        (p) => p.productId === artworkData.productId
                      ).price
                    )}
                  </SectionSubHeader>
                </>
              )}
            </Box>
          </Flex>
        </Flex>
        {mintAttempted && mintFailed && (
          <WarningMessage>
            <Warning size={18} />
            Failed to mint NFTs for this artwork. Please try again.
          </WarningMessage>
        )}
        {(status === 'connected' || artworkData.artworkType === 'OPEN') && (
          <>
            {(!mintAttempted || (mintAttempted && !mintFailed)) && (
              <Button onClick={onNext} color="red" pending={finalizing}>
                Finalize My Artwork
              </Button>
            )}
            {mintAttempted && mintFailed && (
              <Button
                onClick={onMint}
                disabled={finalizing}
                color="red"
                pending={finalizing}
              >
                Mint NFTs
              </Button>
            )}
          </>
        )}
        {status !== 'connected' && artworkData.artworkType === 'NFT' && (
          <ConnectButton />
        )}
        {!mintAttempted && (
          <>
            <br />
            <Button
              buttonStyle="secondary"
              disabled={finalizing}
              onClick={onReset}
            >
              Start Over
            </Button>
          </>
        )}
      </Flex>
    </Step>
  );
}

StepThree.propTypes = {
  artworkData: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  finalizing: PropTypes.bool.isRequired,
  mintAttempted: PropTypes.bool.isRequired,
  mintFailed: PropTypes.bool.isRequired,
  onNext: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onMint: PropTypes.func.isRequired,
};
