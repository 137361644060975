import React from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';

// Hooks
import { useAddToCollection } from '../../../../../../../hooks/mutations';

// Styled
import { ItemWrapper, ItemLink } from './styled';

const getCollectionId = (coll) => {
  switch (coll.playlistType) {
    case 'FAVORITES':
      return 'favorites';
    case 'PURCHASES':
      return 'purchases';
    case 'USER_UPLOAD':
      return 'personal';
    case 'NFT_IMPORT':
      return 'nft';
    default:
      return coll.id;
  }
};

export function CollectionItem({ collection, droppable }) {
  const userEditable = droppable && collection.playlistType === 'STANDARD';
  const addToCollectionMutation = useAddToCollection();

  const [collected, drop] = useDrop({
    accept: userEditable ? 'artwork' : 'none',
    drop: (item) => {
      if (userEditable) {
        addToCollectionMutation.mutate({
          id: collection.id,
          artworkId: item.id,
        });
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  return (
    <ItemWrapper
      ref={drop}
      draggingOver={collected.isOver && userEditable}
      canDrop={collected.canDrop}
    >
      <ItemLink to={`/collections/${getCollectionId(collection)}`}>
        {collection.name}
      </ItemLink>
    </ItemWrapper>
  );
}

CollectionItem.propTypes = {
  collection: PropTypes.object.isRequired,
  droppable: PropTypes.bool,
};

CollectionItem.defaultProps = {
  droppable: false,
};
