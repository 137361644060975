import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import WalletConnectClient from '@walletconnect/client';
import QRCodeModal from '@walletconnect/qrcode-modal';
import { convertUtf8ToHex } from '@walletconnect/utils';

import { Wrapper } from './styled';
import { Button } from '../../../../Button';

export function WalletConnect({ wallets, pairWallet, closeModal }) {
  const [walletConnect, setWalletConnect] = useState(null);
  const [status, setStatus] = useState('notConnected');
  const [account, setAccount] = useState(null);

  // On load we detect if metamask is installed
  useEffect(() => {
    const connector = new WalletConnectClient({
      bridge: 'https://bridge.walletconnect.org',
      qrcodeModal: QRCodeModal,
    });

    setWalletConnect(connector);

    connector.on('connect', (error, payload) => {
      if (error) {
        alert('There was a problem connecting to your wallet.');
      } else {
        const { accounts } = payload.params[0];
        setAccount(accounts[0]);
        setStatus('connected');
      }
    });

    connector.on('disconnect', () => {
      setAccount(null);
      setStatus('notConnected');
      connector.createSession();
    });

    if (connector.connected) {
      setAccount(connector.accounts[0]);
      setStatus('connected');
    } else {
      connector.createSession();
    }
  }, []);

  useEffect(() => {
    const signAndPairWallet = async () => {
      const signedMessage = await walletConnect.signPersonalMessage([
        convertUtf8ToHex('I am verifying my wallet with Blackdove.'),
        account,
      ]);

      await pairWallet(account, signedMessage);
    };

    if (
      status === 'connected' &&
      wallets?.filter((e) => e.walletAddress === account).length === 0
    ) {
      signAndPairWallet();
    }
  }, [status]);

  const selectedIsPaired =
    wallets?.filter((e) => e.walletAddress === account).length > 0;

  return (
    <Wrapper>
      {(status === 'notConnected' || status === 'connecting') && (
        <>
          <h2>Connecting!</h2>
          <p>Please authorize the connection via WalletConnect...</p>
        </>
      )}
      {status === 'connected' && !selectedIsPaired && (
        <>
          <h2>Sign the message in your wallet to continue</h2>
          <p>
            Blackdove uses this signature to verify that you are the owner of
            this wallet.
          </p>
        </>
      )}
      {status === 'connected' && selectedIsPaired && (
        <>
          <h2>You have an existing wallet connection with this wallet.</h2>
          <p>Currently connected wallet is {account.slice(0, 6)}.</p>
          <p>
            To pair additional wallets with Blackdove you must first end the
            WalletConnect session for this wallet.
          </p>
          <p>
            If you disconnect the session, the previous wallet will remain
            paired to your account for NFT Syncing.
          </p>
          <Button onClick={() => walletConnect.killSession()}>
            End Session
          </Button>
          <Button onClick={closeModal}>Close</Button>
        </>
      )}
    </Wrapper>
  );
}

WalletConnect.propTypes = {
  wallets: PropTypes.array.isRequired,
  pairWallet: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};
