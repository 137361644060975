import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';

// Components
import { ConnectForm } from '../../../ConnectForm';
import { Button } from '../../../Button';
import { TextInput } from '../../../FormNext/components/TextInput/TextInput';

// Styled
import {
  ButtonContainer,
  HeaderSection,
  FlexContainer,
  SubTitle,
  Description,
  Divider,
  BreakWrapper,
} from '../../styled';

const validationSchema = yup.object().shape({
  paypalEmail: yup.string(),
});

export function Payment({ loading, user, handleSkip, onNext }) {
  const [initialValues] = React.useState({
    paypalEmail: user?.artist?.paypalEmail,
  });

  return (
    <>
      <FlexContainer>
        <HeaderSection>
          <SubTitle>Setup Payments</SubTitle>
          <Description>
            Connect your Stripe account or Paypal to recieve royalty payments
            generated by your art.
          </Description>
        </HeaderSection>
        <HeaderSection>
          <SubTitle>Connect to bank account</SubTitle>
          <Description>
            Fast automated payouts each month directly to your bank account.
          </Description>
          <Description>
            If the country of your billing address does not appear then Stripe
            Connect is not supported. Please fill out details for your PayPal to
            receive royalty payments.
          </Description>
        </HeaderSection>
        <ConnectForm user={user} />
      </FlexContainer>
      <BreakWrapper>
        <Divider /> <div>OR</div> <Divider />
      </BreakWrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onNext}
      >
        <Form style={{ maxWidth: 480, textAlign: 'start' }}>
          <FlexContainer>
            <HeaderSection>
              <SubTitle>Paypal</SubTitle>
              <Description>We use this to make royalty payments.</Description>
            </HeaderSection>
            <Field
              name="paypalEmail"
              label="Enter Paypal Email"
              placeholder="e.g. yourpaypal@email.com"
              component={TextInput}
            />
          </FlexContainer>
          <ButtonContainer>
            <Button type="button" onClick={handleSkip}>
              Skip
            </Button>
            <Button
              type="submit"
              color={loading ? '' : 'red'}
              disabled={loading}
            >
              {loading ? 'Saving....' : 'Save'}
            </Button>
          </ButtonContainer>
        </Form>
      </Formik>
    </>
  );
}

Payment.propTypes = {
  loading: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  handleSkip: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};
