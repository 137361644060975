import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import Tooltip from './Tooltip';

// Styled
import { Wrapper } from './styled';

class LineChart extends React.Component {
  constructor() {
    super();
    this.setRef = this.setRef.bind(this);
    this.state = {
      top: 0,
      left: 0,
      date: '',
      value: 0,
      showTooltip: false,
    };
  }

  setRef(ref) {
    this.setRef = ref;
  }

  setPositionAndData(top, left, date, value) {
    this.setState({
      top,
      left,
      date,
      value,
    });
  }

  render() {
    const { values } = this.props;
    const { top, left, date, value, showTooltip } = this.state;

    const chartOptions = {
      responsive: true,
      defaultFontFamily: '"DINNextLTPro", Helvetica, Arial, sans-serif',
      maintainAspectRatio: false,
      tooltips: {
        enabled: false,
        mode: 'index',
        position: 'nearest',
        intersect: false,
        custom: (tooltipModel) => {
          if (tooltipModel.opacity === 0) {
            this.setState((prevState) => ({
              ...prevState,
              showTooltip: false,
            }));
            return;
          }

          // set values for display of data in the tooltipModel
          this.setState((prevState) => ({
            ...prevState,
            date: tooltipModel.dataPoints[0].xLabel,
            value: tooltipModel.dataPoints[0].yLabel,
            showTooltip: true,
          }));
          this.setPositionAndData(
            tooltipModel.caretY - tooltipModel.height,
            tooltipModel.caretX,
            date,
            value
          );
        },
      },

      hover: {
        mode: 'nearest',
        intersect: true,
      },
      legend: {
        display: false,
      },

      scales: {
        xAxes: [
          {
            ticks: {
              fontSize: 14,
              fontWeight: 800,
              fontStyle: 'bold',
              fontFamily: '"DINNextLTPro", Helvetica, Arial, sans-serif',
              padding: 6,
            },
            display: true,
            time: {
              unit: 'month',
            },
            scaleLabel: {
              display: true,
            },
            gridLines: {
              color: '#E1E6EA',
              offsetGridLines: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              fontSize: 14,
              fontFamily: '"DINNextLTPro", Helvetica, Arial, sans-serif',
              fontStyle: 'normal',
              padding: 6,
            },
            gridLines: {
              color: '#E1E6EA',
              drawBorder: false,
              zeroLineWidth: 0,
            },
          },
        ],
      },
    };

    return (
      <Wrapper>
        <Line
          data={{
            labels: values.data.labels,
            datasets: [
              {
                fill: true,
                lineTension: 0.6,
                fillAlphas: 0.8,
                backgroundColor: 'rgba(96, 113, 202, .1)',
                borderColor: '#6173CF',
                pointBorderColor: '#ffffff',
                pointBackgroundColor: '#6173CF',
                pointBorderWidth: 2,
                pointHoverRadius: 8,
                pointHoverBackgroundColor: '#6173CF',
                pointHoverBorderColor: '#ffffff',
                pointHoverBorderWidth: 2,
                pointRadius: 8,
                pointHitRadius: 10,
                data: values.data.datasets[0].data,
              },
            ],
          }}
          options={chartOptions}
        />
        {showTooltip ? (
          <Tooltip placement="top" style={{ top, left }}>
            <div>{date}</div>
            <div>Views: {value}</div>
          </Tooltip>
        ) : null}
      </Wrapper>
    );
  }
}

LineChart.propTypes = {
  values: PropTypes.object.isRequired,
};

export default LineChart;
