import React from 'react';
import PropTypes from 'prop-types';

export function Edit({ color, width, height, viewBox }) {
  return (
    <div>
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox={viewBox || '0 0 16 16'}
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 2.50688C17.1026 2.50688 16.2054 2.84707 15.5254 3.52739L4.45797 14.5938C4.24799 14.8038 4.0942 15.0649 4.01266 15.3516L2.52926 20.5459C2.49268 20.6745 2.4911 20.8104 2.52469 20.9398C2.55827 21.0691 2.62581 21.1872 2.7203 21.2817C2.8148 21.3762 2.93282 21.4437 3.06217 21.4773C3.19152 21.5109 3.32749 21.5093 3.45602 21.4727L8.65133 19.9893C8.65166 19.989 8.65199 19.9887 8.65231 19.9883C8.93707 19.9065 9.19757 19.7546 9.40817 19.544L20.4746 8.47661C21.8351 7.11605 21.8351 4.88795 20.4746 3.52739C19.7946 2.84707 18.8973 2.50688 18 2.50688ZM18 3.99809C18.5104 3.99809 19.0201 4.19476 19.4131 4.58794C19.4134 4.58794 19.4137 4.58794 19.414 4.58794C20.2015 5.37537 20.2015 6.62862 19.414 7.41606L18.4443 8.38579L15.6162 5.55766L16.5859 4.58794C16.9789 4.19476 17.4896 3.99809 18 3.99809ZM14.5556 6.61821L17.3838 9.44633L8.34665 18.4834C8.31733 18.5126 8.2802 18.5349 8.23825 18.5469L4.34176 19.6602L5.45504 15.7627C5.45504 15.7624 5.45504 15.7621 5.45504 15.7618C5.46651 15.7214 5.48751 15.6854 5.51852 15.6543L14.5556 6.61821Z"
          fill={color}
        />
      </svg>
    </div>
  );
}

Edit.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};

Edit.defaultProps = {
  color: '#ffffff',
  height: '16',
  width: '16',
  viewBox: '0 0 16 16',
};
