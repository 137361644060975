import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
  border-radius: 12px;
`;

export const Inner = styled.div`
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme, dragOver }) =>
    dragOver ? 'transparent' : theme.colors.base};
  text-align: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
`;

export const Logo = styled.img`
  display: inline-block;
  height: 3em;
  margin-bottom: 1em;
  margin-top: 2.5em;
  pointer-events: none;
`;

export const UploadInstructions = styled.span`
  display: block;
  pointer-events: none;
`;

export const FileInput = styled.input`
  display: none;
`;
