import React from 'react';
import { Formik, Form, Field } from 'formik';
import styled from '@emotion/styled';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { useNotifications } from 'reapop';
import { BlackdoveClient } from '@blackdove/utils';

import { Button } from '../Button';
import { Select } from '../FormNext';

import stripeCountries from '../../constants/countries';

const Wrapper = styled.div`
  margin-bottom: 40px;
`;

const validationSchema = yup.object().shape({
  country: yup.string().required(),
});

const initialValues = {};

export const StripeTosForm = () => {
  const { notify } = useNotifications();

  const updateStripeMutation = useMutation(
    async (data) => {
      const result = await BlackdoveClient.patch(
        '/v2/users/stripe/connect',
        data.params
      );
      return result.data;
    },
    {
      onSuccess: () => {
        notify({
          status: 'info',
          title: 'Stripe Connect',
          message:
            'TOS has been accepted. Please check back in a few minutes to see the status of your account.',
        });
      },
      onError: () => {
        notify({
          status: 'error',
          title: 'Connection Failed',
          message: 'Could not accept TOS. Please contact support.',
        });
      },
    }
  );

  const handleSubmit = async (values, { setSubmitting }) => {
    await updateStripeMutation.mutateAsync({
      params: values,
    });
    setSubmitting(false);
  };

  return (
    <div>
      <Wrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Field name="country" label="Country" component={Select}>
                <option>Select a Country</option>
                {stripeCountries.map(({ label, value }) => (
                  <option value={value}>{label}</option>
                ))}
              </Field>
              <Button
                type="submit"
                color="red"
                disabled={isSubmitting}
                pending={isSubmitting}
              >
                Accept TOS
              </Button>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </div>
  );
};
