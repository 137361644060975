import React from 'react';
import { render } from 'react-dom';
import { setUpNotifications } from 'reapop';
import TagManager from 'react-gtm-module';

import { BlackdoveClient } from '@blackdove/utils';

import App from './App';

if (process.env.NODE_ENV === 'development') {
  BlackdoveClient.setApiUrl('http://localhost:3004');
  BlackdoveClient.setClientId('fdea8d89jufd89kufd');
} else {
  BlackdoveClient.setApiUrl('https://api.blackdove.io');
  BlackdoveClient.setClientId('fdea8d89jufd89kufd');
}

setUpNotifications({
  defaultProps: {
    position: 'bottom-left',
    dismissible: true,
    dismissAfter: 5000,
  },
});

const tagManagerArgs = {
  gtmId: 'GTM-TX3X7T',
};

if (window.location.hostname === 'www.blackdove.com') {
  TagManager.initialize(tagManagerArgs);
}

render(<App />, document.getElementById('app'));
