import styled from '@emotion/styled';

export const RootWrapper = styled.div`
  position: relative;
`;

export const Wrapper = styled.div``;

export const CardWrapper = styled.div`
  margin: 24px;

  thead {
    border: 0;
  }
  td {
    color: white;
    background: ${({ theme }) => theme.colors.base2};
  }
  button {
    background: ${({ theme }) => theme.colors.base2};
  }
`;

export const PageTitle = styled.h1`
  font-weight: bold;
  margin-bottom: 40px;
  margin-left: 24px;
`;

export const PageSubTitle = styled.h2``;

export const EarningTab = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: 30px;
`;

export const EarningTabItem = styled.li`
  display: inline;
  text-decoration: none;
  margin-right: 20px;
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  border-bottom: ${({ isActive }) => (isActive ? '2px solid' : 'none')};
  padding-bottom: 5px;
  cursor: pointer;
`;

export const Panel = styled.div`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 40px 0px ${({ theme }) => theme.colors.shadow};
  margin-bottom: 2em;
  padding: 2em;
`;

export const PanelTitle = styled.h3`
  margin-top: 0;
  font-size: 1.8em;
  font-weight: bold;
`;

export const ValueList = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ValueItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20%;
  border-right: 1px solid ${({ theme }) => theme.colors.light};
  margin-right: 2em;
`;

export const ValueDescription = styled.span`
  font-weight: bold;
  display: inline-block;
  font-size: 20px;
`;

export const TotalPrice = styled.h3``;

export const H5 = styled.h5``;

export const WithdrawalTitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const AmountInput = styled.input`
  padding: 10px;
  width: 200px;
  border: 1px solid black;
  margin-right: 10px;
  background: white;
`;

export const PaymentType = styled.select`
  padding: 10px;
  border: 1px solid;
  background: white;
  margin-right: 10px;
`;

export const PaymentButton = styled.button`
  background: black;
  color: white;
  border: 1px solid black;
  padding: 10px 15px;
  text-transform: uppercase;
`;
