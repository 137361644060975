import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const loader = keyframes`
0% {
    transform: translateX(-100%);
}
50% {
    transform: translateX(-60%);
}
100% {
    transform: translateX(100%);
}
`;

export const SkeletonCardWrapper = styled.div`
  position: relative;
  margin: ${({ margin }) => margin};
  flex-basis: calc(${(1 / 1) * 100}% - 24px);

  @media (min-width: 500px) {
    flex-basis: ${({ flexBasis }) => `calc(${flexBasis * 100}%  - 24px)`};
    max-width: ${({ flexBasis }) => `calc(${flexBasis * 100}% - 24px)`};
  }
  @media (min-width: 768px) {
    margin: ${({ marginMid }) => marginMid};
    flex-basis: ${({ flexBasisMid }) => `calc(${flexBasisMid * 100}%  - 48px)`};
    max-width: ${({ flexBasisMid }) => `calc(${flexBasisMid * 100}% - 48px)`};
  }
  @media (min-width: 1024px) {
    margin: ${({ marginL }) => marginL};
    flex-basis: ${({ flexBasisLarge }) =>
      `calc(${flexBasisLarge * 100}%  - 48px)`};
    max-width: ${({ flexBasisLarge }) =>
      `calc(${flexBasisLarge * 100}% - 48px)`};
  }
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.45);
  border-radius: 12px;
`;

export const SkeletonArtistCardWrapper = styled.div`
  position: relative;
  margin: ${({ margin }) => margin};
  flex-basis: calc(${(1 / 1) * 100}% - 24px);
  width: 100%;
  @media (min-width: 768px) {
    margin: ${({ marginMid }) => marginMid};
    flex-basis: ${({ flexBasisMed }) => `calc(${flexBasisMed * 100}%  - 24px)`};
    max-width: ${({ flexBasisMed }) => `calc(${flexBasisMed * 100}% - 24px)`};
  }

  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.45);
  border-radius: 12px;
`;

export const SkeletonWrapper = styled.div`
  position: relative;
`;

export const SkeletonThumbnail = styled.div`
  width: 100%;
  height: 100%;
  background: #333333;
`;

export const SkeletonText = styled.div`
  width: 70%;
  margin-top: 10px;
  background: #d2dbe2;
  border-radius: 7px;
  background: #333333;
  min-height: 10px;
  position: relative;
`;

export const SkeletonCircle = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50px;
  vertical-align: top;
  margin-right: 10px;
  background: #333333;
`;
export const SkeletonBigCircle = styled.div`
  border-radius: 100px;
  height: 84px;
  width: 84px;
  margin-right: 10px;
  background: #333333;
  position: relative;

  @media (min-width: 768px) {
    height: 144px;
    width: 200px;
  }
`;

export const SkeletonTitle = styled.div`
  width: 30%;
  margin-top: 10px;
  border-radius: 7px;
  background: #333333;
  min-height: 10px;
  position: relative;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  width: 100%;
`;
export const ShimmerWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const SkeletonCardBlock = styled.div`
  width: 100%;
  height: 96px;
  background: #333333;
  overflow: hidden;
  border-radius: 0 0 12px 12px;
`;

export const Shimmer = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background: #444444;
  animation: ${loader} 2s ease-in-out infinite;
`;

export const ThumbnailAspectRatioBox = styled.div`
  padding-bottom: 100%;
  position: relative;
  width: 100%;
  border-radius: 12px;
  background: #333333;

  .thumbnail {
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    border-radius: 12px;
  }
`;
