import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const MetadataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
`;

const MetadataItem = styled.div`
  flex: 1;
  padding: 10px 0;
`;

const MetadataValue = styled.span`
  ont-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.015em;
  color: #ffffff;
`;

const MetadataTitle = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #777777;
  color: ${({ theme }) => theme.colors.secondaryLighter};
`;

const getDuration = (seconds) => {
  const normalizedSeconds = Math.floor(seconds);
  const minutes = Math.floor(normalizedSeconds / 60);
  const extraSeconds = normalizedSeconds - minutes * 60;

  if (normalizedSeconds < 59) return `${normalizedSeconds}s`;
  if (normalizedSeconds === 60) return '1m';
  return `${minutes}m ${extraSeconds}s`;
};

const formatBytes = (bytes, bitrate = false) => {
  if (bytes >= 1000000000) {
    return `${(bytes / 1000000000).toFixed(2)} GB`;
  }

  if (bytes >= 1000000) {
    return `${(bytes / 1000000).toFixed(2)} ${bitrate ? 'MB/S' : 'MB'}`;
  }

  return `${parseInt(bytes / 1000, 10)} ${bitrate ? 'KB/S' : 'KB'}`;
};

export const VideoMetadata = ({ artwork }) => {
  const { metadata } = artwork?.sourceFile;

  return (
    <MetadataWrapper>
      <MetadataItem>
        <MetadataTitle>Aspect Ratio</MetadataTitle>
        <MetadataValue>{metadata?.aspectRatio}</MetadataValue>
      </MetadataItem>
      <MetadataItem>
        <MetadataTitle>Framerate</MetadataTitle>
        <MetadataValue>
          {metadata?.framerate % 1 === 0
            ? metadata?.framerate
            : (metadata?.framerate).toFixed(2)}
        </MetadataValue>
      </MetadataItem>
      <MetadataItem>
        <MetadataTitle>FileSize</MetadataTitle>
        <MetadataValue>{formatBytes(metadata?.size)}</MetadataValue>
      </MetadataItem>
      <MetadataItem>
        <MetadataTitle>Resolution</MetadataTitle>
        <MetadataValue>{`${metadata?.width}x${metadata?.height}`}</MetadataValue>
      </MetadataItem>
      <MetadataItem>
        <MetadataTitle>Length</MetadataTitle>
        <MetadataValue>{getDuration(metadata?.duration)}</MetadataValue>
      </MetadataItem>
      <MetadataItem>
        <MetadataTitle>Bitrate</MetadataTitle>
        <MetadataValue>{formatBytes(metadata?.bitrate, true)}</MetadataValue>
      </MetadataItem>
      <MetadataItem>
        <MetadataTitle>Video Codec</MetadataTitle>
        <MetadataValue>{metadata?.videoCodecId.toUpperCase()}</MetadataValue>
      </MetadataItem>
    </MetadataWrapper>
  );
};

VideoMetadata.propTypes = {
  artwork: PropTypes.object.isRequired,
};
