import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const slideIn = keyframes`
  from { right: -480px; }
  to { right: 0px; }
`;

const slideOut = keyframes`
  from { right: 0px; }
  to { right: -480px; }
`;

export const UploadWrapper = styled.div`
  width: 480px;
  max-width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 201;
  top: 0;
  right: -480px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #1a1a1a;
  box-shadow: 6px 6px 48px #111111;
  border-radius: 24px 0px 0px 24px;
  overflow-x: hidden;
  overflow: scroll;
  overflow-x: auto;
  animation: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  animation-name: ${({ open }) => {
    if (open) return slideIn;
    if (!open) return slideOut;
    return null;
  }};

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 24px;
  background: #222222;
  border-radius: 0px 0px 0px 24px;
  height: 88px;
`;

export const Title = styled.span`
  font-weight: bold;
  font-size: 20px;
  text-transform: capitalize;
  color: #ffffff;
`;

export const CloseModalIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-right: 12px;
`;

export const ModalContent = styled.div`
  max-height: calc(100% - 75px);
  height: 100%;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const YourUploadInputWrapper = styled.div`
  margin-bottom: 24px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const YourUploadLabel = styled.div`
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 12px;
`;

export const TextInput = styled.input`
  width: 100%;
  background: #333333;
  border: 2px solid #333333;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.45);
  border-radius: 12px;
  color: #ffffff;
  padding: 11px;
  font-size: 16px;

  &:focus {
    outline: none !important;
    border: 2px solid #910048;
  }
`;

export const Alert = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: ${({ bgColor }) => bgColor};
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.45);
  border-radius: 12px;
  margin-bottom: 24px;
`;

export const WarningIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 5px;
`;

export const AlertText = styled.span`
  color: ${({ textColor }) => textColor};
  padding: 5px;
  font-weight: 600;
  font-size: 11px;
`;
