import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BlackdoveClient } from '@blackdove/utils';

// Components
import { Loader } from '../../../components/Loader';
import { Button } from '../../../components/Button';

// Styled
import {
  Wrapper,
  ContentWrapper,
  MainPanel,
  ItemDetails,
  ItemName,
  ItemPrice,
  CallToAction,
  CallToActionInner,
  Greeting,
  Description,
} from './styled';

export function OrderConfirmation() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [items, setItems] = useState();

  // GET session data
  useEffect(async () => {
    const result = await BlackdoveClient.get(
      `/v2/billing/checkout/session/${search.replace('?session_id=', '')}`
    );
    setData(result.data);
  }, []);

  // SET session items
  useEffect(async () => {
    if (data) {
      const listItems = [];

      data.line_items.data.map((item) =>
        listItems.push({
          id: item.price.id,
          name: item.description,
          brand: 'Blackdove',
          category: 'Subscriptions',
          quantity: item.quantity,
          price: parseFloat(item.amount_total, 10) / 100,
        })
      );
      setItems(listItems);

      window.gtag('event', 'purchase', {
        transaction_id: data.id,
        affiliation: 'Blackdove Subcriptions',
        value: parseFloat(data.amount_total, 10) / 100,
        currency: data.currency,
        items: listItems,
      });
      window.lintrk('track', { conversion_id: 6756044 });
      window.fbq('track', 'Purchase', {
        transaction_id: data.id,
        affiliation: 'Blackdove Subcriptions',
        value: parseFloat(data.amount_total, 10) / 100,
        currency: data.currency,
        contents: listItems,
      });
    }
  }, [data]);

  return (
    <Wrapper>
      {!items && <Loader />}
      {items && (
        <ContentWrapper>
          <MainPanel>
            <ItemDetails>
              {items && (
                <>
                  {items.map((item) => (
                    <>
                      <ItemName>{item.name}</ItemName>
                      <ItemPrice>
                        ${item.price.toLocaleString('en-US')}
                      </ItemPrice>
                    </>
                  ))}
                </>
              )}
            </ItemDetails>
            <CallToAction>
              <CallToActionInner>
                <Greeting>Order Confirmation</Greeting>
                <Description>
                  Thank you for completing your purchase. Your subscription is
                  live. Enjoy!
                </Description>
                <Button
                  color="red"
                  borderColor="#171717"
                  onClick={() => navigate('/artwork/latest')}
                >
                  Browse Art
                </Button>
              </CallToActionInner>
            </CallToAction>
          </MainPanel>
        </ContentWrapper>
      )}
    </Wrapper>
  );
}
