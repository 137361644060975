export const tags = [
  'VFX',
  '3D Art',
  '3D Mapping',
  '2D',
  'Graphic Design',
  'Creative Code',
  'Generative',
  'AI Art',
  'Fractals',
  'Motion Graphics',
  'Motion/Kinesis',
  'Crypto Art',
  'Computer Graphics',
  'CGI',
  'Animation',
  'Stop Motion',
  'Cinemagraph',
  'Sound Art',
  'Audio-Visual',
  'Oscilloscope',
  'Multi-media',
  'Mixed Media',
  'Interdisciplinary',
  'Experimental Cinema',
  'Film',
  'Conceptual',
  'Interactive',
  'Installation',
  'AR',
  'Lighting Design',
  'Time-based media',
  'Time lapse',
  'Performance Art',
  'Illustration',
  'Cartoon',
  'Gif',
  'Glitch',
];
