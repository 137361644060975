import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: relative;
  height: 40vh;
  width: 100%;

  .chart__container {
    position: relative;
    height: 40vh;
    width: 100%;
  }
`;
