import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ThemeProvider } from '@emotion/react';

import { theme } from '../../theme';

import GlobalStyles from '../../GlobalStyles';

import { Header } from '../../Main/components/Header';
import { MainMenu } from '../../Main/components/MainMenu';

const RootWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #222222;
`;

const Wrapper = styled.div`
  height: 100%;
  position: relative;
  margin: 0 auto;
`;

const ContentBlack = styled.div`
  position: relative;
  height: 100%;
  transition: 0.5s;
`;

const LandingPageLayout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <RootWrapper>
        <Wrapper>
          <GlobalStyles />
          <MainMenu />
          <Header />
          <ContentBlack>{children}</ContentBlack>
        </Wrapper>
      </RootWrapper>
    </ThemeProvider>
  );
};

LandingPageLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default LandingPageLayout;
