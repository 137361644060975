import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { useNotifications } from 'reapop';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Refresh } from '@emotion-icons/ionicons-outline';

// Hooks
import { useArtworkUpload } from '../../hooks/useArtworkUpload';

// Components
import { Select } from '../FormNext/components/Select/Select';
import { TextInput } from '../FormNext/components/TextInput/TextInput';
import ProgressBar from '../ProgressBar';
import { Edit, Copy } from '../SVGIcon';

const slideIn = keyframes`
  from {
    max-height: 104px;
  }
  to {
    max-height: 100%;
  }
`;
const slideOut = keyframes`
  from {
    max-height: 100%;
  }
  to {
    max-height: 104px;
  }
`;

const YourUploaderWrapper = styled.div`
  background: ${({ theme, dragOver }) =>
    dragOver ? theme.colors.secondary : theme.colors.base2};
  border-radius: 12px;
  width: 100%;
  margin-bottom: 24px;
  ${({ removeSelf }) => removeSelf && 'display: none;'}
`;

const Wrapper = styled.div`
  text-align: center;
  min-height: 50px;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.45);
  background: #222222;
`;

const InfoWrapper = styled.div`
  display: flex;
  height: 36px;
`;

const Number = styled.div`
  background: #333333;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.45);
  border-radius: 12px 0px 12px 0px;
  padding: 6px 16px;
`;

const Delete = styled.div`
  background: #333333;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.45);
  border-radius: 0px 12px 0px 12px;
  width: 36px;
  padding: 6px;
  cursor: pointer;

  svg {
    vertical-align: inherit;
  }
`;

const ProgressWrapper = styled.div`
  width: 100%;
  height: 12px;
  margin: 12px;
`;

const FormWrapper = styled.div`
  padding: 24px 24px 0 24px;
  overflow: hidden;
  max-height: ${({ open }) => (open ? '100%' : '104px')};
  animation: ${(props) => (props.open ? slideIn : slideOut)} 0.5s linear;
`;

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
});

const YourUploader = ({
  allowedTypes,
  maxFileSizeMB,
  file,
  completeFile,
  addPrivateArtwork,
  index,
  userCollections,
  currentCollection,
  handleRemoveFile,
}) => {
  const { notify } = useNotifications();
  const upload = useArtworkUpload();
  const formRef = React.useRef();
  const [open, setOpen] = React.useState(false);
  const [removeSelf, setRemoveSelf] = React.useState(false);

  const collections = userCollections?.pages[0]?.collections?.filter((item) => {
    return item.name !== 'Personal Uploads' && item.name !== 'Imported NFTs';
  });

  const validateFile = (fileInfo) => {
    const fileSize = fileInfo.size / 20000000;
    const validType = allowedTypes.includes(fileInfo.type);
    const validSize = fileSize <= maxFileSizeMB;

    if (!validType) {
      notify({
        status: 'error',
        title: 'Uh oh!',
        message: `That filetype is not allowed. The allowed types are ${allowedTypes.join(
          ', '
        )}`,
      });
      return false;
    }

    if (!validSize) {
      notify({
        status: 'error',
        title: 'Uh oh!',
        message: `That file is too big. The max size is ${maxFileSizeMB}MB. Your file is ${parseInt(
          fileSize,
          10
        )}MB`,
      });
      return false;
    }
    return true;
  };

  const handleUploadReset = () => {
    upload.selectFile(file);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    if (upload.isComplete && !upload.isPending) {
      setSubmitting(false);
      return;
    }

    const result = await upload.finalizeFile(values.rotation);
    await addPrivateArtwork.mutateAsync({
      params: {
        name: values.name,
        qrUrl: values.qrUrl,
        sourceFileId: result.sourceFileId,
        collectionId: values.collectionId,
      },
    });
    setRemoveSelf(true);
    handleRemoveFile(file);
  };

  const handlePaste = async (setFieldValue) => {
    const text = await navigator.clipboard.readText();
    setFieldValue('qrUrl', text);
  };

  useEffect(() => {
    if (file) {
      const fileValid = validateFile(file);
      if (fileValid) {
        upload.selectFile(file);
      }
    }
  }, [file]);

  useEffect(() => {
    if (
      upload.isPending &&
      upload.isStarted &&
      !upload.isUploading &&
      upload.progress?.loaded !== upload.progress?.total
    ) {
      handleUploadReset();
    }
  }, [upload.progress, upload.isPending, upload.isStarted, upload.isUploading]);

  useEffect(() => {
    if (completeFile && upload.progress?.loaded === upload.progress?.total) {
      formRef.current.handleSubmit();
    }
  }, [completeFile]);

  return (
    <YourUploaderWrapper removeSelf={removeSelf}>
      <Wrapper>
        <InfoWrapper>
          <Number>{index + 1}</Number>
          <ProgressWrapper>
            {upload.isStarted && (
              <ProgressBar
                current={upload.progress?.loaded}
                total={upload.progress?.total}
              />
            )}
          </ProgressWrapper>
          <Delete onClick={handleUploadReset}>
            <Refresh size={20} />
          </Delete>
        </InfoWrapper>
        <FormWrapper open={open}>
          <Formik
            initialValues={{
              name:
                file?.name.substr(0, file?.name.lastIndexOf('.')) || file?.name,
              qrUrl: '',
              rotation: false,
              collectionId:
                currentCollection === 'personal' ? '' : currentCollection,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            innerRef={formRef}
          >
            {({ setFieldValue }) => (
              <Form>
                <Field
                  name="name"
                  label="Name"
                  component={TextInput}
                  autoComplete="off"
                  maxLength={open ? 50 : false}
                  Icon={Edit}
                  iconColor="#a5a5a5"
                  iconWidth="24"
                  iconHeight="24"
                  iconViewBox="0 0 24 24"
                  iconText="edit"
                  iconClick={() => setOpen(!open)}
                  open={open}
                />
                <Field
                  name="qrUrl"
                  label="QR Code Link (optional)"
                  placeholder="QR Code Link"
                  component={TextInput}
                  autoComplete="off"
                  maxLength={false}
                  Icon={Copy}
                  iconColor="#a5a5a5"
                  iconWidth="24"
                  iconHeight="24"
                  iconViewBox="0 0 24 24"
                  iconText="copy"
                  iconClick={() => handlePaste(setFieldValue)}
                />
                <Field
                  name="collectionId"
                  label="Collection"
                  component={Select}
                  maxLength={false}
                >
                  <option value="">Choose a collection</option>
                  {collections.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Field>
              </Form>
            )}
          </Formik>
        </FormWrapper>
      </Wrapper>
    </YourUploaderWrapper>
  );
};

YourUploader.propTypes = {
  allowedTypes: PropTypes.arrayOf(PropTypes.string),
  maxFileSizeMB: PropTypes.number,
  file: PropTypes.object,
  completeFile: PropTypes.bool,
  addPrivateArtwork: PropTypes.func,
  index: PropTypes.number,
  userCollections: PropTypes.arrayOf(PropTypes.object),
  currentCollection: PropTypes.string,
  handleRemoveFile: PropTypes.func,
};

YourUploader.defaultProps = {
  allowedTypes: ['*'],
  maxFileSizeMB: 20000,
  file: null,
  completeFile: null,
  addPrivateArtwork: null,
  index: null,
  userCollections: null,
  currentCollection: null,
  handleRemoveFile: null,
};

export default YourUploader;
