import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import BulkUploader from '../../Uploader/BulkUploader';

// Assets
import Back from '../../../assets/images/icons/Back.png';

// Styled
import {
  UploadWrapper,
  ModalHeader,
  Title,
  CloseModalIcon,
  ModalContent,
} from './styled';

const AddNewUploadModal = ({ closeModal }) => {
  const [open, setOpen] = React.useState(true);
  const selectedFiles = useSelector(
    (state) => state.app.modalParams?.selectedFiles
  );
  const refresh = useSelector((state) => state.app.modalParams?.setRefreshKey);
  const handleCloseModal = () => {
    setOpen(false);
    closeModal();
  };
  return (
    <UploadWrapper open={open}>
      <ModalHeader>
        <CloseModalIcon src={Back} onClick={handleCloseModal} />
        <Title>Upload art</Title>
      </ModalHeader>
      <ModalContent>
        <BulkUploader
          allowedTypes={[
            'video/mp4',
            'video/quicktime',
            'image/jpeg',
            'image/jpg',
            'image/gif',
            'image/png',
            'image/bmp',
          ]}
          maxFileSizeMB={20000}
          closeModal={handleCloseModal}
          files={selectedFiles}
          refresh={() => refresh((k) => k + 1)}
        />
      </ModalContent>
    </UploadWrapper>
  );
};

AddNewUploadModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default AddNewUploadModal;
