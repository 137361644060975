import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Components
import { InfiniteUserCollectionGrid } from '../../../components/Collections/InfiniteGrid';

// Actions
import { openModal } from '../../../store/app/actions';

// Assets
import edit from '../../../assets/images/icons/edit-pencil.svg';
import add from '../../../assets/images/icons/Add.svg';

// Styled
import {
  Wrapper,
  Header,
  HeaderTitle,
  ActionWrapper,
  ButtonWrapper,
} from './styled';

export const CollectionTotal = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [editable, setEditable] = useState(false);
  const [total, setTotal] = useState();

  const handleAddCollection = () => {
    dispatch(openModal({ modal: 'COLLECTION', params: {} }));
  };

  return (
    <Wrapper>
      <Header>
        <HeaderTitle>
          {params.type === 'me' && `Your Collections`}
          {params.type === 'followed' && `Followed Collections`}
        </HeaderTitle>
        <ActionWrapper>
          <div className="total">{`${total || '0'} collections`}</div>
          <ButtonWrapper onClick={handleAddCollection}>
            <img src={add} alt="add" />
          </ButtonWrapper>
          <ButtonWrapper
            bg={editable && '#910048'}
            onClick={() => setEditable((e) => !e)}
          >
            <img src={edit} alt="edit" />
          </ButtonWrapper>
        </ActionWrapper>
      </Header>
      {params.type === 'me' && (
        <>
          <InfiniteUserCollectionGrid
            key="userCollection"
            queryKey="userCollections"
            editable={editable}
            setTotal={setTotal}
          />
        </>
      )}
      {params.type === 'followed' && (
        <>
          <InfiniteUserCollectionGrid
            key="followedCollections"
            queryKey="followedCollections"
            subpath="/followed"
            editable={editable}
            setTotal={setTotal}
          />
        </>
      )}
    </Wrapper>
  );
};
