import React from 'react';
import PropTypes from 'prop-types';

// Assets
import defaultProfile from '../../../assets/images/default/Profile.png';

// Styled
import {
  StyledLink,
  ThumbnailContainer,
  ArtistTitle,
  TextContainer,
  ArtistBio,
} from './styled';

export function ArtistCard({ artist }) {
  function addDefaultSrc(ev) {
    ev.target.src = defaultProfile;
  }

  return (
    <StyledLink to={`/collectors/${artist?.userId}`}>
      <ThumbnailContainer>
        <img
          key={`${artist?.id}`}
          src={artist?.media?.images?.avatar}
          onError={addDefaultSrc}
          alt="artist avatar"
        />
      </ThumbnailContainer>
      <TextContainer>
        <ArtistTitle>{artist?.displayName}</ArtistTitle>
        <ArtistBio>{artist?.bio}</ArtistBio>
      </TextContainer>
    </StyledLink>
  );
}

ArtistCard.propTypes = {
  artist: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
    bio: PropTypes.string,
    userId: PropTypes.string,
    media: PropTypes.shape({
      images: PropTypes.shape({
        avatar: PropTypes.string,
      }),
    }),
  }).isRequired,
};
