import React from 'react';
import PropTypes from 'prop-types';

const TooltipStyle = {
  position: 'absolute',
  zIndex: 1,
  transition: '1s ease',
  textAlign: 'center',
  minWidth: '100px',
  fontSize: '.9em',
  boxShadow: 'rgba(23, 23, 23, .7) 0px 0px 4px 0px',
  borderRadius: '3px',
};

const TooltipInnerStyle = {
  padding: '3px 0px',
  color: '#fff',
  textAlign: 'center',
  borderRadius: 3,
  backgroundColor: '#000',
  opacity: 0.95,
};

const TooltipArrowStyle = {
  position: 'absolute',
  width: 0,
  height: 0,
  borderRightColor: 'transparent',
  borderLeftColor: 'transparent',
  borderTopColor: 'transparent',
  borderBottomColor: 'transparent',
  borderStyle: 'solid',
  opacity: 0.95,
};

const PlacementStyles = {
  left: {
    tooltip: { marginLeft: -3 },
    arrow: {
      right: 0,
      marginTop: -5,
      borderWidth: '5px 0 5px 5px',
      borderLeftColor: '#000',
    },
  },
  right: {
    tooltip: { marginLeft: 10 },
    arrow: {
      left: 0,
      marginTop: 1,
      borderWidth: '5px 5px 5px 0',
      borderRightColor: '#000',
    },
  },
  top: {
    tooltip: { marginTop: -3, left: -50, top: -35 },
    arrow: {
      borderWidth: '10px 5px',
      borderTopColor: '#000',
      bottom: '-19px',
      left: 45,
    },
  },
  bottom: {
    tooltip: { marginBottom: 3 },
    arrow: {
      top: 0,
      marginLeft: -5,
      borderWidth: '0 5px 5px',
      borderBottomColor: '#000',
    },
  },
};

const Tooltip = (props) => {
  const { placement } = props;
  const placementStyle = PlacementStyles[placement];

  const {
    style,
    arrowOffsetLeft: left = placementStyle.arrow.left,
    arrowOffsetTop: top = placementStyle.arrow.top,
    children,
  } = props;

  style.left += placementStyle.tooltip.left;
  style.top += placementStyle.tooltip.top;
  return (
    <div style={{ ...TooltipStyle, ...placementStyle.tooltip, ...style }}>
      <div
        style={{
          ...TooltipArrowStyle,
          ...placementStyle.arrow,
          left,
          top,
        }}
      />
      <div style={TooltipInnerStyle}>{children}</div>
    </div>
  );
};

Tooltip.propTypes = {
  placement: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  arrowOffsetLeft: PropTypes.object.isRequired,
  arrowOffsetTop: PropTypes.object.isRequired,
};

export default Tooltip;
