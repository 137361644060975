import styled from '@emotion/styled';
import { keyframes, css } from '@emotion/react';
import { Link } from 'react-router-dom';

const slideIn = keyframes`
  from { left: -100%; }
  to { left: 0px; }
`;

const slideOut = keyframes`
  from { left: 0px; }
  to { left: -100%; }
`;

const activeNavStyles = ({ theme }) => css`
  background: ${theme.colors.base3};
  border-radius: 12px;
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  animation: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  animation-name: ${({ open, touched }) => {
    if (touched && open) return slideIn;
    if (touched && !open) return slideOut;
    return null;
  }};

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const SearchBarContainer = styled.div`
  max-width: 1440px;
  background: #222222;
  display: flex;
  padding: 12px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.45);

  position: absolute;
  top: 0;
  width: 100%;
  margin: 24px;

  input {
    height: 48px;
    width: 100%;
    background: #333333;
    outline: none;
    border: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-indent: 12px;
    border-radius: 12px 0 0 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
  button {
    width: 48px;
    height: 48px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    @media (min-width: 768px) {
      width: 104px;
    }
  }
  .searchIcon {
    @media (min-width: 768px) {
      display: none;
    }
  }
  .text {
    @media (max-width: 767px) {
      display: none;
    }
  }
  @media (min-width: 768px) {
    padding: 24px;
    width: calc(100% - 96px);
    border-radius: 24px;
  }
`;

export const ButtonWrapper = styled.div`
  background: #333333;
  border-radius: 0 12px 12px 0;
`;

export const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  padding: 12px;
  margin-left: 16px;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
  }

  @media (min-width: 768px) {
    margin-left: 24px;
  }
`;

export const SearchResultsContainer = styled.div`
  max-width: 1440px;
  background: #1a1a1a;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.45);
  width: 100%;
  bottom: 0;
  height: calc(100% - 144px);
  margin: 160px 0px 0 0px;
  position: absolute;
  animation: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  animation-name: ${({ open }) => {
    if (open) return slideIn;
    if (!open) return slideOut;
    return null;
  }};

  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 768px) {
    margin: ${({ width }) =>
      width
        ? `160px ${(width - 1440) / 2}px 0 ${(width - 1440) / 2}px`
        : '160px 48px 0 48px'};
    width: calc(100% - 96px);
    border-radius: 24px;
  }
`;

export const SearchDetailsContainer = styled.div`
  overflow-y: hidden;
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const SearchResultHeader = styled.div`
  height: 64px;
  padding: 12px;
  background: #222222;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.45);

  @media (min-width: 768px) {
    height: 84px;
    padding: 24px;
    border-radius: 24px;
  }
`;

export const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;

  button {
    border: 0;
    background: transparent;
    cursor: pointer;
  }
  img {
    margin-right: 12px;

    @media (min-width: 768px) {
      margin-right: 24px;
    }
  }
`;

export const Divider = styled.div`
  border: 1px solid #444444;
  transform: rotate(90deg);
  width: 16px;
  height: 0px;
`;

export const NavLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 12px;
  border-radius: 12px;
  position: relative;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  cursor: pointer;
  ${({ isActive }) => (isActive ? activeNavStyles : null)}

  &:hover {
    background: #333333;
    border-radius: 12px;
  }

  @media (min-width: 375px) {
    font-size: 12px;
    line-height: 16px;
  }
  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1em;
  }
`;

export const SearchResultDetails = styled.div`
  padding: 24px;

  #pagination {
    height: 24px;
    background: #222222;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.45);
    border-radius: 12px;
    display: flex;
    align-items: center;

    button {
      width: 24px;
      height: 24px;
      display: flex;
      padding: 0px;
      justify-content: center;
      align-items: center;
      background: transparent;
      border: 0;
      cursor: pointer;

      &:hover {
        background: #3d3d3d;
        border-radius: 50px;
      }

      @media (min-width: 768px) {
        width: 40px;
        height: 40px;
      }
    }
    img {
      width: 16px;
      height: 16px;
    }
    span {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #a5a5a5;
      margin: 0 10px;
    }

    @media (min-width: 768px) {
      height: 40px;
    }
  }

  @media (min-width: 768px) {
    padding: 48px;
  }
`;

export const SearchDetailHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #444444;
  padding-bottom: 16px;
  margin-bottom: 24px;

  .header,
  .total {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.025em;

    @media (min-width: 768px) {
      font-size: 30px;
      line-height: 40px;
      align-items: flex-end;
      letter-spacing: -0.02em;
    }
  }
  .total {
    color: #a5a5a5;
    margin-left: 5px;
  }

  .see-all {
    .text {
      cursor: pointer;
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 16px;
      display: flex;
      align-items: flex-end;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #a5a5a5;
      margin-top: 12px;

      @media (min-width: 768px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    button {
      background: transparent;
      border: 0;
      cursor: pointer;
    }
  }

  @media (min-width: 768px) {
    padding-bottom: 24px;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;

  @media (min-width: 1024px) {
    flex-direction: row;

    > * {
      flex-basis: calc(50% - 12px);
      max-width: calc(50% - 12px);
    }
  }
`;

export const Item = styled(Link)`
  display: flex;
  background: #222222;
  border-radius: ${({ artist }) => (artist ? '100px 12px 12px 100px' : '12px')};
  height: 84px;
  text-decoration: none;

  &:hover {
    background: #333333;
  }

  @media (min-width: 768px) {
    height: 144px;
  }
`;

export const ThumbnailContainer = styled.div`
  img {
    object-fit: cover;
    height: 84px;
    width: 84px;
    border-radius: ${({ artist }) => (artist ? '100px' : '12px')};

    @media (min-width: 768px) {
      width: 144px;
      height: 144px;
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  justify-content: center;

  @media (min-width: 768px) {
    padding: 24px;
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  min-height: 24px;
  letter-spacing: 0.025em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.015em;
    margin-bottom: 16px;
  }
`;

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.025em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  img {
    margin-right: 12px;
    border-radius: 360px;
    width: 20px;
    height: 20px;
  }

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    min-height: 40px;
    -webkit-line-clamp: 2;
  }
`;

export const LoadingMore = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
`;
