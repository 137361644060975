import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { WalletButton } from '../../WalletButton';
import { MetaMask } from './components/MetaMask';
import { WalletConnect } from './components/WalletConnect';

// Hooks
import { useAllWallets } from '../../../hooks/data';
import { useAddWallet } from '../../../hooks/mutations';

// Styled
import { RootWrapper, ModalHeader, WalletConnectWrapper } from './styled';

function AddWalletModal({ closeModal }) {
  const [selectedProvider, setSelectedProvider] = useState(null);
  const wallets = useAllWallets();
  const addWalletMutation = useAddWallet();

  const handlePairWallet = async (walletAddress, signedMessage) => {
    await addWalletMutation.mutateAsync({
      params: { signedMessage, walletAddress },
    });
    closeModal();
  };

  return (
    <RootWrapper>
      <ModalHeader>Connect A Wallet</ModalHeader>
      <WalletConnectWrapper>
        {!selectedProvider && (
          <>
            <h2>Lets get started!</h2>
            <p>Select your wallet provider.</p>
            <WalletButton
              variant="metamask"
              onClick={() => setSelectedProvider('METAMASK')}
            >
              MetaMask
            </WalletButton>
            <WalletButton
              variant="walletconnect"
              onClick={() => setSelectedProvider('WALLET_CONNECT')}
            >
              WalletConnect
            </WalletButton>
          </>
        )}

        {selectedProvider === 'METAMASK' && (
          <MetaMask
            wallets={wallets.data}
            pairWallet={handlePairWallet}
            closeModal={closeModal}
          />
        )}

        {selectedProvider === 'WALLET_CONNECT' && (
          <WalletConnect
            wallets={wallets.data}
            pairWallet={handlePairWallet}
            closeModal={closeModal}
          />
        )}
      </WalletConnectWrapper>
    </RootWrapper>
  );
}

AddWalletModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
export default AddWalletModal;
