import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNotifications } from 'reapop';
import { Helmet } from 'react-helmet';

// Components
import { Loader } from '../../../components/Loader';
import { CollectorHeader } from '../../../components/CollectorHeader';
import { InfiniteUserPublicCollectionGrid } from '../../../components/Collections/InfiniteGrid';
import {
  InfiniteArtworkGrid,
  InfiniteUserOwnedArtworkGrid,
} from '../../../components/Artwork/InfiniteGrid';

// Hooks
import {
  useAccount,
  useSingleUser,
  useUserFollowing,
} from '../../../hooks/data';

// Styled
import { Wrapper } from './styled';

export function CollectorDetails() {
  const { notify } = useNotifications();
  const { id } = useParams();
  const currentUser = useAccount();
  const user = useSingleUser(id);
  const following = useUserFollowing();
  const [tab, setTab] = useState();

  useEffect(() => {
    if (user.isError) {
      notify({
        status: 'error',
        title: 'Uh oh!',
        message: 'There was a problem loading collector data.',
      });
    }
  }, [user.isError]);
  useEffect(() => {
    setTab(user.data?.user?.artist?.id ? 'created' : 'owned');
  }, [user.data?.user]);

  return (
    <Wrapper>
      {user.isLoading && <Loader />}
      {!user.isLoading && (
        <>
          <Helmet>
            <meta
              property="og:site_name"
              content={
                user.data?.user?.displayName ||
                user.data?.user?.artist?.displayName ||
                `${user.data?.user?.givenName} ${user.data?.user?.surName}`
              }
            />
            <meta
              property="og:title"
              content={
                user.data?.user?.displayName ||
                user.data?.user?.artist?.displayName ||
                `${user.data?.user?.givenName} ${user.data?.user?.surName}`
              }
            />
            <meta
              property="og:description"
              content="Blackdove Digital Canvas Artwork Displays"
            />
            <meta
              property="og:image"
              content={user.data?.user?.media?.images?.avatar}
            />
            <meta
              property="og:url"
              content={`https://blackdove.com/collectors/${user.data?.user?.id}`}
            />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@blackdove" />
            <meta name="twitter:url" content="<%= meta.appUrl %>" />
            <meta
              name="twitter:title"
              content={
                user.data?.user?.displayName ||
                user.data?.user?.artist?.displayName ||
                `${user.data?.user?.givenName} ${user.data?.user?.surName}`
              }
            />
            <meta
              name="twitter:description"
              content="Blackdove Digital Canvas Artwork Displays"
            />
            <meta name="twitter:image:alt" content="Blackdove Logo" />
          </Helmet>
          <CollectorHeader
            following={following}
            collector={user.data?.user}
            currentUser={currentUser.data}
            setTab={setTab}
            tab={tab}
          />
          {tab === 'created' && (
            <>
              <InfiniteArtworkGrid
                queryKey={['artist', user.data?.user?.artist?.id, 'artwork']}
                queryParams={{
                  filter: `artistId:${user.data?.user?.artist?.id}`,
                }}
              />
            </>
          )}
          {tab === 'owned' && (
            <>
              <InfiniteUserOwnedArtworkGrid id={id} />
            </>
          )}
          {tab === 'collections' && (
            <>
              <InfiniteUserPublicCollectionGrid id={id} />
            </>
          )}
        </>
      )}
    </Wrapper>
  );
}
