import React from 'react';
import PropTypes from 'prop-types';
// import { getIn } from 'formik';
import styled from '@emotion/styled';

// Styled
const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  margin-bottom: 24px;
  background: #910048;
`;

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  background: #2b2b2b;
`;

const Input = styled.textarea`
  width: 100%;
  border: none;
  background: #2b2b2b;
  border-radius: 0 0 8px 8px;
  color: #ffffff;
  padding: 0 0 0px 16px;
  height: 100px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;

  ::placeholder {
    color: #fff;
    opacity: 1;
    text-transform: inherit;
  }

  &:focus {
    outline: none;
    ::placeholder {
      color: #a5a5a5;
      opacity: 1;
    }
  }

  &:disabled {
    opacity: 0.5;
  }

  &:-webkit-autofill {
    border: 2px solid #333333;
    -webkit-text-fill-color: #ffffff;
    box-shadow: 0 0 0px 1000px #333333 inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const Label = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.025em;
  text-align: start;
  padding: 8px 0 0 16px;
  color: #a5a5a5;
  background: #2b2b2b;
  display: inline-block;
  border-radius: 8px 8px 0 0;
`;

const LengthCounter = styled.div`
  background: ${({ exceed }) => (exceed ? '#910048' : '#333333')};
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.45);
  border-radius: 14px;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  text-align: end;
  color: #ffffff;
  padding: 6px;
  background: #910048;
  height: 28px;
`;

// const HelperText = styled.span`
//   display: block;
// `;

// const ErrorText = styled.span`
//   display: block;
//   background: #910048;
//   color: #ffffff;
//   text-transform: uppercase;
//   font-size: 12px;
//   font-weight: bold;
//   padding-left: 10px;
//   padding-top: 5px;
// `;
const InputContainer = styled.div`
  display: flex;
  background: #2b2b2b;
  height: 100%;
  border-radius: 14px;
`;

export function TextArea({
  field,
  // form: { touched, errors },
  label,
  helperText,
  maxLength,
  ...props
}) {
  // const hasError = getIn(touched, field.name) && Boolean(getIn(errors, field.name));
  // const errorText = getIn(errors, field.name);

  return (
    <Wrapper>
      <InputContainer>
        <InputWrapper>
          {label && <Label htmlFor={field.name}>{label}</Label>}
          <Input
            type="text"
            id={field.name}
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            maxLength={maxLength > 0 ? maxLength : ''}
            {...props}
          />
        </InputWrapper>
      </InputContainer>
      {maxLength && (
        <LengthCounter
          exceed={field.value?.length > maxLength}
        >{`${field.value?.length} / ${maxLength}`}</LengthCounter>
      )}
      {/* {helperText && <HelperText>{helperText}</HelperText>} */}
      {/* {hasError && <ErrorText>{errorText}</ErrorText>} */}
    </Wrapper>
  );
}

TextArea.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
  }).isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  maxLength: PropTypes.number,
};

TextArea.defaultProps = {
  label: '',
  helperText: '',
  maxLength: null,
};
