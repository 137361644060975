import React from 'react';
import PropTypes from 'prop-types';

// Styled
import {
  SkeletonWrapper,
  SkeletonThumbnail,
  ShimmerWrapper,
  SkeletonTitle,
  Shimmer,
  ThumbnailAspectRatioBox,
  SkeletonCardWrapper,
} from '../styled';

export const CollectionCardSkeleton = ({
  margin,
  marginMid,
  flexBasis,
  flexBasisMid,
  flexBasisLarge,
}) => (
  <SkeletonCardWrapper
    margin={margin}
    marginMid={marginMid}
    flexBasis={flexBasis}
    flexBasisMid={flexBasisMid}
    flexBasisLarge={flexBasisLarge}
  >
    <ThumbnailAspectRatioBox>
      <SkeletonThumbnail />
      <ShimmerWrapper>
        <Shimmer />
      </ShimmerWrapper>
    </ThumbnailAspectRatioBox>
    <br />
    <SkeletonWrapper>
      <SkeletonTitle>
        <ShimmerWrapper>
          <Shimmer />
        </ShimmerWrapper>
      </SkeletonTitle>
    </SkeletonWrapper>
    <br />
  </SkeletonCardWrapper>
);

CollectionCardSkeleton.propTypes = {
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginMid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flexBasis: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flexBasisMid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flexBasisLarge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CollectionCardSkeleton.defaultProps = {
  margin: null,
  marginMid: null,
  flexBasis: null,
  flexBasisMid: null,
  flexBasisLarge: null,
};
