import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNotifications } from 'reapop';
import { useDispatch } from 'react-redux';

// Components
import { Button } from '../Button';
import { HeaderSplash } from '../HeaderSplash';

// Hooks
import { useMenuClickOutside } from '../../hooks/useMenuClickOutside';
import { useFollowUser, useUnfollowUser } from '../../hooks/mutations';

// Assets
import share from '../../assets/images/icons/Share.svg';
import website from '../../assets/images/social/website.svg';
import instagram from '../../assets/images/social/instagram.svg';
import twitter from '../../assets/images/social/twitter.svg';
import snapchat from '../../assets/images/social/snapchat.svg';
import tiktok from '../../assets/images/social/tiktok.svg';
import facebook from '../../assets/images/social/facebook.svg';
import dots from '../../assets/images/More.svg';

// Actions
import { openModal } from '../../store/app/actions';

// Styled
import {
  Wrapper,
  ArtistName,
  VideoAspectRatioBoxHeader,
  SplashWrapper,
  ArtistPic,
  ArtistPicWrapper,
  ButtonWrapper,
  ArtistDescription,
  TabWrapper,
  TabItem,
  Divider,
  SocialLinks,
  Dropdown,
  ShareWrapper,
} from './styled';

export const CollectorHeader = ({
  currentUser,
  collector,
  following,
  tab,
  setTab,
}) => {
  const { notify } = useNotifications();
  const dispatch = useDispatch();
  const followCollector = useFollowUser();
  const unfollowCollector = useUnfollowUser();
  const [more, setMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFollowing, setIsFollowing] = useState();
  const dropRef = useRef(null);

  const [isActive, setIsActive] = useMenuClickOutside(dropRef, false);

  useEffect(() => {
    if (following.data?.followed?.length > 0) {
      setIsFollowing(
        following.data?.followed?.some((f) => f?.id === collector?.id)
      );
    }
  }, [following.data]);

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `https://app.blackdove.com/collectors/${collector?.id}`
    );
    notify({
      status: 'success',
      title: 'Copied to Clipboard',
      message:
        'The share link for this collector was copied to your clipboard.',
    });
  };

  const handleOpenFollowing = ({ page }) => {
    dispatch(
      openModal({
        modal: 'FOLLOWERS',
        params: {
          followData: following.data,
          tabPage: page || 'followers',
          name:
            collector?.displayName ||
            collector?.artist.displayName ||
            `${collector?.givenName} ${collector?.surname}`,
        },
      })
    );
  };
  const handleFollowUser = async () => {
    setLoading(true);
    if (isFollowing) {
      await unfollowCollector.mutateAsync({ id: collector?.id });
      following.refetch();
    } else {
      await followCollector.mutateAsync({ id: collector?.id });
      following.refetch();
    }
    setLoading(false);
  };

  const socials = {
    ...collector?.artist?.website,
    ...collector?.artist?.instagramUsername,
    ...collector?.artist?.twitterUsername,
    ...collector?.artist?.facebookUsername,
    ...collector?.artist?.tiktokUsername,
    ...collector?.artist?.snapchatUsername,
    ...collector?.website,
    ...collector?.instagramUsername,
    ...collector?.twitterUsername,
    ...collector?.facebookUsername,
    ...collector?.tiktokUsername,
    ...collector?.snapchatUsername,
  };
  const areFalse = Object.values(socials).every((value) => value === false);

  return (
    <Wrapper>
      <SplashWrapper>
        <VideoAspectRatioBoxHeader />
        <ArtistPicWrapper>
          <ArtistPic>
            <img
              src={collector?.media?.images?.avatar}
              alt={
                collector?.displayName ||
                collector?.artist?.displayName ||
                `${collector?.givenName} ${collector?.surname}`
              }
            />
          </ArtistPic>
        </ArtistPicWrapper>
      </SplashWrapper>
      <HeaderSplash
        justifyContent="flex-start"
        padding="0px 12px 12px 12px"
        paddingLarge="0px 48px 24px 48px"
      >
        <ButtonWrapper>
          {collector?.id === currentUser?.user?.id && (
            <>
              <button
                type="button"
                className="following"
                onClick={() => handleOpenFollowing({ page: 'followers' })}
              >{`${following.data?.followers?.length} Followers`}</button>
              <button
                type="button"
                className="following"
                onClick={() => handleOpenFollowing({ page: 'following' })}
              >{`${following.data?.followed?.length} Following`}</button>
            </>
          )}
          {collector?.id && (
            <Dropdown isActive={isActive} ref={dropRef}>
              <Button
                className="dots"
                type="button"
                onClick={() => setIsActive(!isActive)}
              >
                <img src={dots} alt="more" />
              </Button>
              <div className="cards">
                {collector?.id === currentUser?.user?.id && (
                  <>
                    <button
                      type="button"
                      className="button"
                      onClick={() => handleOpenFollowing({ page: 'followers' })}
                    >{`${following.data?.followers?.length} Followers`}</button>
                    <button
                      type="button"
                      className="button"
                      onClick={() => handleOpenFollowing({ page: 'following' })}
                    >{`${following.data?.followed?.length} Following`}</button>
                  </>
                )}
                <button className="button" type="button" onClick={handleCopy}>
                  Share
                </button>
                {(collector?.artist?.website || collector?.website) && (
                  <button
                    className="button"
                    type="button"
                    onClick={() =>
                      window.open(
                        `${
                          collector?.artist?.website?.includes('https') ||
                          collector?.website?.includes('https')
                            ? collector?.artist?.website || collector?.website
                            : `https://${
                                collector?.artist?.website || collector?.website
                              }`
                        }`
                      )
                    }
                  >
                    Website
                  </button>
                )}
                {(collector?.artist?.instagramUsername ||
                  collector?.instagramUsername) && (
                  <button
                    className="button"
                    type="button"
                    onClick={() =>
                      window.open(
                        `https://www.instagram.com/${
                          collector?.artist?.instagramUsername?.[0] === '@' ||
                          collector?.instagramUsername?.[0] === '@'
                            ? collector?.artist?.instagramUsername?.slice(1) ||
                              collector?.instagramUsername?.slice(1)
                            : collector?.artist?.instagramUsername ||
                              collector?.instagramUsername
                        }`
                      )
                    }
                  >
                    Instagram
                  </button>
                )}
                {(collector?.artist?.twitterUsername ||
                  collector?.twitterUsername) && (
                  <button
                    className="button"
                    type="button"
                    onClick={() =>
                      window.open(
                        `https://www.twitter.com/${
                          collector?.artist?.twitterUsername?.[0] === '@' ||
                          collector?.twitterUsername?.[0] === '@'
                            ? collector?.artist?.twitterUsername?.slice(1) ||
                              collector?.twitterUsername?.slice(1)
                            : collector?.artist?.twitterUsername ||
                              collector?.twitterUsername
                        }`
                      )
                    }
                  >
                    Twitter
                  </button>
                )}
                {(collector?.artist?.snapchatUsername ||
                  collector?.snapchatUsername) && (
                  <button
                    className="button"
                    type="button"
                    onClick={() =>
                      window.open(
                        `https://accounts.snapchat.com/${
                          collector?.artist?.snapchatUsername?.[0] === '@' ||
                          collector?.snapchatUsername?.[0] === '@'
                            ? collector?.artist?.snapchatUsername?.slice(1) ||
                              collector?.snapchatUsername?.slice(1)
                            : collector?.artist?.snapchatUsername ||
                              collector?.snapchatUsername
                        }`
                      )
                    }
                  >
                    Snapchat
                  </button>
                )}
                {(collector?.artist?.tiktokUsername ||
                  collector?.tiktokUsername) && (
                  <button
                    className="button"
                    type="button"
                    onClick={() =>
                      window.open(
                        `https://www.tiktok.com/${
                          collector?.artist?.tiktokUsername?.[0] === '@' ||
                          collector?.tiktokUsername?.[0] === '@'
                            ? collector?.artist?.tiktokUsername ||
                              collector?.tiktokUsername
                            : `@${
                                collector?.artist?.tiktokUsername ||
                                collector?.tiktokUsername
                              }`
                        }`
                      )
                    }
                  >
                    Tiktok
                  </button>
                )}
                {(collector?.artist?.facebookUsername ||
                  collector?.facebookUsername) && (
                  <button
                    className="button"
                    type="button"
                    onClick={() =>
                      window.open(
                        `https://www.facebook.com/${
                          collector?.artist?.facebookUsername?.[0] === '@' ||
                          collector?.facebookUsername?.[0] === '@'
                            ? collector?.artist?.facebookUsername?.slice(1) ||
                              collector?.facebookUsername?.slice(1)
                            : collector?.artist?.facebookUsername ||
                              collector?.facebookUsername
                        }`
                      )
                    }
                  >
                    Facebook
                  </button>
                )}
              </div>
            </Dropdown>
          )}
          {collector?.id && !areFalse && (
            <SocialLinks>
              {(collector?.artist?.website || collector?.website) && (
                <button
                  className="button"
                  type="button"
                  onClick={() =>
                    window.open(
                      `${
                        collector?.artist?.website?.includes('https') ||
                        collector?.website?.includes('https')
                          ? collector?.artist?.website || collector?.website
                          : `https://${
                              collector?.artist?.website || collector?.website
                            }`
                      }`
                    )
                  }
                >
                  <img src={website} alt="website" />
                </button>
              )}
              {(collector?.artist?.instagramUsername ||
                collector?.instagramUsername) && (
                <button
                  className="button"
                  type="button"
                  onClick={() =>
                    window.open(
                      `https://www.instagram.com/${
                        collector?.artist?.instagramUsername?.[0] === '@' ||
                        collector?.instagramUsername?.[0] === '@'
                          ? collector?.artist?.instagramUsername?.slice(1) ||
                            collector?.instagramUsername?.slice(1)
                          : collector?.artist?.instagramUsername ||
                            collector?.instagramUsername
                      }`
                    )
                  }
                >
                  <img src={instagram} alt="instagram" />
                </button>
              )}
              {(collector?.artist?.twitterUsername ||
                collector?.twitterUsername) && (
                <button
                  className="button"
                  type="button"
                  onClick={() =>
                    window.open(
                      `https://www.twitter.com/${
                        collector?.artist?.twitterUsername?.[0] === '@' ||
                        collector?.twitterUsername?.[0] === '@'
                          ? collector?.artist?.twitterUsername?.slice(1) ||
                            collector?.twitterUsername?.slice(1)
                          : collector?.artist?.twitterUsername ||
                            collector?.twitterUsername
                      }`
                    )
                  }
                >
                  <img src={twitter} alt="twitter" />
                </button>
              )}
              {(collector?.artist?.snapchatUsername ||
                collector?.snapchatUsername) && (
                <button
                  className="button"
                  type="button"
                  onClick={() =>
                    window.open(
                      `https://accounts.snapchat.com/${
                        collector?.artist?.snapchatUsername?.[0] === '@' ||
                        collector?.snapchatUsername?.[0] === '@'
                          ? collector?.artist?.snapchatUsername?.slice(1) ||
                            collector?.snapchatUsername?.slice(1)
                          : collector?.artist?.snapchatUsername ||
                            collector?.snapchatUsername
                      }`
                    )
                  }
                >
                  <img src={snapchat} alt="snapchat" />
                </button>
              )}
              {(collector?.artist?.tiktokUsername ||
                collector?.tiktokUsername) && (
                <button
                  className="button"
                  type="button"
                  onClick={() =>
                    window.open(
                      `https://www.tiktok.com/${
                        collector?.artist?.tiktokUsername?.[0] === '@' ||
                        collector?.tiktokUsername?.[0] === '@'
                          ? collector?.artist?.tiktokUsername ||
                            collector?.tiktokUsername
                          : `@${
                              collector?.artist?.tiktokUsername ||
                              collector?.tiktokUsername
                            }`
                      }`
                    )
                  }
                >
                  <img src={tiktok} alt="tiktok" />
                </button>
              )}
              {(collector?.artist?.facebookUsername ||
                collector?.facebookUsername) && (
                <button
                  className="button"
                  type="button"
                  onClick={() =>
                    window.open(
                      `https://www.facebook.com/${
                        collector?.artist?.facebookUsername?.[0] === '@' ||
                        collector?.facebookUsername?.[0] === '@'
                          ? collector?.artist?.facebookUsername?.slice(1) ||
                            collector?.facebookUsername?.slice(1)
                          : collector?.artist?.facebookUsername ||
                            collector?.facebookUsername
                      }`
                    )
                  }
                >
                  <img src={facebook} alt="facebook" />
                </button>
              )}
            </SocialLinks>
          )}
          <ShareWrapper>
            <Button
              type="button"
              color={collector?.id === currentUser?.user?.id && 'red'}
              className="share"
              onClick={handleCopy}
            >
              <img src={share} alt="share" />
            </Button>
          </ShareWrapper>
          {collector?.id !== currentUser?.user?.id && (
            <Button
              type="button"
              className="share"
              color={isFollowing ? '' : 'red'}
              onClick={handleFollowUser}
              disabled={loading}
            >
              {isFollowing ? 'Unfollow' : 'Follow'}
            </Button>
          )}
        </ButtonWrapper>
        <ArtistName>
          {collector?.displayName ||
            collector?.artist?.displayName ||
            `${collector?.givenName} ${collector?.surname}`}
        </ArtistName>
        {collector?.bio ||
          (collector?.artist?.bio && (
            <ArtistDescription more={more}>
              {more
                ? collector?.bio || collector?.artist?.bio
                : collector?.bio?.slice(0, 200) ||
                  collector?.artist?.bio.slice(0, 200)}
              <button type="button" onClick={() => setMore(!more)}>
                {collector?.bio?.length > 200 ||
                  (collector?.artist?.bio.length > 200 &&
                    (more ? '...Show less' : '...Show more'))}
              </button>
            </ArtistDescription>
          ))}
        <TabWrapper>
          {collector?.artist?.id && (
            <>
              <TabItem
                onClick={() => setTab('created')}
                isActive={tab === 'created'}
              >
                Created
              </TabItem>

              <Divider />
            </>
          )}
          <TabItem onClick={() => setTab('owned')} isActive={tab === 'owned'}>
            Owned
          </TabItem>
          <Divider />
          <TabItem
            onClick={() => setTab('collections')}
            isActive={tab === 'collections'}
          >
            Collections
          </TabItem>
        </TabWrapper>
      </HeaderSplash>
    </Wrapper>
  );
};

CollectorHeader.propTypes = {
  collector: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  following: PropTypes.object.isRequired,
  tab: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
};
