import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

// Components
import {
  InfiniteArtworkGrid,
  InfiniteSingleCollectionGrid,
} from '../../components/Artwork/InfiniteGrid';
import { Button } from '../../components/Button';
import { HeaderSplash } from '../../components/HeaderSplash';

// Assets
import upload from '../../assets/images/icons/Upload.svg';
import drive from '../../assets/images/Google_Drive_text_logo_grey.png';
import dropbox from '../../assets/images/Dropbox_logo.png';

// Hooks
import { useUser } from '../../hooks/data';
import { useMenuClickOutside } from '../../hooks/useMenuClickOutside';

// Actions
import { openModal } from '../../store/app/actions';

// Styled
import {
  HeaderWrapper,
  LowerWrapper,
  SortWrapper,
  UploadWrapper,
  UploadText,
  TabWrapper,
  Divider,
  NavLink,
  Dropdown,
  ButtonText,
  ButtonContainer,
  UploadButton,
} from './styled';

export const Vault = () => {
  const dispatch = useDispatch();
  const user = useUser();
  const [tab, setTab] = useState('user');
  const [refreshKey, setRefreshKey] = useState(0);
  const [orderBy, setOrderBy] = useState([
    ['createdAt', 'DESC'],
    ['order', 'ASC'],
  ]);
  const dropRef = useRef(null);
  const [isActive, setIsActive] = useMenuClickOutside(dropRef, false);
  const [dragOver, setDragOver] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState();
  const hiddenFileInput = React.useRef(null);
  const allowedTypes = [
    'video/mp4',
    'video/quicktime',
    'image/jpeg',
    'image/jpg',
    'image/gif',
    'image/png',
    'image/bmp',
  ];
  const accepted = allowedTypes.join();

  const handleClick = useCallback(() => {
    if (!selectedFiles) {
      hiddenFileInput.current.click();
    }
  });

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    setDragOver(false);

    const files = e.target.files || e.dataTransfer.files;
    const filesArray = Object.values(files);
    setSelectedFiles(filesArray);
  });

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
  });

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    setDragOver(true);
  });

  const handleDragLeave = useCallback(() => {
    setDragOver(false);
  });

  const handleUpload = async () => {
    await dispatch(
      openModal({
        modal: 'ADD_NEW_UPLOAD',
        params: { selectedFiles, setRefreshKey },
      })
    );
    setSelectedFiles();
    hiddenFileInput.current.value = '';
  };

  useEffect(() => {
    if (selectedFiles?.length > 0) {
      handleUpload();
    }
  }, [selectedFiles]);

  const handleOrderBy = (filter) => {
    setOrderBy(filter);
    setIsActive(!isActive);
  };

  const { google } = window;

  async function pickerCallback(data) {
    if (data.action === google.picker.Action.PICKED) {
      const document = data[google.picker.Response.DOCUMENTS][0];
      const fileId = document[google.picker.Document.ID];
      const URL = 'https://www.googleapis.com/drive/v3/files';

      const {
        gapi: {
          auth,
          client: {
            drive: { files },
          },
        },
      } = window;
      const { access_token: accessToken } = auth.getToken();
      const fetchOptions = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };

      const {
        result: { name, mimeType, modifiedTime },
      } = await files.get({
        fileId,
        fields: 'name, mimeType, size, modifiedTime',
      });

      const blob = await fetch(`${URL}/${fileId}?alt=media`, fetchOptions).then(
        (res) => res.blob()
      );
      const fileOptions = {
        type: mimeType,
        lastModified: new Date(modifiedTime).getTime(),
      };

      const file = new File([blob], name, fileOptions);
      setSelectedFiles([file]);
    }
  }

  // Create and render a Google Picker object for selecting from Drive
  const createPicker = useCallback(() => {
    const showPicker = () => {
      // TODO(developer): Replace with your API key
      const picker = new google.picker.PickerBuilder()
        .addView(google.picker.ViewId.DOCS_IMAGES_AND_VIDEOS)
        // eslint-disable-next-line
        .setOAuthToken(accessToken)
        .setDeveloperKey('AIzaSyBSUJAvnnh_sSOR1r2g4Lo3MmrUhSAAAbo')
        .setCallback(pickerCallback)
        .build();
      picker.setVisible(true);
    };

    // Request an access token
    // eslint-disable-next-line
    tokenClient.callback = async (response) => {
      if (response.error !== undefined) {
        throw response;
      }
      // eslint-disable-next-line
      accessToken = response.access_token;
      showPicker();
    };

    // eslint-disable-next-line
    if (accessToken === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      // eslint-disable-next-line
      tokenClient.requestAccessToken({ prompt: 'consent' });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      // eslint-disable-next-line
      tokenClient.requestAccessToken({ prompt: '' });
    }
  }, []);

  const dropboxPicker = useCallback(() => {
    const { Dropbox } = window;
    let accessToken;
    const { hash } = window.location;

    if (hash) {
      accessToken = new URLSearchParams(window.location.hash).get(
        '#access_token'
      );
    }

    if (!accessToken) {
      const redirectURI = '&redirect_uri=https://app.blackdove.com/vault';
      const URL = `https://www.dropbox.com/oauth2/authorize?client_id=shm2dhzhc2s6m7m${redirectURI}&response_type=token`;
      window.location = URL;
    } else {
      Dropbox.choose({
        success: async (files) => {
          const { id, name } = files[0];
          const blob = await fetch(
            'https://content.dropboxapi.com/2/files/download',
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Dropbox-API-Arg': JSON.stringify({ path: id }),
              },
            }
          ).then((res) => res.blob());

          const fileTypes = {
            mp4: 'video/mp4',
            mov: 'video/quicktime',
            jpeg: 'image/jpeg',
            jpg: 'image/jpg',
            gif: 'image/gif',
            png: 'image/png',
            bmp: 'image/bmp',
          };
          const ext = name.split('.').pop();

          const fileOptions = {
            type: fileTypes[ext],
          };

          const file = new File([blob], name, fileOptions);
          setSelectedFiles([file]);
        },
        extensions: ['.mov', '.jpg', '.jpeg', '.png', '.gif', '.mp4', '.bmp'],
      });
    }
  }, []);

  useEffect(() => {
    if (window.location.hash) {
      dropboxPicker();
    }
  }, []);

  return (
    <div key={refreshKey}>
      <HeaderSplash padding="12px 0 0 0" paddingLarge="24px 0 0 0">
        <HeaderWrapper>
          <TabWrapper>
            <NavLink onClick={() => setTab('user')} isActive={tab === 'user'}>
              Uploads
            </NavLink>
            {/* {user.data?.user?.artist?.id && ( */}
            <>
              <Divider />
              <NavLink
                onClick={() => setTab('artist')}
                isActive={tab === 'artist'}
              >
                Artworks
              </NavLink>
            </>
            {/* )} */}
          </TabWrapper>
          <SortWrapper>
            <Dropdown isActive={isActive} ref={dropRef}>
              <Button
                type="button"
                className="styled"
                onClick={() => setIsActive(!isActive)}
              >
                <ButtonText>
                  {orderBy?.[0]?.[1] === 'DESC' ? 'Most Recent' : 'Oldest'}
                </ButtonText>
              </Button>
              <div className="cards">
                <button
                  className="button"
                  type="button"
                  onClick={() =>
                    handleOrderBy([
                      ['createdAt', 'DESC'],
                      ['order', 'ASC'],
                    ])
                  }
                >
                  Most Recent
                </button>
                <button
                  className="button"
                  type="button"
                  onClick={() =>
                    handleOrderBy([
                      ['createdAt', 'ASC'],
                      ['order', 'ASC'],
                    ])
                  }
                >
                  Oldest
                </button>
              </div>
            </Dropdown>
          </SortWrapper>
        </HeaderWrapper>
      </HeaderSplash>
      <LowerWrapper>
        <div style={{ padding: '24px' }}>
          <UploadWrapper
            onClick={handleClick}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            dragOver={dragOver}
          >
            <img src={upload} alt="upload" />
            <div className="text">
              <UploadText>
                Drop your artwork here or click to browse and upload your
                creation
              </UploadText>
              <UploadText>.mp4 | 10 GB Max</UploadText>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={(e) => handleDrop(e)}
                accept={accepted}
                multiple
              />
            </div>
          </UploadWrapper>
          <ButtonContainer type="button">
            <UploadButton padding="0px" onClick={createPicker}>
              <img src={drive} alt="google drive" />
            </UploadButton>
            <UploadButton type="button" padding="6px" onClick={dropboxPicker}>
              <img src={dropbox} alt="google drive" />
            </UploadButton>
          </ButtonContainer>
        </div>
        {tab === 'user' && (
          <>
            <InfiniteSingleCollectionGrid
              id="personal"
              queryParams={{
                type: 'incomplete',
              }}
              isOwner={false}
            />
          </>
        )}
        {tab === 'artist' && user.data?.user?.artist?.id && (
          <>
            <InfiniteArtworkGrid
              queryKey={['artist', user.data?.user?.artist?.id, 'artwork']}
              queryParams={{
                filter: `artistId:${user.data?.user?.artist?.id}`,
              }}
            />
          </>
        )}
      </LowerWrapper>
    </div>
  );
};
