import React from 'react';
import PropTypes from 'prop-types';

export function Instagram({ color, width, height, viewBox }) {
  return (
    <div>
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox={viewBox || '0 0 16 16'}
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.49984 1.66667C3.38863 1.66667 1.6665 3.3888 1.6665 5.5V10.5C1.6665 12.6109 3.38857 14.3333 5.49984 14.3333H10.4998C12.6108 14.3333 14.3332 12.611 14.3332 10.5V5.5C14.3332 3.38874 12.6108 1.66667 10.4998 1.66667H5.49984ZM5.49984 2.66667H10.4998C12.0703 2.66667 13.3332 3.92927 13.3332 5.5V10.5C13.3332 12.0704 12.0702 13.3333 10.4998 13.3333H5.49984C3.9291 13.3333 2.6665 12.0704 2.6665 10.5V5.5C2.6665 3.92921 3.92904 2.66667 5.49984 2.66667ZM11.3332 4.00001C10.9648 4.00001 10.6665 4.29834 10.6665 4.66667C10.6665 5.035 10.9648 5.33334 11.3332 5.33334C11.7015 5.33334 11.9998 5.035 11.9998 4.66667C11.9998 4.29834 11.7015 4.00001 11.3332 4.00001ZM7.99984 4.66667C6.1649 4.66667 4.6665 6.16506 4.6665 8C4.6665 9.83494 6.1649 11.3333 7.99984 11.3333C9.83478 11.3333 11.3332 9.83494 11.3332 8C11.3332 6.16506 9.83478 4.66667 7.99984 4.66667ZM7.99984 5.66667C9.29423 5.66667 10.3332 6.70561 10.3332 8C10.3332 9.2944 9.29423 10.3333 7.99984 10.3333C6.70544 10.3333 5.6665 9.2944 5.6665 8C5.6665 6.70561 6.70544 5.66667 7.99984 5.66667Z"
          fill={color}
        />
      </svg>
    </div>
  );
}

Instagram.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};

Instagram.defaultProps = {
  color: '#ffffff',
  height: '16',
  width: '16',
  viewBox: '0 0 16 16',
};
