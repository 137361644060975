import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { BlackdoveClient } from '@blackdove/utils';

// Hooks
import { useAccount } from '../../../../hooks/data';
import { useUpdateUser, useUpdatePassword } from '../../../../hooks/mutations';

// Components
import { Flex, Box } from '../../../../components/Flexbox';
import { Button } from '../../../../components/Button';
import { TextInput } from '../../../../components/FormNext';

// Styled
import {
  FormWrapper,
  HeaderSection,
  FlexContainer,
  FormSection,
  Title,
  Description,
  SubscriptionWrapper,
  ContentHeaderLine,
  ProfileInfoLine,
  Label,
  InfoText,
} from '../../styled';

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
});

const validationSchemaPass = yup.object().shape({
  currentPassword: yup.string().min(8).required(),
  newPassword: yup.string().min(8).required(),
  resetNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required()
    .label('Repeat Password'),
});

export function Account({ user }) {
  const updateUser = useUpdateUser();
  const updatePasswordMutation = useUpdatePassword();
  const account = useAccount();
  const navigate = useNavigate();

  const [initialValues] = React.useState({
    email: user.email,
  });
  const [initialValuesPass] = React.useState({
    currentPassword: '',
    newPassword: '',
    resetNewPassword: '',
  });

  const handleEmailChange = async (values, { setSubmitting }) => {
    const params = {
      email: values.email,
    };

    await updateUser.mutateAsync({ params });
    setSubmitting(false);
  };

  const handlePasswordChange = async (values, { setSubmitting }) => {
    const params = {
      oldPassword: values.currentPassword,
      password: values.newPassword,
      passwordAgain: values.resetNewPassword,
    };
    await updatePasswordMutation.mutateAsync({ userId: user.id, params });
    setSubmitting(false);
  };
  const handleManageSubscription = async () => {
    const result = await BlackdoveClient.get('/v2/billing/manage');
    window.location = result?.data?.sessionUrl;
  };

  const renderSubDescription = (plan) => {
    switch (plan) {
      case 'FREE':
        return 'Free';
      case 'NFT_ONLY':
        return 'NFT Only';
      case 'FULL':
        return 'Premium';
      default:
        return 'Free';
    }
  };

  return (
    <>
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleEmailChange}
        >
          {({ isSubmitting }) => (
            <Form>
              <FlexContainer>
                <HeaderSection>
                  <Title>Edit your email</Title>
                  <Description>
                    This information will not appear on your profile but helps
                    Blackdove contact you with unique oppurtunities.
                  </Description>
                </HeaderSection>
                <FormSection>
                  <Field
                    name="email"
                    label="Enter Email"
                    placeholder="e.g. youremail@email.com"
                    component={TextInput}
                  />
                  <Button
                    type="submit"
                    color="red"
                    disabled={isSubmitting}
                    pending={isSubmitting}
                  >
                    Save Changes
                  </Button>
                </FormSection>
              </FlexContainer>
            </Form>
          )}
        </Formik>
        <Formik
          initialValues={initialValuesPass}
          validationSchema={validationSchemaPass}
          onSubmit={handlePasswordChange}
        >
          {({ isSubmitting }) => (
            <Form>
              <FlexContainer>
                <HeaderSection>
                  <Title>Change Password</Title>
                  <Description>
                    This information will not appear on your profile but helps
                    Blackdove contact you with unique oppurtunities.
                  </Description>
                </HeaderSection>
                <FormSection>
                  <Field
                    name="currentPassword"
                    type="password"
                    label="Current Password"
                    placeholder=""
                    component={TextInput}
                  />
                  <Field
                    name="newPassword"
                    type="password"
                    label="New Password"
                    placeholder=""
                    component={TextInput}
                  />
                  <Field
                    name="resetNewPassword"
                    type="password"
                    label="Repeat New Passwrod"
                    placeholder=""
                    component={TextInput}
                  />
                  <Button
                    type="submit"
                    color="red"
                    disabled={isSubmitting}
                    pending={isSubmitting}
                  >
                    Change Password
                  </Button>
                </FormSection>
              </FlexContainer>
            </Form>
          )}
        </Formik>
        <SubscriptionWrapper>
          <ContentHeaderLine>Your Subscription</ContentHeaderLine>
          <ProfileInfoLine>
            <Flex flexWrap="wrap" mb={25}>
              <Box width={[1, 1 / 2, 1 / 2]}>
                <Label>Plan</Label>
              </Box>
              <Box width={[1, 1 / 2, 1 / 2]}>
                <InfoText>
                  {account.isFetched &&
                    renderSubDescription(
                      account.data?.subscription?.subscriptionType
                    )}
                </InfoText>
              </Box>
              <Box width={[1, 1 / 2, 1 / 2]}>
                <Label>Device Usage</Label>
              </Box>
              <Box width={[1, 1 / 2, 1 / 2]}>
                <InfoText>
                  {`${
                    account.isFetched && account.data?.usage?.devices?.used
                  }/${account.data?.usage?.devices?.total}`}
                </InfoText>
              </Box>
              <Box width={[1, 1 / 2, 1 / 2]}>
                <Label>Collection Usage</Label>
              </Box>
              <Box width={[1, 1 / 2, 1 / 2]}>
                <InfoText>
                  {`${
                    account.isFetched && account.data?.usage?.collections?.used
                  }/${account.data?.usage?.collections?.total}`}
                </InfoText>
              </Box>
            </Flex>
          </ProfileInfoLine>
          <Flex flexWrap="wrap">
            <Box width={[1, 1 / 2, 1 / 2]}>
              {account.isFetched &&
                account.data?.subscription?.subscriptionType === 'FREE' && (
                  <Button
                    color="red"
                    borderColor="#111111"
                    onClick={() => navigate('/pricing')}
                  >
                    Subscribe
                  </Button>
                )}
              {account.isFetched &&
                account.data?.subscription?.subscriptionType !== 'FREE' && (
                  <Button
                    borderColor="#111111"
                    onClick={handleManageSubscription}
                  >
                    Manage Subscription
                  </Button>
                )}
            </Box>
          </Flex>
        </SubscriptionWrapper>
      </FormWrapper>
    </>
  );
}

Account.propTypes = {
  user: PropTypes.object.isRequired,
};
