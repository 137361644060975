import React from 'react';
import PropTypes from 'prop-types';

// Styled
import {
  SkeletonText,
  ShimmerWrapper,
  SkeletonBigCircle,
  SkeletonTitle,
  Shimmer,
  SkeletonArtistCardWrapper,
  FlexWrapper,
} from '../styled';

export const ArtistCardSkeleton = ({
  margin,
  marginMid,
  flexBasis,
  flexBasisMid,
}) => (
  <SkeletonArtistCardWrapper
    margin={margin}
    marginMid={marginMid}
    flexBasis={flexBasis}
    flexBasisMid={flexBasisMid}
  >
    <FlexWrapper>
      <SkeletonBigCircle>
        <ShimmerWrapper>
          <Shimmer />
        </ShimmerWrapper>
      </SkeletonBigCircle>
      <FlexWrapper direction="column" justify="center">
        <SkeletonTitle>
          <ShimmerWrapper>
            <Shimmer />
          </ShimmerWrapper>
        </SkeletonTitle>
        <SkeletonText>
          <ShimmerWrapper>
            <Shimmer />
          </ShimmerWrapper>
        </SkeletonText>
        <SkeletonText>
          <ShimmerWrapper>
            <Shimmer />
          </ShimmerWrapper>
        </SkeletonText>
      </FlexWrapper>
    </FlexWrapper>
  </SkeletonArtistCardWrapper>
);

ArtistCardSkeleton.propTypes = {
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginMid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flexBasis: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flexBasisMid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ArtistCardSkeleton.defaultProps = {
  margin: null,
  marginMid: null,
  flexBasis: null,
  flexBasisMid: null,
};
