import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { Button } from '../../../../Button';
import { ArtistCardSkeleton } from '../../../../Skeletons/ArtistCardSkeleton';

// Hooks
import {
  useSearchAllArtists,
  useSearchAllCollections,
  useSearchAllArtworks,
} from '../../../../../hooks/data';
import useElementSize from '../../../../../hooks/useElementSize';

// Actions
import { closeSearch } from '../../../../../store/app/actions';

// Assets
import close from '../../../../../assets/images/icons/icon-close.svg';
import searchicon from '../../../../../assets/images/icons/Search.svg';
import ArrowLeft from '../../../../../assets/images/icons/ArrowLeft.svg';
import ArrowRight from '../../../../../assets/images/icons/ArrowRight.svg';
import defaultImg from '../../../../../assets/images/favicon-96x96.png';

// Styled
import {
  Wrapper,
  SearchBarContainer,
  IconWrapper,
  ButtonWrapper,
  SearchResultsContainer,
  SearchDetailsContainer,
  SearchResultHeader,
  SearchResultDetails,
  SearchDetailHeader,
  TabWrapper,
  NavLink,
  Divider,
  ItemWrapper,
  Item,
  ThumbnailContainer,
  TextContainer,
  Title,
  SubTitle,
  LoadingMore,
} from './styled';

export const Search = () => {
  const dispatch = useDispatch();
  const containerRef = React.useRef();
  const [containerWidth] = useElementSize(containerRef);
  const search = useSelector((state) => state.app);
  const [result, setResult] = React.useState('all');
  const [input, setInput] = React.useState();
  const [query, setQuery] = React.useState();
  const [width, setWidth] = React.useState(containerWidth);
  const [open, setOpen] = React.useState(false);
  const [limit] = React.useState(20);
  const [artworkOffset, setArtworkOffset] = React.useState(0);
  const [artistOffset, setArtistOffset] = React.useState(0);
  const [collectionOffset, setCollectionOffset] = React.useState(0);
  const artistParams = { query, limit, offset: artistOffset };
  const searchArtistData = useSearchAllArtists(artistParams || undefined);
  const collectionParams = { query, limit, offset: collectionOffset };
  const searchCollectionData = useSearchAllCollections(
    collectionParams || undefined
  );
  const artworkParams = { query, limit, offset: artworkOffset };
  const searchArtworkData = useSearchAllArtworks(artworkParams || undefined);
  const [count, setCount] = React.useState({
    prev: artworkOffset - 19,
    next: artworkOffset,
  });

  React.useEffect(() => {
    setWidth(containerWidth);
  }, [containerWidth]);
  React.useEffect(() => {
    setCount({ ...count, prev: artworkOffset - 19, next: artworkOffset });
  }, [artworkOffset]);

  const handleClose = () => {
    dispatch(closeSearch());
  };

  const handleSearch = () => {
    searchArtistData.remove();
    searchCollectionData.remove();
    searchArtworkData.remove();
    setArtworkOffset(0);
    setCollectionOffset(0);
    setArtistOffset(0);

    setQuery(input);
    setOpen(true);
  };

  const handleOnChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeypress = (event) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      handleSearch();
    }
  };

  const handleNextPage = (data, setIndex, index, action) => {
    if (action === 'NEXT' && data.data?.length % 10 === 0) {
      setIndex(index + 20);
      data.remove();
      data.refetch();
    }
    if (action === 'PREV' && index !== 0) {
      setIndex(index - 20);
      data.remove();
      data.refetch();
    }
  };

  const handleBrokenLink = (e) => {
    e.target.src = `${defaultImg}`;
  };

  return (
    <Wrapper
      open={search.searchOpen}
      touched={search.touched}
      ref={containerRef}
    >
      <SearchBarContainer>
        <input
          type="text"
          onChange={(e) => handleOnChange(e)}
          onKeyPress={handleKeypress}
        />
        <ButtonWrapper onClick={handleSearch}>
          <Button
            id="search-button"
            color="red"
            pending={searchArtworkData.isLoading}
            disabled={searchArtworkData.isLoading}
          >
            <img className="searchIcon" src={searchicon} alt="search-icon" />
            <div className="text">Search</div>
          </Button>
        </ButtonWrapper>
        <IconWrapper onClick={handleClose}>
          <img src={close} alt="close-icon" />
        </IconWrapper>
      </SearchBarContainer>
      <SearchResultsContainer
        open={searchArtworkData.data || open}
        width={width > 1536 && width}
      >
        <SearchResultHeader>
          <TabWrapper>
            <NavLink
              isActive={result === 'all'}
              onClick={() => setResult('all')}
            >
              All
            </NavLink>
            <Divider />
            <NavLink
              isActive={result === 'artworks'}
              onClick={() => setResult('artworks')}
            >
              Artworks
            </NavLink>
            <Divider />
            <NavLink
              isActive={result === 'collections'}
              onClick={() => setResult('collections')}
            >
              Collections
            </NavLink>
            <Divider />
            <NavLink
              isActive={result === 'artists'}
              onClick={() => setResult('artists')}
            >
              Artists
            </NavLink>
          </TabWrapper>
        </SearchResultHeader>
        <SearchDetailsContainer>
          {(result === 'all' || result === 'artworks') && (
            <>
              <SearchResultDetails>
                <SearchDetailHeader>
                  <div className="header">
                    Artworks
                    <div className="total">
                      (
                      {`${
                        searchArtworkData.data?.length
                          ? searchArtworkData.data?.length + artworkOffset
                          : 0
                      }`}
                      {searchArtworkData.data?.length % 10 === 0 && (
                        <div>+</div>
                      )}
                      )
                    </div>
                  </div>
                  {result === 'all' && (
                    <div
                      className="see-all"
                      onClick={() => setResult('artworks')}
                    >
                      <div className="text">See all</div>
                    </div>
                  )}
                  {result === 'artworks' && (
                    <div className="see-all" id="pagination">
                      {artworkOffset !== 0 && (
                        <button
                          type="button"
                          onClick={() =>
                            handleNextPage(
                              searchArtworkData,
                              setArtworkOffset,
                              artworkOffset,
                              'PREV'
                            )
                          }
                        >
                          <img src={ArrowLeft} alt="left-arrow" />
                        </button>
                      )}
                      <span>
                        {`${artworkOffset + 1} -
                          ${
                            searchArtworkData.data?.length + artworkOffset ||
                            artworkOffset + 20
                          }`}
                      </span>
                      {(artworkOffset === 0 ||
                        (artworkOffset > 0 && artworkOffset % 10 === 0)) && (
                        <button
                          type="button"
                          onClick={() =>
                            handleNextPage(
                              searchArtworkData,
                              setArtworkOffset,
                              artworkOffset,
                              'NEXT'
                            )
                          }
                        >
                          <img src={ArrowRight} alt="right-arrow" />
                        </button>
                      )}
                    </div>
                  )}
                </SearchDetailHeader>
                <ItemWrapper>
                  <>
                    {searchArtworkData.isLoading ? (
                      <>
                        {result === 'all' ? (
                          <>
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                          </>
                        ) : (
                          <>
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton /> <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton /> <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton /> <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton /> <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {searchArtworkData.data?.map(
                          (artwork, index) =>
                            (result === 'all'
                              ? index < 4
                              : index < searchArtworkData.data?.length) && (
                              <>
                                <Item
                                  key={`${artwork?.id}`}
                                  to={`/artwork/${
                                    artwork?.slug ? artwork?.slug : artwork?.id
                                  }`}
                                  onClick={handleClose}
                                >
                                  <ThumbnailContainer>
                                    <img
                                      src={
                                        artwork?.media?.image?.low?.square ||
                                        defaultImg
                                      }
                                      onError={handleBrokenLink}
                                      alt="artwork"
                                    />
                                  </ThumbnailContainer>
                                  <TextContainer>
                                    <Title>{artwork?.name}</Title>
                                    <SubTitle>
                                      <img
                                        src={
                                          artwork?.artist?.media?.images?.avatar
                                        }
                                        alt="artist"
                                      />
                                      {artwork?.artist?.displayName}
                                    </SubTitle>
                                  </TextContainer>
                                </Item>
                              </>
                            )
                        )}
                      </>
                    )}
                  </>
                </ItemWrapper>
                {result === 'artworks' && (
                  <LoadingMore>
                    <div className="see-all" id="pagination">
                      {artworkOffset !== 0 && (
                        <button
                          type="button"
                          onClick={() =>
                            handleNextPage(
                              searchArtworkData,
                              setArtworkOffset,
                              artworkOffset,
                              'PREV'
                            )
                          }
                        >
                          <img src={ArrowLeft} alt="left-arrow" />
                        </button>
                      )}
                      <span>
                        {`${artworkOffset + 1} -
                          ${
                            searchArtworkData.data?.length + artworkOffset ||
                            artworkOffset + 20
                          }`}
                      </span>
                      {(artworkOffset === 0 ||
                        (artworkOffset > 0 && artworkOffset % 10 === 0)) && (
                        <button
                          type="button"
                          onClick={() =>
                            handleNextPage(
                              searchArtworkData,
                              setArtworkOffset,
                              artworkOffset,
                              'NEXT'
                            )
                          }
                        >
                          <img src={ArrowRight} alt="right-arrow" />
                        </button>
                      )}
                    </div>
                  </LoadingMore>
                )}
              </SearchResultDetails>
            </>
          )}
          {(result === 'all' || result === 'collections') && (
            <>
              <SearchResultDetails>
                <SearchDetailHeader>
                  <div className="header">
                    Collections
                    <div className="total">
                      (
                      {`${
                        searchCollectionData.data?.length
                          ? searchCollectionData.data?.length + collectionOffset
                          : 0
                      }`}
                      {searchCollectionData.data?.length % 10 === 0 && (
                        <div>+</div>
                      )}
                      )
                    </div>
                  </div>
                  {result === 'all' && (
                    <div
                      className="see-all"
                      onClick={() => setResult('collections')}
                    >
                      <div className="text">See all</div>
                    </div>
                  )}
                  {result === 'collections' && (
                    <div className="see-all" id="pagination">
                      {collectionOffset !== 0 && (
                        <button
                          type="button"
                          onClick={() =>
                            handleNextPage(
                              searchCollectionData,
                              setCollectionOffset,
                              collectionOffset,
                              'PREV'
                            )
                          }
                        >
                          <img src={ArrowLeft} alt="left-arrow" />
                        </button>
                      )}
                      <span>
                        {`${collectionOffset + 1} -
                         ${
                           searchCollectionData.data?.length +
                             collectionOffset || collectionOffset + 20
                         }`}
                      </span>
                      {(collectionOffset === 0 ||
                        (collectionOffset > 0 &&
                          collectionOffset % 10 === 0)) && (
                        <button
                          type="button"
                          onClick={() =>
                            handleNextPage(
                              searchCollectionData,
                              setCollectionOffset,
                              collectionOffset,
                              'NEXT'
                            )
                          }
                        >
                          <img src={ArrowRight} alt="right-arrow" />
                        </button>
                      )}
                    </div>
                  )}
                </SearchDetailHeader>
                <ItemWrapper>
                  <>
                    {searchCollectionData.isLoading ? (
                      <>
                        {result === 'all' ? (
                          <>
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                          </>
                        ) : (
                          <>
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton /> <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton /> <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton /> <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton /> <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {searchCollectionData.data?.map(
                          (collection, index) =>
                            (result === 'all'
                              ? index < 4
                              : index < searchCollectionData.data?.length) && (
                              <>
                                <Item
                                  key={`${collection?.id}`}
                                  to={`/collections/${collection?.id}`}
                                  onClick={handleClose}
                                >
                                  <ThumbnailContainer>
                                    <img
                                      src={collection?.media?.images?.thumbnail}
                                      alt="collection"
                                    />
                                  </ThumbnailContainer>
                                  <TextContainer>
                                    <Title>{collection?.name}</Title>
                                    <SubTitle>{`${collection?.artwork?.length} Artworks`}</SubTitle>
                                  </TextContainer>
                                </Item>
                              </>
                            )
                        )}
                      </>
                    )}
                  </>
                </ItemWrapper>
                {result === 'collections' && (
                  <LoadingMore>
                    <div className="see-all" id="pagination">
                      {collectionOffset !== 0 && (
                        <button
                          type="button"
                          onClick={() =>
                            handleNextPage(
                              searchCollectionData,
                              setCollectionOffset,
                              collectionOffset,
                              'PREV'
                            )
                          }
                        >
                          <img src={ArrowLeft} alt="left-arrow" />
                        </button>
                      )}
                      <span>
                        {`${collectionOffset + 1} -
                         ${
                           searchCollectionData.data?.length +
                             collectionOffset || collectionOffset + 20
                         }`}
                      </span>
                      {(collectionOffset === 0 ||
                        (collectionOffset > 0 &&
                          collectionOffset % 10 === 0)) && (
                        <button
                          type="button"
                          onClick={() =>
                            handleNextPage(
                              searchCollectionData,
                              setCollectionOffset,
                              collectionOffset,
                              'NEXT'
                            )
                          }
                        >
                          <img src={ArrowRight} alt="right-arrow" />
                        </button>
                      )}
                    </div>
                  </LoadingMore>
                )}
              </SearchResultDetails>
            </>
          )}
          {(result === 'all' || result === 'artists') && (
            <>
              <SearchResultDetails>
                <SearchDetailHeader>
                  <div className="header">
                    Artists
                    <div className="total">
                      (
                      {`${
                        searchArtistData.data?.length
                          ? searchArtistData.data?.length
                          : 0
                      }`}{' '}
                      {searchArtistData.data?.length % 10 === 0 && <div>+</div>}
                      )
                    </div>
                  </div>
                  {result === 'all' && (
                    <div
                      className="see-all"
                      onClick={() => setResult('artists')}
                    >
                      <div className="text"> See all </div>
                    </div>
                  )}
                  {result === 'artists' && (
                    <div className="see-all" id="pagination">
                      {artistOffset !== 0 && (
                        <button
                          type="button"
                          onClick={() =>
                            handleNextPage(
                              searchArtistData,
                              setArtistOffset,
                              artistOffset,
                              'PREV'
                            )
                          }
                        >
                          <img src={ArrowLeft} alt="left-arrow" />
                        </button>
                      )}
                      <span>
                        {`${artistOffset + 1} -
                       ${
                         searchArtistData.data?.length + artistOffset ||
                         artistOffset + 20
                       }`}
                      </span>
                      {(artistOffset === 0 ||
                        (artistOffset > 0 && artistOffset % 10 === 0)) && (
                        <button
                          type="button"
                          onClick={() =>
                            handleNextPage(
                              searchArtistData,
                              setArtistOffset,
                              artistOffset,
                              'NEXT'
                            )
                          }
                        >
                          <img src={ArrowRight} alt="right-arrow" />
                        </button>
                      )}
                    </div>
                  )}
                </SearchDetailHeader>
                <ItemWrapper>
                  <>
                    {searchArtistData.isLoading ? (
                      <>
                        {result === 'all' ? (
                          <>
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                          </>
                        ) : (
                          <>
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton /> <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton /> <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton /> <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton /> <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                            <ArtistCardSkeleton />
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {searchArtistData.data?.map(
                          (artist, index) =>
                            (result === 'all'
                              ? index < 4
                              : index < searchArtistData.data?.length) && (
                              <>
                                <Item
                                  key={`${artist?.id}`}
                                  to={`/collectors/${artist?.userId}`}
                                  onClick={handleClose}
                                  artist
                                >
                                  <ThumbnailContainer artist>
                                    <img
                                      src={artist?.media?.images?.avatar}
                                      alt="artist"
                                    />
                                  </ThumbnailContainer>
                                  <TextContainer>
                                    <Title>{artist?.displayName}</Title>
                                    <SubTitle>{artist?.bio}</SubTitle>
                                  </TextContainer>
                                </Item>
                              </>
                            )
                        )}
                      </>
                    )}
                  </>
                </ItemWrapper>
                {result === 'artists' && (
                  <LoadingMore>
                    <div className="see-all" id="pagination">
                      {artistOffset !== 0 && (
                        <button
                          type="button"
                          onClick={() =>
                            handleNextPage(
                              searchArtistData,
                              setArtistOffset,
                              artistOffset,
                              'PREV'
                            )
                          }
                        >
                          <img src={ArrowLeft} alt="left-arrow" />
                        </button>
                      )}
                      <span>
                        {`${artistOffset + 1} -
                       ${
                         searchArtistData.data?.length + artistOffset ||
                         artistOffset + 20
                       }`}
                      </span>
                      {(artistOffset === 0 ||
                        (artistOffset > 0 && artistOffset % 10 === 0)) && (
                        <button
                          type="button"
                          onClick={() =>
                            handleNextPage(
                              searchArtistData,
                              setArtistOffset,
                              artistOffset,
                              'NEXT'
                            )
                          }
                        >
                          <img src={ArrowRight} alt="right-arrow" />
                        </button>
                      )}
                    </div>
                  </LoadingMore>
                )}
              </SearchResultDetails>
            </>
          )}
        </SearchDetailsContainer>
      </SearchResultsContainer>
    </Wrapper>
  );
};
