import Christies from '../../assets/images/galleries/Christies.webp';
import SeattleNFT from '../../assets/images/galleries/Seattle_NFT.webp';
import Kollectiff from '../../assets/images/galleries/Kollectiff.webp';
import PAMM from '../../assets/images/galleries/PAMM.webp';

export const iconData = [
  { alt: 'Christies', icon: `${Christies}` },
  { alt: 'SeattleNFT', icon: `${SeattleNFT}` },
  { alt: 'Kollectiff', icon: `${Kollectiff}` },
  { alt: 'PAMM', icon: `${PAMM}` },
];

export const firstData = [
  {
    title: 'Sell digital art',
    sub: 'Quickly and easily gain the tools needed to sell digital art in your gallery.   Training, education and support available to assure your success from day one.',
  },
  {
    title: 'Multi-Display Management',
    sub: 'Manage unlimited control-paired displays from mobile/webapp from anywhere. Blackdove’s innovative and proprietary IoT based technology stack provides the ultimate in flexibility.',
  },
  {
    title: 'Easy Content Uploading',
    sub: 'Blockchain integration with Wallet Connect for easy NFT importing of owned assets or use the manual upload feature for drag and drop functionality from your local hard drive.',
  },
  {
    title: 'Collection Organization',
    sub: 'Simple to use Collection creation allows organization of your artwork catalog to manage your exhibitions, present offers to clients or manage public curations.',
  },
  {
    title: 'Secure Collector Art Storage',
    sub: `Digital Rights Management and end-to-end encryption provide industry first Art Protect services for Collectors of digital art.`,
  },
  {
    title: 'Post Sale Collector Installation',
    sub: 'Blackdove is your ongoing partner, assuring that Collectors have training, installation and post sales support to assure of ongoing relationship management.',
  },
];
