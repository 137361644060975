import React, { useState, useCallback, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useMutation } from 'react-query';
import { useNotifications } from 'reapop';
import Web3 from 'web3';
import { BlackdoveClient } from '@blackdove/utils';

// Components
import { Checkbox } from '../../FormNext';
import { TextInput } from '../../FormNext/components/TextInput/TextInput';
import { TextArea } from '../../FormNext/components/TextArea/TextArea';
import { Select } from '../../FormNext/components/Select/Select';
import { Button } from '../../Button';
import { StepOne, StepTwo, StepThree } from './Steps';
import { VideoMetadata } from '../../VideoMetadata';
import { IngestTimeline } from '../../IngestTimeline';
import { formatMoney } from '../../../util/formatMoney';

// Hooks
import {
  useUpdateArtworkTokens,
  useUpdateArtwork,
} from '../../../hooks/mutations';
import useMarketplace from '../../../hooks/useMarketplace';
import { useUser, useProducts, useTokens } from '../../../hooks/data';

// Assets
import IconClose from '../../../assets/images/icons/icon-close.svg';
import { Copy, Link } from '../../SVGIcon';

// Styled
import {
  RootWrapper,
  HeaderWrapper,
  ModalHeader,
  CloseModalIcon,
  ModalContent,
  ArtworkTile,
  ArtworkInfoWrapper,
  ArtworkTitle,
  ArtworkImage,
  ArtworkInfo,
  ArtistInfo,
  ArtistAvatar,
  ArtistName,
  InfoWrapper,
  Types,
  SectionHeading,
  SectionSubHeader,
  TabWrapper,
  Divider,
  NavLink,
  LowerWrapper,
  DetailsWrapper,
  TokenList,
  IngestPlaceholder,
  IngestTitle,
  IngestDescription,
} from './styled';

function reducer(state, action) {
  switch (action.type) {
    case 'FINALIZE':
      return { ...state, finalizing: true };
    case 'RESET':
      return { ...state, finalizing: false };
    case 'MINT_ATTEMPTED':
      return { ...state, mintAttempted: true };
    case 'MINT_FAILED':
      return { ...state, mintFailed: true };
    case 'CURRENT_STEP':
      return { ...state, currentStep: action.payload };
    case 'UPDATE_ARTWORK':
      return { ...state, artworkData: action.payload };
    case 'ARTWORK_CREATED':
      return { ...state, createdArtwork: action.payload };
    case 'FILE_UPDATE':
      return { ...state, newFile: action.payload };
    default:
      throw new Error('Reducer does not handle this action');
  }
}

export function EditArtworkModal({ closeModal, artwork, tabPage, refetch }) {
  const {
    id,
    name,
    description,
    qrUrl,
    artworkType,
    visibility,
    subscription,
  } = artwork;
  const { notify } = useNotifications();
  const updateArtworkTokens = useUpdateArtworkTokens();
  const updateArt = useUpdateArtwork();
  const user = useUser();
  const products = useProducts();
  const artworkTokens = useTokens(id);
  const { status, account, web3, marketplace } = useMarketplace();

  const [open, setOpen] = useState(true);
  const [tab, setTab] = useState(tabPage || 'details');
  const [artworkPrice, setArtworkPrice] = useState();
  const [productPrice, setProductPrice] = useState();
  const [initialFormState] = useState({
    name: name || '',
    visibility: visibility || 'private',
    qrUrl: qrUrl || '',
    // tags: tags[0] ? JSON.parse(tags?.join(', ')) : '',
    description: description || '',
    subscription,
  });

  useEffect(() => {
    if (artworkTokens.isFetched && products.isFetched) {
      const selectedProduct =
        artworkType === 'OPEN'
          ? products.data?.find(
              (p) => p.productId === artwork?.products?.[0]?.productId
            )
          : null;
      const etherPrice = artworkTokens?.data?.available?.[0]?.price
        ? parseFloat(
            Web3.utils.fromWei(
              artworkTokens?.data?.available?.[0]?.price.toString(),
              'ether'
            )
          )
        : 0;
      setArtworkPrice({
        price:
          artwork?.data?.artworkType === 'NFT'
            ? etherPrice
            : selectedProduct?.price / 100.0 || 0,
      });
      setProductPrice(selectedProduct || '');
    }
  }, [artwork, products.data, artworkTokens.data, artworkType]);

  const [state, dispatch] = useReducer(reducer, {
    finalizing: false,
    mintAttempted: false,
    mintFailed: false,
    currentStep: 0,
    createdArtwork: null,
    newFile: { complete: false, finalized: false },
    artworkData: {
      name: '',
      description: '',
      // tags: '',
      orientation: 'landscape',
      visibility: 'unlisted',
      gallery: 'Blackdove',
      purchasable: true,
      collection: null,
      productId: '',
      appleProductId: '',
    },
  });

  const handleCloseModal = () => {
    setOpen(false);
    closeModal();
  };

  const mintTokens = async () => {
    try {
      dispatch({ type: 'MINT_ATTEMPTED' });
      const price = web3.utils.toWei(
        state.createdArtwork.price.toString(),
        'ether'
      );
      const metadata = [];
      for (let i = 0; i < state.createdArtwork.quantity; i += 1) {
        metadata.push(`https://api.blackdove.io/metadata/${artwork.data.slug}`);
      }

      const transaction = await marketplace.methods.mintAndListTokens(
        metadata,
        price
      );
      const estimatedGas = await transaction.estimateGas({ from: account });
      const gas = web3.utils.toHex(Math.ceil(estimatedGas * 1.2));
      await transaction.send({ from: account, gas });

      notify({
        status: 'success',
        title: 'Mint Successful',
        message:
          'Your tokens have been minted, we are confirming and setting up your NFT now.',
      });
    } catch (e) {
      dispatch({ type: 'MINT_FAILED' });
      dispatch({ type: 'RESET' });
      notify({
        status: 'error',
        title: 'Failed to Mint',
        message: `There was a problem minting your NFTs. Error: ${e.code}`,
      });
    }
  };

  const updateArtworkMutation = useMutation(
    async (data) => {
      const result = await BlackdoveClient.patch(
        `/artists/${data.artistId}/artworks/${data.artworkId}`,
        data.params
      );
      return result.data;
    },
    {
      onSuccess: async (data) => {
        notify({
          status: 'success',
          title: 'Artwork Updated',
          message: 'Artwork has been successfully updated.',
        });
        if (
          state.createdArtwork?.artworkType === 'NFT' &&
          state.createdArtwork?.quantity > 0
        ) {
          mintTokens(data.data);
        }
      },
      onError: () => {
        dispatch({ type: 'RESET' });
        notify({
          status: 'error',
          title: 'Failed to Create',
          message: 'Artwork could not be updated.',
        });
      },
    }
  );

  // Updates 'PENDING' artwork type created on upload when finalized.
  const saveArtwork = async () => {
    if (state?.createdArtwork?.artworkType === 'OPEN') {
      const selectedProduct = products.data.find(
        (p) => p.productId === state.createdArtwork.productId
      );
      const lastDigit = selectedProduct.price % 10;
      const params = {
        artworkType: state.createdArtwork.artworkType,
        price:
          lastDigit === 0
            ? `${(selectedProduct.price / 100).toLocaleString('en-US')}.00`
            : (selectedProduct.price / 100).toLocaleString('en-US'),
        appleProductId: selectedProduct.appleProductId,
        productId: selectedProduct?.productId,
        artistId: user?.data?.user?.artist?.id,
        subscription: state.createdArtwork.subscription,
      };
      await updateArt.mutateAsync({
        id,
        params: { artistId: user?.data?.user?.artist?.id },
      });
      await updateArtworkMutation.mutateAsync({
        artistId: user?.data?.user?.artist?.id,
        artworkId: id,
        params,
      });
    }
    if (state?.createdArtwork?.artworkType === 'NFT') {
      const tokenParams = {
        quantity: state.createdArtwork.quantity,
        price: parseFloat(
          Web3.utils.toWei(state.createdArtwork?.price.toString(), 'ether')
        ),
      };
      const params = {
        artworkType: state.createdArtwork.artworkType,
      };
      await updateArt.mutateAsync({ id, params });
      await updateArtworkTokens.mutateAsync({
        artistId: user?.data?.user?.artist?.id,
        artworkId: id,
        tokenParams,
      });
      await updateArtworkMutation.mutateAsync({
        artistId: user?.data?.user?.artist?.id,
        artworkId: id,
        params,
      });
    }
    refetch();
    handleCloseModal();
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const params = {
      name: values.name,
      description: values.description,
      qrUrl: values.qrUrl,
      // tags:
      //   values.tags?.trim() !== ''
      //     ? JSON.stringify(values.tags.split(',').map((tag) => tag.trim()))
      //     : {},
      visibility: values.visibility,
    };

    if (id) {
      await updateArt.mutateAsync({ id, params });
    }
    refetch();
    setSubmitting(false);
  };

  const handlePriceSubmit = async (values, { setSubmitting }) => {
    if (artworkType === 'OPEN') {
      const product = products.data?.find(
        (p) => p.productId === values.productId
      );
      const lastDigit = product.price % 10;
      const params = {
        ...initialFormState,
        ...product,
        subscription: values.subscription,
        price:
          lastDigit === 0
            ? `${(product.price / 100).toLocaleString('en-US')}.00`
            : (product.price / 100).toLocaleString('en-US'),
      };
      await updateArtworkMutation.mutateAsync({
        artistId: user?.data?.user?.artist?.id,
        artworkId: id,
        params,
      });
    }
    if (artworkType === 'NFT') {
      const params = {
        price: values.price,
      };
      await updateArtworkTokens.mutateAsync({
        artistId: user?.data?.user?.artist?.id,
        artworkId: id,
        params,
      });
    }
    refetch();
    setSubmitting(false);
  };

  const handlePaste = async (setFieldValue) => {
    const text = await navigator.clipboard.readText();
    setFieldValue('qrUrl', text);
  };

  const handleStepOne = useCallback((type) => {
    if (type === 'NFT') {
      dispatch({
        type: 'ARTWORK_CREATED',
        payload: { ...state.createdArtwork, artworkType: type },
      });
    }

    if (type === 'OPEN') {
      dispatch({
        type: 'ARTWORK_CREATED',
        payload: {
          ...state.createdArtwork,
          artworkType: type,
          price: null,
          quantity: null,
          subscription,
        },
      });
    }

    dispatch({ type: 'CURRENT_STEP', payload: 1 });
  });

  const handleStepTwo = useCallback((formData, { setSubmitting }) => {
    setSubmitting(false);
    dispatch({
      type: 'ARTWORK_CREATED',
      payload: { ...state.createdArtwork, ...formData },
    });
    dispatch({ type: 'CURRENT_STEP', payload: 2 });
  });

  const handleStepThree = useCallback(async () => {
    dispatch({ type: 'FINALIZE' });
    saveArtwork();
  });

  const handleReset = useCallback(() => {
    dispatch({ type: 'CURRENT_STEP', payload: 0 });
  });

  const handleMint = useCallback(() => {
    dispatch({ type: 'FINALIZE' });
    mintTokens();
  });

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `https://app.blackdove.com/artwork/${artwork.slug || id}`
    );
    notify({
      status: 'success',
      title: 'Copied to Clipboard',
      message: 'The share link for this artwork was copied to your clipboard.',
    });
  };

  return (
    <RootWrapper open={open}>
      <HeaderWrapper>
        <ModalHeader>
          <CloseModalIcon src={IconClose} onClick={handleCloseModal} />
          <ArtworkTitle>Edit</ArtworkTitle>
        </ModalHeader>
        <ArtworkTile>
          <ArtworkImage src={artwork?.media?.image?.low?.square} />
          <ArtworkInfo>
            <ArtworkTitle>{artwork?.name}</ArtworkTitle>
            <ArtistInfo>
              <ArtistAvatar
                src={artwork?.artist?.media?.images?.avatar}
                alt="artist avatar"
              />{' '}
              <ArtistName>{artwork?.artist?.displayName}</ArtistName>
            </ArtistInfo>
          </ArtworkInfo>
        </ArtworkTile>
        <Formik
          initialValues={{
            share: `www.blackdove.com/artwork/${artwork.slug || id}`,
          }}
        >
          {() => (
            <Form>
              <Field
                name="share"
                label="Share Url"
                placeholder="Share Url"
                component={TextInput}
                autoComplete="off"
                maxLength={false}
                Icon={Link}
                iconColor="#777777"
                iconText="paste"
                iconWidth="24"
                iconHeight="24"
                iconViewBox="0 0 24 24"
                disabled
                iconClick={handleCopy}
              />
            </Form>
          )}
        </Formik>
        <TabWrapper>
          <NavLink
            onClick={() => setTab('details')}
            isActive={tab === 'details'}
          >
            DETAILS
          </NavLink>
          {user.data?.user?.artist?.id && (
            <>
              <Divider />
              <NavLink
                onClick={() => setTab('pricing')}
                isActive={tab === 'pricing'}
              >
                PRICING
              </NavLink>
            </>
          )}
          <Divider />
          <NavLink onClick={() => setTab('info')} isActive={tab === 'info'}>
            INFO
          </NavLink>
        </TabWrapper>
      </HeaderWrapper>

      <LowerWrapper>
        {tab === 'pricing' && (
          <>
            {(artworkType === 'PENDING' || artworkType === 'USER_UPLOAD') &&
            user.data?.user?.artist?.id ? (
              <>
                {state.currentStep === 0 && <StepOne onNext={handleStepOne} />}
                {state.currentStep === 1 && (
                  <StepTwo
                    artworkData={state.createdArtwork}
                    products={products.data}
                    onNext={handleStepTwo}
                  />
                )}
                {state.currentStep === 2 && (
                  <StepThree
                    artworkData={state.createdArtwork}
                    products={products.data}
                    status={status}
                    finalizing={state.finalizing}
                    mintAttempted={state.mintAttempted}
                    mintFailed={state.mintFailed}
                    onNext={handleStepThree}
                    onReset={handleReset}
                    onMint={handleMint}
                  />
                )}
              </>
            ) : (
              <></>
            )}
            {artworkType === 'OPEN' && (
              <>
                <InfoWrapper>
                  <div>
                    <SectionHeading>Artwork Type</SectionHeading>
                    <SectionSubHeader>OPEN</SectionSubHeader>
                  </div>
                </InfoWrapper>
                <Types jcl="flex-end">
                  <div className="dud" />
                  <Formik
                    initialValues={{
                      productId: productPrice?.productId,
                      subscription,
                    }}
                    onSubmit={handlePriceSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <Field
                          name="productId"
                          label="Artwork Price"
                          maxLength={false}
                          component={Select}
                        >
                          {products.data?.map((p) => (
                            <option key={p.productId} value={p.productId}>
                              {formatMoney(p?.price)}
                            </option>
                          ))}
                        </Field>
                        <Field
                          name="subscription"
                          label="Include in subscription"
                          component={Checkbox}
                        />
                        <Button
                          type="submit"
                          color="red"
                          disabled={isSubmitting}
                        >
                          Update Price
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </Types>
              </>
            )}

            {artworkType === 'NFT' && (
              <>
                <InfoWrapper>
                  <div>
                    <SectionHeading>Artwork Type</SectionHeading>
                    <SectionSubHeader>NFT</SectionSubHeader>
                  </div>
                </InfoWrapper>
                <Types jcl="flex-end">
                  <div className="dud" />
                  <Formik
                    initialValues={artworkPrice}
                    onSubmit={handlePriceSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <Field
                          name="price"
                          label="Price (ETH)"
                          placeholder="0"
                          min="0.01"
                          step="0.01"
                          type="number"
                          maxLength={false}
                          component={TextInput}
                        />
                        <Button
                          type="submit"
                          color="red"
                          disabled={isSubmitting}
                        >
                          Update Price
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </Types>
                <TokenList>
                  <h2>Token Overview</h2>
                  <span>
                    {
                      [
                        ...artworkTokens.data?.available,
                        ...artworkTokens.data?.purchased,
                      ]?.length
                    }{' '}
                    Tokens Minted ({artworkTokens.data?.purchased?.length}{' '}
                    Purchased, {artworkTokens.data?.available?.length}{' '}
                    Available)
                  </span>
                  {artworkTokens?.data?.available?.length === 0 && (
                    <>
                      <br />
                      <div>
                        Your tokens are currently being minted on the Ethereum
                        Blockchain.
                      </div>
                      <div>
                        Tokens will appear here when minting is complete.
                      </div>
                    </>
                  )}
                </TokenList>
              </>
            )}
          </>
        )}

        {tab === 'details' && (
          <>
            <Formik initialValues={initialFormState} onSubmit={handleSubmit}>
              {({ isSubmitting, setFieldValue }) => (
                <Form>
                  <ModalContent>
                    <ArtworkInfoWrapper>
                      <Field
                        name="name"
                        label="Name"
                        placeholder="Nature, Abstract, etc."
                        maxLength={50}
                        component={TextInput}
                      />
                      <Field
                        name="visibility"
                        label="Visibility"
                        component={Select}
                        maxLength={false}
                      >
                        <option value="public">Public</option>
                        <option value="private">Private</option>
                        <option value="unlisted">Unlisted</option>
                      </Field>
                      <Field
                        name="qrUrl"
                        label="QR Code Link (optional)"
                        placeholder="QR Code Link"
                        component={TextInput}
                        autoComplete="off"
                        maxLength={false}
                        Icon={Copy}
                        iconColor="#777777"
                        iconText="paste"
                        iconWidth="24"
                        iconHeight="24"
                        iconViewBox="0 0 24 24"
                        iconClick={() => handlePaste(setFieldValue)}
                      />
                      {/* <Field
                        name="tags"
                        label="Tags"
                        placeholder="Tags separated by comma"
                        autoComplete="off"
                        component={TextInput}
                        maxLength={false}
                      /> */}
                      <Field
                        name="description"
                        label="Description"
                        placeholder="Give your artwork a captivating description"
                        maxLength={1000}
                        rows={6}
                        component={TextArea}
                        resize="none"
                      />
                      <Button
                        type="submit"
                        color="red"
                        disabled={isSubmitting}
                        pending={isSubmitting}
                      >
                        Update Artwork
                      </Button>
                    </ArtworkInfoWrapper>
                  </ModalContent>
                </Form>
              )}
            </Formik>
          </>
        )}
        {tab === 'info' && (
          <>
            {artwork?.sourceFile &&
              artwork?.sourceFile?.status !== 'COMPLETED' && (
                <IngestPlaceholder>
                  <IngestTitle>Processing Artwork</IngestTitle>
                  <IngestDescription>
                    Blackdove is ingesting your artwork. Your artwork will be
                    available after this process is complete.
                  </IngestDescription>
                </IngestPlaceholder>
              )}
            {artwork?.sourceFile?.events?.analyzedAt && (
              <>
                <DetailsWrapper>
                  <IngestTimeline artwork={artwork} />
                  <VideoMetadata artwork={artwork} />
                </DetailsWrapper>
              </>
            )}
          </>
        )}
      </LowerWrapper>
    </RootWrapper>
  );
}

EditArtworkModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  artwork: PropTypes.object.isRequired,
  tabPage: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
};
