import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BlackdoveClient } from '@blackdove/utils';
import { useStripe } from '@stripe/react-stripe-js';

// Hooks
import { useDeviceDownload } from '../../../hooks/useDeviceDownload';

// Imports
import { openSigninMenu } from '../../../store/signinMenu/actions';
import { Button } from '../../Button';

// Styled
import {
  Wrapper,
  SignupText,
  ButtonWrapper,
  Divider,
  BreakWrapper,
} from './styled';

export function SubscribeCard({ user, product, artworkId }) {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const navigate = useNavigate();

  const handleAddSubscription = () => {
    navigate('/pricing');
  };

  const handlePurchase = async () => {
    const result = await BlackdoveClient.post(
      '/v2/billing/checkout/session',
      {
        success_url: `https://app.blackdove.com/order/confirmation/artwork/${artworkId}?session_id={CHECKOUT_SESSION_ID}`,
        cancel_url: `https://app.blackdove.com/artwork/${artworkId}`,
        mode: 'payment',
        line_items: [
          {
            price: product?.productId,
            quantity: 1,
          },
        ],
        payment_intent_data: {
          metadata: {
            licensedArtworkId: artworkId,
          },
        },
      },
      !(user.isFetched && user.data.user.id)
    );

    await stripe.redirectToCheckout({ sessionId: result?.data?.id });
  };

  const handleSignUp = () => {
    dispatch(openSigninMenu());
  };

  return (
    <Wrapper>
      <SignupText>
        {user?.data ? (
          <>
            <div>
              This artwork is available to license and you can begin enjoying
              this digital art on your home television or Digital Canvas
              immediately.
            </div>
            <br />
            <Button type="button" color="red" onClick={handlePurchase}>
              Purchase - ${`${product?.price}`}
            </Button>
            <BreakWrapper>
              <Divider /> <div>OR</div> <Divider />
            </BreakWrapper>
            <div>
              This artwork is included in the Blackdove subscription along with
              3000 other artworks.{' '}
            </div>
            <br />
            <Button type="button" color="red" onClick={handleAddSubscription}>
              Become a Subscriber
            </Button>
          </>
        ) : (
          <>
            <div>
              This artwork is available to license and you can begin enjoying
              this digital art on your home television or Digital Canvas
              immediately.
            </div>
            <br />
            <Button type="button" color="red" onClick={handlePurchase}>
              Purchase - ${`${product?.price}`}
            </Button>
            <BreakWrapper>
              <Divider /> <div>AND</div> <Divider />
            </BreakWrapper>
            <div>
              This artwork is included in the Blackdove subscription along with
              3000 other artworks.
            </div>
            <br />
            <div>Join Blackdove today and become a subscriber!</div>
            <br />
            <ButtonWrapper>
              <Button type="button" color="red" onClick={handleSignUp}>
                Login/Signup
              </Button>
            </ButtonWrapper>
            <br />
            <div>
              <button
                type="button"
                className="text"
                onClick={() => useDeviceDownload()}
              >
                Download the app here
              </button>{' '}
              and display digital art on your Smart TV or Digital Canvas today.
            </div>
          </>
        )}
      </SignupText>
    </Wrapper>
  );
}

SubscribeCard.propTypes = {
  user: PropTypes.object.isRequired,
  product: PropTypes.object,
  artworkId: PropTypes.string,
};

SubscribeCard.defaultProps = {
  artworkId: '',
  product: {},
};
