import React from 'react';
import PropTypes from 'prop-types';

// Components
import { CollectorCard } from '../../Collectors/Card';

// Assets
import Back from '../../../assets/images/icons/Back.png';

// Styled
import {
  UploadWrapper,
  ModalHeader,
  Title,
  CloseModalIcon,
  ModalContent,
  ButtonWrapper,
  TabItem,
  TitleWrapper,
  Divider,
} from './styled';

const FollowersModal = ({ closeModal, followData, tabPage, name }) => {
  const [open, setOpen] = React.useState(true);
  const [tab, setTab] = React.useState(tabPage);
  const { followers, followed } = followData;

  const handleCloseModal = () => {
    setOpen(false);
    closeModal();
  };

  return (
    <UploadWrapper open={open}>
      <ModalHeader>
        <TitleWrapper>
          <CloseModalIcon src={Back} onClick={handleCloseModal} />
          <Title>{name}</Title>
        </TitleWrapper>
        <ButtonWrapper>
          <TabItem
            className="following"
            onClick={() => setTab('followers')}
            isActive={tab === 'followers'}
          >
            Followers
          </TabItem>
          <Divider />
          <TabItem
            className="following"
            onClick={() => setTab('following')}
            isActive={tab === 'following'}
          >
            Following
          </TabItem>
        </ButtonWrapper>
      </ModalHeader>
      <ModalContent>
        {tab === 'followers' &&
          followers?.map((follower) => <CollectorCard collector={follower} />)}

        {tab === 'following' &&
          followed?.map((follower) => <CollectorCard collector={follower} />)}
      </ModalContent>
    </UploadWrapper>
  );
};

FollowersModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  followData: PropTypes.object.isRequired,
  tabPage: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default FollowersModal;
