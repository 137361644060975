import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '@blackdove/ui';
import { useNotifications } from 'reapop';
import { format, subDays } from 'date-fns';
import { DateRangeInput } from '@datepicker-react/styled';

// Components
import { Loader } from '../../components/Loader';
import { LineChart } from '../../components/Chart';
import { Button } from '../../components/Button';

// Hooks
import { useGraphs, useStats, useTotals, useUser } from '../../hooks/data';

import {
  Wrapper,
  PageTitle,
  PanelTitle,
  ValueList,
  ValueItem,
  ValueAmount,
  ValueDescription,
  DatePickerGroupWrapper,
  DatePickerWrapper,
  CardWrapper,
} from './styled';

export function Analytics() {
  const navigate = useNavigate();
  const { notify } = useNotifications();
  const [dateRange, setDateRange] = useState({
    start: subDays(new Date(), 7),
    end: new Date(),
  });
  const user = useUser();
  const stats = useStats();
  const totals = useTotals();
  const graphs = useGraphs({
    start: format(dateRange.start, 'yyyy-MM-dd'),
    end: format(dateRange.end, 'yyyy-MM-dd'),
  });

  useEffect(() => {
    if (stats.isError) {
      notify({
        status: 'error',
        title: 'Stats',
        message: 'Stats could not be loaded.',
      });
      return;
    }
    if (totals.isError) {
      notify({
        status: 'error',
        title: 'Totals',
        message: 'Totals could not be loaded.',
      });
      return;
    }
    if (graphs.isError) {
      notify({
        status: 'error',
        title: 'Graphs',
        message: 'Graphs could not be loaded.',
      });
    }
  }, [stats.isError, totals.isError, graphs.isError]);

  const handleViewArtwork = (artworkId) => {
    navigate(`/artwork/${artworkId}`);
  };

  const getLineChartValues = () => {
    const lineChartValues = {
      data: {
        labels: [],
        datasets: [{ data: [] }],
      },
    };

    graphs.data?.forEach((item) => {
      lineChartValues.data.labels.push(format(new Date(item.date), 'yyyy-MM'));
      lineChartValues.data.datasets[0].data.push(item.total);
    });

    return lineChartValues;
  };

  const statRows = stats?.data?.map(({ name, visibility, views, id }) => [
    { content: name },
    { content: visibility },
    { content: views },
    {
      content: (
        <Button
          small
          mb={0}
          pt={1}
          pb={1}
          onClick={() => handleViewArtwork(id)}
        >
          View
        </Button>
      ),
    },
  ]);

  return (
    <Wrapper>
      <PageTitle>
        {user?.isLoading
          ? 'Welcome back, friend!'
          : `Welcome back, ${user.data?.user.givenName}!`}
      </PageTitle>
      {(totals.isLoading || graphs.isLoading || stats.isLoading) && <Loader />}
      {!totals.isLoading && !graphs.isLoading && !stats.isLoading && (
        <>
          <CardWrapper>
            <PanelTitle>Totals All Time</PanelTitle>
            <ValueList>
              <ValueItem>
                <ValueDescription>Total Earnings</ValueDescription>
                <ValueAmount>${totals?.data?.earnings}</ValueAmount>
              </ValueItem>
              <ValueItem>
                <ValueDescription>Total Withdrawls</ValueDescription>
                <ValueAmount>${totals?.data?.withdrawals}</ValueAmount>
              </ValueItem>
              <ValueItem>
                <ValueDescription>Video Plays</ValueDescription>
                <ValueAmount>{totals?.data?.views}</ValueAmount>
              </ValueItem>
              <ValueItem>
                <ValueDescription>Total Followers</ValueDescription>
                <ValueAmount>{totals?.data?.follows}</ValueAmount>
              </ValueItem>
            </ValueList>
          </CardWrapper>
          <CardWrapper>
            <PanelTitle>Totals All Time</PanelTitle>
            <DatePickerGroupWrapper>
              <DatePickerWrapper>
                <DateRangeInput
                  startDate={dateRange.start}
                  endDate={dateRange.end}
                  onDatesChange={(data) =>
                    setDateRange({ start: data.startDate, end: data.endDate })
                  }
                  focusedInput={null}
                />
              </DatePickerWrapper>
            </DatePickerGroupWrapper>
            <LineChart values={getLineChartValues()} />
          </CardWrapper>
          <CardWrapper>
            <PanelTitle>Totals All Time</PanelTitle>
            {!statRows && <p>You have no artwork...</p>}
            {statRows && (
              // <></>
              <Table
                headerColumns={[
                  { content: 'Artwork Name' },
                  { content: 'Visibility' },
                  { content: 'Plays' },
                  { content: 'Actions' },
                ]}
                bodyRows={statRows}
              />
            )}
          </CardWrapper>
        </>
      )}
    </Wrapper>
  );
}
