import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { BlackdoveClient } from '@blackdove/utils';
import { useNotifications } from 'reapop';

// Hooks
// import { useStripeConnectAccount } from '../../../../hooks/data';
import { useUpdateArtist } from '../../../../hooks/mutations';

// Components
import { ConnectForm } from '../../../../components/ConnectForm';
import { StripeTosForm } from '../../../../components/StripeTosForm';
import { Button } from '../../../../components/Button';
import { TextInput } from '../../../../components/FormNext';

// Styled
import {
  FormWrapper,
  HeaderSection,
  FlexContainer,
  FormSection,
  Title,
  Description,
} from '../../styled';

const validationSchema = yup.object().shape({});

export function Payments({ user }) {
  const { notify } = useNotifications();
  const { artist } = user;
  const updateArtistMutation = useUpdateArtist();

  const [initialValues] = React.useState({
    paypalEmail: artist.paypalEmail,
  });

  const stripeOnboardingMutation = useMutation(
    async () => {
      const result = await BlackdoveClient.get(
        '/v2/users/stripe/connect/onboarding?isUserUpdate=false'
      );
      return result.data;
    },
    {
      onSuccess: (data) => {
        window.open(data.url, '_blank');
      },
      onError: () => {
        notify({
          status: 'error',
          title: 'Connection Failed',
          message: 'Failed to complete setup.',
        });
      },
    }
  );

  const handleSubmit = async (values, { setSubmitting }) => {
    const params = {
      paypalEmail: values.paypalEmail,
    };

    await updateArtistMutation.mutateAsync({ artistId: artist.id, params });
    setSubmitting(false);
  };

  const handleOnboarding = async () => {
    await stripeOnboardingMutation.mutateAsync({
      params: { isUserUpdate: false },
    });
  };

  return (
    <>
      <FormWrapper>
        <FlexContainer>
          <HeaderSection>
            <Title>Stripe Connect</Title>
            <Description>
              Fast automated payouts each month directly to your bank account.
            </Description>
            <Description>
              If your country of residence is not available then Stripe Connect
              is not supported.
            </Description>
          </HeaderSection>
          <FormSection>
            {user.stripeConnectStatus !== 'NOT_CREATED' &&
              !user.stripeTosAccepted && (
                <>
                  <h3>TOS Acceptance Required</h3>
                  <p>You must accept the Stripe Connect TOS.</p>
                  <StripeTosForm />
                </>
              )}
            {user.stripeConnectStatus === 'NOT_CREATED' && <ConnectForm />}
            {user.stripeTosAccepted && (
              <>
                {user.stripeConnectStatus === 'ACTION_REQUIRED' && (
                  <>
                    <h3>Action Required</h3>
                    <p>
                      There are one or more problems with your account that are
                      preventing us from processing your payouts.
                    </p>
                    <Button onClick={handleOnboarding}>Fix Issues</Button>
                  </>
                )}
                {user.stripeConnectStatus === 'ONBOARDING_PENDING' && (
                  <>
                    <h3>Setup Incomplete</h3>
                    <p>
                      You have not finished setting up your account. We can not
                      process payouts until you complete the setup process.
                    </p>
                    <Button onClick={handleOnboarding}>Complete Setup</Button>
                  </>
                )}
                {user.stripeConnectStatus === 'ONBOARDING_COMPLETE' && (
                  <>
                    <h3>Account Ready</h3>
                    <p>Your account is fully set up to recieve payouts.</p>
                  </>
                )}
              </>
            )}
          </FormSection>
        </FlexContainer>
      </FormWrapper>

      <FormWrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FlexContainer>
                <HeaderSection>
                  <Title>Paypal</Title>
                  <Description>
                    We use this to make royalty payments.
                  </Description>
                </HeaderSection>
                <FormSection>
                  <Field
                    name="paypalEmail"
                    label="Enter Paypal Email"
                    placeholder="e.g. yourpaypal@email.com"
                    component={TextInput}
                  />
                </FormSection>
              </FlexContainer>
              <Button
                type="submit"
                color="red"
                disabled={isSubmitting}
                pending={isSubmitting}
              >
                Save Changes
              </Button>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </>
  );
}

Payments.propTypes = {
  user: PropTypes.object.isRequired,
};
