import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';

export const Wrapper = styled.div`
  height: 90vh;
  justify-content: flex-end;

  .header {
    height: 100%;
  }
`;

export const ArtworkContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  filter: drop-shadow(0px 8px 7px rgba(0, 0, 0, 0.25))
    drop-shadow(0px 3.34221px 2.92443px rgba(0, 0, 0, 0.179714))
    drop-shadow(0px 1.7869px 1.56354px rgba(0, 0, 0, 0.149027))
    drop-shadow(0px 1.00172px 0.876509px rgba(0, 0, 0, 0.125))
    drop-shadow(0px 0.532008px 0.465507px rgba(0, 0, 0, 0.100973))
    drop-shadow(0px 0.221381px 0.193708px rgba(0, 0, 0, 0.0702864));
`;

export const AspectRatioBox = styled.div`
  button {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    background: rgba(26, 26, 26, 0.75);
    border-radius: 18px;
    padding: 8px;
    width: 32px;
    height: 32px;
    border: ${({ muted }) => (muted ? '2px solid #910048' : '0')};
    cursor: pointer;
  }
  img {
    pointer-events: none;
  }
`;

export const Frame = styled.div`
  height: 65vh;
  position: relative;
  margin: auto;
  max-height: 65vh;
  ${({ rotate }) =>
    rotate &&
    `padding-top: 85px;
  padding-right: 60px;`}

  img,
  video,
  div {
    display: flex;
    max-height: '100%';
    max-width: 100%;
    object-fit: contain;
    width: 100%;
    margin: auto;
  }

  div,
  video,
  img {
    max-height: ${({ rotate }) => (rotate ? '92%' : '100%')};
    ${({ rotate }) =>
      rotate &&
      `transform: rotate(150deg) translateY(-100%);
  transform-origin: center top;`}
  }

  button {
    ${({ rotate }) =>
      rotate &&
      `transform: rotate(208deg) translateY(-100%);
  transform-origin: center top;`}
  }

  @media (min-width: 768px) {
    ${({ menu }) => menu}
  }
`;

export const Icon = styled.img`
  margin: 0 12px 0 0;
  height: 12px;
  width: 12px;
`;

export const LoadingMore = styled.div`
  margin-bottom: 12px;
`;

export const SubNavMenu = styled.nav`
  z-index: 9999;
  position: absolute;
  right: 20%;
  pointer-events: ${({ visible }) => (visible ? 'default' : 'none')};

  opacity: ${({ visible }) => (visible ? 1 : 0)};

  display: ${({ visible }) => !visible && 'none'};
  ul {
    -webkit-app-region: no-drag;
    background-color: #444444;
    border-radius: 4px;
    -webkit-box-shadow: 0 16px 24px rgb(0 0 0 / 30%), 0 6px 8px rgb(0 0 0 / 20%);
    box-shadow: 0 16px 24px rgb(0 0 0 / 30%), 0 6px 8px rgb(0 0 0 / 20%);
    max-height: calc(100vh - 24px);
    max-width: 180px;
    min-width: 160px;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    list-style-type: none;
    cursor: pointer;
  }
  div {
    border: 0;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
  }
  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin: 0;
    vertical-align: baseline;
    width: 180px;
    min-width: 100%;
    height: auto;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    letter-spacing: 0.025em;
    color: #ffffff;
    padding: 12px;
    &:hover {
      background: #3d3d3d;
    }
  }
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  margin: 12px 0 0 0;
  position: relative;
  width: 100%;
  justify-content: center;

  button {
    padding: 8px 24px;
    margin: 12px;
    border: 0;
    color: white;
    cursor: pointer;
    width: 92px;
    height: 32px;
    position: absolute;
    text-transform: uppercase;
    background: ${({ theme }) => theme.colors.base3};
    border-radius: 18px;
  }

  .share {
    top: -4px;
    margin-left: 120px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1em;
  }
  .more {
    top: -4px;
    margin-right: 120px;
    width: 64px;
  }

  @media (min-width: 768px) {
    justify-content: flex-end;

    .share {
      top: 8px;
    }
    .more {
      top: 8px;
    }
  }
`;

const activeNavStyles = ({ theme }) => css`
  background: ${theme.colors.base3};
  border-radius: 12px;
`;

export const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
`;

export const NavLink = styled(Link, {
  shouldForwardProp: (prop) =>
    isPropValid(prop) && !['isActive'].includes(prop),
})`
  flex-basis: calc(33.33% - 24px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 12px;
  border-radius: 12px;
  position: relative;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  ${({ isActive }) => (isActive ? activeNavStyles : null)}

  @media (min-width: 375px) {
    font-size: 12px;
    line-height: 16px;
  }
  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1em;
  }
`;

export const Dropdown = styled.div`
  position: absolute;
  display: inline-block;
  top: 56px;

  .cards {
    display: ${({ open, isActive }) => (open || isActive ? 'block' : 'none')};
    position: relative;
    background-color: #444444;
    min-width: 100px;
    border-radius: 12px;
    z-index: 1;

    button {
      position: relative;
    }
    button:last-child {
      border-radius: 0 0 12px 12px;
    }
    button:first-child {
      border-radius: 12px 12px 0 0;
    }
    button:hover {
      border-radius: 12px;
    }
  }
  .button {
    color: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.025em;
    display: block;
    width: 191px;
    border-left: 0;
    border-top: 0;
    background: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    text-transform: none;
    text-align: start;
    margin: 0;
    padding: 12px;
    height: 44px;

    &:hover {
      background: #444444;
      border-radius: 12px;
    }
  }

  @media (max-width: 767px) {
  }
`;
