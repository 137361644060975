import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMetaMask } from 'metamask-react';
import { detect } from 'detect-browser';
import Web3 from 'web3';
import { Wrapper } from './styled';
import { Button } from '../../../../Button';

export function MetaMask({ wallets, pairWallet, closeModal }) {
  const [web3, setWeb3] = useState(null);
  const { status, connect, account } = useMetaMask();

  // On load we detect if metamask is installed
  useEffect(() => {
    const installed = !!window?.ethereum?.isMetaMask;
    if (installed) {
      setWeb3(new Web3(window.ethereum));
      connect();
    } else {
      const browser = detect();
      switch (browser?.name) {
        case 'chrome':
          window.open(
            'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en',
            '_blank'
          );
          break;
        case 'firefox':
          window.open(
            'https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/',
            '_blank'
          );
          break;
        case 'safari':
          window.open(
            'https://apps.apple.com/us/app/metamask-blockchain-wallet/id1438144202',
            '_blank'
          );
          break;
        case 'opera':
          window.open(
            'https://addons.opera.com/en/extensions/details/metamask/',
            '_blank'
          );
          break;
        default:
          break;
      }
    }
  }, []);

  useEffect(() => {
    const signAndPairWallet = async () => {
      const signedMessage = await web3.eth.personal.sign(
        'I am verifying my wallet with Blackdove.',
        account
      );
      await pairWallet(account, signedMessage);
    };

    if (
      status === 'connected' &&
      wallets?.filter((e) => e.walletAddress === account).length === 0
    ) {
      signAndPairWallet();
    }
  }, [status, account]);

  const selectedIsPaired =
    wallets?.filter((e) => e.walletAddress === account).length > 0;

  return (
    <Wrapper>
      {(status === 'notConnected' || status === 'connecting') && (
        <>
          <h2>Connecting!</h2>
          <p>Please authorize the connection via MetaMask...</p>
        </>
      )}
      {status === 'connected' && !selectedIsPaired && (
        <>
          <h2>Sign the message in your wallet to continue</h2>
          <p>
            Blackdove uses this signature to verify that you are the owner of
            this wallet.
          </p>
        </>
      )}
      {status === 'connected' && selectedIsPaired && (
        <>
          <h2>Your active MetaMask Wallet is already paired.</h2>
          <p>
            You may pair additional wallets via MetaMask using one of the
            following workflows.
          </p>
          <p>
            If you selected multiple wallets for connection, change the active
            wallet from within MetaMask.
          </p>
          <p>
            If you did not select multiple wallets for connection. Please
            disconnect your wallet via MetaMask and re-connect with your desired
            wallets.
          </p>
          <Button onClick={closeModal}>Close</Button>
        </>
      )}
    </Wrapper>
  );
}

MetaMask.propTypes = {
  wallets: PropTypes.array.isRequired,
  pairWallet: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};
