import React, { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { VideoPlayer } from '@blackdove/player';
import { BlackdoveClient } from '@blackdove/utils';

// Components
import { Loader } from '../../../../components/Loader';
import MainButton from '../../../../components/Button/MainButton';

// Hooks
import { useSingleArtwork } from '../../../../hooks/data';

// Styled
import {
  Wrapper,
  ContentWrapper,
  MainPanel,
  ArtDetails,
  PreviewWrapper,
  ArtworkName,
  ArtistWrapper,
  ArtistImage,
  ArtistName,
  CallToAction,
  CallToActionInner,
  Greeting,
  Description,
} from '../styled';

export function ArtworkSuccessPage() {
  const { id } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  const artwork = useSingleArtwork(id);
  const artist = artwork.data?.artist;

  const [data, setData] = React.useState();

  // GET session data
  useEffect(async () => {
    if (search) {
      const result = await BlackdoveClient.get(
        `/v2/billing/checkout/session/${search?.replace('?session_id=', '')}`
      );
      setData(result.data);
    }
  }, []);

  // SET session items
  useEffect(async () => {
    if (data) {
      const listItems = [];
      data.line_items.data.map((item) =>
        listItems.push({
          id: item.price.id,
          name: item.description,
          brand: 'Blackdove',
          category: 'Artwork',
          quantity: item.quantity,
          price: parseFloat(item.amount_total, 10) / 100,
        })
      );
      window.gtag('event', 'purchase', {
        transaction_id: data.id,
        affiliation: 'Blackdove Artwork',
        value: parseFloat(data.amount_total, 10) / 100,
        currency: data.currency,
        items: listItems,
      });
      window.lintrk('track', { conversion_id: 6756044 });
      window.fbq('track', 'Purchase', {
        transaction_id: data.id,
        affiliation: 'Blackdove Artwork',
        value: parseFloat(data.amount_total, 10) / 100,
        currency: data.currency,
        contents: listItems,
      });
    }
  }, [data]);

  return (
    <Wrapper>
      {artwork.isLoading && <Loader />}
      {!artwork.isLoading && (
        <ContentWrapper>
          <MainPanel>
            <ArtDetails>
              <PreviewWrapper>
                <VideoPlayer
                  videoUrl={artwork.data?.media.video.dash}
                  posterUrl={artwork.data?.media.image.low.landscape}
                  artistName=""
                  initialize
                  muted
                  showLogo={false}
                  showLoading={false}
                />
              </PreviewWrapper>
              <ArtworkName>{artwork.data?.name}</ArtworkName>
              <ArtistWrapper>
                <ArtistImage src={artist.media.images.avatar} />
                <ArtistName to={`/collectors/${artist.userId}`}>
                  {artist.displayName}
                </ArtistName>
              </ArtistWrapper>
            </ArtDetails>
            <CallToAction>
              <CallToActionInner>
                <Greeting>Congratulations</Greeting>
                <Description>
                  You have purchased a copy of this artwork. You can access this
                  artwork in your My Purchases collection, and it can be
                  displayed on any Blackdove supported device.
                </Description>
                <MainButton
                  title="View My Artwork"
                  onClick={() => navigate(`/artwork/${id}`)}
                  color="red"
                  borderColor="#171717"
                />
              </CallToActionInner>
            </CallToAction>
          </MainPanel>
        </ContentWrapper>
      )}
    </Wrapper>
  );
}
