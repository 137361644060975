import React from 'react';
import PropTypes from 'prop-types';

// Styled
import {
  SkeletonWrapper,
  SkeletonThumbnail,
  SkeletonText,
  ShimmerWrapper,
  SkeletonCircle,
  SkeletonTitle,
  Shimmer,
  ThumbnailAspectRatioBox,
  SkeletonCardBlock,
  SkeletonCardWrapper,
  FlexWrapper,
} from '../styled';

export const ArtCardSkeleton = ({
  margin,
  marginMid,
  marginL,
  flexBasis,
  flexBasisMid,
  flexBasisLarge,
}) => (
  <SkeletonCardWrapper
    margin={margin}
    marginMid={marginMid}
    marginL={marginL}
    flexBasis={flexBasis}
    flexBasisMid={flexBasisMid}
    flexBasisLarge={flexBasisLarge}
  >
    <ThumbnailAspectRatioBox>
      <SkeletonThumbnail />
      <ShimmerWrapper>
        <Shimmer />
      </ShimmerWrapper>
    </ThumbnailAspectRatioBox>
    <br />
    <SkeletonWrapper>
      <SkeletonTitle>
        <ShimmerWrapper>
          <Shimmer />
        </ShimmerWrapper>
      </SkeletonTitle>
    </SkeletonWrapper>
    <br />
    <SkeletonWrapper>
      <FlexWrapper>
        <SkeletonCircle />
        <SkeletonText>
          <ShimmerWrapper>
            <Shimmer />
          </ShimmerWrapper>
        </SkeletonText>
      </FlexWrapper>
    </SkeletonWrapper>
    <br />
    <SkeletonWrapper>
      <SkeletonCardBlock>
        <ShimmerWrapper>
          <Shimmer />
        </ShimmerWrapper>
      </SkeletonCardBlock>
    </SkeletonWrapper>
  </SkeletonCardWrapper>
);

ArtCardSkeleton.propTypes = {
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginMid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginL: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flexBasis: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flexBasisMid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flexBasisLarge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ArtCardSkeleton.defaultProps = {
  margin: null,
  marginMid: null,
  marginL: null,
  flexBasis: null,
  flexBasisMid: null,
  flexBasisLarge: null,
};
