import React from 'react';
import PropTypes from 'prop-types';

// Components
import { HeaderSplash } from '../HeaderSplash';

// Styled
import { Wrapper, NavLink, TabWrapper, Divider } from './styled';

export function DiscoverHeader({ activeLink }) {
  return (
    <>
      <Wrapper>
        <HeaderSplash height="10vh" />
        <TabWrapper>
          <NavLink to="/artists" isActive={activeLink === 'artists'}>
            Artists
          </NavLink>
          <Divider />
          <NavLink to="/collections" isActive={activeLink === 'collections'}>
            Collections
          </NavLink>
          <Divider />
          <NavLink to="/artwork/latest" isActive={activeLink === 'latest'}>
            Latest
          </NavLink>
        </TabWrapper>
      </Wrapper>
    </>
  );
}

DiscoverHeader.propTypes = {
  activeLink: PropTypes.string.isRequired,
};
