import styled from '@emotion/styled';

export const Wrapper = styled.div``;

export const CardWrapper = styled.div`
  margin: 24px;

  .css-1sh8bci:nth-of-type(even) {
    background-color: #333333 !important;
  }
  thead {
    border: 0;
  }
  td {
    color: white;
  }
  tr:nth-child(even) {
    background: #333333;
    background-color: #333333;
    color: #ffffff;
  }
  button {
    background: ${({ theme }) => theme.colors.base2};
  }
`;

export const PageTitle = styled.h1`
  font-weight: bold;
  margin-bottom: 40px;
  margin-left: 24px;
`;

export const Panel = styled.div`
  background: ${({ theme }) => theme.colors.base2};
  box-shadow: 0px 0px 40px 0px ${({ theme }) => theme.colors.shadow};
  margin-bottom: 2em;
  padding: 2em;
`;

export const PanelTitle = styled.h3`
  margin-top: 0;
  font-size: 1.8em;
  font-weight: bold;
`;

export const ValueList = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ValueItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  border-right: 1px solid ${({ theme }) => theme.colors.light};
  margin-right: 2em;
`;

export const ValueAmount = styled.div`
  font-size: 3rem;
  font-weight: bold;
  display: inline-block;
  margin-right: 0.2em;
`;

export const ValueDescription = styled.div`
  font-weight: bold;
  display: inline-block;
`;

export const DatePickerGroupWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

export const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export const DatePickerTitle = styled.div`
  margin-right: 10px;
`;
