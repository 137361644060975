import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';

// Components
import { TextInput } from '../../../FormNext/components/TextInput/TextInput';
import { Button } from '../../../Button';
import {
  Facebook,
  Instagram,
  Website,
  Twitter,
  Tiktok,
  Snapchat,
} from '../../../SVGIcon';

// Styled
import { SubTitle, ButtonContainer } from '../../styled';

const validationSchema = yup.object().shape({
  website: yup.string(),
  instagramUsername: yup.string(),
  twitterUsername: yup.string(),
  facebookUsername: yup.string(),
  tiktokUsername: yup.string(),
  snapchatUsername: yup.string(),
});
const initialValues = {
  website: '',
  instagramUsername: '',
  twitterUsername: '',
  facebookUsername: '',
  tiktokUsername: '',
  snapchatUsername: '',
};

export function Social({ loading, handleSkip, onNext }) {
  return (
    <>
      <SubTitle>Social Links</SubTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onNext}
      >
        <Form style={{ maxWidth: 480, textAlign: 'start' }}>
          <Field
            name="website"
            label="Website"
            Icon={Website}
            iconColor="#777777"
            iconText="website"
            iconWidth="24"
            iconHeight="24"
            component={TextInput}
          />
          <Field
            name="instagramUsername"
            label="Instagram"
            Icon={Instagram}
            iconColor="#777777"
            iconText="instagram"
            iconWidth="24"
            iconHeight="24"
            component={TextInput}
          />
          <Field
            name="twitterUsername"
            label="Twitter"
            Icon={Twitter}
            iconColor="#777777"
            iconText="twitter"
            iconWidth="24"
            iconHeight="24"
            component={TextInput}
          />
          <Field
            name="facebookUsername"
            label="Facebook"
            Icon={Facebook}
            iconColor="#777777"
            iconText="facebook"
            iconWidth="24"
            iconHeight="24"
            component={TextInput}
          />
          <Field
            name="tiktokUsername"
            label="Tiktok"
            Icon={Tiktok}
            iconColor="#777777"
            iconText="tiktok"
            iconWidth="24"
            iconHeight="24"
            component={TextInput}
          />
          <Field
            name="snapchatUsername"
            label="Snapchat"
            Icon={Snapchat}
            iconColor="#777777"
            iconText="snapchat"
            iconWidth="24"
            iconHeight="24"
            component={TextInput}
          />
          <br />
          <ButtonContainer>
            <Button type="button" onClick={handleSkip}>
              Skip
            </Button>
            <Button
              type="submit"
              color={loading ? '' : 'red'}
              disabled={loading}
            >
              {loading ? 'Saving....' : 'Save'}
            </Button>
          </ButtonContainer>
        </Form>
      </Formik>
    </>
  );
}

Social.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSkip: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};
