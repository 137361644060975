import styled from '@emotion/styled';

export const Wrapper = styled.div`
  margin: auto;
`;

export const VideoAspectRatioBoxHeader = styled.div`
  height: 40vh;
  overflow: hidden;
  padding: 0;
  position: relative;
  border-radius: 24px;

  img {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    border: none;
    object-fit: cover;
  }

  @media (min-width: 768px) {
  }
`;

export const ArtistName = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  text-transform: none;
  color: #ffffff;
  margin: 0;

  @media (min-wdith: 768px) {
    font-size: 36px;
    line-height: 42px;
  }

  @media (min-wdith: 1024px) {
    font-size: 48px;
    line-height: 48px;
  }
`;

export const ArtistDescription = styled.div`
  margin: 24px 0 24px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.025em;

  button {
    background: transparent;
    border: 0;
    font-weight: bold;
    font-size: 16px;
    color: #444444;
  }

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;

    button {
      font-size: 16x;
    }
  }
`;

export const ArtistPic = styled.div`
  position: absolute;
  top: -94px;
  z-index: 1;
  margin: 12px 12px 0px 48px;

  img {
    border: 6px solid #222222;
    border-radius: 360px;
    object-fit: cover;

    @media (max-width: 767px) {
      width: 120px;
      height: 120px;
    }
    @media (min-width: 768px) {
      width: 156px;
      height: 156px;
    }
  }

  @media (max-width: 767px) {
    margin: 12px 12px 0px 12px;
    top: -75px;
  }
`;

export const ArtistPicWrapper = styled.div`
  position: relative;
`;

export const SplashWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 180px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 24px 0;
  gap: 24px;

  .share {
    width: 92px;
    height: 32px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.45);
    border-radius: 18px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1em;
  }
  .following {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #a5a5a5;
    border: 0;
    background: transparent;
    width: auto;
    height: 16px;
    box-shadow: none;
    padding: 0;
    cursor: pointer;

    @media (max-width: 767px) {
      display: none;
    }
  }

  @media (max-width: 374px) {
    gap: 16px;
  }
`;

export const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;

  button {
    border: 0;
    cursor: pointer;
  }
  img {
    margin-right: 12px;

    @media (min-width: 768px) {
      margin-right: 24px;
    }
  }
`;

export const Divider = styled.div`
  border: 1px solid #444444;
  transform: rotate(90deg);
  width: 16px;
  height: 0px;
`;

export const TabItem = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 12px;
  border-radius: 12px;
  position: relative;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  height: 40px;
  background: ${({ isActive }) => (isActive ? '#333333' : 'transparent')};

  &:hover {
    background: #333333;
  }
  @media (min-width: 375px) {
    font-size: 12px;
    line-height: 16px;
  }
  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1em;
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  background: #333333;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.45);
  border-radius: 36px;
  gap: 18px;

  button {
    all: unset;
    cursor: pointer;
    display: flex;
  }
  img {
    width: 16px;
    height: 16px;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`;

export const ShareWrapper = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`;

export const Dropdown = styled.div`
  position: relative;
  display: inline-block;

  .dots {
    width: 64px;
    height: 32px;
  }
  .cards {
    display: ${({ open, isActive }) => (open || isActive ? 'block' : 'none')};
    position: absolute;
    background-color: #333333;
    min-width: 100px;
    box-shadow: 6px 6px 48px #111111;
    border-radius: 12px;
    z-index: 1;
    right: 24px;

    button:last-child {
      border-radius: 0 0 12px 12px;
    }

    @media (max-width: 374px) {
      right: -12px;
    }
  }
  .button {
    color: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.025em;
    display: block;
    width: 191px;
    border-bottom: 1px solid #444444;
    border-left: 0;
    border-top: 0;
    background: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    text-transform: none;
    text-align: start;
    margin: 0;
    padding: 12px;
    height: 44px;

    &:hover {
      background: #444444;
    }
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;
