import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';

import { Button } from '../Button';
import YourUploader from './YourUploader';
import { useManyCollectionsInfinite } from '../../hooks/data';
import { useAddPrivateArtwork } from '../../hooks/mutations';

// Assets
import uploadlogo from '../../assets/images/icons/icon-upload.svg';

const BulkUploaderWrapper = styled.div`
  background: ${({ theme, dragOver }) =>
    dragOver ? theme.colors.secondary : theme.colors.base2};
  padding: ${({ selectedFiles }) => !selectedFiles && '24px'};
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.div`
  text-align: center;
  background: ${({ theme, dragOver }) =>
    dragOver ? theme.colors.secondary : theme.colors.base2};
  border: ${({ selectedFiles }) =>
    selectedFiles ? 'none' : '1px dashed #3d3d3d'};

  min-height: 200px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${({ selectedFiles }) =>
    selectedFiles ? 'space-between' : 'center'};
  align-items: center;
  border-radius: 12px;
  overflow-y: hidden;
`;

const Logo = styled.img`
  display: inline-block;
  height: 3em;
  margin-bottom: 1em;
  margin-top: 2.5em;
  pointer-events: none;
`;

const UploadInstructions = styled.span`
  display: block;
  pointer-events: none;
`;

const UploadsWrapper = styled.div`
  width: 100%;
  height: 100%;
  justify-content: ${({ selectedFiles }) => !selectedFiles && 'center'};
  overflow-y: ${({ selectedFiles }) => selectedFiles && 'scroll'};
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  padding: 24px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ButtonWrapper = styled.div`
  height: 96px;
  background: #222222;
  padding: 24px;
  width: 100%;
`;

const InputFile = styled.input`
  display: none;
`;

const BulkUploader = ({
  maxFileSizeMB,
  allowedTypes,
  files,
  closeModal,
  refresh,
}) => {
  const [dragOver, setDragOver] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState();
  const hiddenFileInput = React.useRef(null);
  const [completeFile, setCompleteFile] = React.useState(false);
  const addPrivateArtwork = useAddPrivateArtwork();
  const userCollections = useManyCollectionsInfinite(
    'userCollections',
    '',
    100
  );
  const { id } = useParams();

  const handleClick = useCallback(() => {
    if (!selectedFiles) {
      hiddenFileInput.current.click();
    }
  });

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    setDragOver(false);

    const allFiles = e.target.files || e.dataTransfer.files;
    const filesArray = Object.values(allFiles);
    setSelectedFiles(filesArray);
  });

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
  });

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    setDragOver(true);
  });

  const handleDragLeave = useCallback(() => {
    setDragOver(false);
  });

  const handleBulkUpload = () => {
    setCompleteFile(true);
    setTimeout(() => {
      setCompleteFile(false);
    }, 5000);
  };

  const handleRemoveFile = (file) => {
    if (selectedFiles?.length > 0) {
      selectedFiles?.forEach((f, i) => {
        if (f === file) {
          setSelectedFiles((sf) => (sf.length > 1 ? sf?.splice(i, 1) : []));
        }
      });
    }
  };

  useEffect(() => {
    if (selectedFiles?.length === 0) {
      setSelectedFiles(undefined);
      closeModal();
      refresh();
    }
  }, [selectedFiles]);
  useEffect(() => {
    if (files?.length > 0) {
      setSelectedFiles(files);
    }
  }, [files]);
  const accepted = allowedTypes.join();

  return (
    <BulkUploaderWrapper selectedFiles={selectedFiles}>
      <Wrapper
        onClick={handleClick}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        dragOver={dragOver}
      >
        {selectedFiles?.length > 0 ? (
          <>
            <UploadsWrapper selectedFiles={selectedFiles}>
              {selectedFiles?.map((selectedFile, index) => (
                <YourUploader
                  key={selectedFile.name}
                  allowedTypes={allowedTypes}
                  maxFileSizeMB={20000}
                  file={selectedFile}
                  completeFile={completeFile}
                  addPrivateArtwork={addPrivateArtwork}
                  userCollections={userCollections.data}
                  index={index}
                  currentCollection={id}
                  handleRemoveFile={handleRemoveFile}
                />
              ))}
            </UploadsWrapper>
            <ButtonWrapper>
              <Button
                color={completeFile ? '' : 'red'}
                type="submit"
                onClick={handleBulkUpload}
                disabled={completeFile}
              >
                Complete Upload
              </Button>
            </ButtonWrapper>
          </>
        ) : (
          <UploadsWrapper>
            <Logo src={uploadlogo} alt="Blackdove" />
            <UploadInstructions>
              Drop your artwork here to start uploading.
            </UploadInstructions>
            <UploadInstructions>{`.mp4, .jpg, .png, .bmp, .gif | ${
              maxFileSizeMB / 1000
            } GB Max`}</UploadInstructions>
            <InputFile
              type="file"
              ref={hiddenFileInput}
              onChange={(e) => handleDrop(e)}
              accept={accepted}
              multiple
            />
          </UploadsWrapper>
        )}
      </Wrapper>
    </BulkUploaderWrapper>
  );
};

BulkUploader.propTypes = {
  maxFileSizeMB: PropTypes.number,
  allowedTypes: PropTypes.arrayOf(PropTypes.string),
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
  closeModal: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
};

BulkUploader.defaultProps = {
  maxFileSizeMB: 20000,
  allowedTypes: [
    'video/mp4',
    'image/jpeg',
    'image/gif',
    'image/png',
    'image/bmp',
  ],
};

export default BulkUploader;
