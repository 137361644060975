import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { VideoPlayer } from '@blackdove/player';

// Components
import { Loader } from '../../../components/Loader';
import MainButton from '../../../components/Button/MainButton';

// Hooks
import { useSingleArtwork } from '../../../hooks/data';

// Styled
import {
  Wrapper,
  ContentWrapper,
  MainPanel,
  ArtDetails,
  PreviewWrapper,
  ArtworkName,
  ArtistWrapper,
  ArtistImage,
  ArtistName,
  CallToAction,
  CallToActionInner,
  Greeting,
  Description,
} from './styled';

export function NFTSuccessPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const artwork = useSingleArtwork(id);
  const artist = artwork.data?.artist;

  return (
    <Wrapper>
      {artwork.isLoading && <Loader />}
      {!artwork.isLoading && (
        <ContentWrapper>
          <MainPanel>
            <ArtDetails>
              <PreviewWrapper>
                <VideoPlayer
                  videoUrl={artwork.data?.media.video.dash}
                  posterUrl={artwork.data?.media.image.low.landscape}
                  artistName=""
                  initialize
                  muted
                  showLogo={false}
                  showLoading={false}
                />
              </PreviewWrapper>
              <ArtworkName>{artwork.data?.name}</ArtworkName>
              <ArtistWrapper>
                <ArtistImage src={artist.media.images.avatar} />
                <ArtistName to={`/collectors/${artist.userId}`}>
                  {artist.displayName}
                </ArtistName>
              </ArtistWrapper>
            </ArtDetails>
            <CallToAction>
              <CallToActionInner>
                <Greeting>Congratulations</Greeting>
                <Description>
                  You have purchased an NFT of this artwork. You can access this
                  artwork in your My Purchases collection, and it can be
                  displayed on any Blackdove supported device.
                </Description>
                <MainButton
                  title="View My Purchases"
                  onClick={() => navigate('/collections/purchases')}
                  color="red"
                  borderColor="#171717"
                />
              </CallToActionInner>
            </CallToAction>
          </MainPanel>
        </ContentWrapper>
      )}
    </Wrapper>
  );
}
