import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { Checkmark } from '@emotion-icons/ionicons-sharp';

import {
  InputWrapper,
  VisualWrapper,
  Input,
  Label,
  HelperText,
  ErrorText,
} from './styled';

const Checkbox = ({
  field,
  form: { touched, errors },
  label,
  helperText,
  ...props
}) => {
  const inputRef = useRef(null);
  const hasError =
    getIn(touched, field.name) && Boolean(getIn(errors, field.name));
  const errorText = getIn(errors, field.name);

  return (
    <InputWrapper>
      <VisualWrapper
        isChecked={!!field.value}
        onClick={() => inputRef.current.click()}
      >
        <Input
          ref={inputRef}
          type="checkbox"
          hasError={hasError}
          id={field.name}
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          {...props}
        />
        {field.value && <Checkmark size={18} />}
      </VisualWrapper>
      {label && <Label htmlFor={field.name}>{label}</Label>}
      {helperText && <HelperText>{helperText}</HelperText>}
      {hasError && <ErrorText>{errorText}</ErrorText>}
    </InputWrapper>
  );
};

Checkbox.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
  }).isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  maxLength: PropTypes.number,
};

Checkbox.defaultProps = {
  label: '',
  helperText: '',
  maxLength: 0,
};

export default Checkbox;
