import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useNotifications } from 'reapop';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';

// Imports
import { Button } from '../Button';
import { TextInput } from '../Form';
import { WalletButton } from '../WalletButton';
import { WalletConnect } from '../Modals/AddWalletModal/components/WalletConnect';
import { MetaMask } from '../Modals/AddWalletModal/components/MetaMask';
import { Signup, Profile, Social } from './Steps';

import Alert from '../Alert';

// Hooks
import { useAccount, useAllWallets } from '../../hooks/data';
import useMarketplace from '../../hooks/useMarketplace';
import {
  useAuthenticate,
  useCreateUser,
  useAddWallet,
  useUpdateUser,
} from '../../hooks/mutations';

// Actions
import {
  closeSigninMenu,
  openSigninMenu,
} from '../../store/signinMenu/actions';

// Assets
import Back from '../../assets/images/icons/Back.png';
import IconChecked from '../../assets/images/icons/icon-checked.svg';

// Redux
import { forgotPassword } from '../../store/password/actions';
import {
  FORGOT_USER_SUCCESS,
  FORGOT_USER_FAILURE,
} from '../../store/password/constants';

// Styled
import {
  RootWrapper,
  LoginFormWrapper,
  TitleWrapper,
  Title,
  CloseIcon,
  SignUpWrapper,
  IndicatorContainer,
  Indicator,
  SubTitle,
  Description,
  DescriptionTwo,
  ConnectWrapper,
  ButtonWrapper,
  ButtonContainer,
  Wrapper,
  ForgotWrapper,
  AlertWrapper,
} from './styled';

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(8).required(),
});
const initialValues = {
  email: '',
  password: '',
};
const validationSchemaForgot = yup.object().shape({
  email: yup.string().email().required(),
});
const initialValuesForgot = {
  email: '',
};

export const SigninMenu = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notify } = useNotifications();

  const user = useAccount();
  const wallets = useAllWallets();
  const authenticate = useAuthenticate();
  const createUser = useCreateUser();
  const updateUser = useUpdateUser();
  const addWalletMutation = useAddWallet();
  const { status, account, web3 } = useMarketplace();

  const signinMenu = useSelector((state) => state.signinMenu);
  const [loading, setLoading] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [savingWallet, setSavingWallet] = useState(false);
  const [page, setPage] = useState('login');
  const [currentStep, setCurrentStep] = useState('signup');
  const [isEmailSending, setEmailSending] = useState(false);
  const [isArtist, setIsArtist] = useState(false);

  React.useEffect(() => {
    if (pathname === '/login') {
      setPage('login');
      dispatch(openSigninMenu());
    }
    if (pathname === '/signup') {
      setPage('signup');
      dispatch(openSigninMenu());
    }
  }, [pathname]);

  const handleClose = () => {
    dispatch(closeSigninMenu());
  };
  const handleReset = () => {
    setPage('login');
    setCurrentStep(false);
    setSelectedProvider(false);
    dispatch(closeSigninMenu());
  };
  const handleLoginSubmit = useCallback(async (values) => {
    setLoading(true);
    const result = await authenticate.mutateAsync({
      values,
      setLoading,
      history,
    });
    if (result.accessToken) {
      dispatch(closeSigninMenu());
    }
  });

  const handleSignupSubmit = useCallback(async (values) => {
    setLoading(true);
    delete values.repeatPassword;
    setIsArtist(values.isArtist);
    await createUser.mutateAsync({ values, setLoading, history });
    await authenticate.mutate({ values, setLoading });
    setLoading(false);
    setCurrentStep('profile');
  });

  const handleForgotSubmit = useCallback(async (values) => {
    setLoading(true);
    const forgotResult = await dispatch(forgotPassword(values));

    if (forgotResult.type === FORGOT_USER_SUCCESS) {
      setEmailSending(true);
      setLoading(false);
    }

    if (forgotResult.type === FORGOT_USER_FAILURE) {
      notify({
        status: 'error',
        title: 'Uh oh!',
        message: 'Failed to request password reset.',
      });
      setLoading(false);
    }
  });

  const handleSignup = () => {
    setPage('signup');
  };
  const handleSignin = () => {
    setPage('login');
  };

  const handleProfileSubmit = async (values) => {
    setLoading(true);
    await updateUser.mutateAsync({ params: values });
    setLoading(false);
    setCurrentStep('social');
  };

  const handleSocialSubmit = async (values) => {
    setLoading(true);
    await updateUser.mutateAsync({ params: values });
    setLoading(false);
    setCurrentStep('wallet');
  };

  const handleVerifyWallet = async () => {
    setSavingWallet(true);
    const signedMessage = await web3.eth.personal.sign(
      'I am verifying my wallet with Blackdove.',
      account
    );
    await addWalletMutation.mutateAsync({
      params: { signedMessage, walletAddress: account },
    });
    setSavingWallet(false);
  };

  const handlePairWallet = async (walletAddress, signedMessage) => {
    await addWalletMutation.mutateAsync({
      params: { signedMessage, walletAddress },
    });
    setCurrentStep('finish');
  };

  const isWalletLinked = (walletAddress) => {
    if (wallets.data?.length > 0) {
      const matchingWallet = wallets.data.find(
        (wallet) =>
          wallet.walletAddress.toLowerCase() === walletAddress.toLowerCase()
      );
      if (matchingWallet) return true;
    }

    return false;
  };

  const handleSkip = (section) => {
    setCurrentStep(section || 'finish');
  };

  const handleNFT = () => {
    navigate('/nft/import');
    handleClose();
  };

  const handleExit = () => {
    navigate('/artwork/latest');
    setPage('login');
    setCurrentStep('signup');
    handleClose();
  };

  const handleExitArtist = () => {
    navigate('/settings/payments');
    setPage('login');
    setCurrentStep('signup');
    handleClose();
  };

  const getLinkStep = () => {
    if (!user.isFetched) return 'LOGGED_OUT';
    if (status !== 'connected') return 'NOT_CONNECTED';
    if (!wallets.isFetched || wallets.data?.length === 0) return 'NOT_VERIFIED';
    if (!isWalletLinked(account)) return 'INVALID_WALLET';
    return 'READY';
  };

  const linkStep = getLinkStep();

  return (
    <Wrapper touched={signinMenu.touched} open={signinMenu.menuOpen}>
      <RootWrapper touched={signinMenu.touched} open={signinMenu.menuOpen}>
        {page === 'signup' && (
          <>
            <TitleWrapper>
              <Title>Sign up</Title>
              <CloseIcon src={Back} onClick={handleClose} />
            </TitleWrapper>
            <LoginFormWrapper>
              <IndicatorContainer>
                <Indicator index={currentStep === 'signup' || currentStep} />
                <Indicator
                  index={currentStep === 'profile' || currentStep !== 'signup'}
                />
                <Indicator
                  index={
                    (currentStep === 'social' && isArtist) ||
                    (currentStep !== 'profile' && currentStep !== 'signup')
                  }
                />
                <Indicator
                  index={
                    currentStep === 'wallet' ||
                    (currentStep !== 'social' &&
                      currentStep !== 'profile' &&
                      currentStep !== 'signup')
                  }
                />
                <Indicator index={currentStep === 'finish'} />
              </IndicatorContainer>
              {currentStep === 'signup' && (
                <Signup
                  loading={loading}
                  handleSignin={handleSignin}
                  onNext={handleSignupSubmit}
                />
              )}
              {currentStep === 'profile' && (
                <Profile
                  loading={loading}
                  user={user.data?.user}
                  handleSkip={() => handleSkip('social')}
                  onNext={handleProfileSubmit}
                />
              )}
              {currentStep === 'social' && (
                <Social
                  loading={loading}
                  handleSkip={() => handleSkip('wallet')}
                  onNext={handleSocialSubmit}
                />
              )}
              {currentStep === 'wallet' && (
                <>
                  <ConnectWrapper>
                    {(linkStep === 'NOT_CONNECTED' ||
                      linkStep === 'LOGGED_OUT') &&
                      currentStep !== 'finish' && (
                        <>
                          <SubTitle>Connect Metamask</SubTitle>
                          <Description>
                            Link your Metamask wallet to your Blackdove account
                            to purchase limited edition NFT artworks and also
                            view NFTs purchased from other platforms. No
                            transaction will take place without your express
                            permission.
                          </Description>
                          <ButtonWrapper>
                            {!selectedProvider && (
                              <>
                                <WalletButton
                                  className="skip"
                                  variant="metamask"
                                  onClick={() =>
                                    setSelectedProvider('METAMASK')
                                  }
                                >
                                  MetaMask
                                </WalletButton>
                                <WalletButton
                                  variant="walletconnect"
                                  onClick={() =>
                                    setSelectedProvider('WALLET_CONNECT')
                                  }
                                >
                                  WalletConnect
                                </WalletButton>
                              </>
                            )}
                            {selectedProvider === 'METAMASK' && (
                              <MetaMask
                                wallets={wallets.data}
                                pairWallet={handlePairWallet}
                                closeModal={handleReset}
                              />
                            )}

                            {selectedProvider === 'WALLET_CONNECT' && (
                              <WalletConnect
                                wallets={wallets.data}
                                pairWallet={handlePairWallet}
                                closeModal={handleReset}
                              />
                            )}
                          </ButtonWrapper>
                          <ButtonContainer one>
                            <Button
                              type="button"
                              className="skip"
                              onClick={() => handleSkip('finish')}
                            >
                              Skip
                            </Button>
                          </ButtonContainer>
                        </>
                      )}
                    {linkStep === 'NOT_VERIFIED' && currentStep !== 'finish' && (
                      <>
                        <SubTitle>
                          Please sign the message in the MetaMask extension to
                          continue
                        </SubTitle>
                        <Description>
                          Blackdove uses this signature to verify that you’re
                          the owner of this Ethereum address.
                        </Description>
                        <ButtonWrapper>
                          <Button
                            type="button"
                            className="skip"
                            onClick={() => handleSkip('finish')}
                          >
                            Skip
                          </Button>
                          <Button
                            onClick={handleVerifyWallet}
                            disabled={savingWallet}
                            pending={savingWallet}
                            primary
                          >
                            Verify Your Wallet
                          </Button>
                        </ButtonWrapper>
                      </>
                    )}
                    {linkStep === 'INVALID_WALLET' && currentStep !== 'finish' && (
                      <>
                        <SubTitle>Verification failed!</SubTitle>
                        <Description>
                          Please connect with the wallet that you previously
                          verified with blackdove.
                        </Description>
                        <ButtonWrapper>
                          <Button
                            type="button"
                            className="skip"
                            onClick={() => handleSkip('finish')}
                          >
                            Skip
                          </Button>
                          <Button
                            onClick={handleVerifyWallet}
                            disabled={savingWallet}
                            pending={savingWallet}
                            primary
                          >
                            Verify Your Wallet
                          </Button>
                        </ButtonWrapper>
                      </>
                    )}
                    {linkStep === 'READY' && currentStep !== 'finish' && (
                      <>
                        <SubTitle>Wallet connected</SubTitle>
                        <Description>
                          Your wallet has been successfully connected with
                          Blackdove. View ‘Imported NFTs’ collection and start
                          showcasing your collected artworks.
                        </Description>
                        <ButtonWrapper>
                          <Button
                            type="button"
                            className="skip"
                            onClick={() => handleSkip('finish')}
                          >
                            Finish
                          </Button>
                          <Button
                            onClick={handleNFT}
                            disabled={savingWallet}
                            pending={savingWallet}
                            primary
                          >
                            View your NFTs
                          </Button>
                        </ButtonWrapper>
                      </>
                    )}
                  </ConnectWrapper>
                </>
              )}
              {currentStep === 'finish' && (
                <>
                  <SubTitle>Account created successfully</SubTitle>
                  {isArtist ? (
                    <>
                      <Description>
                        Welcome to blackdove. As an artist your next step should
                        be to finalize your account by adding your banking
                        details for fast payouts.
                      </Description>
                      <Button
                        type="button"
                        color="red"
                        onClick={handleExitArtist}
                      >
                        Connect via Stripe
                      </Button>
                    </>
                  ) : (
                    <>
                      <Description>
                        Welcome to Blackdove. Start collecting limited edition
                        NFTs, licensed artworks, or subscribe to access curated
                        collections to exhibit on a Digital Canvas or any smart
                        TV
                      </Description>
                      <Button type="button" color="red" onClick={handleExit}>
                        Start collecting
                      </Button>
                    </>
                  )}
                </>
              )}
            </LoginFormWrapper>
          </>
        )}
        {page === 'login' && (
          <>
            <TitleWrapper>
              <Title>Log In</Title>
              <CloseIcon src={Back} onClick={handleClose} />
            </TitleWrapper>
            <LoginFormWrapper>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleLoginSubmit}
              >
                <Form style={{ maxWidth: 480, textAlign: 'start' }}>
                  <Field
                    name="email"
                    type="email"
                    label="Email"
                    placeholder="you@email.com"
                    component={TextInput}
                  />
                  <Field
                    name="password"
                    type="password"
                    label="Password"
                    component={TextInput}
                  />
                  <ForgotWrapper>
                    <button type="button" onClick={() => setPage('forgot')}>
                      Forgot password?
                    </button>
                  </ForgotWrapper>
                  <Button
                    type="submit"
                    color={loading ? '' : 'red'}
                    disabled={loading}
                  >
                    {loading ? 'Loading....' : 'Sign In'}
                  </Button>
                </Form>
              </Formik>
              <SignUpWrapper>
                <div>
                  Dont have an account?{' '}
                  <button type="button" className="bold" onClick={handleSignup}>
                    Sign up here
                  </button>
                </div>
              </SignUpWrapper>
            </LoginFormWrapper>
          </>
        )}
        {page === 'forgot' && (
          <>
            <TitleWrapper>
              <Title>Forgot password?</Title>
              <CloseIcon src={Back} onClick={handleClose} />
            </TitleWrapper>
            <LoginFormWrapper>
              <DescriptionTwo>
                Don’t worry happens to the best of us. Just enter your email
                below and we’ll send you instructions to reset your password
              </DescriptionTwo>
              <Formik
                initialValues={initialValuesForgot}
                validationSchema={validationSchemaForgot}
                onSubmit={handleForgotSubmit}
              >
                <Form style={{ maxWidth: 480, textAlign: 'start' }}>
                  <Field
                    name="email"
                    type="email"
                    label="Email"
                    placeholder="you@email.com"
                    component={TextInput}
                  />
                  <ButtonContainer>
                    <Button
                      type="button"
                      className="skip"
                      onClick={() => setPage('login')}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      color={loading ? '' : 'red'}
                      disabled={isEmailSending}
                    >
                      {loading ? 'Loading....' : 'Send Reset Instructions'}
                    </Button>
                  </ButtonContainer>
                  {isEmailSending && (
                    <AlertWrapper>
                      <Alert
                        width="100%"
                        bgColor="#009D3F"
                        borderColor="#009D3F"
                        textColor="#ffffff"
                        icon={IconChecked}
                        text="Email with instructions has been sent"
                      />
                    </AlertWrapper>
                  )}
                </Form>
              </Formik>
            </LoginFormWrapper>
          </>
        )}
      </RootWrapper>
    </Wrapper>
  );
};
