import React, { useState, useEffect } from 'react';
import { addDays, format, parseISO } from 'date-fns';
import { useNotifications } from 'reapop';
import { Table } from '@blackdove/ui';
import { BlackdoveClient } from '@blackdove/utils';

// Components
import { Loader } from '../../components/Loader';

// Hooks
import { useUser, useEarnings, usePayments } from '../../hooks/data';

import {
  RootWrapper,
  Wrapper,
  PageTitle,
  EarningTab,
  EarningTabItem,
  PanelTitle,
  ValueList,
  ValueItem,
  ValueDescription,
  TotalPrice,
  H5,
  WithdrawalTitleWrapper,
  AmountInput,
  PaymentType,
  PaymentButton,
  CardWrapper,
} from './styled';

export function Earnings() {
  const { notify } = useNotifications();
  const user = useUser();
  const earnings = useEarnings();
  const payments = usePayments();
  const [submitting, setSubmitting] = useState(false);
  const [currentTabId, setCurrentTabId] = useState('Earnings');

  useEffect(() => {
    if (earnings.isError) {
      notify({
        status: 'error',
        title: 'Earnings',
        message: 'Earnings could not be loaded.',
      });
      return;
    }
    if (payments.isError) {
      notify({
        status: 'error',
        title: 'Payments',
        message: 'Payments could not be loaded.',
      });
    }
  }, [earnings.isError, payments.isError]);

  const formatDate = (date) => {
    return format(new Date(date), 'dd MMMM yyyy');
  };

  const formatPaymentMethod = (method) => {
    switch (method) {
      case 'bankTransfer':
        return 'Bank Transfer';
      case 'paypal':
        return 'Paypal';
      case 'stripeTransfer':
        return 'Stripe Transfer';
      default:
        return method;
    }
  };

  const handlePaymentRequest = async (e) => {
    e.preventDefault();

    if (submitting) return;

    setSubmitting(true);

    const params = {
      amount: e.target[0].value,
      paymentMethod: e.target[1].value,
    };

    const result = await BlackdoveClient.post(
      `/artists/${user?.data?.artist?.id}/earnings/withdrawals`,
      params
    );

    if (result.status === 200) {
      notify({
        status: 'success',
        title: 'Payment Request',
        message: 'Your payment request has been submitted!',
      });
      setSubmitting(false);
      return;
    }

    notify({
      status: 'error',
      title: 'Payment Request',
      message:
        'Your payment request could not be submitted, please try again later.',
    });

    setSubmitting(false);
  };

  const earningsNodes = earnings.data?.previousMonths.map(
    ({ date, views, grossEarnings, blackdoveTake, tax, withdrawable }) => [
      { content: format(addDays(parseISO(date), 15), 'MMMM yyyy') },
      { content: Number(views) },
      { content: Number(grossEarnings) },
      { content: Number(blackdoveTake) },
      { content: Number(tax) },
      { content: Number(withdrawable) },
    ]
  );

  const withdrawalsNodes = payments.data?.overview
    .reverse()
    .map(({ createdAt, amount, status, paymentMethod, updatedAt }) => [
      { content: formatDate(createdAt) },
      { content: amount },
      { content: formatPaymentMethod(paymentMethod) },
      { content: status.toUpperCase() },
      { content: formatDate(updatedAt) },
    ]);

  const remainingBalance = (
    earnings.data?.totals.totalEarnings - payments.data?.totalWithdrawals
  ).toFixed(2);

  return (
    <RootWrapper>
      <PageTitle>
        {user?.isLoading
          ? 'Welcome back, friend!'
          : `Welcome back, ${user.data?.user?.givenName || 'Friend'}!`}
      </PageTitle>

      {(earnings.isLoading || payments.isLoading) && <Loader />}
      {!earnings.isLoading && !payments.isLoading && (
        <>
          <CardWrapper>
            <PanelTitle>Totals All Time</PanelTitle>
            <ValueList>
              <ValueItem>
                <ValueDescription>
                  Total Earned
                  <TotalPrice>{`$${earnings.data?.totals?.totalEarnings}`}</TotalPrice>
                </ValueDescription>
              </ValueItem>
              <ValueItem>
                <ValueDescription>
                  Cashed Out
                  <TotalPrice>{`$${payments.data?.totalWithdrawals.toFixed(
                    2
                  )}`}</TotalPrice>
                </ValueDescription>
              </ValueItem>
              <ValueItem>
                <ValueDescription>
                  Remaining Balance
                  <TotalPrice>{`$${remainingBalance}`}</TotalPrice>
                </ValueDescription>
              </ValueItem>
            </ValueList>
          </CardWrapper>
          <CardWrapper>
            <EarningTab>
              <EarningTabItem
                isActive={currentTabId === 'Earnings'}
                onClick={() => setCurrentTabId('Earnings')}
              >
                Monthly Earnings
              </EarningTabItem>
              <EarningTabItem
                isActive={currentTabId === 'Withdrawals'}
                onClick={() => setCurrentTabId('Withdrawals')}
              >
                Payments
              </EarningTabItem>
            </EarningTab>
            {currentTabId === 'Earnings' && (
              <Wrapper name="Earnings">
                <Wrapper>
                  <Wrapper>
                    <PanelTitle>Earnings Overview</PanelTitle>
                    <H5 className="h--spacing-sm h--normalize-weight">
                      <ValueDescription>By Month</ValueDescription>
                    </H5>
                    <Table
                      headerColumns={[
                        { content: 'Month' },
                        { content: 'Total Views' },
                        { content: 'Gross Earnings ($)' },
                        {
                          content: 'Blackdove Take 20% ($)',
                          // content: `Blackdove Take ${earnings.data?.calculationPercentages.blackdoveTake}% ($)`,
                        },
                        {
                          content: `Tax ${earnings.data?.calculationPercentages.tax}%`,
                        },
                        { content: 'Withdrawable ($)' },
                      ]}
                      bodyRows={earningsNodes}
                    />
                  </Wrapper>
                </Wrapper>
              </Wrapper>
            )}
            {currentTabId === 'Withdrawals' && (
              <Wrapper name="Withdrawals">
                <WithdrawalTitleWrapper>
                  <Wrapper>
                    <PanelTitle>Payment History</PanelTitle>
                    <H5>
                      <ValueDescription>{`$${remainingBalance} Available`}</ValueDescription>
                    </H5>
                  </Wrapper>
                  <Wrapper>
                    {remainingBalance >= 50 && (
                      <form onSubmit={handlePaymentRequest}>
                        <AmountInput
                          label="Amount"
                          helpText="The amount you would like to withdraw in USD (50 USD minimum)"
                          placeholder="Amount in USD"
                          invalidMsg="Please enter a valid amount"
                          name="amount"
                          type="number"
                          min="50"
                          max={remainingBalance}
                          step="50"
                          required
                        />
                        <PaymentType
                          label="Payment Method"
                          name="paymentMethod"
                          helpText="How you would like us to pay you"
                        >
                          <option value="paypal">Paypal</option>
                          <option value="bankTransfer">Bank Transfer</option>
                          {user?.data?.artist?.isVerified && (
                            <option value="stripeTransfer">
                              Stripe Transfer
                            </option>
                          )}
                        </PaymentType>
                        <PaymentButton type="submit" disabled={submitting}>
                          Request Payment
                        </PaymentButton>
                      </form>
                    )}
                    {remainingBalance < 50 && (
                      <span>$50 minimum required to request a payment</span>
                    )}
                  </Wrapper>
                </WithdrawalTitleWrapper>
                <Wrapper>
                  <Wrapper>
                    <Table
                      headerColumns={[
                        { content: 'Date' },
                        { content: 'Amount ($)' },
                        { content: `Payment Method` },
                        { content: `Status` },
                        { content: 'Status Updated On' },
                      ]}
                      bodyRows={withdrawalsNodes}
                    />
                  </Wrapper>
                </Wrapper>
              </Wrapper>
            )}
          </CardWrapper>
        </>
      )}
    </RootWrapper>
  );
}
