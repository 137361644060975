import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ThemeProvider } from '@emotion/react';
import { BlackdoveClient } from '@blackdove/utils';

import { theme } from '../theme';

// Components
import GlobalStyles from '../GlobalStyles';
import Navigation from './AccountNavigation';
import { Header } from '../Main/components/Header';

// Hooks
import { useQuery } from '../../../hooks/useQuery';

// Actions
import { setAuthenticated, setImpersonating } from '../../../store/app/actions';
import { fetchUser } from '../../../store/user/actions';

const RootWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #910048 0%, #111111 100%);
  position: fixed;
`;

const Wrapper = styled.div`
  width: 1008px;
  height: 100%;
  position: relative;
  margin: 0 auto;
`;

const ContentBlack = styled.div`
  height: 100%;
  position: relative;
  margin-left: 240px;
  padding-top: 65px;
`;

const AccountLayout = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery();
  const app = useSelector((state) => state.app);

  // Fetch data that is used throughout the app
  useEffect(() => {
    const authenticate = async () => {
      const runAs = query.get('run-as');
      const refreshToken = query.get('refresh');

      if (runAs) {
        BlackdoveClient.setRunAs(runAs);
        await BlackdoveClient.refreshAuth(refreshToken);
      }

      if (BlackdoveClient.getImpersonated()) {
        dispatch(setImpersonating(true));
      }

      if (!app?.data?.authenticated) {
        const creds = BlackdoveClient.getCredentials();

        if (!creds.accessToken) {
          BlackdoveClient.clearAuth();
          navigate('/login');
          return;
        }

        dispatch(setAuthenticated());
      }

      await dispatch(fetchUser());
    };

    authenticate();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <RootWrapper>
        <Header />
        <Wrapper>
          <GlobalStyles />
          <Navigation />
          <ContentBlack>{children}</ContentBlack>
        </Wrapper>
      </RootWrapper>
    </ThemeProvider>
  );
};

AccountLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default AccountLayout;
