import styled from '@emotion/styled';
import { css } from '@emotion/react';

const activeNavStyles = ({ theme }) => css`
  background: ${theme.colors.base3};
  border-radius: 12px;
`;

export const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 20px;
`;

export const Label = styled.label`
  margin-bottom: 0.5em;
  display: inline-block;
`;

export const SectionHeading = styled.h1`
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
`;

export const Divider = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-bottom: 30px;
`;

export const ProfileImageContainer = styled.div`
  display: flex;
  justify-content: center;
  background: #333333;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.45);
  border-radius: 12px;
  flex-direction: column;
  padding: 12px;
  cursor: pointer;

  @media (min-width: 768px) {
    flex-direction: row;
    margin-top: 50px;
  }
`;

export const ProfileImageCenter = styled.div`
  display: flex;
  width: 84px;
  height: 84px;
  position: relative;
  border-radius: 120px;
  background: #bdcdf9;
  background: url(${(props) => props.profileImageURL});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 12px;
  align-self: center;

  @media (min-width: 768px) {
    margin-right: 12px;
  }
`;

export const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
`;

export const ProfileImageEditButton = styled.span`
  position: absolute;
  width: 50px;
  height: 50px;
  right: 5px;
  bottom: 5px;
  border: solid 2px #4373f6;
  color: #4373f6;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
  background: white;

  &:hover {
    border: solid 3px #4f7fff;
  }
`;

export const FileInput = styled.input`
  display: none;
`;

export const FormWrapper = styled.div`
  padding: 24px;
  max-width: 800px;
  margin: auto;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const FormSection = styled.div`
  padding: 24px;
  flex-basis: 50%;

  select {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #a5a5a5;
    letter-spacing: 0.025em;

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  span {
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.025em;
    color: #a5a5a5;
  }

  ul {
    background: #333333;
    border: 2px solid #333;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.45);
    border-radius: 12px;
    height: 50px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #a5a5a5;
    letter-spacing: 0.025em;
    padding: 10px 0 20px 20px;
    list-style-type: none;
    display: flex;

    img {
      margin-top: 8px;
      height: 13px;
    }

    .label {
      flex-basis: 90%;
    }

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  @media (max-width: 767px) {
    padding: 0;
  }
`;

export const ListWrapper = styled.div`
  position: absolute;
  background: #333333;
  z-index: 100;
  border: 1px solid #a9a9a9;
  width: 300px;
  max-height: 500px;
  overflow-y: scroll;

  &:focus {
    outline: none;
    border: 1px solid #4373f6;
  }
  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  div {
    margin-left: 20px;
    cursor: pointer;

    &:hover {
      background: #444444;
    }
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.015em;
  margin: 24px 0px;
  color: #a5a5a5;

  @media (min-width: 768px) {
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.02em;
  }
`;

export const Description = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.025em;
  color: #a5a5a5;
  margin-bottom: 24px;

  ul {
    padding-left: 25px;
  }

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const HeaderSection = styled.div`
  padding: 24px;
  flex-basis: 50%;

  @media (max-width: 767px) {
    padding: 0;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;

  select {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #a5a5a5;
    letter-spacing: 0.025em;
    color: #a5a5a5;
  }
  > :first-child {
    margin-right: 24px;
  }
  div:last-child {
    margin-top: 32px;
  }
`;

export const DropUploadContainer = styled.div`
  padding: 24px;
  border: 1px dashed #777777;
  border-radius: 6px;
  text-align: center;
  flex-basis: 50%;
  width: 100%;

  @media (min-width: 768px) {
    flex-basis: 68%;
  }
`;

export const DropDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.025em;
  color: #a5a5a5;
`;

export const Option = styled.option`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #a5a5a5;
  letter-spacing: 0.025em;
  color: #a5a5a5;
`;

export const SelectedTabs = styled.div`
  background: #333333;
  min-height: 86px;
  padding: 12px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.45);
  border-radius: 12px;
`;

export const Tab = styled.div`
  width: fit-content;
  height: 32px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.025em;
  background: #910048;
  border-radius: 24px;
  padding: 8px 12px;
  display: flex;

  .name {
    margin-right: 10px;
  }
  .close {
    cursor: pointer;
  }

  :not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: #222222;
  border-radius: 12px;
  margin: 0px 0 12px 0;
  padding: 84px 0 24px 0;
`;

export const DividerTwo = styled.div`
  border: 1px solid #444444;
  transform: rotate(90deg);
  width: 16px;
  height: 0px;
  margin-top: 50px;
`;

export const SubscriptionWrapper = styled.div`
  width: 100%;
  margin-top: 50px;
  padding: 24px;
`;

export const ContentHeaderLine = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 10px;
`;

export const ProfileInfoLine = styled.div`
  padding: 10px 0;
`;

export const InfoText = styled.span`
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
`;

export const ButtonWrapper = styled.div`
  margin-top: 50px;
  cursor: pointer;
  padding: 12px;
  ${({ active }) => (active ? activeNavStyles : null)}
`;
