import styled from '@emotion/styled';

export const Wrapper = styled.div`
  background-color: ${({ bg }) => bg};
  width: 100%;
  margin: auto;
  padding: ${({ padding }) => padding};
`;

export const HeaderWrapper = styled.div`
  height: calc(100vh - 96px);
  width: 100%;
  position: relative;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: ${({ fd }) => fd};
  padding: ${({ padding }) => padding};
  max-width: 1440px;
  margin: ${({ margin }) => margin};
  flex-wrap: ${({ fw }) => fw};
  justify-content: ${({ jc }) => jc};
  background-color: ${({ bg }) => bg};
  gap: ${({ gap }) => gap};
  align-items: ${({ ai }) => ai};
  order: ${({ order }) => order};
  position: ${({ position }) => position};

  @media (min-width: 375px) {
    padding: ${({ paddingM }) => paddingM};
    margin: ${({ marginM }) => marginM};
  }
  @media (min-width: 768px) {
    flex-direction: ${({ fdl }) => fdl};
    padding: ${({ paddingLarge }) => paddingLarge};
    flex-basis: ${({ fbl }) => fbl};
    justify-content: ${({ jcl }) => jcl};
    align-items: ${({ ail }) => ail};
    order: ${({ orderL }) => orderL};
    margin: ${({ marginL }) => marginL};
  }

  @media (min-width: 1440px) {
    flex-direction: ${({ fdxl }) => fdxl};
    flex-basis: ${({ fbxl }) => fbxl};
    margin: ${({ marginXL }) => marginXL};
    padding: ${({ paddingXLarge }) => paddingXLarge};
    gap: ${({ gapXL }) => gapXL};
  }
`;

export const VideoAspectRatioBox = styled.div`
  width: 100%;
  max-height: calc(100vh - 96px);
  position: relative;
  margin: ${({ margin }) => margin};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};

  @media (min-width: 375px) {
    margin: ${({ marginL }) => marginL};
  }
  @media (min-width: 768px) {
    margin: ${({ marginXL }) => marginXL};
    padding-bottom: ${({ paddingBottomLarge }) => paddingBottomLarge};
  }
  @media (min-width: 1440px) {
    padding-bottom: ${({ paddingBottomXLarge }) => paddingBottomXLarge};
  }
`;

export const ImageAspectRatioBox = styled.div`
  width: 100%;
  height: 88px;
  position: relative;
  @media (min-width: 375px) {
    height: 99px;
  }
  @media (min-width: 768px) {
    height: 182.4px;
  }
  @media (min-width: 1024px) {
    height: 204.8px;
  }
  @media (min-width: 1440px) {
    height: 256.8px;
  }
`;

export const ImageAspectRatioBoxTwo = styled.div`
  width: 50px;
  height: 40px;
  position: relative;
  @media (min-width: 375px) {
    width: 63.75px;
    height: 51px;
  }
  @media (min-width: 768px) {
    width: 108px;
    height: 86.4px;
  }
  @media (min-width: 1024px) {
    width: 136px;
    height: 108.8px;
  }
  @media (min-width: 1440px) {
    width: 201px;
    height: 160.8px;
  }
`;

export const VideoFrame = styled.div`
  img {
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: ${({ borderRadius }) => borderRadius};
  }
`;

export const VideoFrameTwo = styled.div`
  div,
  video,
  img,
  iframe {
    object-fit: ${({ objectFit }) => objectFit};
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: ${({ borderRadius }) => borderRadius};
    z-index: 0;
  }
`;

export const HeaderBarWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  max-height: calc(100% / 3);
`;

export const ScrollIcon = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    width: 48px;
    height: 48px;

    @media (min-width: 768px) {
      width: 96px;
      height: 96px;
    }
  }
`;

export const HeaderBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
  background: rgba(26, 26, 26, 0.75);
  padding: 24px 24px 36px 24px;

  @media (min-width: 768px) {
    padding: 48px 48px 60px 48px;
  }
`;

export const HeaderTitle = styled.h1`
  text-transform: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.025em;
  text-align: center;
  color: #ffffff;
  margin-bottom: 24px;

  @media (min-width: 375px) {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.015em;
  }
  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 48px;
    letter-spacing: -0.02em;
  }
  @media (min-width: 1024px) {
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
  }
`;

export const HeaderSubtitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.025em;
  color: #a5a5a5;

  @media (min-width: 375px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media (min-width: 768px) {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.015em;
  }
  @media (min-width: 1440px) {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.02em;
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.015em;
  color: #ffffff;
  margin-bottom: ${({ mb }) => mb};

  @media (min-width: 1024px) {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.02em;
  }
`;
export const Subtitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.025em;
  color: #a5a5a5;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.025em;
    text-align: start;
  }
`;

export const ItemTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.025em;
  color: #ffffff;
  margin-bottom: 4px;

  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.015em;
  }
`;

export const ItemSub = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.025em;
  color: #a5a5a5;

  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const TopTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #1a1a1a;
  margin-bottom: 48px;

  @media (min-width: 768px) {
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
  }
`;

export const VideoSubtext = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.025em;
  color: #3d3d3d;
  margin: 12px 0 24px 0;
`;

export const CaseTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.025em;
  color: #1a1a1a;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.02em;
  }
`;

export const CaseSubtitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.025em;
  color: #3d3d3d;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const IconWrapper = styled.div`
  width: ;
`;

export const ContactTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: #ffffff;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 48px;
    letter-spacing: -0.02em;
    margin-bottom: 48px;
  }
`;

export const PositionTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.025em;
  color: #777777;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Name = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.025em;
  color: #ffffff;

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.015em;
  }
`;

export const Email = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.025em;
  color: #ffffff;

  @media (min-width: 768px) {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const ImageAvatar = styled.div`
  margin-bottom: 12px;

  img {
    width: 120px;
    height: 120px;
    border: 4px solid #910048;
    box-sizing: border-box;
    border-radius: 10000px;

    @media (min-width: 768px) {
      width: 240px;
      height: 240px;
    }
  }
`;

export const ButtonWrapperOne = styled.div`
  flex-basis: 50%;
  max-width: 137px;

  @media (max-width: 767px) {
    display: none;
  }
  @media (min-width: 1440px) {
    width: 384px;
    max-width: 384px;
    max-height: 105px;
  }
`;

export const ButtonWrapper = styled.div`
  padding: 0 24px 24px 24px;

  @media (min-width: 375px) {
    padding: 0 48px 48px 48px;
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
`;

export const BackgroundRect = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    #ffffff;
  background-blend-mode: darken, normal;
  transform: matrix(1, 0, 0, -1, 0, 0);
  position: absolute;
  margin-left: 72px;
  width: 85%;
  height: 500px;
  bottom: 0;
`;

export const CaseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  z-index: 1;
`;

export const DesktopImg = styled.img`
  @media (max-width: 1439px) {
    display: none;
  }
`;
export const TabletImg = styled.img`
  @media (max-width: 768px) {
    display: none;
  }
  @media (min-width: 1440px) {
    display: none;
  }
`;
export const MobileImg = styled.img`
  @media (min-width: 768px) {
    display: none;
  }
`;
