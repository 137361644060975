import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import { ArtistCardSkeleton } from '../../Skeletons/ArtistCardSkeleton';
import { ArtCardSkeleton } from '../../Skeletons/ArtCardSkeleton';
import { CollectionTile } from '../Tile';
import { CollectionCard } from '../Card';

// Hooks
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver';
import {
  useManyCollectionsInfinite,
  useUserPublicCollections,
} from '../../../hooks/data';
import { useDeleteCollection } from '../../../hooks/mutations';

// Styled
import { LoadingMore, ItemWrapper, CardsWrapper } from './styled';

export function InfiniteUserCollectionGrid({
  queryKey,
  subpath,
  editable,
  setTotal,
}) {
  const loadMoreRef = useRef();
  const collections = useManyCollectionsInfinite(queryKey, subpath, 100);
  const deleteCollection = useDeleteCollection();

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: collections.fetchNextPage,
    enabled: collections.hasNextPage,
  });

  const onRemoveClick = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    if (confirm('Are you sure you want to delete this playlist?')) {
      deleteCollection.mutate({ id });
    }
  };

  useEffect(() => {
    if (collections.data) {
      setTotal(collections.data?.pages?.[0]?.total);
    }
  }, [collections.data]);

  return (
    <>
      <ItemWrapper>
        {collections?.data?.pages?.map((page) => (
          <React.Fragment key={`page-${page.nextOffset}`}>
            {page?.collections?.map((collection) => (
              <CollectionTile
                collection={collection}
                editable={editable}
                onRemoveClick={onRemoveClick}
              />
            ))}
          </React.Fragment>
        ))}
      </ItemWrapper>
      <LoadingMore ref={loadMoreRef}>
        {(collections.isFetchingNextPage || collections.isLoading) && (
          <>
            <ArtistCardSkeleton />
            <ArtistCardSkeleton />
            <ArtistCardSkeleton />
            <ArtistCardSkeleton />
            <ArtistCardSkeleton />
            <ArtistCardSkeleton />
            <ArtistCardSkeleton />
            <ArtistCardSkeleton />
            <ArtistCardSkeleton />
            <ArtistCardSkeleton />
            <ArtistCardSkeleton />
            <ArtistCardSkeleton />
          </>
        )}
        {!collections.isFetchingNextPage &&
          !collections.isLoading &&
          !collections.hasNextPage &&
          collections.data?.pages?.length > 1 && (
            <span>No more collections...</span>
          )}
        {collections.data?.pages?.length === 1 &&
          collections.data?.pages?.[0]?.collection?.artwork?.length === 0 && (
            <span>No collections...</span>
          )}
      </LoadingMore>
    </>
  );
}

InfiniteUserCollectionGrid.propTypes = {
  queryKey: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  subpath: PropTypes.string,
  setTotal: PropTypes.func.isRequired,
};

InfiniteUserCollectionGrid.defaultProps = {
  editable: false,
  subpath: '',
};

export function InfiniteUserPublicCollectionGrid({ id }) {
  const loadMoreRef = useRef();
  const collections = useUserPublicCollections(id);

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: collections.fetchNextPage,
    enabled: collections.hasNextPage,
  });

  return (
    <>
      <CardsWrapper>
        {collections?.data?.pages?.map((page) => (
          <React.Fragment key={`page-${page.nextOffset}`}>
            {page?.collections?.map((collection) => (
              <CollectionCard collection={collection} />
            ))}
          </React.Fragment>
        ))}
      </CardsWrapper>
      <LoadingMore ref={loadMoreRef}>
        {(collections.isFetchingNextPage || collections.isLoading) && (
          <>
            <ArtCardSkeleton />
            <ArtCardSkeleton />
            <ArtCardSkeleton />
            <ArtCardSkeleton />
            <ArtCardSkeleton />
            <ArtCardSkeleton />
            <ArtCardSkeleton />
            <ArtCardSkeleton />
            <ArtCardSkeleton />
            <ArtCardSkeleton />
            <ArtCardSkeleton />
            <ArtCardSkeleton />
          </>
        )}
        {!collections.isFetchingNextPage &&
          !collections.isLoading &&
          !collections.hasNextPage &&
          collections.data?.pages?.length > 1 && (
            <span>No more collections...</span>
          )}
        {collections.data?.pages?.length === 1 &&
          collections.data?.pages?.[0]?.collection?.artwork?.length === 0 && (
            <span>No collections...</span>
          )}
      </LoadingMore>
    </>
  );
}

InfiniteUserPublicCollectionGrid.propTypes = {
  id: PropTypes.string.isRequired,
};
