import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Checkmark } from '@emotion-icons/ionicons-sharp';

import { InputWrapper, Input, Label, VisualWrapper } from './styled';

export function Checkbox({ field, label, helperText, ...props }) {
  const inputRef = useRef(null);

  return (
    <InputWrapper>
      <VisualWrapper
        isChecked={!!field.value}
        onClick={() => inputRef.current.click()}
      >
        <Input
          ref={inputRef}
          type="checkbox"
          id={field.name}
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          {...props}
        />
        {field.value && <Checkmark size={18} />}
      </VisualWrapper>
      {label && (
        <Label isChecked={!!field.value} htmlFor={field.name}>
          {label}
        </Label>
      )}
    </InputWrapper>
  );
}

Checkbox.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }).isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
};

Checkbox.defaultProps = {
  label: '',
  helperText: '',
};
