import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

// Components
import { CollectionItem } from '../CollectionItem';
import { Loader } from '../../../../../../Loader';
import external from '../../../../../../../assets/images/icons/External Link.svg';

// Hooks
import { useIntersectionObserver } from '../../../../../../../hooks/useIntersectionObserver';

const MenuWrapper = styled.nav`
  max-height: 500px;
  overflow: scroll;
  margin-bottom: 10px;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;

const MenuTitle = styled.span`
  display: inline-block;
  padding: 0 0 0 18px;
  font-size: 13px;
  text-transform: uppercase;
  color: rgb(179, 179, 179);
  position: relative;
  width: 100%;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    border: 0;
    background: transparent;
    color: #ffffff;
    border-radius: 100px;
    cursor: pointer;

    &:hover {
      background: #444444;
    }

    img {
      width: 16px;
      height: 16px;
    }
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 2px;
  right: -10px;
`;

const LoadingMore = styled.span``;

export function CollectionMenu({ collections, title, droppable }) {
  const loadMoreRef = useRef();
  const navigate = useNavigate();

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: collections.fetchNextPage,
    enabled: collections.hasNextPage,
  });

  return (
    <MenuWrapper>
      <MenuTitle>
        <Title>
          {title}
          {title === 'Your Collections' && (
            <button type="button" onClick={() => navigate('/collection/me')}>
              <img src={external} alt="arrow" />
            </button>
          )}
          {title === 'Followed Collections' && (
            <button
              type="button"
              onClick={() => navigate('/collection/followed')}
            >
              <img src={external} alt="arrow" />
            </button>
          )}
        </Title>
        {collections.isLoading && (
          <LoaderWrapper>
            <Loader size="15px" />
          </LoaderWrapper>
        )}
      </MenuTitle>

      {!collections.isLoading && (
        <ul>
          {collections.data?.pages?.map((page) => (
            <React.Fragment key={`page-${page.nextOffset}`}>
              {page?.collections?.map((collection) =>
                ['STANDARD', 'FAVORITES', 'PURCHASES', 'ARTIST'].includes(
                  collection.playlistType
                ) ? (
                  <>
                    <CollectionItem
                      key={collection.id}
                      collection={collection}
                      droppable={droppable}
                    />
                  </>
                ) : null
              )}
            </React.Fragment>
          ))}
          <LoadingMore ref={loadMoreRef}>
            {(collections.isFetchingNextPage || collections.isLoading) && (
              <Loader size="15px" />
            )}
          </LoadingMore>
        </ul>
      )}
    </MenuWrapper>
  );
}

CollectionMenu.propTypes = {
  collections: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  droppable: PropTypes.bool,
};

CollectionMenu.defaultProps = {
  droppable: false,
};
