import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { VideoPlayer } from '@blackdove/player';
import Web3 from 'web3';
import { useDispatch } from 'react-redux';
import { useDrag } from 'react-dnd';
import {
  Star as StarOutline,
  Trash,
  Square,
} from '@emotion-icons/ionicons-outline';
import { Star as StarSolid, Add } from '@emotion-icons/ionicons-solid';
import { useNotifications } from 'reapop';

// Components
import { Button } from '../../Button';
import arrowLeftIcon from '../../Images/ArrowLeft.svg';
import { Loader } from '../../Loader';

// Hooks
import {
  useFavoritesCollection,
  useManyCollectionsInfinite,
  useUser,
} from '../../../hooks/data';
import {
  useAddFavorite,
  useRemoveFavorite,
  useAddToCollection,
  useDeleteArtwork,
} from '../../../hooks/mutations';
import { useMenuClickOutside } from '../../../hooks/useMenuClickOutside';
import useHover from '../../../hooks/useHover';
import useIntersect from '../../../hooks/useIntersect';
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver';

// Actions
import { openMainMenu } from '../../../store/mainMenu/actions';
import { openModal } from '../../../store/app/actions';

// Assets
import LoadingImage from '../../../assets/images/loading.svg';
import dots from '../../../assets/images/More.svg';
import defaultProfile from '../../../assets/images/default/Profile.png';
import landscape from '../../../assets/images/icons/landscape.svg';
import portrait from '../../../assets/images/icons/portrait.svg';

// Styled
import {
  Wrapper,
  ThumbnailContainer,
  ThumbnailAspectRatioBox,
  ArtTitleWrapper,
  ArtworkTitle,
  ArtistWrapper,
  PlayableWrapper,
  FavoriteButton,
  PurchaseWrapper,
  UpperWrapper,
  LowerWrapper,
  CTA,
  NavMenu,
  SubNavMenu,
  Icon,
  RemoveButton,
  DeleteButton,
  AddMenu,
  AddButton,
  TranscodeInfo,
  MonetizeWrapper,
  LoadingMore,
  OrientationInfo,
} from './styled';

export function VaultCard({ artwork, refetch }) {
  const dispatch = useDispatch();
  const { notify } = useNotifications();
  const [ref, isHovered] = useHover();
  const elementRef = useRef(null);
  const loadMoreRefs = useRef();
  const loadMoreRefsTwo = useRef();
  const onScreen = useIntersect(elementRef);
  const [{ isDragging }, drag] = useDrag({
    type: 'artwork',
    item: { id: artwork.id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const favorites = useFavoritesCollection('favorites');
  const [isFavorite, setIsFavorite] = useState(() => {
    return favorites.isFetched
      ? favorites.data?.artwork?.map((a) => a.id).includes(artwork.id)
      : false;
  });

  useEffect(() => {
    setIsFavorite(() => {
      return favorites.isFetched
        ? favorites.data?.artwork?.map((a) => a.id).includes(artwork.id)
        : false;
    });
  }, [favorites]);

  useEffect(() => {
    if (isDragging) dispatch(openMainMenu());
  }, [isDragging]);

  const navRef = useRef(null);
  const subNavRef = useRef(null);
  const addRef = useRef(null);
  const [isActive, setIsActive] = useMenuClickOutside(navRef, false);
  const [isSubActive, setIsSubActive] = useMenuClickOutside(subNavRef, false);
  const [isAddActive, setIsAddActive] = useMenuClickOutside(addRef, false);
  const userCollections = useManyCollectionsInfinite('userCollections', '', 50);
  const addToCollection = useAddToCollection();
  const addFavoriteMutation = useAddFavorite();
  const removeFavoriteMutation = useRemoveFavorite();
  const deleteArtworkMutation = useDeleteArtwork();
  const user = useUser();

  const tokensAvailable =
    artwork.tokenMetadata.total - artwork.tokenMetadata.purchased; // Checks if this is a favorite on favorites change

  useIntersectionObserver({
    target: loadMoreRefs,
    onIntersect: userCollections?.fetchNextPage,
    enabled: userCollections?.hasNextPage,
  });
  useIntersectionObserver({
    target: loadMoreRefsTwo,
    onIntersect: userCollections?.fetchNextPage,
    enabled: userCollections?.hasNextPage,
  });
  const isArtworkOwner =
    user.data?.user?.id === artwork.artist.userId ||
    artwork.artworkType === 'USER_UPLOAD';

  const handleToggleFavorite = () => {
    if (isFavorite) {
      removeFavoriteMutation.mutate({ id: artwork.id });
    } else {
      addFavoriteMutation.mutate({ id: artwork.id });
    }
  };

  const handleCollections = () => {
    setIsSubActive(!isSubActive);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `https://app.blackdove.com/artwork/${artwork.slug || artwork.id}`
    );
    notify({
      status: 'success',
      title: 'Copied to Clipboard',
      message: 'The share link for this artwork was copied to your clipboard.',
    });
    setIsActive(false);
  };

  const handleMonetizeArtwork = () => {
    dispatch(
      openModal({
        modal: 'EDIT_ARTWORK',
        params: { artwork, tabPage: 'pricing' },
      })
    );
    setIsActive(false);
  };

  const handleEditArtwork = () => {
    dispatch(
      openModal({
        modal: 'EDIT_ARTWORK',
        params: { artwork, tabPage: 'details', refetch },
      })
    );
    setIsActive(false);
  };

  const handleDeleteUpload = () => {
    if (confirm('Are you sure you want to delete this artwork?')) {
      deleteArtworkMutation.mutate({ id: artwork.id });
    }
  };

  function addDefaultSrc(ev) {
    ev.target.src = defaultProfile;
  }

  function getArtworkOrientation() {
    const { orientation, sourceFile } = artwork;
    if (orientation === 'portrait') return 'portrait';
    if (!sourceFile?.metadata) return 'landscape';

    const aspectRatio = sourceFile.metadata.width / sourceFile.metadata.height;
    if (aspectRatio > 0.95 && aspectRatio < 1.05) return 'square';
    if (aspectRatio < 1) return 'portrait';
    return 'landscape';
  }

  const orientation = getArtworkOrientation();

  return (
    <>
      <Wrapper>
        <UpperWrapper ref={elementRef}>
          {isArtworkOwner && (
            <>
              <TranscodeInfo>
                {artwork.sourceFile?.status !== 'COMPLETED'
                  ? artwork.sourceFile?.status
                  : artwork.artworkType}
              </TranscodeInfo>
            </>
          )}

          <div style={{ position: 'relative' }}>
            <ThumbnailContainer
              to={`/artwork/${artwork.slug || artwork.id}`}
              ref={drag}
            >
              <ThumbnailAspectRatioBox ref={ref}>
                {artwork.sourceFile?.status !== 'COMPLETED' && (
                  <>
                    <img src={LoadingImage} alt="Incomplete" />
                  </>
                )}
                {artwork.contentType === 'VIDEO' && (
                  <>
                    {artwork.sourceFile?.status === 'COMPLETED' && (
                      <VideoPlayer
                        videoUrl={artwork.media?.video.hls}
                        posterUrl={artwork.media?.image.low.landscape}
                        artistName=""
                        initialize={isHovered || onScreen}
                        rotate={artwork?.orientation === 'portrait'}
                        autoplay={isHovered || onScreen}
                        muted
                        showLogo={false}
                        showLoading={false}
                      />
                    )}
                  </>
                )}
                {artwork.contentType === 'HTML5' && (
                  <img
                    alt={artwork.name}
                    src={artwork.media?.image.low.square}
                  />
                )}
              </ThumbnailAspectRatioBox>
            </ThumbnailContainer>

            {orientation === 'landscape' && (
              <OrientationInfo>
                <img src={landscape} alt="lanscape" />
              </OrientationInfo>
            )}
            {orientation === 'portrait' && (
              <OrientationInfo>
                <img src={portrait} alt="portrait" />
              </OrientationInfo>
            )}
            {orientation === 'square' && (
              <OrientationInfo>
                <Square size="24px" />
              </OrientationInfo>
            )}

            <RemoveButton
              type="button"
              alt="more"
              onClick={() => setIsActive(!isActive)}
            >
              <img src={dots} alt="more" />
            </RemoveButton>

            <NavMenu ref={navRef} visible={isActive}>
              <div>
                <ul>
                  <button type="button" onClick={handleCollections}>
                    <Icon src={arrowLeftIcon} /> Add to collection
                  </button>

                  <SubNavMenu ref={subNavRef} visible={isSubActive}>
                    <ul>
                      {userCollections.data?.pages?.map((page) => (
                        <React.Fragment key={`page-${page.nextOffset}`}>
                          {page?.collections?.map((collection) =>
                            collection.playlistType === 'STANDARD' ? (
                              <>
                                <button
                                  key={collection.id}
                                  type="button"
                                  onClick={() =>
                                    addToCollection.mutate({
                                      id: collection.id,
                                      artworkId: artwork.id,
                                    })
                                  }
                                >
                                  {collection.name}
                                </button>
                              </>
                            ) : null
                          )}
                        </React.Fragment>
                      ))}

                      <LoadingMore ref={loadMoreRefsTwo}>
                        {(userCollections?.isFetchingNextPage ||
                          userCollections?.isLoading) && <Loader size="15px" />}
                      </LoadingMore>
                    </ul>
                  </SubNavMenu>

                  <button type="button" onClick={handleToggleFavorite}>
                    Add to favorites
                  </button>

                  <button type="button" onClick={handleCopy}>
                    Share
                  </button>

                  {isArtworkOwner && (
                    <>
                      <button type="button" onClick={handleEditArtwork}>
                        Edit
                      </button>

                      <button type="button" onClick={handleDeleteUpload}>
                        Delete artwork
                      </button>
                    </>
                  )}
                </ul>
              </div>
            </NavMenu>
          </div>

          <ArtTitleWrapper>
            <ArtworkTitle>{artwork.name}</ArtworkTitle>
            <ArtistWrapper to={`/collectors/${user.data?.user?.id}`}>
              <img
                src={artwork.artist.media.images.avatar}
                onError={addDefaultSrc}
                alt={artwork.artist.displayName}
              />
              <span>{artwork.artist.displayName}</span>
            </ArtistWrapper>
          </ArtTitleWrapper>
        </UpperWrapper>

        <LowerWrapper>
          {!user.data?.user?.artist?.approved &&
            artwork.artworkType !== 'NFT' &&
            artwork.artworkType !== 'OPEN' && (
              <PlayableWrapper>
                <DeleteButton
                  type="button"
                  alt="Delete"
                  onClick={
                    artwork.artworkType === 'USER_UPLOAD' && handleDeleteUpload
                  }
                >
                  <Trash size="30px" />
                </DeleteButton>

                <AddButton
                  type="button"
                  onClick={() => setIsAddActive(!isAddActive)}
                  alt="Add to Collection"
                >
                  <Add size={36} />
                </AddButton>

                <FavoriteButton onClick={handleToggleFavorite}>
                  {isFavorite && <StarSolid size={25} />}
                  {!isFavorite && <StarOutline size={25} />}
                </FavoriteButton>
              </PlayableWrapper>
            )}

          {user.data?.user?.artist?.approved &&
            (artwork.artworkType === 'USER_UPLOAD' ||
              artwork.artworkType === 'PENDING') && (
              <>
                <MonetizeWrapper>
                  <Button
                    color="red"
                    type="button"
                    onClick={handleMonetizeArtwork}
                  >
                    Monetize your art
                  </Button>
                </MonetizeWrapper>
              </>
            )}

          {artwork.artworkType === 'NFT' && (
            <PurchaseWrapper to={`/artwork/${artwork.slug || artwork.id}`}>
              <CTA className="left">
                <div className="header">Price</div>
                <div className="sub">
                  {tokensAvailable > 0
                    ? `${Web3.utils.fromWei(artwork.tokenMetadata.price)} ETH`
                    : 'Sold Out'}
                </div>
              </CTA>

              <CTA className="right">
                <div className="header">Editions</div>
                <div className="sub">{artwork?.tokenMetadata?.total}</div>
              </CTA>
            </PurchaseWrapper>
          )}

          {artwork.artworkType === 'OPEN' && (
            <PurchaseWrapper to={`/artwork/${artwork.slug || artwork.id}`}>
              <CTA>
                <div className="header">Price</div>
                <div className="sub">${artwork?.products?.[0]?.price}</div>
              </CTA>
            </PurchaseWrapper>
          )}
        </LowerWrapper>
      </Wrapper>

      <AddMenu ref={addRef} visible={isAddActive}>
        <div>
          <ul>
            {userCollections.data?.pages?.map((page) => (
              <React.Fragment key={`page-${page.nextOffset}`}>
                {page?.collections?.map((collection) =>
                  collection.playlistType === 'STANDARD' ? (
                    <>
                      <button
                        key={collection.id}
                        type="button"
                        onClick={() =>
                          addToCollection.mutate({
                            id: collection.id,
                            artworkId: artwork.id,
                          })
                        }
                      >
                        {collection.name}
                      </button>
                    </>
                  ) : null
                )}
              </React.Fragment>
            ))}

            <LoadingMore ref={loadMoreRefs}>
              {(userCollections?.isFetchingNextPage ||
                userCollections?.isLoading) && <Loader size="15px" />}
            </LoadingMore>
          </ul>
        </div>
      </AddMenu>
    </>
  );
}

VaultCard.propTypes = {
  artwork: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};
