import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const slideIn = keyframes`
  0% { left: -20px; opacity: 0;}
  50% {left: 20px; opacity: 0.5;}
  100% { left: 48px; opacity: 1;}
`;

const fadeIn = keyframes`
0% { opacity: 0;}
50% { opacity: 0.5;}
100% { opacity: 1;}
`;

export const Slide = styled.div`
  display: flex;
  width: 100%;
  bottom: ${(props) => props.bottom && props.bottom};
  position: absolute;
  left: -1000px;
  animation: ${(props) => props.in && slideIn} 1s linear forwards;
  animation-delay: ${(props) => props.delay && props.delay};

  @media (min-width: 768px) {
    bottom: ${(props) => props.bottomL && props.bottomL};
  }
  @media (min-width: 1024px) {
    bottom: ${(props) => props.bottomXL && props.bottomXL};
  }
  @media (min-width: 1440px) {
    bottom: ${(props) => props.bottomXXL && props.bottomXXL};
  }
  @media (min-width: 1920px) {
    bottom: ${(props) => props.bottomXXXL && props.bottomXXXL};
  }
  @media (min-width: 1024px) and (max-height: 1000px) {
    bottom: ${(props) => props.bottomHeight && props.bottomHeight};
  }
`;

export const Fade = styled.div`
  display: flex;
  width: 100%;
  bottom: ${(props) => props.bottom && props.bottom};
  position: absolute;
  left: 48px;
  opacity: 0;
  animation: ${(props) => props.in && fadeIn} 1s linear forwards;
  animation-delay: ${(props) => props.delay && props.delay};

  @media (min-width: 768px) {
    bottom: ${(props) => props.bottomL && props.bottomL};
  }
  @media (min-width: 1024px) {
    bottom: ${(props) => props.bottomXL && props.bottomXL};
  }
  @media (min-width: 1440px) {
    bottom: ${(props) => props.bottomXXL && props.bottomXXL};
  }
`;
// export const SlideRight = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   width: 100%;
//   bottom: ${(props) => props.bottom && props.bottom};
//   position: absolute;
//   right: -1000px;
//   animation: ${(props) => props.in && slideInRight} 1s linear forwards;
//   animation-delay: ${(props) => props.delay && props.delay};

//   @media (min-width: 768px) {
//     bottom: ${(props) => props.bottomL && props.bottomL};
//   }
// `;

export const Wrapper = styled.div`
  background-color: ${({ bg }) => bg};
  width: 100%;
  margin: auto;
  padding: ${({ padding }) => padding};
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  position: relative;
  margin: ${({ margin }) => margin};
`;

export const HeaderTextWrapper = styled.div`
  position: absolute;
  text-decoration: none;
  ${({ left }) => left && 'left: 0;'}
  ${({ right }) => right && 'right: 0; align-items: flex-end;'}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 24px;
  width: 100%;
  overflow: hidden;
`;

export const DesktopImg = styled.img`
  @media (max-width: 1439px) {
    display: none;
  }
`;

export const TabletImg = styled.img`
  @media (max-width: 768px) {
    display: none;
  }
  @media (min-width: 1440px) {
    display: none;
  }
`;

export const MobileImg = styled.img`
  @media (min-width: 768px) {
    display: none;
  }
`;

export const MobileVideoPlayer = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;
export const DesktopVideoPlayer = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`;

export const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  background: transparent;
  border: 0;
  padding: 0;

  a,
  button {
    text-decoration: none;
    background: transparent;
    border: 0;
    color: #ffffff;
    text-transform: none;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    letter-spacing: -0.015em;
    cursor: pointer;
    padding: 0;
  }

  @media (min-width: 768px) {
    a,
    button {
      padding: 0 0 0 5px;
      font-size: 36px;
    }
  }
`;

export const Title = styled.h1`
  font-family: OpenSans;
  text-transform: none;
  font-style: bold;
  font-weight: 700;
  font-size: 42px;
  line-height: 48px;
  letter-spacing: -0.015em;
  color: #ffffff;
  margin-bottom: ${({ mb }) => mb};

  @media (min-width: 768px) {
    font-size: 72px;
    line-height: 78px;
    letter-spacing: -0.02em;
  }
  @media (min-width: 1024px) {
    font-size: 96px;
    line-height: 96px;
  }
`;

export const Subtitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.025em;
  color: #a5a5a5;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.025em;
    text-align: start;
  }
`;

export const Bar = styled.div`
  width: ${({ width }) => width};
  border-bottom: 6px solid #ffffff;
  animation: ${(props) => props.in && slideIn} 0.5s linear forwards;
  animation-delay: ${(props) => props.delay && props.delay};
  cursor: pointer;
  margin: ${(props) => (props.noMargin ? '0' : '5px 0 0 12px')};

  @media (min-width: 768px) {
    border-bottom: 8px solid #ffffff;
    margin: ${(props) => (props.noMargin ? '0' : '10px 0 0 12px')};
    width: ${({ widthL }) => widthL};
  }
  @media (min-width: 1024px) {
    width: ${({ widthXL }) => widthXL};
  }
`;

export const VideoFrameTwo = styled.div`
  div,
  video,
  img,
  iframe {
    object-fit: ${({ objectFit }) => objectFit};
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: ${({ borderRadius }) => borderRadius};
    z-index: 0;
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    opacity: ${({ opacity }) => opacity};
    border: 0;
  }
`;

export const HeaderBarWrapper = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 24px;
  width: 50%;

  @media (min-width: 768px) {
    padding: 72px;
  }
  @media (min-width: 1440px) {
    padding: 96px;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: ${({ fd }) => fd};
  padding: ${({ padding }) => padding};
  max-width: 1440px;
  margin: ${({ margin }) => margin};
  flex-wrap: ${({ fw }) => fw};
  justify-content: ${({ jc }) => jc};
  background-color: ${({ bg }) => bg};
  gap: ${({ gap }) => gap};
  align-items: ${({ ai }) => ai};
  order: ${({ order }) => order};
  position: ${({ position }) => position};
  text-align: ${({ ta }) => ta};

  @media (min-width: 375px) {
    padding: ${({ paddingM }) => paddingM};
    margin: ${({ marginM }) => marginM};
  }
  @media (min-width: 768px) {
    flex-direction: ${({ fdl }) => fdl};
    padding: ${({ paddingLarge }) => paddingLarge};
    flex-basis: ${({ fbl }) => fbl};
    justify-content: ${({ jcl }) => jcl};
    align-items: ${({ ail }) => ail};
    order: ${({ orderL }) => orderL};
    margin: ${({ marginL }) => marginL};
  }
  @media (min-width: 1440px) {
    flex-direction: ${({ fdxl }) => fdxl};
    flex-basis: ${({ fbxl }) => fbxl};
    margin: ${({ marginXL }) => marginXL};
    padding: ${({ paddingXLarge }) => paddingXLarge};
    gap: ${({ gapXL }) => gapXL};
  }
`;

export const VideoAspectRatioBox = styled.div`
  width: 100%;

  position: relative;
  margin: ${({ margin }) => margin};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};

  @media (min-width: 375px) {
    margin: ${({ marginL }) => marginL};
  }
  @media (min-width: 768px) {
    margin: ${({ marginXL }) => marginXL};
    padding-bottom: ${({ paddingBottomLarge }) => paddingBottomLarge};
  }
  @media (min-width: 1440px) {
    padding-bottom: ${({ paddingBottomXLarge }) => paddingBottomXLarge};
  }
`;

export const ImageAspectRatioBox = styled.div`
  width: 100%;
  height: 88px;
  position: relative;
  @media (min-width: 375px) {
    height: 99px;
  }
  @media (min-width: 768px) {
    height: 182.4px;
  }
  @media (min-width: 1024px) {
    height: 204.8px;
  }
  @media (min-width: 1440px) {
    height: 256.8px;
  }
`;

export const ImageAspectRatioBoxTwo = styled.div`
  width: 50px;
  height: 40px;
  position: relative;
  @media (min-width: 375px) {
    width: 63.75px;
    height: 51px;
  }
  @media (min-width: 768px) {
    width: 108px;
    height: 86.4px;
  }
  @media (min-width: 1024px) {
    width: 136px;
    height: 108.8px;
  }
  @media (min-width: 1440px) {
    width: 201px;
    height: 160.8px;
  }
`;

export const VideoFrame = styled.div`
  img {
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: ${({ borderRadius }) => borderRadius};
  }
`;

export const ScrollIcon = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  img {
    width: 96px;
    height: 96px;
  }
`;

export const HeaderBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
  padding: 96px 48px;
  background: rgba(26, 26, 26, 0.75);
  backdrop-filter: blur(12px);
`;

export const HeaderTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.025em;
  text-align: center;
  color: #ffffff;
  margin-bottom: 24px;
  @media (min-width: 375px) {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.015em;
  }
  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 48px;
    letter-spacing: -0.02em;
  }
  @media (min-width: 1024px) {
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
  }
`;

export const HeaderSubtitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.025em;
  color: #a5a5a5;
  @media (min-width: 375px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media (min-width: 768px) {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.015em;
  }
  @media (min-width: 1440px) {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.02em;
  }
`;

export const ItemTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.025em;
  color: #1a1a1a;
  margin-bottom: 4px;
  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.015em;
  }
`;

export const ItemSub = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.025em;
  color: #777777;
  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const TopTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #1a1a1a;
  margin-bottom: 48px;
  @media (min-width: 768px) {
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
  }
`;

export const VideoSubtext = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.025em;
  color: #3d3d3d;
  margin: 12px 0 24px 0;
`;

export const CaseTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.025em;
  color: #1a1a1a;
  margin-bottom: 24px;
  @media (min-width: 768px) {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.02em;
  }
`;

export const CaseSubtitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.025em;
  color: #3d3d3d;
  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const ContactTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: #ffffff;
  margin-bottom: 24px;
  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 48px;
    letter-spacing: -0.02em;
    margin-bottom: 48px;
  }
`;

export const PositionTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.025em;
  color: #777777;
  margin-bottom: 24px;
  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Name = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.025em;
  color: #ffffff;
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.015em;
  }
`;

export const Email = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.025em;
  color: #ffffff;
  @media (min-width: 768px) {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const ImageAvatar = styled.div`
  margin-bottom: 12px;
  img {
    width: 120px;
    height: 120px;
    border: 4px solid #910048;
    box-sizing: border-box;
    border-radius: 10000px;
    @media (min-width: 768px) {
      width: 240px;
      height: 240px;
    }
  }
`;

export const ButtonWrapperOne = styled.div`
  flex-basis: 50%;
  max-width: 137px;
  @media (max-width: 767px) {
    display: none;
  }
  @media (min-width: 1440px) {
    width: 384px;
    max-width: 384px;
    max-height: 72px;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
`;

export const BackgroundRect = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    #ffffff;
  background-blend-mode: darken, normal;
  transform: matrix(1, 0, 0, -1, 0, 0);
  position: absolute;
  margin-left: 72px;
  width: 85%;
  height: 500px;
  bottom: 0;
`;

export const CaseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  z-index: 1;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`;
