import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { BlackdoveClient } from '@blackdove/utils';
import { useUser } from '../../../../../hooks/data';

// Components
import Dropdown from './HeaderDropDown';
import OutsideAlerter from '../../../../OutsideAlerter';

// Actions
import { toggleMainMenu } from '../../../../../store/mainMenu/actions';
import { device } from '../../../../../configs/DeviceConfig';
import { toggleDeviceMenu } from '../../../../../store/deviceMenu/actions';
import {
  closeSigninMenu,
  toggleSigninMenu,
} from '../../../../../store/signinMenu/actions';
import { openSearch } from '../../../../../store/app/actions';

// Assets
import Search from '../../../../../assets/images/icons/Search.svg';
import logo from '../../../../../assets/images/header/logo.png';
import logoMobile from '../../../../../assets/images/header/logo-mobile.svg';
import IconMenu from '../../../../../assets/images/icons/icon-menu.svg';
import Devices from '../../../../../assets/images/icons/Devices.png';
import IconUserLoggedIn from '../../../../../assets/images/icons/Account_Filled.svg';
import IconUserLoggedOut from '../../../../../assets/images/icons/Account_Outlined.svg';

// Styled
import {
  Wrapper,
  NavStartWrapper,
  Logo,
  LogoMobile,
  NavEndWrapper,
  NavIconWrapper,
  NavStartMenuIcon,
  NavEndCartIconWrapper,
  NavEndCartIcon,
  LoginLink,
  NavEndUserIconWrapper,
  NavEndUserIcon,
} from './styled';

export function Header() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useUser();
  const [isClickDropIcon, setIsClickDropIcon] = useState(false);

  const dropDownList = [
    {
      id: 'collector-profile',
      title: 'Profile',
      hasSubMenu: false,
      onClick: () => navigate(`/collectors/${user.data?.user?.id}`),
    },
    { id: 'account-settings', title: 'Account Settings', hasSubMenu: false },
    { id: 'logout', title: 'Logout', hasSubMenu: false },
  ];
  const dropDownArtistList = [
    {
      id: 'collector-profile',
      title: 'Profile',
      hasSubMenu: false,
      onClick: () => navigate(`/collectors/${user.data?.user?.id}`),
    },
    { id: 'account-settings', title: 'Account Settings', hasSubMenu: false },
    {
      id: 'statistics',
      title: 'Stats',
      hasSubMenu: false,
      onClick: () => navigate('/statistics'),
    },
    { id: 'logout', title: 'Logout', hasSubMenu: false },
  ];
  const dropDownListLogout = [
    {
      id: 'login',
      title: 'Login',
      hasSubMenu: false,
      onClick: () => navigate('/login'),
    },
    {
      id: 'signup',
      title: 'Sign Up',
      hasSubMenu: false,
      onClick: () => navigate('/signup'),
    },
  ];

  const handleClickMenu = () => {
    dispatch(toggleMainMenu());
  };

  const handleSearch = () => {
    dispatch(openSearch());
  };

  const handleClickLogout = () => {
    setIsClickDropIcon(false);
    BlackdoveClient.clearAuth();
    navigate('/');
    window.location.reload();
  };

  const handleClickUserIcon = () => {
    if (user?.data?.user) {
      setIsClickDropIcon(!isClickDropIcon);
    } else {
      handleClickLogout();
    }
  };

  const handleClickDeviceIcon = () => {
    dispatch(closeSigninMenu());
    dispatch(toggleDeviceMenu());
  };

  const handleToggle = () => {
    dispatch(toggleSigninMenu());
  };

  return (
    <>
      <Wrapper>
        <NavStartWrapper>
          {!location.pathname.includes('/account') && (
            <>
              <NavIconWrapper>
                <NavStartMenuIcon src={IconMenu} onClick={handleClickMenu} />
              </NavIconWrapper>
              <NavIconWrapper type="button" onClick={handleSearch}>
                <NavStartMenuIcon src={Search} alt="search" />
              </NavIconWrapper>
            </>
          )}
        </NavStartWrapper>
        <Logo device={device} src={logo} onClick={() => navigate('/home')} />
        <LogoMobile
          device={device}
          src={logoMobile}
          onClick={() => navigate('/home')}
        />
        <NavEndWrapper>
          {user?.data?.user ? (
            <>
              <NavEndCartIconWrapper>
                <NavEndCartIcon src={Devices} onClick={handleClickDeviceIcon} />
              </NavEndCartIconWrapper>
              <OutsideAlerter onClickOutside={() => setIsClickDropIcon(false)}>
                <NavEndUserIconWrapper onClick={handleClickUserIcon}>
                  <NavEndUserIcon
                    src={user?.data?.user ? IconUserLoggedIn : IconUserLoggedIn}
                  />
                </NavEndUserIconWrapper>
                {isClickDropIcon && (
                  <Dropdown
                    listItems={
                      user.data?.user?.artist?.id
                        ? dropDownArtistList
                        : dropDownList
                    }
                    onLogout={handleClickLogout}
                    onClose={() => setIsClickDropIcon(false)}
                  />
                )}
              </OutsideAlerter>
            </>
          ) : (
            <>
              <div className="fill" />
              <LoginLink onClick={handleToggle}>
                <NavEndUserIconWrapper>
                  <NavEndUserIcon
                    src={
                      user?.data?.user ? IconUserLoggedIn : IconUserLoggedOut
                    }
                  />
                </NavEndUserIconWrapper>
                {isClickDropIcon && (
                  <Dropdown
                    listItems={dropDownListLogout}
                    onLogout={handleClickLogout}
                    onClose={() => setIsClickDropIcon(false)}
                  />
                )}
              </LoginLink>
            </>
          )}
        </NavEndWrapper>
      </Wrapper>
    </>
  );
}
