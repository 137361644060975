import styled from '@emotion/styled';

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 25px;
  display: flex;
`;

export const VisualWrapper = styled.div`
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  padding: 4px;
  border: ${({ isChecked }) => !isChecked && '2px solid #ffffff'};
  background: ${({ isChecked }) => (isChecked ? '#910048' : '#444444')};
  cursor: pointer;
  color: white;
  margin-right: 10px;
  border-radius: 4px;
  transition: all 0.2s ease-in;
  text-align: left;

  svg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
  }
`;

export const Input = styled.input`
  display: none;
`;

export const Label = styled.label`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: white;
  display: inline-block;
  transition: all 0.2s ease-in;
  cursor: pointer;
`;

export const HelperText = styled.span`
  display: block;
  font-size: 12px;
  margin-top: 5px;
  color: rgba(0, 0, 0, 0.5);
`;

export const ErrorText = styled.span`
  display: block;
  color: #ffa800;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  padding-left: 22px;
  padding-top: 5px;
`;
