import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';

const DoughnutChart = ({ value }) => (
  <Doughnut
    data={value.data}
    options={{
      fullWidth: true,
      cutoutPercentage: 40,
      circumference: 2 * Math.PI,
      legend: {
        display: true,
        position: 'right',
        fontFamily: '"DINNextLTPro", Helvetica, Arial, sans-serif',
      },
    }}
  />
);

DoughnutChart.propTypes = {
  value: PropTypes.object.isRequired,
};

export default DoughnutChart;
