import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';

import { formatMoney } from '../../../../../util/formatMoney';

import { Button } from '../../../../Button';
import { TextInput, Select, Checkbox } from '../../../../FormNext';

import {
  Step,
  SectionHeading,
  SectionSubHeader,
  InfoWrapper,
  Types,
} from '../../styled';

const nftValidationSchema = yup.object().shape({
  quantity: yup.number().required().min(1),
  price: yup.number().required().min(0.01),
});

const nftInitialValues = {
  quantity: '',
  price: '',
};

const openValidationSchema = yup.object().shape({
  productId: yup.string().required().label('Price'),
  subscription: yup.bool(),
});

export function StepTwo({ artworkData, products, onNext }) {
  return (
    <Step>
      {artworkData.artworkType === 'NFT' && (
        <>
          <InfoWrapper>
            <div>
              <SectionHeading>Minting details</SectionHeading>
              <SectionSubHeader>
                This information will appear on your public profile on
                Blackdove.
              </SectionSubHeader>
            </div>
          </InfoWrapper>
          <Types jcl="flex-end">
            <div className="dud" />
            <Formik
              initialValues={nftInitialValues}
              validationSchema={nftValidationSchema}
              onSubmit={onNext}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Field
                    name="quantity"
                    label="Total To Mint"
                    placeholder="0"
                    min="1"
                    max="1000"
                    type="number"
                    component={TextInput}
                  />
                  <Field
                    name="price"
                    label="Price (ETH)"
                    placeholder="0"
                    min="0.01"
                    step="0.01"
                    type="number"
                    component={TextInput}
                  />
                  <Button type="submit" color="red" disabled={isSubmitting}>
                    Next
                  </Button>
                </Form>
              )}
            </Formik>
          </Types>
        </>
      )}
      {artworkData.artworkType === 'OPEN' && (
        <>
          <InfoWrapper>
            <div>
              <SectionHeading>In app rental price</SectionHeading>
              <SectionSubHeader>
                This is the price at which your artwork is purchased in app as a
                1 year rental.
              </SectionSubHeader>
            </div>
          </InfoWrapper>
          <Types>
            <div className="dud" />
            <Formik
              initialValues={{
                productId: artworkData.productId,
                subscription: artworkData.subscription,
              }}
              validationSchema={openValidationSchema}
              onSubmit={onNext}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Field
                    name="productId"
                    label="Artwork Price (USD)"
                    component={Select}
                  >
                    <option value="">Select a price...</option>
                    {products?.map((p) => (
                      <option key={p.productId} value={p.productId}>
                        {formatMoney(p.price)}
                      </option>
                    ))}
                  </Field>
                  <Field
                    name="subscription"
                    label="Include in subscription"
                    component={Checkbox}
                  />
                  <Button type="submit" color="red" disabled={isSubmitting}>
                    Next
                  </Button>
                </Form>
              )}
            </Formik>
          </Types>
        </>
      )}
    </Step>
  );
}

StepTwo.propTypes = {
  artworkData: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  onNext: PropTypes.func.isRequired,
};
