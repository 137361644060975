import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { useNotifications } from 'reapop';
import { countries, BlackdoveClient } from '@blackdove/utils';

// Hooks
import { useUpdateArtist, useUpdateUser } from '../../../../hooks/mutations';

// Components
import { Button } from '../../../../components/Button';
import { TextInput, TextArea, Select } from '../../../../components/FormNext';
import { ProfileUploader } from '../../../../components/ProfileUploader';

// Assets
import defaultProfilePic from '../../../../assets/images/Profile Pic.png';
import down from '../../../../assets/images/icons/Down.svg';
import close from '../../../../assets/images/icons/Close.svg';

// Utils
import { tags } from '../../../../util/tags';
import { years } from '../../../../util/years';

// Styled
import {
  ProfileImageContainer,
  ProfileImageCenter,
  FormWrapper,
  HeaderSection,
  FlexContainer,
  FormSection,
  Title,
  Description,
  FieldWrapper,
  DropUploadContainer,
  ListWrapper,
  SelectedTabs,
  Tab,
} from '../../styled';

const validationSchema = yup.object().shape({});

export function ArtistForm({ user }) {
  const { artist } = user;
  const fileInput = useRef(null);
  const { notify } = useNotifications();
  const updateArtistMutation = useUpdateArtist();
  const updateUserMutation = useUpdateUser();
  const [initialValues] = useState({
    displayName: artist.displayName,
    slug: artist.slug,
    paypalEmail: artist.paypalEmail,
    jobTitle: artist.jobTitle,
    website: artist.website,
    bio: artist.bio,
    address: artist.address,
    city: artist.city,
    state: artist.state,
    zip: artist.zip,
    country: artist.country,
    receiveNewsletter: artist.receiveNewsletter,
    receiveCommercialOpportunity: artist.receiveCommercialOpportunity,
    receiveCallsForArt: artist.receiveCallsForArt,
    receiveCommissionRequests: artist.receiveCommissionRequests,
    location: artist.location,
    birthYear: artist.birthYear,
    tags: artist.tags || [],
    instagramUsername: artist.instagramUsername,
    twitterUsername: artist.twitterUsername,
    facebookUsername: artist.facebookUsername,
    tiktokUsername: artist.tiktokUsername,
    snapchatUsername: artist.snapchatUsername,
    pronouns: artist.pronouns || [],
    pronounFirst: artist.pronouns?.[0],
    pronounSecond: artist.pronouns?.[1],
    givenName: user?.givenName,
    surname: user?.surname,
  });
  const [allowedTypes] = useState([
    'video/mp4',
    'video/quicktime',
    'image/jpeg',
    'image/gif',
    'image/png',
    'image/bmp',
  ]);
  const [maxFileSizeMB] = useState(10);
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [tagsList, setTagsList] = useState({
    boxOne: tags
      ? tags
          ?.filter((e) => !artist.tags?.includes(e))
          ?.sort()
          ?.map((item) => item)
      : [],
    boxTwo: artist.tags ? artist.tags?.map((e) => e) : [],
  });
  const [hidden, setHidden] = useState(true);
  const profileImageURL =
    user?.media?.images?.avatar?.high || defaultProfilePic;

  const handleSubmit = async (values, { setSubmitting }) => {
    const params = {
      displayName: values.displayName,
      slug: values.slug,
      paypalEmail: values.paypalEmail,
      jobTitle: values.jobTitle,
      website: values.website,
      bio: values.bio,
      address: values.address,
      city: values.city,
      state: values.state,
      zip: values.zip,
      country: values.country,
      receiveNewsletter: values.receiveNewsletter,
      receiveCommercialOpportunity: values.receiveCommercialOpportunity,
      receiveCallsForArt: values.receiveCallsForArt,
      receiveCommissionRequests: values.receiveCommissionRequests,
      location: values.location,
      birthYear: values.birthYear,
      tags: tagsList.boxTwo,
      instagramUsername: values.instagramUsername,
      twitterUsername: values.twitterUsername,
      facebookUsername: values.facebookUsername,
      tiktokUsername: values.tiktokUsername,
      snapchatUsername: values.snapchatUsername,
      pronouns: [values.pronounFirst, values.pronounSecond],
    };

    const userParams = {
      givenName: values.givenName,
      surname: values.surname,
      displayName: values.displayName,
    };

    await updateArtistMutation.mutateAsync({ artistId: artist.id, params });
    await updateUserMutation.mutateAsync({ params: userParams });
    setSubmitting(false);
  };

  const handleClick = useCallback(() => {
    fileInput.current.click();
  });

  const _onComplete = async (data) => {
    const { id: fileId } = data;
    const result = await BlackdoveClient.put(`/users/${user.id}`, {
      image: fileId,
    });

    if (result.status === 200) {
      notify({
        status: 'success',
        title: 'Save Profile Image',
        message: `Profile image is saved successfully!`,
      });
    } else {
      notify({
        status: 'error',
        title: 'Save Profile Image',
        message: `Profile image can't be saved successfully!`,
      });
    }
  };

  const _onCancel = () => {
    notify({
      status: 'error',
      title: 'Save Profile Image',
      message: `Profile image can't be saved successfully!`,
    });
  };

  const onUpload = (file) => {
    const formData = new FormData();
    const xhr = new XMLHttpRequest();
    const url = 'https://upload.blackdove.io/files';

    formData.append('file', file);

    xhr.addEventListener(
      'readystatechange',
      () => {
        if (xhr.readyState !== xhr.DONE) {
          return;
        }

        if (xhr.status === 200) {
          // parse response JSON
          const jsonRes = JSON.parse(xhr.responseText);

          // if request successful, trigger callback
          if (typeof _onComplete === 'function') {
            _onComplete(jsonRes);
          }
        } else {
          _onCancel();
        }
      },
      false
    );

    xhr.open('POST', url, true);
    const credentials = BlackdoveClient.getCredentials();
    xhr.setRequestHeader(
      'Authorization',
      `${credentials.tokenType} ${credentials.accessToken}`
    );
    xhr.setRequestHeader('cache-control', 'no-cache');

    xhr.send(formData);
  };

  const validateFile = (file) => {
    const fileSize = parseInt(file.size / 1000000, 10);
    const validType = allowedTypes.includes(file.type);
    const validSize = fileSize <= maxFileSizeMB;

    if (!validType) {
      notify({
        status: 'error',
        title: 'Incompatible File',
        message: `That filetype is not allowed. The allowed types are ${allowedTypes.join(
          ', '
        )}`,
      });
      return false;
    }

    if (!validSize) {
      notify({
        status: 'error',
        title: 'Incompatible File',
        message: `That filetype is too big. The maximum file size is ${maxFileSizeMB} MB. Your file is ${fileSize} MB`,
      });
      return false;
    }
    return true;
  };

  const handleChanged = useCallback((e) => {
    e.preventDefault();

    const files = e.target.files || e.dataTransfer.files;
    const file = files[0];
    const fileValid = validateFile(file);

    if (fileValid) {
      const URL = window.URL || window.webkitURL;
      const fileURL = URL.createObjectURL(file);
      setProfileImageFile(fileURL);

      onUpload(file);
    }
  });

  const handleTagClick = (name) => {
    const isInBoxOne = tagsList.boxOne?.includes(name);
    const isBoxLimit = tagsList.boxTwo?.length === 3;

    if (isBoxLimit) {
      setTagsList({
        boxOne: isInBoxOne
          ? tagsList.boxOne
          : [...tagsList.boxOne, name]?.sort(),
        boxTwo: isInBoxOne
          ? tagsList.boxTwo
          : tagsList.boxTwo?.filter((i) => i !== name),
      });
    } else {
      setTagsList({
        boxOne: isInBoxOne
          ? tagsList.boxOne?.filter((i) => i !== name)
          : [...tagsList.boxOne, name]?.sort(),
        boxTwo: isInBoxOne
          ? [...tagsList.boxTwo, name]
          : tagsList.boxTwo?.filter((i) => i !== name),
      });
    }
  };

  return (
    <div>
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FlexContainer>
                <HeaderSection>
                  <Title>Enter your details</Title>
                  <Description>
                    This information will appear on your public profile on
                    Blackdove.
                  </Description>
                </HeaderSection>
                <FormSection>
                  <Field
                    name="displayName"
                    label="Display Name"
                    placeholder="What should we call you"
                    helperText="This is your public persona on the Blackdove platform."
                    component={TextInput}
                    rows={1}
                  />
                  <Field
                    name="givenName"
                    label="First Name"
                    placeholder="John"
                    component={TextInput}
                  />
                  <Field
                    name="surname"
                    label="Last Name"
                    placeholder="Doe"
                    component={TextInput}
                  />
                  <FieldWrapper>
                    <Field
                      name="pronounFirst"
                      label="Pronouns"
                      component={Select}
                    >
                      <option>First</option>
                      <option>He</option>
                      <option>She</option>
                      <option>They</option>
                    </Field>
                    <Field name="pronounSecond" component={Select}>
                      <option>Second</option>
                      <option>Him</option>
                      <option>Her</option>
                      <option>Them</option>
                    </Field>
                  </FieldWrapper>
                  <Field name="location" label="Location" component={Select}>
                    <option value="">Choose where you live</option>
                    {countries.map(({ code, name }) => (
                      <option value={code}>{name}</option>
                    ))}
                  </Field>
                  <Field
                    name="country"
                    label="Country of Origin"
                    component={Select}
                  >
                    <option value="">Choose your nationality</option>
                    {countries.map(({ code, name }) => (
                      <option value={code}>{name}</option>
                    ))}
                  </Field>
                  <Field name="birthYear" label="Birth Year" component={Select}>
                    <option>Choose a year</option>
                    {years.map((name) => (
                      <option value={name}>{name}</option>
                    ))}
                  </Field>
                </FormSection>
              </FlexContainer>

              <FlexContainer>
                <HeaderSection>
                  <Title>Add a profile image</Title>
                  <Description>Recommended size:</Description>
                  <Description>
                    <ul>
                      <li>1000x1000px</li>
                      <li>JPG, PNG or GIF.</li>
                      <li> 10MB max size.</li>
                    </ul>
                  </Description>
                </HeaderSection>
                <FormSection>
                  <ProfileImageContainer onClick={handleClick}>
                    <ProfileImageCenter
                      profileImageURL={profileImageFile || profileImageURL}
                    />
                    <DropUploadContainer>
                      <ProfileUploader
                        allowedTypes={[
                          'video/mp4',
                          'video/quicktime',
                          'image/jpeg',
                          'image/gif',
                          'image/png',
                          'image/bmp',
                        ]}
                        maxFileSizeMB={10000}
                        handleDrop={handleChanged}
                        onUpload={onUpload}
                        inputRef={fileInput}
                      />
                    </DropUploadContainer>
                  </ProfileImageContainer>
                </FormSection>
              </FlexContainer>

              <FlexContainer>
                <HeaderSection>
                  <Title>Creative field</Title>
                  <Description>Tags that you can choose from</Description>
                </HeaderSection>
                <FormSection onClick={() => setHidden(!hidden)}>
                  <ul>
                    <div className="label">Select up to 3</div>
                    {!hidden && (
                      <ListWrapper>
                        {tagsList.boxOne?.map((name) => (
                          <div
                            value={name}
                            onClick={() => handleTagClick(name)}
                          >
                            {name}
                          </div>
                        ))}
                      </ListWrapper>
                    )}
                    <img src={down} alt="down arrow" />
                  </ul>
                  <SelectedTabs>
                    {tagsList.boxTwo?.map((name) => (
                      <Tab>
                        <div className="name">{name}</div>
                        <div
                          className="close"
                          onClick={() => handleTagClick(name)}
                        >
                          <img src={close} alt="remove tag" />
                        </div>
                      </Tab>
                    ))}
                  </SelectedTabs>
                </FormSection>
              </FlexContainer>
              <FlexContainer>
                <HeaderSection>
                  <Title>Add a short bio</Title>
                  <Description>
                    Let collectors know a little bit about you
                  </Description>
                </HeaderSection>
                <FormSection>
                  <Field
                    name="bio"
                    label="Bio"
                    rows={7}
                    resize="none"
                    component={TextArea}
                    maxLength={350}
                  />
                </FormSection>
              </FlexContainer>

              <FlexContainer>
                <HeaderSection>
                  <Title>Social links</Title>
                  <Description>
                    Link your other social media links to display them on your
                    Blackdove profile
                  </Description>
                </HeaderSection>
                <FormSection>
                  <Field
                    name="website"
                    label="Website"
                    placeholder="www.example.com"
                    component={TextInput}
                  />
                  <Field
                    name="instagramUsername"
                    label="Instagram"
                    placeholder="@exampleIG"
                    component={TextInput}
                  />
                  <Field
                    name="twitterUsername"
                    label="Twitter"
                    placeholder="@twitterExample"
                    component={TextInput}
                  />
                  <Field
                    name="tiktokUsername"
                    label="Tiktok"
                    placeholder="@tiktokExample"
                    component={TextInput}
                  />
                  <Field
                    name="snapchatUsername"
                    label="Snapchat"
                    placeholder="snap_example123"
                    component={TextInput}
                  />
                </FormSection>
              </FlexContainer>

              <Button
                type="submit"
                color="red"
                disabled={isSubmitting}
                pending={isSubmitting}
              >
                Save Changes
              </Button>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </div>
  );
}

ArtistForm.propTypes = {
  user: PropTypes.object.isRequired,
};
