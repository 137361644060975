export const UPDATE_FINANCIAL_INFORMATION_REQUEST =
  'UPDATE_FINANCIAL_INFORMATION_REQUEST';
export const UPDATE_FINANCIAL_INFORMATION_SUCCESS =
  'UPDATE_FINANCIAL_INFORMATION_SUCCESS';
export const UPDATE_FINANCIAL_INFORMATION_FAILURE =
  'UPDATE_FINANCIAL_INFORMATION_FAILURE';

export const REDEEM_COUPON_CODE_REQUEST = 'REDEEM_COUPON_CODE_REQUEST';
export const REDEEM_COUPON_CODE_SUCCESS = 'REDEEM_COUPON_CODE_SUCCESS';
export const REDEEM_COUPON_CODE_FAILURE = 'REDEEM_COUPON_CODE_FAILURE';
