import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Components
import { Loader } from '../../components/Loader';
import { ArtistForm } from './components/ArtistForm';
import { Payments } from './components/Payments';
import { Account } from './components/Account';

// Hooks
import { useUser } from '../../hooks/data';

// Styled Components
import { Wrapper, FilterWrapper, ButtonWrapper, DividerTwo } from './styled';

export function Settings() {
  const user = useUser();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Wrapper>
      {user.isLoading ? (
        <Loader />
      ) : (
        <>
          <FilterWrapper>
            <ButtonWrapper
              active={
                pathname === '/settings/account' || pathname === '/settings'
              }
              onClick={() => navigate('/settings')}
            >
              ACCOUNT
            </ButtonWrapper>
            {user.data?.user?.artist?.id && (
              <>
                <DividerTwo />
                <ButtonWrapper
                  active={pathname === '/settings/profile'}
                  onClick={() => navigate('/settings/profile')}
                >
                  PROFILE
                </ButtonWrapper>
                <DividerTwo />
                <ButtonWrapper
                  active={pathname === '/settings/payments'}
                  onClick={() => navigate('/settings/payments')}
                >
                  PAYMENTS
                </ButtonWrapper>
              </>
            )}
          </FilterWrapper>
        </>
      )}
      {!user.isLoading &&
        (pathname === '/settings/account' || pathname === '/settings') && (
          <>
            <Account user={user.data?.user} />
          </>
        )}
      {!user.isLoading &&
        user.data?.user?.artist?.id &&
        pathname === '/settings/profile' && (
          <>
            <ArtistForm user={user.data?.user} />
          </>
        )}
      {!user.isLoading &&
        user.data?.user?.artist?.id &&
        pathname === '/settings/payments' && (
          <>
            <Payments user={user.data?.user} />
          </>
        )}
    </Wrapper>
  );
}
