import React from 'react';
import PropTypes from 'prop-types';

import { Step, TypesOne, TypeChoice, SectionDescription } from '../../styled';

// TODO: Remove when nft fully ready for production.
const nftEnabled = true;

export function StepOne({ onNext }) {
  return (
    <Step>
      <TypesOne>
        {nftEnabled && (
          <TypeChoice className="top" onClick={() => onNext('NFT')}>
            <h3>Mint an NFT</h3>
            <SectionDescription className="top">
              Mint either single or multiple editions of an artwork as an NFT
              secured by the blockchain.{' '}
            </SectionDescription>
            <SectionDescription>
              Minted artwork is not available in the subscription.
            </SectionDescription>
          </TypeChoice>
        )}
        <TypeChoice onClick={() => onNext('OPEN')}>
          <h3>Add to Licensing</h3>
          <SectionDescription className="top">
            Sell open edition non-nft artworks to the blackdove community
            through licensing.{' '}
          </SectionDescription>
          <SectionDescription>
            It is offered as a 1 year rental via in-app purchases.
          </SectionDescription>
        </TypeChoice>
      </TypesOne>
    </Step>
  );
}

StepOne.propTypes = {
  onNext: PropTypes.func.isRequired,
};
