import React from 'react';
import PropTypes from 'prop-types';

// Assets
import trash from '../../../assets/images/icons/Trash.svg';
import defaultImg from '../../../assets/images/default/Collection.png';

// Styled
import {
  Item,
  ThumbnailContainer,
  TextContainer,
  Title,
  SubTitle,
  RemoveButton,
} from './styled';

export const CollectionTile = ({ editable, onRemoveClick, collection }) => {
  function addDefaultSrc(ev) {
    ev.target.src = defaultImg;
  }
  return (
    <Item
      key={`${collection?.id}`}
      to={`/collections/${
        collection.playlistType === 'USER_UPLOAD' ? 'personal' : collection?.id
      }`}
    >
      <ThumbnailContainer>
        <img
          src={collection?.media?.images?.thumbnail || defaultImg}
          onError={addDefaultSrc}
          alt="collection"
        />
      </ThumbnailContainer>
      <TextContainer>
        <Title>{collection?.name}</Title>
        <SubTitle>{`${collection?.total || 0} Artworks`}</SubTitle>
      </TextContainer>
      {editable && (
        <>
          <RemoveButton onClick={(e) => onRemoveClick(e, collection?.id)}>
            <img src={trash} alt="delete" />
          </RemoveButton>
        </>
      )}
    </Item>
  );
};

CollectionTile.propTypes = {
  collection: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  onRemoveClick: PropTypes.func,
};

CollectionTile.defaultProps = {
  editable: false,
  onRemoveClick: null,
};
