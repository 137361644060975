import React from 'react';
import { Formik, Form, Field } from 'formik';
import styled from '@emotion/styled';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { useNotifications } from 'reapop';
import { BlackdoveClient } from '@blackdove/utils';

import { Button } from '../Button';
import { Select } from '../FormNext';

import stripeCountries from '../../constants/countries';

const Wrapper = styled.div`
  margin-bottom: 40px;
`;

const validationSchema = yup.object().shape({
  country: yup.string().required(),
});

const initialValues = {};

export const ConnectForm = () => {
  const { notify } = useNotifications();

  const connectStripeMutation = useMutation(
    async (data) => {
      const result = await BlackdoveClient.post(
        '/v2/users/stripe/connect',
        data.params
      );
      return result.data;
    },
    {
      onSuccess: (data) => {
        notify({
          status: 'info',
          title: 'Stripe Connect',
          message:
            'Setting up your stripe connect account. You will be forwarded to stripe to complete setup.',
        });
        setTimeout(() => {
          window.open(data.url, '_blank');
        }, 5000);
      },
      onError: () => {
        notify({
          status: 'error',
          title: 'Connection Failed',
          message: 'Could not connect your Stripe account to Blackdove.',
        });
      },
    }
  );

  const handleSubmit = async (values, { setSubmitting }) => {
    await connectStripeMutation.mutateAsync({
      params: values,
    });
    setSubmitting(false);
  };

  return (
    <div>
      <Wrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Field name="country" label="Country" component={Select}>
                <option>Select a Country</option>
                {stripeCountries.map(({ label, value }) => (
                  <option value={value}>{label}</option>
                ))}
              </Field>
              <Button
                type="submit"
                color="red"
                disabled={isSubmitting}
                pending={isSubmitting}
              >
                Connect
              </Button>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </div>
  );
};
