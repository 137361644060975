import React, { useState, useRef, useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { BlackdoveClient } from '@blackdove/utils';
import { useNotifications } from 'reapop';
import PropTypes from 'prop-types';

// Components
import { TextInput } from '../../../FormNext/components/TextInput/TextInput';
import { TextArea } from '../../../FormNext/components/TextArea/TextArea';
import { Button } from '../../../Button';
import { ProfileUploader } from '../../../ProfileUploader';

// Assets
import defaultProfilePic from '../../../../assets/images/Profile Pic.png';

// Styled
import {
  SubTitle,
  ProfileImageContainer,
  ProfileImageCenter,
  DropUploadContainer,
  ButtonContainer,
} from '../../styled';

const validationSchemaProfile = yup.object().shape({
  displayName: yup.string(),
  bio: yup.string(),
});
const initialValuesProfile = {
  displayName: '',
  bio: '',
};

export function Profile({ loading, user, handleSkip, onNext }) {
  const { notify } = useNotifications();
  const fileInput = useRef(null);
  const [profileImageFile, setProfileImageFile] = useState(null);
  const maxFileSizeMB = 10;
  const profileImageURL =
    user?.media?.images?.avatar?.high || defaultProfilePic;

  const allowedTypes = [
    'video/mp4',
    'video/quicktime',
    'image/jpeg',
    'image/gif',
    'image/png',
    'image/bmp',
  ];

  const handleClick = useCallback(() => {
    fileInput.current.click();
  });

  const _onComplete = async (data) => {
    const { id: fileId } = data;
    const result = await BlackdoveClient.put(`/users/${user.id}`, {
      image: fileId,
    });

    if (result.status === 200) {
      notify({
        status: 'success',
        title: 'Save Profile Image',
        message: `Profile image is saved successfully!`,
      });
    } else {
      notify({
        status: 'error',
        title: 'Save Profile Image',
        message: `Profile image can't be saved successfully!`,
      });
    }
  };

  const _onCancel = () => {
    notify({
      status: 'error',
      title: 'Save Profile Image',
      message: `Profile image can't be saved successfully!`,
    });
  };

  const onUpload = (file) => {
    const formData = new FormData();
    const xhr = new XMLHttpRequest();
    const url = 'https://upload.blackdove.io/files';
    formData.append('file', file);

    xhr.addEventListener(
      'readystatechange',
      () => {
        if (xhr.readyState !== xhr.DONE) {
          return;
        }

        if (xhr.status === 200) {
          // parse response JSON
          const jsonRes = JSON.parse(xhr.responseText);

          // if request successful, trigger callback
          if (typeof _onComplete === 'function') {
            _onComplete(jsonRes);
          }
        } else {
          _onCancel();
        }
      },
      false
    );

    xhr.open('POST', url, true);
    const credentials = BlackdoveClient.getCredentials();
    xhr.setRequestHeader(
      'Authorization',
      `${credentials.tokenType} ${credentials.accessToken}`
    );
    xhr.setRequestHeader('cache-control', 'no-cache');

    xhr.send(formData);
  };

  const validateFile = (file) => {
    const fileSize = parseInt(file.size / 1000000, 10);
    const validType = allowedTypes.includes(file.type);
    const validSize = fileSize <= maxFileSizeMB;

    if (!validType) {
      notify({
        status: 'error',
        title: 'Incompatible File',
        message: `That filetype is not allowed. The allowed types are ${allowedTypes.join(
          ', '
        )}`,
      });
      return false;
    }

    if (!validSize) {
      notify({
        status: 'error',
        title: 'Incompatible File',
        message: `That filetype is too big. The maximum file size is ${maxFileSizeMB} MB. Your file is ${fileSize} MB`,
      });
      return false;
    }
    return true;
  };

  const handleChanged = useCallback((e) => {
    e.preventDefault();

    const files = e.target.files || e.dataTransfer.files;
    const file = files[0];
    const fileValid = validateFile(file);

    if (fileValid) {
      const URL = window.URL || window.webkitURL;
      const fileURL = URL.createObjectURL(file);
      setProfileImageFile(fileURL);

      onUpload(file);
    }
  });

  return (
    <>
      <SubTitle>Profile Details</SubTitle>
      <ProfileImageContainer onClick={handleClick}>
        <ProfileImageCenter
          profileImageURL={profileImageFile || profileImageURL}
        />
        <DropUploadContainer>
          <ProfileUploader
            allowedTypes={[
              'video/mp4',
              'video/quicktime',
              'image/jpeg',
              'image/gif',
              'image/png',
              'image/bmp',
            ]}
            maxFileSizeMB={10000}
            handleDrop={handleChanged}
            onUpload={onUpload}
            inputRef={fileInput}
          />
        </DropUploadContainer>
      </ProfileImageContainer>
      <Formik
        initialValues={initialValuesProfile}
        validationSchema={validationSchemaProfile}
        onSubmit={onNext}
      >
        <Form style={{ maxWidth: 480, textAlign: 'start' }}>
          <Field
            name="displayName"
            label="Display Name"
            component={TextInput}
          />
          <Field name="bio" label="Bio" component={TextArea} />
          <ButtonContainer>
            <Button type="button" onClick={handleSkip}>
              Skip
            </Button>
            <Button
              type="submit"
              color={loading ? '' : 'red'}
              disabled={loading}
            >
              {loading ? 'Saving....' : 'Save'}
            </Button>
          </ButtonContainer>
        </Form>
      </Formik>
    </>
  );
}

Profile.propTypes = {
  loading: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  handleSkip: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};
