import React from 'react';
import styled from '@emotion/styled';

// Components
import { HeaderSplash } from '../../components/HeaderSplash';

// Styled
export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .text {
    padding: 24px;
  }
  .contact {
    width: 100%;
    padding: 24px;
  }

  @media (min-width: 768px) {
    width: 50%;
  }
`;

const Privacy = () => {
  return (
    <>
      <HeaderSplash
        padding="84px 24px 24px 24px;"
        paddingLarge="96px 36px 36px 36px;"
      >
        <h1>Privacy Policy</h1>
      </HeaderSplash>
      <Wrapper>
        <div className="text">
          <p>
            The purpose of the Blackdove Privacy Policy is to inform our users
            about the information that we collect, how we use that information
            and the ways users can control how that information is used or
            shared. The entire policy can be found below. To make it a bit
            easier to digest our Privacy Policy, we want to highlight some key
            items. For information about users&apos; obligations and use of the
            Blackdove® digital art service, please see our Terms of Use.
          </p>
          <p>
            You have significant control over the information that is shared
            with other Blackdove users and the public through the privacy
            settings for your profile:
          </p>
          <ul>
            <li>
              We do not sell or give your email address to other companies for
              their marketing purposes without your permission.
            </li>
            <li>
              Information that you post in our community and through our social
              networking features is available to members of the public.
            </li>
            <li>
              We collect information about the art played on Blackdove to make
              sure artists and copyright owners get paid. We create content
              reports to ensure copyright use is documented.
            </li>
            <li>
              We collect information about the channels, artworks and artists
              you view, like, share or bookmark, and the information you provide
              about yourself, to personalize your Blackdove experience through
              suggestions of art, artists, galleries and other content you may
              enjoy, as well as advertisements and social networking features.
              This information is publicly available, unless your profile is
              marked private.
            </li>
            <li>
              Anyone can search for, and view, channels that you create on
              Blackdove. If your profile is private, then other people can find
              one of your channels in a search, but your profile will not be
              linked to that gallery.
            </li>
            <li>
              Please read the Blackdove Privacy Policy below in its entirety to
              learn more about the information that we collect, how we use and
              share this information, and your options to control how that
              information is used or shared.
            </li>
          </ul>
          <h2>PRIVACY POLICY</h2>
          <p>
            This Privacy Policy is effective as of January 30, 2014, for
            Blackdove, Inc. and its subsidiaries (&quot;Blackdove&quot;
            &quot;we&quot; &quot;us&quot; or &quot;our&quot; It applies to the
            United States operations for the Blackdove® digital art service (the
            &quot;Service&quot; that we offer through our website located at
            www.Blackdove.com, supported mobile devices, consumer electronics
            devices, devices connected to or installed in an automobile,
            automotive sound systems and other mechanisms.
          </p>
          <p>
            We will continue to evaluate this Privacy Policy against new
            technologies, business practices, and our users&apos; needs, and may
            make changes to the Privacy Policy accordingly. Please check this
            page periodically for updates. If we make any material changes to
            this Privacy Policy, we will post the updated Privacy Policy here
            and notify you by email or by means of a notice on our website.
          </p>
          <h3>The types of information we collect</h3>
          <p>
            There are several areas where you can submit information to us, and
            some features that automatically collect information from the users
            of our Service and visitors to our website. To enable you to enjoy
            certain features of our Service, we collect certain types of
            information, including personally identifiable information, during
            your interactions with our Service. For example, we collect
            information when you:
          </p>
          <ul>
            <li>Register to use our Service;</li>
            <li>
              Enter information in your Profile, including the people you are
              following and the people whom you allow to follow you;
            </li>
            <li>
              Post comments on other Blackdove users&apos; pages, use community
              and social networking features that are part of our Service, or
              send us email;
            </li>
            <li>
              Participate in polls, contests, or other features of the Service,
              or respond to offers or advertisements on the Service; and
            </li>
            <li>Sign up to receive email newsletters.</li>
          </ul>
          <p>
            When you register, we ask you to provide certain information, which
            includes (but may not be limited to) your email address, birth year,
            gender, and zip code, as well as a password for your viewer account.
            If you choose to subscribe to fee-based portions of the Service, you
            will also be asked to provide (at a minimum) your name and credit
            card information.
          </p>
          <p>Information we receive from third parties</p>
          <p>
            We may receive information about you from third parties and store it
            in our databases with other information (including personally
            identifiable information) that you provide to us. Some examples of
            these third parties are listed below:
          </p>
          <ul>
            <li>
              Service partners and advertisers that make features available
              through the Service;
            </li>
            <li>
              Service partners through whom we allow registered users to access
              the Service;
            </li>
            <li>
              Marketing companies that create professional marketing lists or
              sell other marketing data;
            </li>
            <li>
              Other websites or applications that you have registered to use, or
              that people you know have registered to use, when we allow those
              websites or applications to interact through the Service to
              provide personalized services to you. In some cases, those
              websites or applications may automatically provide us with
              information about you to facilitate personalization unless you use
              the controls available on those websites or applications to
              opt-out of such sharing.
            </li>
          </ul>
          <p>
            Blackdove is not responsible for, and will assume no liability, if a
            business partner or other entity collects, uses or shares any
            information about you in violation of its own privacy policy or
            applicable law.
          </p>
          <p>Information that we receive from other users</p>
          <p>
            We may collect information about you from people who have stored
            information about you when they elect to use our social networking
            or community features, or when they enable connectivity with another
            website or application where they have stored such information. For
            instance, your friends may have stored information about you in
            places such as their friend lists, address books, or photos on our
            website and other sites with which the Service interacts.
          </p>
          <p>
            We also collect your email address when people you know share a
            station with you, but we will not add your name to any of our
            mailing lists as a result of this feature.
          </p>
          <p>Automatic Data Collection</p>
          <p>
            Viewing Activity: Blackdove keeps track of your viewing activity.
            Blackdove uses software that is designed to ensure that artists and
            copyright owners receive applicable royalties for the art you view.
            This software identifies and counts the artwork you have accessed.
            Blackdove also collects information about channels, artists and art
            you have viewed or in which you have expressed an interest for
            advertising and social networking features.
          </p>
          <p>
            Service partners: When you access the Service through a service
            partner, we may add select aspects of your registration information
            (such as your email address), so that we can identify which of our
            service partners or other sign-in pages to send to you when you log
            on to the Service.
          </p>
          <p>
            Information about your computer or device: We may also collect
            information about the computer, mobile or other devices you use to
            access and view to the Service. For example, our servers receive and
            record information about your computer and browser, including
            potentially your IP address, browser type, and other software or
            hardware information. If you access the Service from a mobile or
            other device, we may collect a unique device identifier assigned to
            that device or other transactional information for that device.
          </p>
          <p>
            Tracking technology: We use what are commonly called
            &quot;cookies,&quot; a small data string our server writes to your
            hard drive, and other tracking technology to help us manage and
            track your interaction with Blackdove Services. These technologies
            collect non-personally identifiable information to improve Blackdove
            Services, including to keep count of return visits to our website or
            our advertisers&apos; or partners&apos; websites, collect and report
            on aggregate statistical information, save your password and login
            information, and / or display advertising or content based on your
            interests. We may also capture other data such as search criteria
            and results, date, time, connection speed and IP addresses. Third
            parties whose products or services are accessible or advertised via
            the Service may also use cookies, and we advise you to check their
            privacy policies for information about their cookies and other
            privacy practices.
          </p>
          <p>
            Advertisers, and the third-party advertising companies with whom we
            or our advertisers partner to deliver ads to you, may place or
            recognize a unique cookie or similar technology on your browser in
            order to collect non-personally identifiable information about your
            visits to the Service
          </p>
          <h3>How we use the information we collect</h3>
          <p>
            We use the information that we collect for the following purposes:
          </p>
          <ul>
            <li>
              To customize and personalize the advertising and the content you
              see.
            </li>
            <li>
              To enhance the viewer experience in our network of websites. We
              use tracking information to determine how well each page and
              station performs overall based on aggregate viewer demographics
              and traffic patterns to those pages and channels. This helps us
              continue to build a better service for you.
            </li>
            <li>
              To fulfill your requests for certain products and services, such
              as sending out electronic newsletters and enabling you to
              participate in and renew paid services, polls, contests, and
              message boards.
            </li>
            <li>
              To send you information that you agreed to receive about topics
              that we think will be of interest to you.
            </li>
            <li>
              To alert you to the latest developments and features on our
              Service.
            </li>
            <li>
              To pay artists and copyright owners for artwork you hear, by
              reporting viewing information to copyright licensing agencies.
              These reports contain aggregated data only, and do not include
              your personally identifiable information.
            </li>
          </ul>
          <h3>How the information we collect is shared</h3>
          <p>
            We may make your personally identifiable information available to
            other companies, websites, applications or people. For example we
            may share:
          </p>
          <ul>
            <li>
              Information you choose to provide in the course of your use of the
              Service by participating in community and social networking
              features such as forums, channel sharing, viewer opinions and
              reviews, or other forms of public communication and interaction.
            </li>
            <li>
              Information you allow us to share when you register with us, or
              through a subsequent affirmative election.
            </li>
            <li>
              Information used when we hire or partner with third parties to
              provide specialized services on our behalf, such as credit card
              processing, sweepstakes management and prize fulfillment, data
              processing, customer/support services and other products or
              services that we choose to make available to our registered users.
            </li>
            <li>
              Information about the artists, artwork, and channels you have
              created or viewed to via the Service. If your profile is private,
              then other people can find one of your channels in a search, but
              your profile will not be linked to that station; however, if
              someone enters your entire email address into our search system,
              they will be able to see a list of all the channels created under
              that email address.
            </li>
            <li>
              Your registration data, when we jointly offer certain features or
              services with third parties, such as service partners that make
              features available through the Service, business partners through
              whom we allow registered users to access Service, advertisers, or
              marketing companies. When you use these features or services, you
              grant us permission to pass your registration information back to
              that service partner. Such third parties may have different data
              collection, use and sharing practices than us, and you should
              review their applicable privacy policies.
            </li>
            <li>
              We may share your information with a third party when we jointly
              offer a service or a feature with that third party, such as
              connectivity with other websites or applications, to provide
              personalization to the Service.
            </li>
            <li>
              If you unsubscribe for a mailing that we send to you on behalf of
              a third party, we may share your removal instructions with the
              third party so that your email address may be added to that
              company&apos;s list of people to whom they will not send emails
              directly.
            </li>
            <li>
              We may share your information in connection with a merger between
              Blackdove and another entity, or in the event of a transfer of all
              or some of our assets to another company.
            </li>
            <li>
              We may share your information in order to (i) protect or defend
              the legal rights or property of Blackdove, our business partners,
              employees, agents and contractors (including enforcement of our
              agreements); (ii) protect the safety and security of Blackdove
              users or members of the public including acting in urgent
              circumstances; (iii) protect against fraud or risk management
              purposes; or (iv) comply with the law or legal process.
            </li>
          </ul>
          <p>
            We also may use and share non-personally identifiable information,
            such as general demographic or location information, or information
            about the computer or device from which you access the Service.
            Additionally, we may de-identify personally identifiable information
            and share it in a de-identified or aggregated form with third
            parties, advertisers and/or business partners in order to analyze
            Service usage, improve the Blackdove Services and your viewer
            experience, or for other similar purposes. The use and disclosure of
            such information is not subject to any restrictions under this
            Privacy Policy.
          </p>
          <p>
            How your information may be shared with other users and the public:
          </p>
          <p>
            Unless you make your Profile private, it is visible to other users
            and to the public. You may post favorite artwork, bookmarks,
            channels or other biographical information on your Profile Page.
            When you are making the decision to be public or private, please
            keep in mind that as a result of our partnerships, including,
            Facebook&apos;s Instant Personalization, some of the people who may
            be looking at your profile or your activities on the Service may be
            your Blackdove friends, or your friends on other social networks,
            including Facebook. In addition, such friends may share information
            about you with other third parties, including their friends on other
            social networks.
          </p>

          <h3>How we protect your information</h3>
          <p>
            We have implemented commercially reasonable physical and electronic
            security measures to protect against the loss, misuse, and
            alteration of your personally identifiable information. Despite our
            efforts, however, no security measures are perfect or impenetrable.
          </p>

          <h3>What are my privacy options and how can I change my settings?</h3>
          <p>
            To modify your registration information: Click on the your email
            address in the upper right-hand corner of the page and select
            Settings. On that page, you can change all your registration
            information (including your email address and password). Make sure
            to hit Save changes when you&apos;re done
          </p>
          <p>
            To change your email preferences and opt-out of newsletters,
            promotional or marketing correspondence: If you&apos;d like to stop
            receiving marketing emails from Blackdove, sign in to your account,
            click your email address in the top right corner and select
            Settings. Select Notices and uncheck Send me personalized
            recommendations and tips
          </p>
          {/*
          <p>For more detailed information on how to adjust your privacy settings and email preferences, visit: http://blackdove.zendesk.com</p>
          */}

          <h3>Contact us:</h3>
          <p>
            If you have any questions about this Privacy Policy, or the privacy
            practices of Blackdove, email{' '}
            <a href="support@Blackdove.com">support@blackdove.com</a>.
          </p>
        </div>
      </Wrapper>
    </>
  );
};
export default Privacy;
