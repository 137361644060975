import FacebookIcon from '../assets/images/dropdown/icon-facebook.svg';
import ShareLinkIcon from '../assets/images/icons/icon-share.svg';

export const socialMediaList = [
  { id: 'facebook', title: 'Facebook', icon: FacebookIcon, hasSubMenu: false },
  {
    id: 'blackdove',
    title: 'Blackdove',
    icon: ShareLinkIcon,
    hasSubMenu: false,
  },
];
