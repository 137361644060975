import React from 'react';
import PropTypes from 'prop-types';

export function Tiktok({ color, width, height, viewBox }) {
  return (
    <div>
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox={viewBox || '0 0 16 16'}
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.83333 2C2.82674 2 2 2.82674 2 3.83333V12.1667C2 13.1733 2.82674 14 3.83333 14H12.1667C13.1733 14 14 13.1733 14 12.1667V3.83333C14 2.82674 13.1733 2 12.1667 2H3.83333ZM3.83333 3H12.1667C12.6327 3 13 3.36726 13 3.83333V12.1667C13 12.6327 12.6327 13 12.1667 13H3.83333C3.36726 13 3 12.6327 3 12.1667V3.83333C3 3.36726 3.36726 3 3.83333 3ZM8.81185 4.34115C8.68434 4.34663 8.56376 4.40066 8.4748 4.49216C8.38583 4.58367 8.33522 4.70572 8.33333 4.83333V9.33333C8.33333 10.0759 7.74253 10.6667 7 10.6667C6.25747 10.6667 5.66667 10.0759 5.66667 9.33333C5.66667 8.5908 6.25747 8 7 8C7.06626 8.00094 7.13204 7.9887 7.19352 7.96399C7.25501 7.93928 7.31097 7.9026 7.35815 7.85608C7.40534 7.80956 7.4428 7.75412 7.46838 7.69299C7.49395 7.63186 7.50712 7.56626 7.50712 7.5C7.50712 7.43374 7.49395 7.36814 7.46838 7.30701C7.4428 7.24588 7.40534 7.19044 7.35815 7.14392C7.31097 7.0974 7.25501 7.06072 7.19352 7.03601C7.13204 7.0113 7.06626 6.99906 7 7C5.7172 7 4.66667 8.05053 4.66667 9.33333C4.66667 10.6161 5.7172 11.6667 7 11.6667C8.2828 11.6667 9.33333 10.6161 9.33333 9.33333V6.77865C9.75604 7.10566 10.2608 7.33333 10.8333 7.33333C10.8996 7.33427 10.9654 7.32203 11.0269 7.29732C11.0883 7.27262 11.1443 7.23594 11.1915 7.18941C11.2387 7.14289 11.2761 7.08746 11.3017 7.02633C11.3273 6.9652 11.3405 6.8996 11.3405 6.83333C11.3405 6.76707 11.3273 6.70147 11.3017 6.64034C11.2761 6.57921 11.2387 6.52378 11.1915 6.47725C11.1443 6.43073 11.0883 6.39405 11.0269 6.36934C10.9654 6.34464 10.8996 6.3324 10.8333 6.33333C9.99883 6.33333 9.33333 5.66783 9.33333 4.83333C9.33234 4.76649 9.31796 4.70052 9.29103 4.63933C9.26411 4.57814 9.22518 4.52297 9.17656 4.47708C9.12795 4.43119 9.07062 4.39552 9.00798 4.37217C8.94533 4.34882 8.87864 4.33827 8.81185 4.34115Z"
          fill={color}
        />
      </svg>
    </div>
  );
}

Tiktok.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};

Tiktok.defaultProps = {
  color: '#ffffff',
  height: '16',
  width: '16',
  viewBox: '0 0 16 16',
};
