import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 20px;
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: #222222;
  border-radius: 12px;
  margin: 0px 0 12px 0;
  padding: 84px 0 24px 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 12px;
  border-radius: 12px;
  position: relative;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  ont-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
  background: ${({ active }) => active && '#444444'};

  @media (min-width: 375px) {
    font-size: 12px;
    line-height: 16px;
  }
  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1em;
  }
`;

export const DividerTwo = styled.div`
  border: 1px solid #444444;
  transform: rotate(90deg);
  width: 16px;
  height: 0px;
`;
